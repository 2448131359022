import "./styles.css";

import { TimeSlider } from "@vidstack/react";

export const SliderTime = () => {
  return (
    <TimeSlider.Root
      className={`mobileSlider`}
      style={{ height: "20px !important" }}
    >
      <TimeSlider.Track className={"mobileTrack"} />
      <TimeSlider.TrackFill className={`mobileTrackFill mobileTrack`} />
      <TimeSlider.Progress className={`mobileProgress mobileTrack`} />

      <TimeSlider.Thumb className={"mobileThumb"} />

      <TimeSlider.Preview className={"mobilePreview"}>
        <TimeSlider.Value className={"mobileTimeValue"} />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  );
};

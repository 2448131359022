import { Menu, MenuItem } from "@mui/material";
import styled, { css } from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const MenuTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${({ theme }) => theme.colors.neutral[30]};
  width: 100%;
  text-align: left;
  padding: 16px;
`;

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    max-height: 200px !important;
  }
`;

export const StyledMenuItem = styled(MenuItem)``;

export const MenuSubContainer = styled(MenuItem)<{ isSelected?: boolean }>`
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${({ theme }) => theme.colors.secondary[95]};
          color: ${({ theme }) => theme.colors.primary} !important;

          &:hover {
            opacity: 0.5;
          }
        `
      : css`
          &:hover {
            background-color: #f8f6f8;
          }
        `}
`;

export const MenuItemContainer = styled.div<{ isSelected?: boolean }>`
  padding: 16px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${({ theme }) => theme.colors.primary} !important;
        `
      : css``}
`;

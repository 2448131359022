import { StyledPlayerSubTitle, StyledPlayerTitle } from "./styles";

export const PlayerTitle = ({ selected }) => {
  return (
    <>
      <StyledPlayerTitle variant="body3">
        {selected?.moduleName}
      </StyledPlayerTitle>
      <div style={{ marginBottom: "32px", marginTop: "4px" }}>
        <StyledPlayerSubTitle variant="headline7">
          {selected?.name}
        </StyledPlayerSubTitle>
      </div>
    </>
  );
};

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  IconArea,
  InfoLabel,
  InfoTooltip,
  MetricArea,
  Subtitle,
  SubtitleArea,
  Title,
  StyledIconButton,
  StyledPOneLine,
} from "./styles";
import { StyledTitle, StyledText } from "../../utils/common-styled.utils";

const MetricInfo = ({
  iconName,
  title,
  subtitle,
  studentsInfo = 0,
  tooltipText = "",
  type = "students",
}) => {
  return (
    <MetricArea>
      <StyledIconButton size="medium" variant="filled" icon={iconName} />

      <div style={{ width: "100%", wordBreak: "break-all" }}>
        <div style={{ display: "flex" }}>
          <StyledTitle variant="body3" setColor="neutral50">
            {title}
          </StyledTitle>
          {tooltipText ? (
            <InfoTooltip arrow title={tooltipText}>
              <div>
                <Icons name="IconInfoCircle" fill="transparent" />
              </div>
            </InfoTooltip>
          ) : null}
        </div>

        <SubtitleArea>
          <StyledText
            variant="headline5"
            setColor="neutral30"
            style={{ fontWeight: 700 }}
          >
            {subtitle}
          </StyledText>

          {studentsInfo ? (
            <StyledText
              variant="headline8"
              setColor="neutral50"
              style={{ fontWeight: 700 }}
            >
              • {studentsInfo}{" "}
              {type != "students"
                ? studentsInfo > 1
                  ? "treinamentos"
                  : "treinamento"
                : studentsInfo > 1
                ? "alunos"
                : "aluno"}
            </StyledText>
          ) : null}
        </SubtitleArea>
      </div>
    </MetricArea>
  );
};

export default MetricInfo;

import {
  Typography,
  IconButton,
  Divider,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import { DataItem } from "../DataItem";

import {
  CardWrapper,
  HeaderContainer,
  TitleContainer,
  SectionsContainer,
} from "./styled";

interface DataCardProps {
  title: string;
  subTitle?: string;
  editable?: boolean;
  onEditClick?: React.MouseEventHandler<HTMLButtonElement>;
  sections?: {
    title?: string;
    items: { title?: string; description: string | React.ReactNode }[];
  }[];
  children?: React.ReactNode;
}

export const DataCard = ({
  title,
  subTitle,
  editable,
  onEditClick,
  sections,
  children,
}: DataCardProps) => {
  const theme = useTheme();

  return (
    <CardWrapper>
      <HeaderContainer>
        <TitleContainer>
          <Typography
            variant="headline8"
            variantColor={theme.colors.neutral[30]}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography variant="body4" variantColor={theme.colors.neutral[50]}>
              {subTitle}
            </Typography>
          )}
        </TitleContainer>
        {editable && (
          <IconButton
            size="medium"
            variant="line"
            icon="IconPencil"
            style={{ top: -20 }}
            onClick={onEditClick}
          />
        )}
      </HeaderContainer>
      {sections?.map((section, index) => {
        const { title, items } = section;

        const isNotLast = index + 1 !== sections.length;

        return (
          <SectionsContainer key={title || index}>
            {title && (
              <Typography
                variant="headline9"
                children={title}
                style={{ fontWeight: 700 }}
              />
            )}

            {items.map(({ title, description }) => (
              <DataItem key={title} title={title} description={description} />
            ))}

            {isNotLast && <Divider orientation="horizontal" />}
          </SectionsContainer>
        );
      })}
      {children}
    </CardWrapper>
  );
};

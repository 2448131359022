import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Checkbox, FormControlLabel, Radio } from "@mui/material";

import { IAnswers, IExamQuestion } from "./types";

import {
  AnswerArea,
  AnswerIcon,
  AnswerLabel,
  AnswerText,
  ContentArea,
  Divider,
  FeedbackInfo,
  InfoQuestionArea,
  MandatoryInfo,
  MobileQuestionArea,
  MobileQuestionCount,
  MobileQuestionNumber,
  QuestionTitle,
  RightAnswerArea,
} from "./style";

const Answers = ({
  value,
  checked = false,
  rightAnswer = false,
  wrongAnswer = false,
  handleSelectOption,
  isDone = false,
}: IAnswers) => {
  return (
    <>
      <AnswerArea rightAnswer={rightAnswer} wrongAnswer={wrongAnswer}>
        <div>
          {isDone ? (
            <FormControlLabel
              value=""
              control={<Radio />}
              label=""
              disabled={isDone}
              checked={checked}
            />
          ) : (
            <Checkbox
              disabled={isDone}
              onChange={handleSelectOption}
              checked={checked}
              icon={<Icons name="IconCircle" fill="transparent" />}
              checkedIcon={<Icons name="IconCircleCheck" fill="transparent" />}
            />
          )}

          <AnswerText
            rightAnswer={rightAnswer}
            wrongAnswer={wrongAnswer}
            variant="body3"
          >
            {value}
          </AnswerText>
        </div>
        {rightAnswer && (
          <AnswerIcon
            rightAnswer={rightAnswer}
            name="IconCircleCheck"
            fill="transparent"
          />
        )}
        {!rightAnswer && wrongAnswer && (
          <AnswerIcon
            wrongAnswer={wrongAnswer}
            name="IconCircleX"
            fill="transparent"
          />
        )}
      </AnswerArea>
    </>
  );
};

export const ExamQuestion = ({
  number,
  title,
  alternatives = [],
  handleSelectOption,
  isMandatory = false,
  isDone = false,
  isView = false,
  isMobile = false,
  totalQuestions = 0,
  disabledMargin = false,
}: IExamQuestion) => {
  const rightAnswer = alternatives?.find(
    ({ userAnswer, rightAnswer }) => rightAnswer && userAnswer
  );
  const gradeAnswer = alternatives?.find(({ rightAnswer }) => rightAnswer);

  return (
    <ContentArea disabledMargin={disabledMargin}>
      <InfoQuestionArea isMandatory={isMandatory} isFeedback={isDone}>
        {isView || isMobile
          ? null
          : isDone &&
            isMandatory && (
              <FeedbackInfo rightAnswer={rightAnswer} variant="body4">
                <Icons
                  name={rightAnswer ? "IconCheck" : "IconX"}
                  fill="transparent"
                />
                {rightAnswer ? "Acertou" : "Errou"}
              </FeedbackInfo>
            )}
        {isMandatory && !isMobile && (
          <MandatoryInfo variant="body4">* Questão obrigatória</MandatoryInfo>
        )}
        {isMobile && (!isDone || isMandatory || (isDone && isMandatory)) && (
          <MobileQuestionCount>
            {isDone && isMandatory && (
              <FeedbackInfo rightAnswer={rightAnswer} variant="body4">
                <Icons
                  name={rightAnswer ? "IconCheck" : "IconCircleX"}
                  fill="transparent"
                />
                {rightAnswer ? "Acertou" : "Errou"}
              </FeedbackInfo>
            )}
            {!isDone && (
              <MobileQuestionNumber variant="caption">
                Questão {number}/{totalQuestions}
              </MobileQuestionNumber>
            )}
            {isMandatory && (
              <MandatoryInfo variant="body4">
                * Questão obrigatória
              </MandatoryInfo>
            )}
          </MobileQuestionCount>
        )}
      </InfoQuestionArea>
      <MobileQuestionArea>
        <QuestionTitle>
          <Typography variant={isMobile ? "body3" : "body2"}>
            {number}.
          </Typography>
          <Typography variant={isMobile ? "body3" : "body2"}>
            {title}
          </Typography>
        </QuestionTitle>
        {alternatives?.map(
          (
            { value, checked = false, rightAnswer = false, userAnswer = null },
            index
          ) => (
            <Answers
              key={index}
              value={value}
              checked={isView ? false : userAnswer ?? checked}
              handleSelectOption={() =>
                handleSelectOption({
                  questionIndex: number - 1,
                  optionIndex: index,
                })
              }
              rightAnswer={isDone && rightAnswer && userAnswer}
              wrongAnswer={isDone && userAnswer && !rightAnswer}
              isDone={isDone}
            />
          )
        )}
        {isDone && !rightAnswer ? (
          <>
            <Divider />
            <div>
              <AnswerLabel variant="body2">Resposta correta:</AnswerLabel>
              <RightAnswerArea variant="body3">
                {gradeAnswer?.value}
              </RightAnswerArea>
            </div>
          </>
        ) : null}
      </MobileQuestionArea>
    </ContentArea>
  );
};

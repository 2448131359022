import {
  MonitoringErrorBoundary,
  MonitoringManager,
} from "@flash-tecnologia/hros-web-utility";
import { MonitoringLevel } from "@flash-tecnologia/hros-web-utility/dist/monitoring/MonitoringLevel";

const projectDsn =
  "https://2ffa6f9b9986fbbcd2b92768e3f16a38@o266934.ingest.sentry.io/4505822525521920";

export class ErrorBoundary extends MonitoringErrorBoundary {
  project = projectDsn;

  constructor(props) {
    super(props);
  }

  static captureException(
    error: Error,
    severity?: MonitoringLevel,
    extras?: Record<string, any>
  ) {
    MonitoringManager.captureException(projectDsn, error, severity, extras);
  }

  static identifyUser(id: string) {
    MonitoringManager.identifyUser(id);
  }

  componentDidCatch(error: any) {
    MonitoringManager.captureException(this.project, error);
  }
}

import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { SnapItem } from "react-snaplist-carousel";
import styled from "styled-components";

export const MetricsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`;

export const ToggleButton = styled(LinkButton)`
  display: flex;
`;

export const ChartArea = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const ChartTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const ChartSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
  margin-bottom: 24px;
`;

export const ChartInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoText = styled(Typography)`
  margin-left: 26px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    width: 15px;
    stroke: transparent;
  }
  b {
    font-size: 16px;
    margin-right: 4px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChartGraph = styled.div`
  height: 150px;
  width: 140px;
  canvas {
    display: inline-block;
    left: -80px;
    position: relative;
  }
`;

export const SnapGrid = styled(SnapItem)`
  width: 386px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

export const SnapGrid8 = styled(SnapItem)`
  width: 520px;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 24px;
`;

export const ChevronArea = styled.div`
  display: flex;
  button:first-child {
    margin-right: 16px;
  }
`;

export const StepArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CarouselDot = styled.div<{ active?: boolean }>`
  cursor: pointer;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ theme, active }) =>
    active ? theme.colors.secondary[50] : theme.colors.secondary[90]};
`;

export const DotsArea = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    margin-right: 12px;
  }
`;

export const EmptyArea = styled.div`
  width: 116px;
`;

enum EActions {
  SYSTEM_LOADING_START = "SYSTEM_LOADING_START",
  SYSTEM_LOADING_END = "SYSTEM_LOADING_END",
  GET_COURSES_FOR_ADMIN = "GET_COURSES_FOR_ADMIN",
  GET_COURSES_FOR_ADMIN_FILTERED = "GET_COURSES_FOR_ADMIN_FILTERED",
  GET_ALL_LEARNING_OBJECT = "GET_ALL_LEARNING_OBJECT",
  GET_ALL_LEARNING_OBJECT_FILTERED = "GET_ALL_LEARNING_OBJECT_FILTERED",
  GET_CERTIFICATES = "GET_CERTIFICATES",
  GET_CERTIFICATES_FILTERED = "GET_CERTIFICATES_FILTERED",
  GET_CERTIFICATE_BY_ID = "GET_CERTIFICATE_BY_ID",
  FILTER_COURSES_FOR_ADMIN = "FILTER_COURSES_FOR_ADMIN",
  GET_COURSES_MODULES = "GET_COURSES_MODULES",
  GET_COURSE_BY_ID = "GET_COURSE_BY_ID",
  GET_MY_COURSES = "GET_MY_COURSES",
  GET_MY_COURSES_FILTERED = "GET_MY_COURSES_FILTERED",
  GET_COURSE_HIERARCHY = "GET_COURSE_HIERARCHY",
  SAVE_STEP_INFO = "SAVE_STEP_INFO",
  GET_GROUPS_COMPANY = "GET_GROUPS_COMPANY",
  GET_EMPLOYEES_COMPANY = "GET_EMPLOYEES_COMPANY",
  SAVE_CLASS_TO_CREATE = "SAVE_CLASS_TO_CREATE",
  SAVE_CLASS_URL = "SAVE_CLASS_URL",
  UPDATE_MODAL = "UPDATE_MODAL",
  FILTER_CONTENT_FOR_ADMIN = "FILTER_CONTENT_FOR_ADMIN",
  GET_EMPLOYEE_EXAM_MONITORING = "GET_EMPLOYEE_EXAM_MONITORING",
  GET_MANAGE_COURSES_METRICS = "GET_MANAGE_COURSES_METRICS",
  GET_COURSE_METRIC_BY_ID = "GET_COURSE_METRIC_BY_ID",
  GET_EMPLOYEES = "GET_EMPLOYEES",
  GET_EMPLOYEE_BY_ID = "GET_EMPLOYEE_BY_ID",
  GET_EMPLOYEE_BY_ID_FILTERED = "GET_EMPLOYEE_BY_ID_FILTERED",
  UPDATE_MANAGE_TAB = "UPDATE_MANAGE_TAB",
  GET_DEFAULT_NOTIFICATIONS = "GET_DEFAULT_NOTIFICATIONS",
}

interface IActions {
  type: EActions;
  payload?: any;
}

export { IActions, EActions };

import styled from "styled-components";

import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { IType } from "./types";

export const IconContainer = styled.div<IType>`
  background-color: ${({ theme, type }) =>
    type != "success"
      ? theme.colors.feedback.error.light1
      : theme.colors.secondary.light.light2};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: -35px;
`;

export const StyledIcon = styled(Icons)<IType>`
  color: ${({ theme, type }) =>
    type != "success" ? "#C96C01" : theme.colors.primary};
`;

export const Title = styled(Typography)<IType>`
  color: ${({ theme, type }) =>
    type != "success" ? "#C96C01" : theme.colors.primary};
  font-weight: bold !important;
  margin: 22px 0 16px;
`;

export const SubTitle = styled(Typography)<IType>`
  margin-bottom: 22px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
  span {
    font-weight: bold !important;
    color: ${({ theme, type }) =>
      type != "success" ? "#C96C01" : theme.colors.primary};
  }
`;

export const FooterText = styled(Typography)`
  margin: 24px 0 32px;
  font-weight: bold !important;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const ContentArea = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    text-align: center;
    padding: 0 20px;
  }
`;

export const FooterArea = styled.div`
  width: 80%;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
`;

export const ErrorDescription = styled(Typography)`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const StyledButton = styled(Button)`
  margin: 32px auto;
`;

import styled from "styled-components";
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const GenerateButton = styled(Button)`
  align-self: center !important;
  margin: auto;
`;

export const BannerArea = styled.div`
  display: flex;
  align-items: center;
`;

export const BannerTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: bold !important;
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

export const BannerInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[60]};
  margin-left: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const PreviewArea = styled.div`
  transform: scale(0.1);
  transform-origin: top left;
  width: 130px;
  height: 82px;
  cursor: default;
  user-select: none;
`;

import { useMemo, useState } from "react";

import { IconButton, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledTypography,
  StyledFlex,
  StyledAccordionContainer,
  StyledFlexBetween,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButton,
  StyledAddTextButton,
  StyledAddIconButton,
  TotalClassTag,
  AccordionButtons,
  ContentInfo,
  ContentTitle,
  ContentDescription,
} from "./styled";

import { WarningDeleteModal2 } from "@components/Modals/WarningDeleteModal2";
import { AddModuleModal } from "../AddModuleModal";
import { AddClassModal } from "../AddClassModal";

import thumbPdf from "../../assets/thumbPdf.png";
import thumbVideo from "../../assets/thumbVideo.png";
import thumbPodcast from "../../assets/thumbPodcast.png";
import { StyledText } from "@utils";

export const AddingClassesModule = ({
  courseId,
  courseHierachy,
  learningObject,
  isShared = false,
}) => {
  const [addModule, setAddModule] = useState<any>(false);
  const [addClass, setAddClass] = useState<any>(false);
  const [moduleData, setModuleData] = useState<any>({});
  const [classData, setClassData] = useState<any>({});

  const [modal, setModal] = useState<any>({
    open: false,
    type: null,
    variant: null,
    parentId: null,
    level: null,
    _id: null,
    title: null,
  });

  const getTime = (time) => {
    const totalMinutes = Math.floor(Math.floor(time) / 60);

    const seconds = Math.floor(time) % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours === 0 ? "00" : hours}:${
      minutes === 0 ? "00" : minutes
    }:${seconds}`;
  };

  const renderClassesAccordion = ({ chapters }) => {
    return chapters?.map(
      ({ _id, name, description, learningObjectId }, index) => {
        const classInfo = learningObject?.find(
          ({ _id }) => _id === learningObjectId
        );

        return (
          <StyledAccordionContainer key={index}>
            <StyledAccordionSummary>
              <StyledFlexBetween>
                <StyledFlex>
                  <div>
                    <TotalClassTag variant="disabled">
                      {classInfo?.type === "video"
                        ? "Vídeo"
                        : classInfo?.type === "podcast"
                        ? "Podcast"
                        : classInfo?.type === "pdf"
                        ? "Pdf"
                        : classInfo?.type === "exam"
                        ? "Avaliação"
                        : "Artigo"}
                    </TotalClassTag>
                    <StyledTypography variant="headline7">
                      {name}
                    </StyledTypography>
                  </div>
                </StyledFlex>

                <StyledFlex
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AccordionButtons>
                    <Menu
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                      type="select"
                      options={[
                        {
                          onClick: (e: any) => {
                            setAddClass(true);
                            setClassData({
                              _id,
                              courseId,
                              name,
                              description,
                              learningObjectId,
                              parentId: _id,
                              setAddClass,
                              type: "edit",
                            });
                          },
                          children: (
                            <>
                              <Icons name="IconPencil" />
                              <StyledText
                                variant="body3"
                                setColor="neutral30"
                                style={{ marginLeft: "8px" }}
                              >
                                Editar aula
                              </StyledText>
                            </>
                          ),
                        },
                        {
                          onClick: (e: any) => {
                            setModal({
                              open: true,
                              type: "delete",
                              variant: "deleteClass",
                              parentId: "",
                              level: 2,
                              _id: _id,
                              title: "",
                            });
                          },
                          children: (
                            <>
                              <Icons name="IconTrash" />
                              <StyledText
                                variant="body3"
                                setColor="neutral30"
                                style={{ marginLeft: "8px" }}
                              >
                                Excluir aula
                              </StyledText>
                            </>
                          ),
                        },
                      ]}
                    >
                      <IconButton
                        size="medium"
                        variant="line"
                        icon="IconDotsVertical"
                      />
                    </Menu>
                    <IconButton
                      size="medium"
                      variant="filled"
                      icon="IconChevronDown"
                    />
                  </AccordionButtons>
                </StyledFlex>
              </StyledFlexBetween>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
              <ContentInfo>
                <img
                  src={
                    classInfo?.type === "video"
                      ? thumbVideo
                      : classInfo?.type === "podcast"
                      ? thumbPodcast
                      : thumbPdf
                  }
                  alt={classInfo?.type ?? null}
                />
                <div>
                  <ContentTitle variant="body3">
                    {classInfo?.name ?? ""}
                  </ContentTitle>
                  <ContentDescription variant="body4">
                    {classInfo?.duration ? getTime(classInfo?.duration) : ""}
                  </ContentDescription>
                </div>
              </ContentInfo>
            </StyledAccordionDetails>
          </StyledAccordionContainer>
        );
      }
    );
  };

  const renderModuleAccordion = useMemo(() => {
    return courseHierachy?.classes?.map(({ _id, name, chapters }, index) => (
      <StyledAccordionContainer key={index}>
        <StyledAccordionSummary>
          <StyledFlexBetween>
            <StyledFlex>
              <div>
                <TotalClassTag variant="disabled">
                  {chapters?.length} aulas
                </TotalClassTag>
                <StyledTypography variant="headline7">{name}</StyledTypography>
              </div>
            </StyledFlex>
            <AccordionButtons>
              <Menu
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                type="select"
                options={[
                  {
                    onClick: (e: any) => {
                      setAddModule(true);
                      setModuleData({ name, _id });
                    },
                    children: (
                      <>
                        <Icons name="IconPencil" />
                        <StyledText
                          variant="body3"
                          setColor="neutral30"
                          style={{ marginLeft: "8px" }}
                        >
                          Editar módulo
                        </StyledText>
                      </>
                    ),
                  },
                  {
                    onClick: (e: any) => {
                      setModal({
                        open: true,
                        type: "delete",
                        variant: "deleteModule",
                        parentId: "",
                        level: 1,
                        _id: _id,
                        title: "",
                      });
                    },
                    children: (
                      <>
                        <Icons name="IconTrash" />
                        <StyledText
                          variant="body3"
                          setColor="neutral30"
                          style={{ marginLeft: "8px" }}
                        >
                          Excluir módulo
                        </StyledText>
                      </>
                    ),
                  },
                ]}
              >
                <IconButton
                  size="medium"
                  variant="line"
                  icon="IconDotsVertical"
                />
              </Menu>
              <IconButton
                size="medium"
                variant="filled"
                icon="IconChevronDown"
              />
            </AccordionButtons>
          </StyledFlexBetween>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {renderClassesAccordion({ chapters })}
          <StyledButton
            size="large"
            variant="secondary"
            style={{ width: "100%" }}
            onClick={() => {
              setAddClass(true);

              setClassData({
                courseId,
                parentId: _id,
                setAddClass,
                type: "add",
                isShared,
              });
            }}
          >
            <StyledAddIconButton name="Add" />
            <StyledAddTextButton variant="body3">
              Adicionar nova aula
            </StyledAddTextButton>
          </StyledButton>
        </StyledAccordionDetails>
      </StyledAccordionContainer>
    ));
  }, [addClass, courseHierachy, learningObject]);

  return (
    <div style={{ marginBottom: "20px" }}>
      {renderModuleAccordion}
      <StyledButton
        size="large"
        variant="secondary"
        style={{ width: "100%", marginTop: "40px" }}
        onClick={() => {
          setAddModule(true);
          setModuleData({});
        }}
      >
        <StyledAddIconButton name="Add" />
        <StyledAddTextButton variant="body3">
          Adicionar novo módulo
        </StyledAddTextButton>
      </StyledButton>

      <AddClassModal
        open={addClass}
        closeModal={() => setAddClass(false)}
        classData={classData}
        courseHierachy={courseHierachy}
      />
      <AddModuleModal
        open={addModule}
        closeModal={() => setAddModule(false)}
        courseId={courseId}
        courseHierachy={courseHierachy?._id}
        name={moduleData?.name}
        moduleId={moduleData?._id}
      />
      <WarningDeleteModal2 modal={{ ...modal, courseId, setModal }} />
    </div>
  );
};

import { StyledText, StyledTitle } from "@utils";
import "./styles.css";

import {
  Menu,
  type MenuPlacement,
  usePlaybackRateOptions,
  useMediaRemote,
} from "@vidstack/react";

import {
  MenuContainer,
  MenuItemContainer,
  MenuSubContainer,
  MenuTitleContainer,
} from "./styled";

import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

export interface SettingsProps {
  placement: MenuPlacement;
}

export function SpeedMenu({ placement }: SettingsProps) {
  const options = usePlaybackRateOptions(),
    label = options.selectedValue + "x";

  return (
    <Menu.Root className="menu">
      <Tooltip title={"Velocidade"} placement="top">
        <Menu.Button className={`menuButton button`}>
          <StyledTitle variant="headline8" setColor="primary">
            {label}
          </StyledTitle>
        </Menu.Button>
      </Tooltip>
      <Menu.Content className="menu" placement={placement}>
        <SpeedSubMenu options={options} />
      </Menu.Content>
    </Menu.Root>
  );
}

function SpeedSubMenu({ options }) {
  const remote = useMediaRemote();

  return (
    <Menu.Root>
      <Menu.Button className={"submenuButton"}>
        <MenuTitleContainer>
          <StyledText variant="body3">Velocidade</StyledText>
          <Icons
            size={14}
            name={"IconChevronDown"}
            style={{ fill: "transparent" }}
          />
        </MenuTitleContainer>
      </Menu.Button>

      <Menu.Content className={"submenu"}>
        <MenuContainer>
          {options.map(({ label, value }) => (
            <MenuSubContainer
              key={value}
              isSelected={options.selectedValue == value}
              onClick={() => {
                remote.changePlaybackRate(parseFloat(value));
              }}
            >
              <MenuItemContainer isSelected={options.selectedValue == value}>
                <StyledText variant="body3">
                  {label === "Normal" ? "1x" : label}
                </StyledText>
              </MenuItemContainer>
            </MenuSubContainer>
          ))}
        </MenuContainer>
      </Menu.Content>
    </Menu.Root>
  );
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { useFormik } from "formik";
import * as yup from "yup";

import { Icons, Toggle } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledSectionContainer,
  StyledTypography,
  StyledTitle,
  StyledBoxAdornment,
  Container,
  PageTitle,
} from "./styled";

import { Grid, Skeleton } from "@mui/material";
import dispatchToast from "../../../../utils/dispatchToast";
import { track } from "../../../../utils/segment";
import Banner from "@components/Banner";
import { PageTemplate } from "@components/PageTemplate";
import { getObjDiffs } from "@utils";
import { trpc } from "@api/client";

export const SecondStep = ({
  data,
  learningObject,
  type,
  contentType,
  steps,
  contentId,
  currentStep,
  updateContentMutate,
  isLoadingButton,
}) => {
  const utils = trpc.useContext();

  const navigate = useNavigate();

  const [associateCourses, setAssociateCourses] = useState(0);
  const [saveAsDraft, setSaveAsDraft] = useState(false);

  const { selectedCompany } = useSelectedCompany();

  useEffect(() => {
    if (Object.keys(data).length) {
      Object.keys(data).map((cl) => {
        if (cl === "isShared") formik.setFieldValue(cl, data?.[cl], true);
        return;
      });
    }
  }, [data]);

  useEffect(() => {
    if (learningObject?.length) {
      const data = learningObject?.filter(({ _id }) => _id === contentId);
      setAssociateCourses(data[0]?.associateCourses ?? 0);
    }
  }, [learningObject]);

  const validationSchema = yup.object({
    isShared: yup.boolean().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      isShared: false,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      const params = {
        ...values,
        type: contentType,
        status:
          associateCourses > 0
            ? data?.status
            : saveAsDraft
            ? "draft"
            : data?.status ?? "draft",
      } as any;

      const hasChangedAnyValue = getObjDiffs(values, {
        isShared: data?.isShared,
      });

      if (contentId) {
        if (!!!hasChangedAnyValue) {
          navigate(
            `/lms/manage-courses/${type}/content/${contentType}/${
              currentStep + 1
            }/${contentId}`
          );
          return;
        }

        updateContentMutate(
          {
            contentId,
            params,
          },
          {
            onSuccess: () => {
              utils.learningObject.getContentById.invalidate();
              utils.learningObject.getAllLearningObject.invalidate();

              if (saveAsDraft) {
                dispatchToast({
                  content: "Conteúdo salvo com sucesso!",
                  type: "success",
                });
                navigate("/lms/manage-courses");
              } else {
                navigate(
                  `/lms/manage-courses/${type}/content/${contentType}/${
                    currentStep + 1
                  }/${contentId}`
                );
              }
            },
            onError: (e: any) => {
              const learningObjectNotExists =
                e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

              const message = learningObjectNotExists
                ? "Objeto de aprendizado não existe."
                : "Erro ao tentar atualizar o conteúdo, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
        return;
      }
    },
  });

  const isEditing = type === "edit";

  const titleByType =
    contentType === "video"
      ? `${isEditing ? "Editar" : "Criar"} vídeo`
      : contentType === "podcast"
      ? `${isEditing ? "Editar" : "Criar"} podcast`
      : contentType === "pdf"
      ? `${isEditing ? "Editar" : "Criar"} PDF`
      : contentType === "exam"
      ? `${isEditing ? "Editar" : "Criar"} avaliação`
      : `${isEditing ? "Editar" : "Criar"} artigo`;

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_cancel_clicked`,
            });
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_savedraft_clicked`,
            });

            if (associateCourses === 0) setSaveAsDraft(true);
            formik.handleSubmit();
          },
        },
        goBackProps: {
          title: (
            <>
              Voltar
              <Icons name="IconArrowLeft" fill="transparent" />
            </>
          ),
          callback: () => {
            if (contentId) {
              navigate(
                `/lms/manage-courses/${type}/content/${contentType}/${
                  currentStep - 1
                }/${contentId}`
              );
            } else {
              navigate(
                `/lms/manage-courses/${type}/content/${contentType}/${
                  currentStep - 1
                }`
              );
            }
          },
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: !!isLoadingButton,
          callback: () => {
            setSaveAsDraft(false);

            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_next_clicked`,
            });

            const isValidToSubmit = formik?.validateForm();

            if (Object.keys(isValidToSubmit)?.length) {
              dispatchToast({
                content: "Alguns campos estão incorretos. Favor verificar",
                type: "error",
              });
            }

            formik.handleSubmit();
          },
        },
      }}
    >
      <Container>
        <PageTitle variant="headline6">{titleByType}</PageTitle>

        {associateCourses > 0 ? (
          <Banner
            type="info"
            icon="IconAlertTriangle"
            title="Edição de conteúdo limitada"
            subTitle="Esse conteúdo está associado a treinamentos e sua edição poderia impactar o progresso dos alunos, portanto, é permitido editar somente as informações básicas. Se a alteração for fundamental, crie um novo conteúdo e associe à aula, mas lembre-se de informar os alunos."
            hasHideBanner={true}
            style={{ marginBottom: "32px" }}
          />
        ) : (
          <></>
        )}

        <Grid container>
          <Grid item sm={12} md={5} lg={4}>
            <div style={{ marginRight: "24px" }}>
              <StyledTitle variant="headline7">Configurações</StyledTitle>
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <StyledTypography variant="body3">
                  Adicione as configurações de compartilhamento entre empresas.
                </StyledTypography>
              </div>
            </div>
          </Grid>

          <Grid item sm={12} md={7} lg={8}>
            <form onSubmit={formik.handleSubmit}>
              <StyledSectionContainer style={{ marginBottom: "20px" }}>
                <StyledTypography variant="headline8">
                  Compartilhar{" "}
                  {contentType === "exam"
                    ? "avaliação"
                    : contentType === "pdf"
                    ? "PDF"
                    : contentType === "article"
                    ? "artigo"
                    : contentType}{" "}
                  com todas as empresas do grupo
                </StyledTypography>
                <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                  <StyledTypography variant="body4">
                    Habilite o botão abaixo caso deseje que o{" "}
                    {contentType === "exam"
                      ? "avaliação"
                      : contentType === "pdf"
                      ? "PDF"
                      : contentType}{" "}
                    seja compartilhado e adicionado para todas as empresas
                    vinculadas a sua organização.
                  </StyledTypography>
                </div>

                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <StyledBoxAdornment
                    title="Habilitar compartilhamento."
                    description={`Ao ativar esta opção, o ${
                      contentType === "exam"
                        ? "avaliação"
                        : contentType === "article"
                        ? "artigo"
                        : contentType
                    } será automaticamente compartilhado com as contas de todas as empresas do grupo. Isso significa que todas as empresas terão acesso e permissão para editar este conteúdo. O ${
                      contentType === "exam"
                        ? "avaliação"
                        : contentType === "article"
                        ? "artigo"
                        : contentType
                    } estará acessível na seção “Gerenciar Treinamentos”, na aba de conteúdos.`}
                    leftAdornment={
                      <Toggle
                        checked={formik.values.isShared}
                        disabled={
                          data?.companyId != selectedCompany?.id ||
                          associateCourses > 0
                        }
                        onChange={({ target: { checked } }) => {
                          formik.handleChange({
                            target: {
                              id: "isShared",
                              value: checked,
                            },
                          });
                        }}
                      />
                    }
                  />
                </div>
              </StyledSectionContainer>
            </form>
          </Grid>
        </Grid>
      </Container>
    </PageTemplate>
  );
};

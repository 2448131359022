import styled from "styled-components";

import { Grid } from "@mui/material";

export const SubContainer = styled.div`
  width: 100%;
  overflow: auto;

  padding: 24px;
  display: flex;
  flex-direction: column;
`;

export const ContentArea = styled.div`
  width: 100%;
  height: 100%;
`;

export const ContentContainer = styled(Grid)`
  height: 100%;
  width: 100%;
  position: relative;
  overflow: scroll;
`;

export const ArticleViewer = styled.div`
  width: 100%;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
`;

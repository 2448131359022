import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const Footer = styled.div``;

export const FooterArea = styled.div`
  background: white;
  width: 100%;

  border-left: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
`;

export const TotalAnswers = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  font-weight: 600 !important;
`;

export const ProgressWrapper = styled.div`
  width: 75px;
  margin-right: 12px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral[100]};

  padding: 16px 24px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  overflow: scroll;
`;

import styled from "styled-components";
import { IconButton, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { MenuItem, Select } from "@mui/material";

export const StyledFlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 311px;
  white-space: normal;
  margin-left: 16px;
`;

export const StyledTag = styled(Tag)``;

export const TitleCardContainer = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledIconButtonExtraSmall = styled(IconButton)`
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const StyledImageContainer = styled.div<{ url: string }>`
  width: 64px;
  height: 64px;
  background: ${({ url }) => `url(${url})`};
  border-radius: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StatusTag = styled(Typography)<{ status?: string }>`
  padding: 4px 12px;
  border-radius: 24px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600 !important;
  background-color: ${({ theme, status }) =>
    status === "published"
      ? theme.colors.feedback.success[90]
      : status === "draft"
      ? theme.colors.feedback.error[90]
      : status === "processing"
      ? theme.colors.feedback.info[90]
      : theme.colors.feedback.error[90]};
  color: ${({ theme, status }) =>
    status === "published"
      ? theme.colors.feedback.success[10]
      : status === "draft"
      ? theme.colors.feedback.error[10]
      : status === "processing"
      ? theme.colors.feedback.info[10]
      : theme.colors.feedback.error[10]};
`;

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const EditLabel = styled(Typography)<{ isDelete?: boolean }>`
  color: ${({ theme, isDelete }) =>
    isDelete ? theme.colors.neutral.light.light1 : ""} !important;
`;

export const StyledFlexRow = styled.div<{ isDelete?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme, isDelete }) =>
    isDelete ? theme?.colors?.feedback?.error[40] : ""};
  svg {
    stroke: ${({ theme, isDelete }) =>
      isDelete ? theme?.colors?.feedback?.error[40] : ""};
  }
`;

import { useParams } from "react-router-dom";

import { trpc } from "@api/client";

import { FirstStep } from "./Components/FirstStep";
import { SecondStep } from "./Components/SecondStep";
import { SecondStepExam } from "./Components/SecondStepExam";
import { ThirdStep } from "./Components/ThirdStep";
import { ThirdStepExam } from "./Components/ThirdStepExam";

const CreateEditCourseContent = () => {
  const {
    currentStep = "",
    contentType = undefined,
    contentId = "",
    type = "",
  } = useParams();

  const steps =
    contentType === "exam"
      ? ["Informações básicas", "Questões", "Configurações"]
      : ["Informações básicas", "Configurações", "Detalhes do conteúdo"];

  const parsedNumber = parseInt(currentStep);

  const { data = {}, isFetching: isGettingContentById } =
    trpc.learningObject.getContentById.useQuery(
      { contentId: contentId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!contentId,
      }
    );

  const { data: learningObject = {}, isFetching: isLoadingGetAllLearning } =
    trpc.learningObject.getAllLearningObject.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
    });

  const { mutate: updateContentMutate, isLoading: isUpdatingContent } =
    trpc.learningObject.updateContent.useMutation({});

  const { mutate: createContentMutate, isLoading: isCreatingContent } =
    trpc.learningObject.createContent.useMutation({});

  const { mutate: createExamMutate, isLoading: isCreatingExam } =
    trpc.exams.createExam.useMutation({});

  return parsedNumber === 0 ? (
    <FirstStep
      data={data}
      learningObject={learningObject}
      type={type}
      contentType={contentType}
      steps={steps}
      currentStep={parsedNumber}
      contentId={contentId}
      updateContentMutate={updateContentMutate}
      createContentMutate={createContentMutate}
      createExamMutate={createExamMutate}
      isLoadingButton={isCreatingContent || isCreatingExam || isUpdatingContent}
      isLoadingScreen={isGettingContentById || isLoadingGetAllLearning}
    />
  ) : parsedNumber === 1 ? (
    contentType != "exam" ? (
      <SecondStep
        data={data}
        learningObject={learningObject}
        type={type}
        contentType={contentType}
        steps={steps}
        currentStep={parsedNumber}
        contentId={contentId}
        updateContentMutate={updateContentMutate}
        isLoadingButton={
          isCreatingContent || isCreatingExam || isUpdatingContent
        }
      />
    ) : (
      <SecondStepExam
        data={data}
        learningObject={learningObject}
        type={type}
        contentType={contentType}
        steps={steps}
        currentStep={parsedNumber}
        contentId={contentId}
        isLoadingScreen={isGettingContentById || isLoadingGetAllLearning}
      />
    )
  ) : parsedNumber === 2 ? (
    contentType != "exam" ? (
      <ThirdStep
        data={data}
        learningObject={learningObject}
        type={type}
        contentType={contentType}
        steps={steps}
        currentStep={parsedNumber}
        contentId={contentId}
        updateContentMutate={updateContentMutate}
        isLoadingButton={
          isCreatingContent || isCreatingExam || isUpdatingContent
        }
        isLoadingScreen={isGettingContentById || isLoadingGetAllLearning}
      />
    ) : (
      <ThirdStepExam
        data={data}
        learningObject={learningObject}
        type={type}
        contentType={contentType}
        steps={steps}
        currentStep={parsedNumber}
        contentId={contentId}
        updateContentMutate={updateContentMutate}
        isLoadingButton={
          isCreatingContent || isCreatingExam || isUpdatingContent
        }
        isLoadingScreen={isGettingContentById || isLoadingGetAllLearning}
      />
    )
  ) : (
    <></>
  );
};

export { CreateEditCourseContent };

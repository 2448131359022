import { Container } from "./styled";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Content } from "./components/Content";

const Mobile = ({
  course,
  courseId,
  modules,
  moduleId,
  selected,
  setSelected,
  isDescription,
  setIsDescription,
  isDone,
  setIsDone,
  viewType,
  setIsExpanded,
  handleDoneContent,
  donePdfArticle,
  classId,
  isGettingCoursesModules,
  isMobile,
}) => {
  return (
    <Container>
      <Header
        selected={selected}
        viewType={viewType}
        courseId={courseId}
        setSelected={setSelected}
        isDescription={isDescription}
        setIsDescription={setIsDescription}
      />

      <Content
        classId={classId}
        course={course}
        viewType={viewType}
        modules={modules}
        moduleId={moduleId}
        courseId={courseId}
        selected={selected}
        setSelected={setSelected}
        isDescription={isDescription}
        setIsExpanded={setIsExpanded}
        donePdfArticle={donePdfArticle}
        exams={{
          handleDoneContent,
          isDone,
          setIsDone,
        }}
        isMobile={isMobile}
      />

      <Footer
        course={course}
        viewType={viewType}
        isGettingCoursesModules={isGettingCoursesModules}
        modules={modules}
        moduleId={moduleId}
        courseId={courseId}
        selected={selected}
        setSelected={setSelected}
        setIsDescription={setIsDescription}
        isDescription={isDescription}
      />
    </Container>
  );
};

export { Mobile };

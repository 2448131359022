import { useNavigate } from "react-router-dom";

import { StyledText, StyledTitle, pageSizeOptions } from "@utils";

import {
  EmployeeDataArea,
  EmployeeName,
  EmployeePic,
  ItensPerPageArea,
  NumberInfo,
  PaginationArea,
  PaginationContainer,
  ProgressArea,
  ProgressBar,
  ProgressPercent,
  ProgressWrapper,
  StyledMenuItem,
  StyledSelectField,
  StyledTypography,
} from "./styled";

import { Tooltip } from "@mui/material";
import {
  DataGrid,
  IconButton,
  Pagination,
} from "@flash-tecnologia/hros-web-ui-v2";

interface TableGridProps {
  data: any[];
  rawData: any[];
  page: number;
  setPage: any;
  pageSize: number;
  setPageSize: any;
  total: number;
  loading: boolean;
}

export const EmployeesTable = ({
  data,
  rawData,
  loading,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { name = "" },
        },
      }) => (
        <EmployeeDataArea>
          <EmployeePic>{name?.charAt(0)?.toUpperCase()}</EmployeePic>
          <EmployeeName variant="body3">{name}</EmployeeName>
        </EmployeeDataArea>
      ),
    },
    {
      Header: "Quantidade de treinamentos",
      accessor: "totalCourses",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { totalCourses = 0 },
        },
      }) => <NumberInfo variant="body3">{totalCourses}</NumberInfo>,
    },
    {
      Header: "Não iniciados",
      accessor: "totalNotStart",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { totalNotStarted = 0 },
        },
      }) => <NumberInfo variant="body3">{totalNotStarted}</NumberInfo>,
    },
    {
      Header: "Em andamento",
      accessor: "totalDoing",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { totalDoing = 0 },
        },
      }) => <NumberInfo variant="body3">{totalDoing}</NumberInfo>,
    },
    {
      Header: "Concluídos",
      accessor: "totalDone",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { totalDone = 0 },
        },
      }) => <NumberInfo variant="body3">{totalDone}</NumberInfo>,
    },
    {
      Header: "Taxa de conclusão",
      accessor: "donePercent",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { donePercent = 0 },
        },
      }) => (
        <ProgressArea>
          <ProgressWrapper>
            <ProgressBar totalDone={donePercent}>
              <span />
            </ProgressBar>
          </ProgressWrapper>
          <ProgressPercent variant="body4">
            {donePercent >= 100 ? 100 : donePercent}%
          </ProgressPercent>
        </ProgressArea>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { _id = null },
        },
      }) => (
        <Tooltip arrow title="Ver detalhes do aluno">
          <div>
            <IconButton
              onClick={() =>
                navigate(`/lms/manage-courses/detail-student/${_id}`)
              }
              size="small"
              variant="line"
              icon="IconUser"
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  const totalPages = Math.ceil(total / pageSize);

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum aluno encontrado.",
        }}
        customPagination={({ gotoPage, setPageSize: setComponentPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > total ? total : page * pageSize}
                &nbsp;de&nbsp;{total || 0}&nbsp;
                {total === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={totalPages}
                onChange={(_, value) => {
                  const currentPage = value;

                  setPage(currentPage);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
    </>
  );
};

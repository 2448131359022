import { useState } from "react";

import { Icons, useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

import { ClassesButton, MobileAccordion } from "./styled";
import { CourseDescription } from "../../../Common/CourseDescription";
import { Accordion } from "../../../Common/Accordion";

export const Footer = ({
  course,
  viewType,
  isGettingCoursesModules,
  modules,
  moduleId,
  courseId,
  selected,
  setSelected,
  setIsDescription,
  isDescription,
}) => {
  const [open, setOpen] = useState<any>(false);

  const isPhone = useMediaQuery("(max-width: 480px)");

  return (
    <>
      <ClassesButton
        onClick={() => setOpen(!open)}
        variant="primary"
        size="small"
      >
        <Icons name={open ? "IconX" : "IconMenu2"} />
        Aulas
      </ClassesButton>

      <MobileAccordion
        anchor={isPhone ? "bottom" : "right"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <CourseDescription
          course={course}
          viewType={viewType}
          isGettingCoursesModules={isGettingCoursesModules}
        />
        {modules && (
          <Accordion
            viewType={viewType}
            modules={modules}
            moduleId={moduleId}
            courseId={courseId}
            selected={selected}
            setSelected={setSelected}
            setIsDescription={setIsDescription}
            isDescription={isDescription}
            isGettingCoursesModules={isGettingCoursesModules}
            isMobile={true}
            openMobileDrawer={setOpen}
          />
        )}
      </MobileAccordion>
    </>
  );
};

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Modal } from "@mui/material";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalArea = styled.div`
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
`;

export const Label = styled(Typography)`
  margin-top: 19px;
  color: #83727d;
`;

export const InfoLabel = styled(Typography)`
  color: #83727d;
`;

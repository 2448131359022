import { useState, useEffect } from "react";

import { StyledSearch, SearchContainer } from "./styled";

export const Search = ({ onChange, disabled }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inputValue === undefined) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [inputValue]);

  const handleOnChange = (value) => {
    if (onChange) {
      setInputValue(value);
    }
  };

  const handleOnBlur = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };
  const handleOnInput = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };

  return (
    <SearchContainer>
      <StyledSearch
        onChange={(e) => handleOnChange(e.target.value)}
        onSearchChange={handleOnBlur}
        onInputChange={handleOnInput}
        label="Buscar por treinamento"
        disabled={disabled}
      />
    </SearchContainer>
  );
};

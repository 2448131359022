import styled from "styled-components";

export const ContentContainer = styled.div<{ type?: string }>`
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: ${({ type }) => (type == "exam" ? "hidden" : "auto")};
  padding: ${({ type }) => (type == "exam" ? "0px" : "24px")};
  display: flex;
  flex-direction: column;
`;

export const ArticleViewer = styled.div`
  width: 100%;
  max-height: 350px;
  min-height: 350px;
  overflow-y: auto;
`;

import { useMemo, useState } from "react";

import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";
import { cloneDeep } from "lodash-es";
import { Filters } from "./Filters";
import { NoStudent } from "./NoStudent";
import { Search } from "./Search";
import { StudentPerformanceTable } from "./StudentPerformanceTable";

export const StudentPerformanceTab = ({ data, isGettingCourseById }) => {
  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const filters = [
    {
      accessor: "status",
      label: "Status",
      options: [
        {
          label: "Em andamento",
          value: "progress",
        },
        {
          label: "Não iniciado",
          value: "notStarted",
        },
        {
          label: "Concluído",
          value: "done",
        },
      ],
      type: "checkbox",
    },
  ] as any;

  const filteredData = useMemo(() => {
    if (!search && !Object.keys(filterStates)?.length) return data;

    let dataToFilter = cloneDeep(data);

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const byName = item?.name?.toLowerCase().includes(search.toLowerCase());
        return byName;
      });
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter =
        dataToFilter?.filter((item) => {
          for (const key in filterStates) {
            const itemValue = item[key] !== undefined ? item[key] : undefined;

            if (itemValue === undefined) return false;

            if (Array.isArray(itemValue)) {
              if (!itemValue.some((el) => filterStates[key].includes(el))) {
                return false;
              }
            } else {
              const parsedItem =
                typeof item?.[key] === "object" && item?.[key] != null
                  ? item?.[key]?.name
                  : item?.[key];

              if (!filterStates[key].includes(parsedItem)) return false;
            }
          }
          return true;
        }) || [];
    }

    return dataToFilter;
  }, [data, search, filterStates]);

  return (
    <div>
      <Search onChange={setSearch} disabled={isGettingCourseById} />

      <Filters
        disabled={isGettingCourseById}
        filters={filters}
        onFilter={setFilterStates}
      />

      {!data?.length &&
      !isGettingCourseById &&
      !!!Object.keys(filterStates)?.length &&
      !!!search ? (
        <EmptyState
          buttonText={""}
          description={<NoStudent />}
          buttonProps={{ size: "small", hidden: true }}
        />
      ) : (
        <StudentPerformanceTable
          data={filteredData || []}
          rawData={data || []}
          loading={isGettingCourseById}
        />
      )}
    </div>
  );
};

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useRegisterRoutes } from "@flash-tecnologia/hros-web-utility";

import { ConfigurationContext } from "../context";

import { RoutesGuard } from "./routesGuard";
import { OpenRoutesGuard } from "./openRoutesGuard";

import {
  AddingEditClassesModules,
  Certificate,
  CreateEditCourse,
  CreateEditCertificate,
  CreateEditCourseContent,
  DetailCourse,
  DetailExam,
  DetailStudent,
  MainStudy,
  ManageCourse,
  MyCourse,
} from "../page";

export const routes = {
  //RH
  pageManageCourses: "/lms/manage-courses",
  pageDetailCourse: "/lms/manage-courses/detail-course/:courseId",
  pageDetailStudent: "/lms/manage-courses/detail-student/:employeeId",
  pageCreateEditCourse:
    "/lms/manage-courses/createEditCourse/:type/:step/:courseId",
  pageCreateEditContent:
    "/lms/manage-courses/:type/content/:contentType/:currentStep",
  pageCreateEditClassModules:
    "/lms/manage-courses/addingEditContent/:courseId/:chapterId/:currentStep",
  pageCertificates: "/lms/manage-courses/certificates",
  pageDetailExam:
    "/lms/manage-courses/detail-exam/:courseId/:examId/:studentId",
  pageMainStudyPreview:
    "/lms/manage-courses/:courseId/:moduleId/:classId/:viewType",
  //Student
  pageMyCourses: "/lms/my-courses",
  pageMainStudy: "/lms/my-courses/:courseId/:moduleId/:classId/:viewType",
} as const;

export const slugRoutes = [
  {
    slug: "people_lms_view_manage_courses",
    routes: [
      routes.pageManageCourses,
      routes.pageDetailStudent,
      routes.pageDetailCourse,
      routes.pageCreateEditCourse,
      routes.pageCreateEditContent,
      routes.pageCreateEditContent + "/:contentId",
      routes.pageCreateEditClassModules,
      routes.pageCreateEditClassModules + "/:classId",
      routes.pageCertificates,
      routes.pageCertificates + "/:type/:certificateId",
      routes.pageDetailExam,
    ],
  },
];

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: routes.pageManageCourses,
      hideHeader: false,
    },
    {
      path: routes.pageDetailStudent,
      hideHeader: false,
    },
    {
      path: routes.pageDetailCourse,
      hideHeader: false,
    },
    {
      path: routes.pageCreateEditCourse,
      hideHeader: true,
    },
    {
      path: routes.pageCreateEditContent,
      hideHeader: true,
    },
    {
      path: routes.pageCreateEditContent + "/:contentId",
      hideHeader: true,
    },
    {
      path: routes.pageCreateEditClassModules,
      hideHeader: false,
    },
    {
      path: routes.pageCreateEditClassModules + "/:classId",
      hideHeader: false,
    },
    {
      path: routes.pageCertificates,
      hideHeader: false,
    },
    {
      path: routes.pageCertificates + "/:type/:certificateId",
      hideHeader: false,
    },
    {
      path: routes.pageDetailExam,
      hideHeader: false,
    },
    {
      path: routes.pageMainStudyPreview,
      hideHeader: true,
    },
    {
      path: routes.pageMainStudy,
      hideHeader: true,
    },
  ]);

  return (
    <ConfigurationContext>
      <BrowserRouter>
        <Routes>
          <Route element={<OpenRoutesGuard />}>
            <Route path={routes.pageMyCourses} element={<MyCourse />} />
            <Route path={routes.pageMainStudy} element={<MainStudy />} />
            <Route path={routes.pageMainStudyPreview} element={<MainStudy />} />
          </Route>
          <Route element={<RoutesGuard />}>
            <Route path={routes.pageManageCourses} element={<ManageCourse />} />
            <Route path={routes.pageCreateEditCourse}>
              <Route index element={<CreateEditCourse />} />
            </Route>

            <Route
              path={routes.pageDetailStudent}
              element={<DetailStudent />}
            />
            <Route path={routes.pageDetailCourse} element={<DetailCourse />} />
            <Route path={routes.pageDetailExam} element={<DetailExam />} />
            <Route path={routes.pageCreateEditContent}>
              <Route index element={<CreateEditCourseContent />} />
              <Route path=":contentId" element={<CreateEditCourseContent />} />
            </Route>
            <Route path={routes.pageCreateEditClassModules}>
              <Route index element={<AddingEditClassesModules />} />
              <Route path=":classId" element={<AddingEditClassesModules />} />
            </Route>

            <Route path={routes.pageCertificates}>
              <Route index element={<Certificate />} />
              <Route
                path=":type/:certificateId"
                element={<CreateEditCertificate />}
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigurationContext>
  );
};

export default AppRouter;

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  DescriptionText,
  Divider,
  Info,
  InfoArea,
  InfoText,
  IntroductionArea,
  Title,
} from "./style";
import { IIntroductionExam } from "./types";

export const IntroductionExam = ({
  title,
  totalQuestions = 0,
  minimunGrade = 0,
  description = "",
  attemptsLeft = 0,
  totalAttempts = 0,
  isView = false,
}: IIntroductionExam) => {
  return (
    <IntroductionArea>
      <Title variant="headline7">{title}</Title>
      <InfoArea>
        {isView ? (
          <>
            {totalQuestions ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconMessage2" fill="transparent" />
                  {totalQuestions} questões
                </InfoText>
                {totalAttempts ? <Divider>•</Divider> : null}
              </Info>
            ) : null}
            {minimunGrade ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconCheck" fill="transparent" />
                  Nota mínima: {minimunGrade}
                </InfoText>
                {totalAttempts ? <Divider>•</Divider> : null}
              </Info>
            ) : null}
            {totalAttempts ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconAB" fill="transparent" />
                  {totalAttempts}{" "}
                  {totalAttempts > 1 ? "tentativas" : "tentativa"}
                </InfoText>
              </Info>
            ) : null}
          </>
        ) : (
          <>
            {totalQuestions ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconMessage2" fill="transparent" />
                  {totalQuestions} questões
                </InfoText>
                {minimunGrade || attemptsLeft ? <Divider>•</Divider> : null}
              </Info>
            ) : null}
            {minimunGrade ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconCheck" fill="transparent" />
                  Nota mínima: {minimunGrade}
                </InfoText>
                {attemptsLeft ? <Divider>•</Divider> : null}
              </Info>
            ) : null}
            {attemptsLeft ? (
              <Info>
                <InfoText variant="body4">
                  <Icons name="IconAB" fill="transparent" />
                  {totalAttempts - attemptsLeft + 1}ª de {totalAttempts}{" "}
                  tentativas
                </InfoText>
              </Info>
            ) : null}
          </>
        )}
      </InfoArea>
      <DescriptionText variant="body3">{description}</DescriptionText>
    </IntroductionArea>
  );
};

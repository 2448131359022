import { useNavigate } from "react-router-dom";

import { StyledText, StyledTitle, track } from "@utils";
import { Content } from "./Components/Content";

import {
  Button,
  IconButton,
  Icons,
  PageContainer,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { ButtonArea, FlexBetween } from "./styled";
import { DataGrid } from "@components/DataGrid";

export const ManageCourse = () => {
  const navigate = useNavigate();

  return (
    // <DataGrid loading={true} />

    <PageContainer>
      <FlexBetween>
        <StyledTitle variant="headline6" setColor="neutral20">
          Gerenciar treinamentos
        </StyledTitle>

        <ButtonArea>
          <Tooltip arrow title="Configurações gerais de treinamentos">
            <div>
              <IconButton
                onClick={() => {
                  track({
                    name: "company_lms_coursessettings_clicked",
                  });
                  navigate(`/lms/manage-courses/certificates`);
                }}
                size="small"
                variant="line"
              >
                <Icons name="IconSettings" fill="transparent" />
              </IconButton>
            </div>
          </Tooltip>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              track({ name: "company_lms_createcourse_clicked" });
              navigate(`/lms/manage-courses/createEditCourse/create/0/0`);
            }}
          >
            <StyledText variant="body3" style={{ fontWeight: 700 }}>
              Criar treinamento
            </StyledText>
            <Icons name="Add" color="#fff" />
          </Button>
        </ButtonArea>
      </FlexBetween>

      <Content />
    </PageContainer>
  );
};

import { useNavigate } from "react-router-dom";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { IconButton } from "@mui/material";

import { NextPreviousMenu } from "../../../Common/NextPreviousMenu";

import { MobileTitleArea, PlayerSubTitle } from "./styled";

export const Header = ({
  selected,
  viewType,
  courseId,
  setSelected,
  isDescription,
  setIsDescription,
}) => {
  const navigate = useNavigate();

  const isNotExam = selected?.type != "exam";
  return (
    <>
      <MobileTitleArea>
        <IconButton onClick={() => navigate(`/lms/my-courses`)}>
          <Icons name="IconArrowLeft" />
        </IconButton>
        <PlayerSubTitle variant="headline7">
          {selected
            ? `${selected?.moduleName} | ${selected?.name}`
            : "Descrição"}
        </PlayerSubTitle>
      </MobileTitleArea>

      {isNotExam ? (
        <NextPreviousMenu
          viewType={viewType}
          selected={selected}
          courseId={courseId}
          setSelected={setSelected}
          isDescription={isDescription}
          setIsDescription={setIsDescription}
        />
      ) : (
        <></>
      )}
    </>
  );
};

import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledImageContainer = styled.div<{ url: string }>`
  width: 64px;
  height: 64px;

  background: ${({ url }) => `url(${url})`};
  border-radius: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const StyledTag = styled(Tag)`
  white-space: nowrap;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  margin-right: 12px;
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

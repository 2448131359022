import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseButton,
  HeaderArea,
  StyledButtonContainer,
  StyledContainer,
  StyledIcon,
  StyledIconContainer,
  StyledModal,
  StyledSubTitle,
  StyledTitle,
  SuccessMessage,
} from "./styled";

import { trpc } from "@api/client";

export const GenerateExamsReportModal = ({
  open,
  onClose,
  exams,
  examId,
  reportType,
}) => {
  const { courseId = "" } = useParams();

  const [employee, setEmployee] = useState<any>({});
  const [successs, setSuccess] = useState<any>(false);
  const [hasClicked, setHasClicked] = useState<any>(false);
  const [examName, setExamName] = useState<any>(null);

  useEffect(() => {
    if (exams?.length) {
      const name = exams?.find((item) => item?.value === examId)?.label || "";
      setExamName(name);
    }
  }, [exams, examId]);

  const handleClose = () => {
    setSuccess(false);
    setHasClicked(false);
    onClose();
  };

  const { isFetching: isFetchingEmployee } =
    trpc.employee.getEmployeeByIdFromCompany.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setEmployee(data);
      },
    });

  const { isFetching: gettingReportExams } =
    trpc.reports.getReportEmployeeExamsAnswersById.useQuery(
      { courseId, examId },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled:
          !!hasClicked && !!courseId && !!examId && reportType === "answers",
        onSuccess: () => {
          setSuccess(true);
          setHasClicked(false);
        },
      }
    );

  const { isFetching: gettingReportAnalysis } =
    trpc.reports.getEmployeeAnalysisReport.useQuery(
      { courseId, examId },
      {
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!hasClicked && !!courseId && reportType === "analysis",
        onSuccess: () => {
          setSuccess(true);
          setHasClicked(false);
        },
      }
    );

  return (
    <StyledModal
      isLoading={
        gettingReportExams || gettingReportAnalysis || isFetchingEmployee
      }
      onClose={handleClose}
      open={open}
    >
      <>
        <HeaderArea>
          <StyledIconContainer>
            <StyledIcon
              size={48}
              name={successs ? "IconCheck" : "IconTable"}
              fill="transparent"
            />
          </StyledIconContainer>
          {successs ? (
            <SuccessMessage variant="body3">Pronto!</SuccessMessage>
          ) : null}
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </HeaderArea>
        <StyledContainer>
          <StyledTitle variant="headline6">
            {successs
              ? "Sua avaliação está sendo exportada!"
              : "Deseja exportar a avaliação?"}
          </StyledTitle>
          {successs ? (
            <StyledSubTitle variant="body3">
              Para acessá-lo é só conferir o e-mail que será enviado em
              <br />
              alguns minutos para o endereço:
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>
                4 primeiros dígitos do <br />
                seu CPF
              </b>{" "}
              como senha.
            </StyledSubTitle>
          ) : (
            <StyledSubTitle variant="body3">
              As respostas da <b>{examName}</b> serão convertidas em um
              <br /> arquivo .csv e enviadas para o email:
              <br />
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
            </StyledSubTitle>
          )}
        </StyledContainer>
        <StyledButtonContainer>
          {successs ? (
            <Button size="large" variant="primary" onClick={handleClose}>
              Voltar para Detalhe do Treinamento
            </Button>
          ) : (
            <>
              <LinkButton
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancelar
              </LinkButton>
              <Button
                size="large"
                variant="primary"
                loading={false}
                onClick={async () => {
                  setHasClicked(true);
                }}
              >
                Exportar avaliação
                <Icons name="IconDownload" fill="transparent" />
              </Button>
            </>
          )}
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

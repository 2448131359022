import { useNavigate, useParams } from "react-router-dom";

import EmptyState from "../../assets/emptyState.jpg";

import {
  DataGrid,
  dayjs,
  IconButton,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  Label,
  StyledFlexColumn,
  StyledFlexRow,
  StyledImageContainer,
  StyledTitleCard,
  TitleCardContainer,
} from "./styles";

import { Tooltip } from "@mui/material";

export const StudentExamTableView = ({ content }) => {
  const navigate = useNavigate();
  const { employeeId = "" } = useParams();

  const columns = [
    {
      Header: "Avaliação",
      accessor: "className",
      Cell: ({
        row: {
          original: { examName },
        },
      }) => <Label variant="body3">{examName}</Label>,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({
        row: {
          original: { status },
        },
      }) => {
        switch (status) {
          case "doing":
            return <Tag variant="blue">Em andamento</Tag>;
          case "done":
            return <Tag variant="green">Concluído</Tag>;
          case "notStarted":
            return <Tag variant="yellow">Não iniciado</Tag>;
        }
      },
    },
    {
      Header: "Treinamento",
      accessor: "name",
      Cell: ({
        row: {
          original: { courseName = null, courseBanner = {} as any },
        },
      }) => (
        <div>
          <StyledFlexRow>
            <div>
              <StyledImageContainer
                url={
                  courseBanner?.path
                    ? `${courseBanner?.path}${courseBanner?.signature}`
                    : EmptyState
                }
              />
            </div>
            <StyledFlexColumn>
              <TitleCardContainer>
                <StyledTitleCard variant="body3" tag="p">
                  {courseName}
                </StyledTitleCard>
              </TitleCardContainer>
            </StyledFlexColumn>
          </StyledFlexRow>
        </div>
      ),
    },
    {
      Header: "Data de conclusão",
      accessor: "doneDate",
      Cell: ({
        row: {
          original: { doneDate = 0 },
        },
      }) => (
        <Label variant="body3">
          {doneDate ? dayjs(doneDate).format("DD/MM/YYYY") : ""}
        </Label>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { _id = null, courseId = null },
        },
      }) => (
        <Tooltip arrow title="Ver gabarito do aluno">
          <div>
            <IconButton
              onClick={() =>
                navigate(
                  `/lms/manage-courses/detail-exam/${courseId}/${_id}/${employeeId}`
                )
              }
              size="small"
              variant="line"
              icon="IconReportAnalytics"
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        data={content || []}
        hasPagination={true}
        pageSizeOptions={[
          { label: "5 itens", value: 5 },
          { label: "10 itens", value: 10 },
          { label: "20 itens", value: 20 },
        ]}
        emptyState={{
          message: "O filtro aplicado não apresenta resultados.",
        }}
      />
    </div>
  );
};

import styled from "styled-components";

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const ExpandPdfModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.neutral[90]} !important;
  .modal-container {
    width: 100%;
    height: 100%;
  }
  .modal-content-area {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    padding: 0 !important;
  }
`;

export const ModalPreviewArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[90]} !important;
  height: 100%;
  display: flex;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 12px;
  z-index: 1;
  display: block;
`;

export const ExpandPdfModalArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

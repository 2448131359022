import styled from "styled-components";
import { Button } from "@mui/material";

const FilterContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 32px;
`;

const ButtonArea = styled.div`
  margin-left: 20px;
`;

const StyledButton = styled(Button)<{ selected?: boolean }>`
  svg {
    stroke: ${({ theme, selected }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : theme.colors.neutral.dark.dark5};
  }
`;
export { FilterContainer, ButtonArea, StyledButton, Container };

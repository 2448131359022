import { forwardRef } from "react";
import { ExamQuestion } from "@components/ExamQuestion";
import { IntroductionExam } from "@components/IntroductionExam";
import { DoneExam } from "../../components/Common";

export const Content = forwardRef(
  (
    {
      mobileKeyQuestion,
      isDone,
      aproved,
      examQuestions,
      handleSelectOption,
      selected: {
        name,
        description,
        questions,
        settings: { passingScore, showAnswerSheet, attempts },
      },
      attemptsLeft,
      examInitiate,
      totalCount: { correctCount = 0, questionCount = 0 },
    }: any,
    ref: any
  ) => {
    const validation = (isDone && aproved && showAnswerSheet) || !isDone;

    const findQuestion = examQuestions?.[mobileKeyQuestion] || null;

    if (!validation) return <></>;

    const { question, alternatives, mandatory } = findQuestion;

    return (
      <div style={{ overflow: "scroll", height: "100%" }}>
        <div ref={ref} style={{ padding: "24px" }}>
          {isDone ? (
            <DoneExam
              title="Pronto!"
              type={aproved ? "success" : "error"}
              minimunAnswers={passingScore}
              showAnswerSheet={aproved && showAnswerSheet}
              rightAnswers={correctCount}
              totalAnswers={questionCount}
            />
          ) : !examInitiate ? (
            <IntroductionExam
              title={name}
              description={description}
              minimunGrade={Math.round(passingScore * (10 / questions?.length))}
              totalQuestions={questions?.length}
              attemptsLeft={attemptsLeft}
              totalAttempts={attempts}
            />
          ) : (
            <></>
          )}

          {examInitiate && isDone ? (
            examQuestions?.map(
              ({ question = null, mandatory = false, alternatives }, index) => {
                return (
                  <ExamQuestion
                    key={index}
                    number={index + 1}
                    title={question}
                    handleSelectOption={handleSelectOption}
                    alternatives={alternatives}
                    isMandatory={mandatory}
                    isDone={isDone}
                    isMobile={true}
                    totalQuestions={examQuestions?.length}
                  />
                );
              }
            )
          ) : examInitiate ? (
            <ExamQuestion
              key={mobileKeyQuestion}
              number={mobileKeyQuestion + 1}
              title={question}
              handleSelectOption={handleSelectOption}
              alternatives={alternatives}
              isMandatory={mandatory}
              isDone={isDone}
              isMobile={true}
              totalQuestions={examQuestions?.length}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
);

import { ExpandPdfModal, ExpandPdfModalArea, ModalPreviewArea } from "./styled";

import { PDFVisualizer } from "../../Common";

export const ExpandPdf = ({
  onClose,
  open,
  url,
  isExpanded,
  setIsExpanded,
}) => {
  return (
    <ExpandPdfModal onClose={onClose} open={open}>
      <>
        <ExpandPdfModalArea>
          <ModalPreviewArea>
            <PDFVisualizer
              path={url}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              onClose={() => setIsExpanded(false)}
              headerStyle={{ borderRadius: "0px" }}
              style={{ borderRadius: "0px" }}
            />
          </ModalPreviewArea>
        </ExpandPdfModalArea>
      </>
    </ExpandPdfModal>
  );
};

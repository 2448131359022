import styled from "styled-components";
import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
`;

const TextContainer = styled.div`
  max-width: 536px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const LabelTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    margin-bottom: 15px;
  }
`;

const LabelSubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

const StyledButton = styled(Button)``;

const StyledButtonLabel = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

export {
  Container,
  TextContainer,
  LabelTitle,
  LabelSubTitle,
  StyledButton,
  StyledButtonLabel,
};

import { useCallback, useEffect, useState } from "react";

import {
  Button,
  LinkButton,
  Loader,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseButton,
  Description,
  ModalBody,
  ModalFooter,
  ModalHeader,
  StyledModal,
  Title,
} from "./styled";
import { trpc } from "@api/client";

export const AddModuleModal = ({
  closeModal,
  open,
  courseId,
  courseHierachy,
  name,
  moduleId,
}: {
  closeModal: () => any;
  open: boolean;
  courseId: string;
  courseHierachy: string;
  name?: string | void;
  moduleId?: string | void;
}) => {
  const utils = trpc.useContext();

  const [moduleName, setModuleName] = useState<any>("");
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    if (name) setModuleName(name);
  }, [name]);

  const { mutate: updateNodeCourseHierarchyMutate } =
    trpc.course.updateNodeCourseHierarchy.useMutation({});

  const { mutate: addNodeToHierarchyMutate } =
    trpc.course.addNodeToHierarchy.useMutation({});

  const handleSubmit = useCallback(() => {
    if (!moduleName) return;
    setLoading(true);
    if (name) {
      updateNodeCourseHierarchyMutate(
        {
          params: {
            id: moduleId,
            name: moduleName,
            level: 1,
          },
          courseId,
        },
        {
          onSuccess: () => {
            handleCallback();
          },
        }
      );
    } else {
      addNodeToHierarchyMutate(
        {
          params: {
            name: moduleName,
            parentId: courseHierachy,
            level: 0,
          },
          courseId,
        },
        {
          onSuccess: () => {
            handleCallback();
          },
        }
      );
    }
    setLoading(false);
  }, [moduleName]);

  const handleCallback = async () => {
    utils.course.getCourseHierarchy.invalidate({
      id: courseId,
    });
    setModuleName("");
    closeModal();
  };

  return (
    <StyledModal
      onClose={() => {
        closeModal();
        setModuleName("");
      }}
      open={open}
    >
      <div>
        <ModalHeader>
          <CloseButton
            size="medium"
            variant="line"
            icon="IconX"
            onClick={() => {
              closeModal();
              setModuleName("");
            }}
          />
          <Title variant="headline6">
            {name ? "Editar" : "Adicionar"} módulo
          </Title>
          {!name && (
            <Description variant="body4">
              Crie módulos para organizar as aulas do treinamento. Os
              treinamentos são exibidos aos alunos divididos em módulos e
              subdivididos em aulas.
            </Description>
          )}
        </ModalHeader>
        <ModalBody>
          <TextField
            style={{ width: "100%" }}
            value={moduleName}
            onChange={({ target: { value } }) => setModuleName(value)}
            label="Nome do módulo"
            fullWidth
          />
        </ModalBody>
        <ModalFooter>
          <LinkButton
            variant="primary"
            onClick={() => {
              closeModal();
              setModuleName("");
            }}
          >
            Cancelar
          </LinkButton>
          <Button
            disabled={!moduleName?.length}
            onClick={handleSubmit}
            variant="primary"
            size="medium"
          >
            {loading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>{name ? "Editar" : "Adicionar"} módulo</>
            )}
          </Button>
        </ModalFooter>
      </div>
    </StyledModal>
  );
};

import { useNavigate } from "react-router-dom";

import { track } from "@utils";
import EmptyState from "../../../../../assets/emptyState.jpg";

import { Button, dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Container,
  EmptyContent,
  IconButtonHover,
  LabelSubTitle,
  LabelTitle,
  ProgressArea,
  ProgressBar,
  ProgressPercent,
  ProgressWrapper,
  StyledFlex,
  StyledIconButton,
  StyledTag,
  SubContainer,
  SubImageContainer,
  SubImageHoverContainer,
  TagContainer,
} from "./styles";

const CourseCard = ({
  data: {
    name,
    start,
    end,
    banner,
    _id,
    certificate,
    mandatory,
    doneClassesTotal,
    classesTotal,
    totalViews,
  },
  isResponsive = false,
}) => {
  const navigate = useNavigate();

  let status = "doing";
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const percentDone = Math.round((doneClassesTotal / classesTotal) * 100) ?? 0;

  if (dayjs().isBefore(startDate, "day") || totalViews === 0) {
    status = "beforeInit";
  } else if (doneClassesTotal === classesTotal) {
    status = "done";
  } else {
    status = "doing";
  }

  const disabled =
    status !== "done" &&
    (dayjs().isBefore(startDate, "day") ||
      (endDate && dayjs().isAfter(endDate, "day")) ||
      classesTotal === 0);

  return (
    <Container>
      <SubImageContainer
        url={banner?.path ? `${banner?.path}${banner?.signature}` : EmptyState}
        hasBanner={!!banner?.path}
      >
        <SubImageHoverContainer>
          <IconButtonHover
            size="large"
            variant="filled"
            icon={status === "open" ? "IconPlayerPlay" : "IconLock"}
            style={{ alignSelf: "center" }}
          />
        </SubImageHoverContainer>
      </SubImageContainer>

      <SubContainer>
        <TagContainer>
          <StyledTag
            variant={
              status === "beforeInit"
                ? "error"
                : status === "done"
                ? "success"
                : "info"
            }
          >
            {status === "beforeInit"
              ? "Não iniciado"
              : status === "done"
              ? "Concluído"
              : "Em andamento"}
          </StyledTag>

          {mandatory ? (
            <StyledTag variant="primary">Obrigatório</StyledTag>
          ) : null}
        </TagContainer>
        <LabelTitle variant="body2">{name}</LabelTitle>
        {isResponsive ? (
          <></>
        ) : (
          <>
            <StyledFlex style={{ marginBottom: "16px" }}>
              <StyledIconButton
                variant="line"
                size="small"
                icon={"IconCalendar"}
              />
              <LabelSubTitle variant="body4">
                {start
                  ? `Início em ${dayjs(start).format("DD/MM/YYYY")}`
                  : "Não informado"}
              </LabelSubTitle>
            </StyledFlex>
            <StyledFlex style={{ marginBottom: "16px" }}>
              <StyledIconButton
                variant="line"
                size="small"
                icon={"IconCalendarTime"}
              />
              <LabelSubTitle variant="body4">
                {end
                  ? `Disponível até ${dayjs(end).format("DD/MM/YYYY")}`
                  : "Sempre disponível"}
              </LabelSubTitle>
            </StyledFlex>

            {certificate?._id ? (
              <StyledFlex style={{ marginBottom: "16px" }}>
                <StyledIconButton
                  variant="line"
                  size="small"
                  icon={"IconCertificate"}
                />
                <LabelSubTitle variant="body4">Emite certificado</LabelSubTitle>
              </StyledFlex>
            ) : (
              <EmptyContent />
            )}
          </>
        )}
        <ProgressArea>
          <ProgressWrapper>
            <ProgressBar totalDone={percentDone}>
              <span />
            </ProgressBar>
          </ProgressWrapper>
          <ProgressPercent variant="body4">
            {percentDone >= 100 ? 100 : percentDone}%
          </ProgressPercent>
        </ProgressArea>

        <Button
          disabled={disabled}
          size="large"
          variant="primary"
          style={{ width: "100%" }}
          onClick={() => {
            track({
              name: "employee_lms_mycourses_accesscourse_clicked",
            });
            navigate(`/lms/my-courses/${_id}/0/class/view`);
          }}
        >
          Acessar treinamento
        </Button>
      </SubContainer>
    </Container>
  );
};

export { CourseCard };

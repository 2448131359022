import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ChartArea = styled.div`
  background: #ffffff;
  height: 100%;
  overflow-x: auto;

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
    height: 0px;
  }

  flex: 1;
  .org-tree-container {
    background-color: transparent;
  }
  .react-transform-wrapper {
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
  .org-tree-node-label .org-tree-node-label-inner {
    padding: 0;
    box-shadow: none;
  }
  .org-tree-node-btn {
    z-index: 1;
    margin-top: -10px;
    margin-left: -10px;
  }
  .org-tree-node.is-leaf,
  .org-tree-node.collapsed {
    padding-bottom: 20px;
  }
  ul {
    background: white;
    z-index: 1;
  }
  .horizontal {
    .org-tree-node {
      &:only-child:before {
        top: 1px;
        border-bottom: 1px solid #da0b6d;
      }
      &:not(:only-child):after {
        border-top: 1px solid #da0b6d;
      }
      &:not(:first-child):before,
      &:not(:last-child):after {
        border-left: 1px solid #da0b6d;
      }
    }
    > .org-tree-node:only-child:before {
      border-bottom: 0;
    }

    .org-tree-node-children:before {
      border-top: 1px solid #da0b6d;
    }
  }
`;

export const CardArea = styled.div`
  border-left: 2px solid #78003c;
  background: #f8f6f8;
  border-radius: 8px;
  margin: 20px 0px;
  width: 311px;
`;

export const CardTitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 16px;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  p {
    color: #6b5b66;
    b {
      color: #3b2e37;
    }
  }
`;

export const CardContent = styled(Typography)`
  color: #6b5b66;
  padding: 8px 16px 32px;
  text-align: left;
  font-weight: bold;
`;

export const CardButtons = styled.div`
  position: absolute;
  margin-top: -25px;
  width: 100%;
  justify-content: center;
  display: flex;
  button {
    background: #ffffff;
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
    margin: 0 10px;
  }
  svg {
    fill: transparent;
    stroke: #6b5b66;
  }
`;

export const ModalIcon = styled.div`
  background: #ffecd6;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -41px auto 16px;
  border-radius: 100%;
  svg {
    fill: transparent;
    stroke: #cb6d01;
  }
`;

export const ModalCloseIcon = styled(IconButton)`
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  top: 15px;
  svg {
    fill: transparent;
    stroke: #6d5a66;
  }
`;

export const WarningModalTitle = styled(Typography)`
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin: 0 15px;
  color: #cb6d01;
`;

export const ModalTextContent = styled(Typography)`
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #83727d;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 40px;
  .linkButton-custom-theme {
    align-self: center;
  }
`;

export const AddModalTitle = styled(Typography)`
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #3b2e37;
  padding-bottom: 4px;
`;

export const ModalArea = styled.p`
  padding: 40px 70px 0;
`;

import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Tooltip } from "@mui/material";
import styled from "styled-components";

export const MetricArea = styled.div`
  background: ${({ theme }) => theme.colors.neutral[100]};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
`;

export const IconArea = styled.div`
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 200px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  svg {
    stroke: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 16px;
  && {
    &.MuiButtonBase-root {
      background: ${({ theme }) => theme.colors.secondary[95]} !important;
      pointer-events: none;
      svg {
        stroke: ${({ theme }) => theme.colors.secondary[50]};
      }
    }
  }
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600;
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
`;

export const SubtitleArea = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 700;
  line-height: 24px;
  margin-left: 8px;
`;

export const InfoTooltip = styled(Tooltip)`
  margin-left: 11px;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.neutral[30]};
    width: 16px;
    height: 16px;
  }
`;

export const StyledPOneLine = styled.p<{ numberOfLines: number }>`
  display: block;
  display: -webkit-box;
  line-height: ${({ numberOfLines }) => numberOfLines};
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

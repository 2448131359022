import { useEffect, useState } from "react";

import {
  ItensPerPageArea,
  PaginationArea,
  StyledGrid,
  StyledSelectField,
  PaginationContainer,
  StyledTypography,
  StyledMenuItem,
  EmptyZone,
} from "./styles";

import { CourseCard } from "./CourseCard";

import { Pagination, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import EmptyContentState from "../../../../assets/emptyContentState.svg";

import { StyledText, pageSizeOptions } from "@utils";

const CourseGridView = ({ content, isLoading }) => {
  const [cloneContent, setCloneContent] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(5);
  const [page, setPage] = useState<any>(1);

  useEffect(() => {
    if (content?.length) setCloneContent(handlePaginate(content, pageSize, 1));
  }, [content, pageSize]);

  const handlePaginate = (array, pageSize, pageNumber) => {
    if (array?.length) {
      setPage(pageNumber);
      setTotalPages(Math.ceil(array?.length / pageSize));
      return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }
    return;
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          style={{ width: "100%", height: "400px" }}
        />
      ) : (
        <>
          <StyledGrid container spacing={2} direction="row" alignItems="center">
            <>
              {cloneContent?.length ? (
                cloneContent?.map((course) => (
                  <StyledGrid key={course._id} item sm={12} md={6} lg={4}>
                    <CourseCard data={course} />
                  </StyledGrid>
                ))
              ) : (
                <EmptyZone className="data-grid-empty-table-container">
                  <EmptyContentState />
                  <StyledText variant="body3">
                    O filtro aplicado não apresenta resultados.
                  </StyledText>
                </EmptyZone>
              )}
            </>
          </StyledGrid>
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > content?.length
                  ? cloneContent?.length
                  : page * pageSize}
                &nbsp;de&nbsp;{cloneContent?.length ?? 0}&nbsp;
                {cloneContent?.length === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                count={totalPages}
                onChange={(e, value) =>
                  setCloneContent(handlePaginate(content, pageSize, value))
                }
              />
            </PaginationContainer>
          </PaginationArea>
        </>
      )}
    </>
  );
};

export { CourseGridView };

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  justify-content: top;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 24px;
`;

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 40px;
  gap: 24px;
`;

export const ContentContainer = styled.div``;

export const StyledTitle = styled(Typography)<{ setColor: string }>`
  color: ${({ theme, setColor }) =>
    theme.colors.neutral[setColor === "title" ? 30 : 50]};
  font-weight: 700;
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 600;
`;

export const StyledImg = styled.img`
  width: 100%;
`;

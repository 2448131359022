import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Button } from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  max-width: 536px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const LabelTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    margin-bottom: 15px;
  }
`;

const LabelSubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

const StyledButtonLabel = styled(Typography)`
  && {
    font-weight: 700;
  }
`;

const StyledFlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)<{ selected: boolean }>`
  svg {
    stroke: ${({ theme, selected }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : theme.colors.neutral.dark.dark5};
  }
`;

export {
  Container,
  TextContainer,
  LabelTitle,
  LabelSubTitle,
  StyledButton,
  StyledButtonLabel,
  StyledFlexBetween,
  StyledFlex,
};

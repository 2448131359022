import { CircularProgress } from "@flash-tecnologia/hros-web-ui-v2";

import { InfoLabel, Label, ModalArea, StyledModal } from "./styles";

export const LoadingContentModal = ({
  open = false,
  loadingProgress = 100,
}) => {
  return (
    <StyledModal open={open}>
      <ModalArea>
        <CircularProgress value={loadingProgress} variant="indeterminate" />
        <Label variant="body2">
          {loadingProgress === 100 ? "Finalizando..." : "Criando conteúdo..."}
        </Label>
        <InfoLabel variant="body4">
          Não feche essa janela, se não o progresso será perdido
        </InfoLabel>
      </ModalArea>
    </StyledModal>
  );
};

import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import {
  Container,
  EmailField,
  EmployeeName,
  PageDescription,
  PageInfo,
  PageSubTitle,
  PageTitle,
  SharedTooltip,
  StyledAvatar,
  StyledSectionContainer,
  TableData,
  TableDataArea,
  TransferListArea,
  TransferSectionTitle,
} from "./styled";

import {
  Icons,
  Skeleton,
  Tooltip,
  TransferList,
} from "@flash-tecnologia/hros-web-ui-v2";

import { PageTemplate } from "@components/PageTemplate";
import { Grid } from "@mui/material";
import { StyledText, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { routes } from "@routes";
import { cloneDeep, orderBy } from "lodash-es";
import { DraftCourseModal } from "../Modal/DraftCourseModal";

import { differenceWith, isEqual } from "lodash";

let leftTimer = null as any;
let rightTimer = null as any;

export const FourthStep = ({
  data,
  type,
  updateCourseMutate,
  courseId,
  currentStep,
  isFetching,
}) => {
  const utils = trpc.useContext();

  const navigate = useNavigate();

  const [saveAsDraft, setSaveAsDraft] = useState(false);

  const [selectedDepartments, setSelectedDepartments] = useState<any>([]);
  const [allDepartments, setAllDepartments] = useState([]);

  const [searchFilters, setSearchFilters] = useState<any>([]);

  const [companyGroups, setCompanyGroups] = useState<any>([]);
  const [companyEmployees, setCompanyEmployees] = useState<any>([]);

  const [leftList, setLeftList] = useState<any>([]);
  const [rightList, setRightList] = useState<any>([]);
  const [leftSearch, setLeftSearch] = useState("");
  const [rightSearch, setRightSearch] = useState("");

  const { selectedCompany } = useSelectedCompany();

  const { mutate: addCourseToEmployeesMutate, isLoading: addingCourseLoading } =
    trpc.course.addCourseToEmployees.useMutation({});

  const { isFetching: isGettingEmplyoeeCompanyId } =
    trpc.employee.getEmployeeByCompanyId.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCompanyEmployees(data);
      },
    });

  const { isFetching: getGroupsByCompanyIdLoading } =
    trpc.company.getGroupsByCompanyId?.useQuery(undefined, {
      retry: false,
      onSuccess: (data) => {
        const options = data?.map(({ _id, name, employees }) => ({
          id: _id,
          key: _id,
          label: name,
          asideText: "",
          checked: false,
          hidden: false,
          employees: employees || [],
        }));

        setCompanyGroups(options);
      },
      refetchOnWindowFocus: false,
      onError: () => {
        dispatchToast({
          type: "error",
          content: "Não foi possível localizar grupos e departamentos",
        });
      },
    });

  const { isFetching: isGetDepartmentsByCompanyId } =
    trpc?.company?.getDepartmentsByCompanyId?.useQuery(
      {
        companyId: selectedCompany?.id,
      },
      {
        enabled: !!selectedCompany?.id,
        retry: false,
        onSuccess: (data) => {
          const departments = data;

          setAllDepartments(() =>
            departments?.map((item) => ({
              id: item._id,
              key: item._id,
              label: item?.name,
              checked: false,
              hidden: false,
              employees: item?.employees || [],
            }))
          );

          setSelectedDepartments(() =>
            departments?.map((item) => ({
              id: item._id,
              key: item._id,
              label: item?.name,
              checked: false,
              hidden: false,
              employees: item?.employees || [],
            }))
          );
        },
        refetchOnWindowFocus: false,
        onError: () => {
          dispatchToast({
            type: "error",
            content: "Não foi possível localizar grupos e departamentos",
          });
        },
      }
    );

  const isLoading =
    isFetching ||
    isGetDepartmentsByCompanyId ||
    isGettingEmplyoeeCompanyId ||
    getGroupsByCompanyIdLoading;

  useEffect(() => {
    if (data?.peopleInCourse && companyEmployees) {
      const filteredLeftList = handleFilterLeftList(data?.peopleInCourse);

      const filteredRightList = data?.peopleInCourse
        ?.map((cl) => {
          const found = companyEmployees?.find((c: any) => c._id == cl._id);
          if (found) {
            return { ...cl, email: found?.email || [] };
          }
          return;
        })
        .filter((c) => c);

      setRightList(filteredRightList);
      setLeftList(filteredLeftList);
    }

    setSearchFilters(companyGroups);
  }, [companyGroups, companyEmployees, data]);

  const mapEmployeesToSave = (array) => {
    return array?.map(({ _id }) => ({
      _id,
    }));
  };

  const handleAddCourseToEmployees = () => {
    const mapEmployees = mapEmployeesToSave(rightList) || [];
    const mapData = mapEmployeesToSave(data?.peopleInCourse || []);

    const hasChangedAnyValue =
      differenceWith(mapEmployees, mapData, isEqual)?.length ||
      mapEmployees?.length != mapData?.length;

    if (!!!hasChangedAnyValue) {
      navigate(
        `/lms/manage-courses/createEditCourse/${type}/${
          currentStep + 1
        }/${courseId}`
      );
      return;
    }

    addCourseToEmployeesMutate(
      {
        params: {
          employees: mapEmployeesToSave(rightList),
        },
        courseId,
      },
      {
        onSuccess: () => {
          utils.employee.getEmployees.refetch();
          utils.course.getCourseById.refetch();

          navigate(
            `/lms/manage-courses/createEditCourse/${type}/${
              currentStep + 1
            }/${courseId}`
          );
        },
        onError: (e: any) => {
          const courseNotExists = e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

          const message = courseNotExists
            ? "Não existe o curso."
            : "Erro ao tentar adicionar os alunos ao curso, tente novamente mais tarde!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );
  };

  const handleFilterLeftList = (array = []) => {
    return companyEmployees?.filter(
      ({ _id }) => !array?.some(({ _id: _idRight }) => _idRight === _id)
    );
  };

  const getListSelectedCount = (list = []) => {
    return list?.filter(({ checked }) => checked === true)?.length;
  };

  const columns = [
    {
      Header: "Nome",
      accessor: "name",
      Cell: ({
        row: {
          original: { name = "", email = [] as any },
        },
      }) => {
        return (
          <TableData>
            <StyledAvatar>{name?.charAt(0) ?? ""}</StyledAvatar>
            <TableDataArea>
              <EmployeeName variant="body3">{name ?? ""}</EmployeeName>
              {email ? (
                <EmailField variant="body3">{email ?? null}</EmailField>
              ) : null}
            </TableDataArea>
          </TableData>
        );
      },
    },
  ];

  const sections = [
    {
      id: "groups",
      title: `Grupos (${
        searchFilters?.filter(({ checked }) => checked)?.length ?? 0
      }/${companyGroups?.length ?? 0})`,
      options: searchFilters?.map(
        ({ id, label = "", checked = false, employees }) => {
          return {
            id: id,
            key: id,
            label,
            checked: checked,
            hidden: false,
            employees,
          };
        }
      ),
    },
    {
      id: "departments",
      title: `Departamentos (${
        selectedDepartments?.filter(({ checked }) => checked)?.length ?? 0
      }/${allDepartments?.length ?? 0})`,
      options: selectedDepartments?.map(
        ({ id = 0, label = "", checked = false, employees }) => {
          return {
            id,
            key: id,
            label,
            checked,
            hidden: false,
            employees,
          };
        }
      ),
    },
  ];

  const filteredLeftList = useMemo(() => {
    const cleanValue = leftSearch.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
    const regex = new RegExp(cleanValue, "gi");

    return leftList?.filter(({ name = "" }) => name?.match(regex)) || [];
  }, [leftList, leftSearch]) as any;

  const filteredRightList = useMemo(() => {
    const cleanValue = rightSearch.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
    const regex = new RegExp(cleanValue, "gi");

    return rightList?.filter(({ name = "" }) => name?.match(regex)) || [];
  }, [rightList, rightSearch]) as any;

  return (
    <>
      <PageTemplate
        stepper={{
          steps: [
            "Informações básicas",
            "Configurações",
            "Conteúdo do treinamento",
            "Alunos",
            "Revisão",
          ],
          activeStep: currentStep,
        }}
        footer={{
          cancelProps: {
            title: "Sair sem salvar",
            callback: () => {
              navigate("/lms/manage-courses");
            },
          },
          draftProps: {
            title: "Sair e salvar rascunho",
            hasToShow: data?.status != "published",
            callback: () => {
              updateCourseMutate(
                {
                  courseId,
                  companyId: selectedCompany?.id,
                  status: "draft",
                },
                {
                  onSuccess: () => {
                    utils.course.getManageCourses.invalidate();
                    utils.course.getCourseById.invalidate();

                    setSaveAsDraft(true);
                  },
                  onError: (e: any) => {
                    const courseNotExists =
                      e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

                    const message = courseNotExists
                      ? "Não existe o curso."
                      : "Erro ao tentar atualizar o curso, tente novamente mais tarde!";

                    dispatchToast({
                      type: "error",
                      content: message,
                    });
                  },
                }
              );
            },
          },
          goBackProps: {
            title: (
              <>
                <Icons name="IconArrowLeft" fill="transparent" />
                Voltar
              </>
            ),
            callback: () => {
              const previousStep = currentStep - 1;

              navigate(
                routes.pageCreateEditCourse
                  .replace(":step", previousStep?.toString())
                  .replace(":type", type)
                  .replace(":courseId", courseId || "")
              );
            },
          },
          confirmProps: {
            title: (
              <>
                Continuar
                <Icons name="IconArrowRight" fill="transparent" />
              </>
            ),
            loading: addingCourseLoading,
            disabled: isLoading,
            callback: () => {
              handleAddCourseToEmployees();
            },
          },
        }}
      >
        <Container>
          <PageTitle variant="headline6">
            {type === "create" ? "Criar" : "Editar"} treinamento
          </PageTitle>

          <Grid container>
            {isFetching ? (
              <>
                <Grid item sm={12} md={5} lg={4}>
                  <div style={{ marginRight: "10px" }}>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <div>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <PageInfo>
                  <PageSubTitle variant="headline7">Alunos</PageSubTitle>
                  <PageDescription variant="body3">
                    Agora é só selecionar os alunos que terão acesso ao
                    treinamento.
                  </PageDescription>
                </PageInfo>

                <StyledSectionContainer style={{ marginBottom: "20px" }}>
                  <TransferSectionTitle>
                    <SharedTooltip>
                      <StyledText variant="body2">Seleção de alunos</StyledText>
                      <Tooltip
                        arrow
                        className="sharedTooltip"
                        title="A seleção de alunos abrange somente os alunos pertencente à empresa/CNPJ da qual você faz parte."
                      >
                        <div>
                          <Icons name={"IconInfoCircle"} fill="transparent" />
                        </div>
                      </Tooltip>
                    </SharedTooltip>
                    <StyledText variant="body4">
                      Para quais alunos esse treinamento será exibido?
                    </StyledText>
                  </TransferSectionTitle>
                  <TransferListArea>
                    <TransferList
                      columns={columns}
                      leftList={{
                        data: isLoading ? [] : filteredLeftList,
                        total: isLoading ? 0 : filteredLeftList?.length,
                        loading: isLoading,
                        emptyStageMessage: "alunos da empresa aparecerão aqui.",
                        title: `Todos os alunos (${getListSelectedCount(
                          filteredLeftList
                        )}/${filteredLeftList?.length})`,
                        onSearch: (leftSearch: string) => {
                          if (leftTimer) clearTimeout(leftTimer);

                          leftTimer = setTimeout(() => {
                            setLeftSearch(leftSearch);
                          }, 600);
                        },
                        onCheck: ({ allChecked, data }) => {
                          const base = cloneDeep(leftList);

                          setLeftList(
                            base.map((row) => {
                              const updated = data?.find(
                                (d) => d._id === row._id
                              );
                              const filtered = filteredLeftList?.find(
                                (f) => f._id === row._id
                              );
                              return {
                                ...row,
                                checked:
                                  allChecked !== undefined && filtered
                                    ? allChecked
                                    : updated
                                    ? updated.checked
                                    : row.checked,
                              };
                            })
                          );
                        },
                        customFilters: {
                          title: "Filtros",
                          sections: sections,
                          onCheck: ({ sectionId, options }) => {
                            if (sectionId === "groups") {
                              setSearchFilters(options);
                            }
                            if (sectionId === "departments") {
                              setSelectedDepartments(options);
                            }
                          },
                          onClear: () => {
                            setSearchFilters((prev) =>
                              prev.map((group) => {
                                return {
                                  ...group,
                                  checked: false,
                                };
                              })
                            );
                            setSelectedDepartments((prev) =>
                              prev.map((group) => {
                                return {
                                  ...group,
                                  checked: false,
                                };
                              })
                            );
                          },
                          onFilter: (result) => {
                            const filteredGroups = result[0]?.options?.filter(
                              ({ checked }) => checked
                            ) as any;
                            const filteredDepartments =
                              result[1]?.options?.filter(
                                ({ checked }) => checked
                              ) as any;

                            let res = [];

                            const filteredLeftList =
                              handleFilterLeftList(rightList);

                            if (filteredGroups?.length) {
                              const allEmployees = [] as any;
                              filteredGroups?.map((g) =>
                                g?.employees?.map((i) =>
                                  allEmployees.push(i?._id)
                                )
                              );

                              const array = filteredLeftList?.filter(
                                ({ _id }) => {
                                  return allEmployees?.find((item) => {
                                    return item === _id;
                                  });
                                }
                              );

                              res = res.concat(array);
                            }

                            if (filteredDepartments?.length) {
                              const allEmployees = [] as any;
                              filteredDepartments?.map((g) =>
                                g?.employees?.map((i) => allEmployees.push(i))
                              );

                              const array = filteredLeftList?.filter(
                                ({ _id }) => {
                                  return allEmployees?.find((item) => {
                                    return item === _id;
                                  });
                                }
                              );
                              res = res.concat(array);
                            }

                            setLeftList(
                              res?.length
                                ? orderBy(res, ["name"])
                                : !filteredGroups?.length &&
                                  !filteredDepartments?.length
                                ? filteredLeftList
                                : []
                            );
                          },
                          onSearch: (item) => {
                            const { text, sectionId } = item;
                            const cleanValue = text.replace(
                              /[-[/\]{}()*+?.,\\^$|#]/g,
                              ""
                            );
                            const regex = new RegExp(cleanValue, "gi");
                            if (sectionId === "groups")
                              setSearchFilters(() =>
                                companyGroups?.filter(({ label = "" }) =>
                                  label?.match(regex)
                                )
                              );

                            if (sectionId === "departments")
                              setSelectedDepartments(() =>
                                allDepartments?.filter(
                                  ({ label }: { label: string }) =>
                                    label?.match(regex)
                                )
                              );
                          },
                        },
                      }}
                      rightList={{
                        data: isLoading ? [] : filteredRightList,
                        total: isLoading ? 0 : filteredRightList?.length,
                        loading: isLoading,
                        emptyStageMessage:
                          "Alunos movidos ao treinamento aparecerão aqui.",
                        title: `Selecionados para o treinamento (${getListSelectedCount(
                          filteredRightList
                        )}/${isLoading ? 0 : filteredRightList?.length ?? 0})`,
                        onCheck: ({ allChecked, data }) => {
                          const base = cloneDeep(rightList);

                          setRightList(
                            base.map((row) => {
                              const updated = data?.find(
                                (d) => d._id === row._id
                              );
                              const filtered = filteredRightList?.find(
                                (f) => f._id === row._id
                              );

                              return {
                                ...row,
                                checked:
                                  allChecked !== undefined && filtered
                                    ? allChecked
                                    : updated
                                    ? updated.checked
                                    : row.checked,
                              };
                            })
                          );
                        },
                        onSearch: (rightSearch: string) => {
                          if (rightTimer) clearTimeout(rightTimer);

                          rightTimer = setTimeout(() => {
                            setRightSearch(rightSearch);
                          }, 600);
                        },
                      }}
                      onTransfer={({
                        leftList: transferLeft,
                        rightList: transferRight,
                      }) => {
                        const leftBase = cloneDeep(leftList);
                        const rightBase = cloneDeep(rightList);

                        const hasAdded = leftBase
                          .filter((l) => l.checked)
                          .some((l) =>
                            (transferRight?.data || []).find(
                              (tr) => tr._id === l._id
                            )
                          );

                        const hasRemoved = rightBase
                          .filter((l) => l.checked)
                          .some((l) =>
                            (transferLeft?.data || []).find(
                              (tl) => tl._id === l._id
                            )
                          );

                        if (hasAdded) {
                          const added = leftBase
                            .filter((lb) => lb.checked)
                            .map((lb) => ({ ...lb, checked: false }));

                          rightBase.push(...added);

                          const removed = leftBase
                            .filter((lb) => !lb.checked)
                            .map((lb) => ({ ...lb, checked: false }));

                          setRightList(rightBase);
                          setLeftList(removed);

                          return;
                        }

                        if (hasRemoved) {
                          const added = rightBase
                            .filter((rb) => rb.checked)
                            .map((rb) => ({ ...rb, checked: false }));

                          leftBase.push(...added);

                          const removed = rightBase
                            .filter((rb) => !rb.checked)
                            .map((rb) => ({ ...rb, checked: false }));

                          setRightList(removed);
                          setLeftList(leftBase);
                        }
                      }}
                    />
                  </TransferListArea>
                </StyledSectionContainer>
              </>
            )}
          </Grid>
        </Container>
      </PageTemplate>

      <DraftCourseModal
        open={saveAsDraft}
        onClose={() => navigate("/lms/manage-courses")}
      />
    </>
  );
};

import styled from "styled-components";

import {
  Icons,
  Typography,
  IconButton,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

const StyledModal = styled(Modal)<{ isLoading: boolean }>`
  && {
    .modal-icon-container {
      top: -40px;
    }
    .modal-content-area {
      padding: ${({ isLoading }) =>
        !isLoading ? "40px 40px 40px 112px" : "40px 112px"};
    }
  }
`;

const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error.dark1};
`;

const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.feedback.error.light1};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const StyledTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.feedback.error.dark1};
  text-align: center;
  max-width: 423px;
`;

const StyledSubTitle = styled(Typography)`
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
`;

const StyledContainer = styled.div`
  max-width: 423px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
  margin-left: 32px;
`;

const StyledLoader = styled(Loader)``;

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledTextLoading = styled(Typography)`
  font-weight: 700;
  text-align: center;
  margin-top: 16px;

  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledTextLoading,
  StyledIconButton,
  StyledLoader,
  StyledFlexCenter,
};

import styled, { css } from "styled-components";
import {
  Typography,
  IconButton,
  Tag,
  Menu,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 32px rgba(254, 43, 143, 0.16);
  }
`;

export const SubImageContainer = styled.div`
  height: 210px;
  background-color: ${({ theme }) => theme.colors.neutral.light.light1};
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const TextContainer = styled.div`
  max-width: 536px;
  text-align: center;
`;

export const LabelTitle = styled(Typography)`
  margin-bottom: 24px;
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
    margin-bottom: 24px;
  }
`;

export const StyledTag = styled(Tag)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.feedback.success.light1};
      color: ${({ theme }) => theme.colors.feedback.success.dark2};
    }
  }
`;

export const PreviewArea = styled.div`
  scale: 0.22;
  cursor: default;
  user-select: none;
  width: max-content;
  height: max-content;
  background-color: white;
`;

export const GenerateButton = styled(Button)`
  align-self: center !important;
  margin: auto;
  width: 100%;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const LabelSubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    display: flex;
    align-items: center;
  }
`;

import { useMemo, useState } from "react";

import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { Grid, Container, StyledFlexCenter, EmptyGrid } from "./styled";

import { Header } from "./Components/Header";
import { Row } from "./Components/Row";
import { Pagination } from "./Components/Pagination";
import { Checkbox } from "@flash-tecnologia/hros-web-ui-v2";

import EmptyTableSVG from "../../assets/empty_table.svg";

import { StyledText } from "@utils";

export const DataGrid = ({
  loading,
  empty,
}: {
  loading?: boolean;
  empty?: any;
}) => {
  const [data, setData] = useState([
    {
      firstName: "teste",
      lastName: "bata",
      age: "1",
      age2: "1",
    },
    {
      firstName: "aeste",
      lastName: "cata",
      age: "22",
      age2: "1",
    },
  ]);

  const columns = useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        footer: (props) => props.column.id,
        enableResizing: false,
        enableSorting: false,
      },
      {
        accessorKey: "firstName",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableSortBy: true,
      },
      {
        accessorFn: (row) => row.lastName,
        id: "lastName",
        cell: (info) => info.getValue(),
        header: () => <span>Last Name</span>,
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: "age",
        header: () => "Age",
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: "age2",
        header: () => "Age 2",
        footer: (props) => props.column.id,
        enableSorting: false,
      },
      {
        accessorKey: "actions",
        header: "Ação",
        enableSorting: false,
        // sortingFn: 'datetime' (inferred from the data)
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    columnResizeMode: "onChange",
    state: {
      columnPinning: {
        left: [],
        right: ["actions"],
      },
    },
  });

  if (data?.length)
    return (
      <EmptyGrid>
        <EmptyTableSVG />
        <StyledText variant="body3">
          {empty?.message ?? "Lista vazia"}
        </StyledText>
        {empty?.children && (
          <div style={{ padding: "24px 0px" }}>{empty?.children}</div>
        )}
      </EmptyGrid>
    );

  const bulkActions = {
    actions: [
      {
        label: "Aprovar todos",
      },
      {
        label: "Reprovar todos",
      },
    ],
  };

  return (
    <Container>
      <Grid>
        <Header table={table} bulkActions={bulkActions} />
        <Row table={table} />
      </Grid>

      <Pagination table={table} />
    </Container>
  );
};

import styled from "styled-components";

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  button:first-child {
    align-self: center;
    margin-right: 24px;
    svg {
      color: ${({ theme }) => theme.colors.neutral[40]};
    }
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0px;
`;

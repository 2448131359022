import EmptyContentState from "../../../../../assets/emptyContentState.svg";

import { Container, TextContainer, LabelTitle, LabelSubTitle } from "./styles";

export const NoLearningObject = () => {
  return (
    <Container>
      <EmptyContentState />
      <TextContainer>
        <LabelTitle variant="headline6">
          Você ainda não criou nenhum conteúdo
        </LabelTitle>
        <LabelSubTitle variant="body2">
          Crie conteúdos e depois adicione-os aos treinamentos.
        </LabelSubTitle>
      </TextContainer>
    </Container>
  );
};

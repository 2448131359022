import styled from "styled-components";

import { Modal } from "@components/Modal";
import { Button } from "@flash-tecnologia/hros-web-ui-v2";

export const DoneModal = styled(Modal)`
  &.modal-custom-theme .modal-container {
    width: 100%;
    max-width: 480px;
  }

  .modal-content-area {
    padding: 0 !important;
    border-radius: 10px !important;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.secondary[99]};
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;
export const Footer = styled.div`
  display: flex;
  align-itens: center;
  justify-content: center;
  width: 100%;
  padding: 12px 0px;
`;

export const Flex = styled.div`
  display: flex;
  align-itens: center;
`;

export const StyledButton = styled(Button)``;

export const Gap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

import { useMemo } from "react";

import { Breadcrumbs } from "@flash-tecnologia/hros-web-ui-v2";

import { BreadCrumbItem, BreadcrumbText } from "./styled";

const Breadcrumb = ({
  breadcrumbs,
  separator,
  style,
}: {
  breadcrumbs: any;
  separator: any;
  style?: any;
}) => {
  const handleBreadcrumbs = useMemo(() => {
    if (!breadcrumbs.length) return [<></>];

    return breadcrumbs.map((b, index) => {
      return b.active ? (
        <BreadCrumbItem onClick={b.onClick} key={index}>
          <BreadcrumbText variant="body4">{b.label}</BreadcrumbText>
        </BreadCrumbItem>
      ) : (
        <BreadcrumbText key={index} variant="body4">
          {b.label}
        </BreadcrumbText>
      );
    });
  }, [breadcrumbs]);

  return (
    <Breadcrumbs
      breadcrumbs={handleBreadcrumbs}
      separator={separator}
      style={style}
    />
  );
};

export { Breadcrumb };

import { useNavigate } from "react-router-dom";
import {
  NextPreviousContainer,
  NextPreviousSubTitle,
  NextPreviousTitle,
  StyledFlexColumn,
} from "./styled";

import { Grid } from "@mui/material";
import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledPLine, track } from "@utils";

export const NextPreviousMenu = ({
  viewType,
  selected,
  courseId,
  setSelected,
  isDescription,
  setIsDescription,
}) => {
  const navigate = useNavigate();
  if (!!!selected)
    return (
      <Grid container>
        <Grid item sm={6} md={6} xs={6}>
          <NextPreviousContainer></NextPreviousContainer>
        </Grid>
        <Grid item sm={6} md={6} xs={6}>
          <NextPreviousContainer></NextPreviousContainer>
        </Grid>
      </Grid>
    );

  const { previous, current, next } = selected;

  return (
    <Grid container>
      <Grid item sm={6} md={6} xs={6}>
        <NextPreviousContainer>
          {previous?.name ? (
            <>
              <IconButton
                size="small"
                variant="line"
                icon="IconChevronLeft"
                style={{ marginRight: "16px" }}
                onClick={() => {
                  track({
                    name: "employee_lms_course_previous_clicked",
                  });
                  if (isDescription) setIsDescription(false);

                  setSelected(null);
                  if (viewType === "preview") {
                    navigate(
                      `/lms/manage-courses/${courseId}/${previous?.module}/${previous?._id}/preview`
                    );
                    return;
                  }
                  navigate(
                    `/lms/my-courses/${courseId}/${previous?.module}/${previous?._id}/view`
                  );
                }}
                disabled={previous?.name ? false : true}
              />
              <StyledFlexColumn>
                <StyledPLine numberOfLines={1}>
                  <NextPreviousTitle variant="caption">
                    Anterior
                  </NextPreviousTitle>
                </StyledPLine>
                <StyledPLine numberOfLines={1}>
                  <NextPreviousSubTitle variant="body3">
                    {previous?.name}
                  </NextPreviousSubTitle>
                </StyledPLine>
              </StyledFlexColumn>
            </>
          ) : (
            <></>
          )}
        </NextPreviousContainer>
      </Grid>
      <Grid item sm={6} md={6} xs={6}>
        <NextPreviousContainer
          style={{ justifyContent: "flex-start", flexDirection: "row-reverse" }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronRight"
            style={{ marginLeft: "16px" }}
            onClick={() => {
              track({
                name: "employee_lms_course_next_clicked",
              });
              if (isDescription) setIsDescription(false);
              else {
                setSelected(null);

                if (viewType === "preview") {
                  navigate(
                    `/lms/manage-courses/${courseId}/${next?.module}/${next?._id}/preview`
                  );
                  return;
                }
                navigate(
                  `/lms/my-courses/${courseId}/${next?.module}/${next?._id}/view`
                );
              }
            }}
            disabled={next?.name ? false : true}
          />
          <StyledFlexColumn style={{ alignItems: "flex-end" }}>
            <StyledPLine numberOfLines={1}>
              <NextPreviousTitle variant="caption">Próximo</NextPreviousTitle>
            </StyledPLine>
            <StyledPLine numberOfLines={1}>
              <NextPreviousSubTitle variant="body3">
                {isDescription ? current?.name : next?.name}
              </NextPreviousSubTitle>
            </StyledPLine>
          </StyledFlexColumn>
        </NextPreviousContainer>
      </Grid>
    </Grid>
  );
};

import {
  DataGrid,
  dayjs,
  IconButton,
  Menu,
  Pagination,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import { pageSizeOptions, StyledText, StyledTitle } from "@utils";

import ThumbArticle from "../../../../../assets/thumbArticle.png";
import ThumbPdf from "../../../../../assets/thumbPdf.png";
import ThumbPodcast from "../../../../../assets/thumbPodcast.png";
import ThumbVideo from "../../../../../assets/thumbVideo.png";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../Modal/ConfirmModal";
import { ReplicateContentModal } from "../../Modal/ReplicateContentModal";
import { VideoContentPreviewModal } from "../../Modal/VideoContentPreviewModal";
import { WarningDeleteModal } from "../../Modal/WarningDeleteModal";
import {
  EditLabel,
  ItensPerPageArea,
  PaginationArea,
  PaginationContainer,
  StatusTag,
  StyledFlexColumn,
  StyledFlexRow,
  StyledIconButton,
  StyledIconButtonExtraSmall,
  StyledImageContainer,
  StyledMenuItem,
  StyledSelectField,
  StyledTypography,
} from "./styled";

interface TableGridProps {
  data: any[];
  rawData: any[];
  page: number;
  setPage: any;
  pageSize: number;
  setPageSize: any;
  total: number;
  loading: boolean;
}

export const LearningObjectTable = ({
  data,
  rawData,
  loading,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
}: TableGridProps) => {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [type, setType] = useState("");
  const [contentInfo, setContentInfo] = useState({});

  const [confirmModal, setConfirmModal] = useState<any>(false);
  const [warningDeleteModal, setWarningDeleteModal] = useState<any>(false);
  const [viewContentPreviewModal, setViewContentPreviewModal] =
    useState<any>(false);

  const [replicateModal, setReplicateModal] = useState(false);

  const columns = [
    {
      Header: "Conteúdo",
      accessor: "name",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { type = null, name = null },
        },
      }) => {
        const thumbnailByType =
          type === "video"
            ? ThumbVideo
            : type === "podcast"
            ? ThumbPodcast
            : type === "pdf"
            ? ThumbPdf
            : ThumbArticle;

        return (
          <div>
            <StyledFlexRow>
              <div>
                <StyledImageContainer url={thumbnailByType} />
              </div>
              <StyledFlexColumn
                style={{
                  maxWidth: "311px",
                  whiteSpace: "normal",
                  marginLeft: "16px",
                }}
              >
                <div style={{ textAlign: "left", width: "100%" }}>
                  <StyledTitle variant="body3" tag="p">
                    {name}
                  </StyledTitle>
                </div>
              </StyledFlexColumn>
            </StyledFlexRow>
          </div>
        );
      },
    },
    {
      Header: "Tipo",
      accessor: "type",
      disableSortBy: true,
      Cell: ({ value }) => {
        const iconByType =
          value === "video"
            ? "IconVideo"
            : value === "podcast"
            ? "IconMicrophone"
            : value === "file"
            ? "IconFile"
            : value === "exam"
            ? "IconListNumbers"
            : "IconBlockquote";

        const textByType =
          value === "video"
            ? "Vídeo"
            : value === "podcast"
            ? "Podcast"
            : value === "pdf"
            ? "Pdf"
            : value === "exam"
            ? "Avaliação"
            : "Artigo";

        return (
          <StyledFlexRow>
            <StyledIconButtonExtraSmall
              size="small"
              variant="line"
              icon={iconByType}
              style={{ marginRight: "8px" }}
            />
            <StyledText variant="body3">{textByType}</StyledText>
          </StyledFlexRow>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <StatusTag variant="body3" status={value}>
            {value === "published"
              ? "Disponível"
              : value === "draft"
              ? "Rascunho"
              : value === "processing"
              ? "Processando"
              : "Erro"}
          </StatusTag>
        );
      },
    },
    {
      Header: "Compartilhamento",
      accessor: "isShared",
      disableSortBy: true,
      Cell: ({ value }) => (
        <StyledText variant="body3">{value ? "Sim" : "Não"}</StyledText>
      ),
    },
    {
      Header: "Treinamentos associados",
      accessor: "associateCourses",
      disableSortBy: true,
      Cell: ({ value = 0 }) => <StyledText variant="body3">{value}</StyledText>,
    },
    {
      Header: "Criado em",
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ value }) => (
        <StyledText variant="body3">
          {value ? dayjs(value).format("DD/MM/YYYY") : "-"}
        </StyledText>
      ),
    },
    {
      Header: "Última atualização",
      accessor: "updatedAt",
      disableSortBy: true,
      Cell: ({ value = null }) => (
        <StyledText variant="body3">
          {value ? dayjs(value).format("DD/MM/YYYY") : "-"}
        </StyledText>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const {
          _id,
          type,
          associateCourses = 0,
          content,
          url,
          questions = [],
          isShared,
        } = original;

        const validation =
          type === "article"
            ? content?.html || url?.path?.html
            : type === "exam"
            ? questions?.length
            : type === "podcast"
            ? url?.path
            : type === "video"
            ? url?.path
            : type === "pdf"
            ? url?.path
            : true;

        const options = [
          {
            onClick: (e: any) => {
              if (isShared) {
                setId(_id);
                setType(type);
                setReplicateModal(true);
                return;
              }

              navigate(`/lms/manage-courses/edit/content/${type}/0/${_id}`);
            },
            children: (
              <StyledFlexRow>
                <StyledIconButton
                  size="small"
                  variant="line"
                  icon="IconPencil"
                />
                <EditLabel variant="body3">Editar conteúdo</EditLabel>
              </StyledFlexRow>
            ),
          },
        ];

        if (validation)
          options.unshift({
            onClick: (e: any) => {
              setViewContentPreviewModal(true);
              setContentInfo(original);
            },
            children: (
              <StyledFlexRow>
                <StyledIconButton size="small" variant="line" icon="IconEye" />
                <StyledText variant="body3">Visualizar conteúdo</StyledText>
              </StyledFlexRow>
            ),
          });

        if (associateCourses <= 0)
          options.push({
            onClick: (e: any) => {
              setId(_id);
              setWarningDeleteModal(true);
            },
            children: (
              <Tooltip arrow title={""}>
                <StyledFlexRow isDelete={true}>
                  <StyledIconButton
                    size="small"
                    variant="line"
                    icon="IconTrash"
                  />
                  <EditLabel isDelete={associateCourses > 0} variant="body3">
                    Excluir conteúdo
                  </EditLabel>
                </StyledFlexRow>
              </Tooltip>
            ),
          });

        return (
          <>
            <Menu
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              type="select"
              options={options}
            >
              <IconButton size="small" variant="line" icon="IconDotsVertical" />
            </Menu>
          </>
        );
      },
    },
  ];

  const totalPages = Math.ceil(total / pageSize);

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum conteúdo encontrado.",
        }}
        customPagination={({ gotoPage, setPageSize: setComponentPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > total ? total : page * pageSize}
                &nbsp;de&nbsp;{total || 0}&nbsp;
                {total === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={totalPages}
                onChange={(_, value) => {
                  const currentPage = value;

                  setPage(currentPage);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
      <WarningDeleteModal
        type={"content"}
        _id={id}
        open={warningDeleteModal}
        setOpen={setWarningDeleteModal}
        setConfirmModal={setConfirmModal}
      />
      <ConfirmModal
        type={"content"}
        open={confirmModal}
        setOpen={setConfirmModal}
      />

      <VideoContentPreviewModal
        open={viewContentPreviewModal}
        setOpen={setViewContentPreviewModal}
        data={contentInfo}
      />

      <ReplicateContentModal
        open={replicateModal}
        onClose={() => setReplicateModal(false)}
        handleClick={() =>
          navigate(`/lms/manage-courses/edit/content/${type}/0/${id}`)
        }
      />
    </>
  );
};

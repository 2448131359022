import "./styles.css";

import {
  PlayButton,
  useMediaState,
  SeekButton,
  MuteButton,
  FullscreenButton,
  VolumeSlider,
} from "@vidstack/react";

import PlayIcon from "../../../../../assets/player-play.svg";
import PauseIcon from "../../../../../assets/player-pause.svg";
import Backward5Icon from "../../../../../assets/player-backward-5.svg";
import Forward5Icon from "../../../../../assets/player-forward-5.svg";
import MaximizeIcon from "../../../../../assets/player-maximize.svg";
import LowSoundIcon from "../../../../../assets/player-low-sound.svg";
import HighSoundIcon from "../../../../../assets/player-high-sound.svg";
import MuteSoundIcon from "../../../../../assets/player-mute-sound.svg";

import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

export function Play() {
  const isPaused = useMediaState("paused");
  return (
    <Tooltip title={isPaused ? "Play" : "Pause"} placement="top">
      <PlayButton className={`play-button button`}>
        {isPaused ? (
          <PlayIcon style={{ transform: "scale(0.6)" }} />
        ) : (
          <PauseIcon style={{ transform: "scale(0.6)" }} />
        )}
      </PlayButton>
    </Tooltip>
  );
}

export function Forward5() {
  return (
    <Tooltip title={"Avançar 5s"} placement="top">
      <SeekButton className={`forward5 button`} seconds={5}>
        <Forward5Icon style={{ transform: "scale(0.6)" }} />
      </SeekButton>
    </Tooltip>
  );
}

export function Backward5() {
  return (
    <Tooltip title={"Voltar 5s"} placement="top">
      <SeekButton className={`backward5 button`} seconds={-5}>
        <Backward5Icon style={{ transform: "scale(0.6)" }} />
      </SeekButton>
    </Tooltip>
  );
}

export function Mute() {
  const volume = useMediaState("volume"),
    isMuted = useMediaState("muted");

  return (
    <>
      <Tooltip title={isMuted ? "Reativar o som" : "Sem áudio"} placement="top">
        <MuteButton className={`mute-button button`}>
          {isMuted || volume == 0 ? (
            <MuteSoundIcon />
          ) : volume < 0.5 ? (
            <LowSoundIcon />
          ) : (
            <HighSoundIcon />
          )}
        </MuteButton>
      </Tooltip>

      <VolumeSlider.Root className={`volume-slider slider sliderSmall`}>
        <VolumeSlider.Track className={"track"} />
        <VolumeSlider.TrackFill className={`trackFill track`} />
        <VolumeSlider.Preview className={"previewVolume"} noClamp>
          <VolumeSlider.Value className={"volumeValue"} />
        </VolumeSlider.Preview>
        <VolumeSlider.Thumb className={"thumb"} />
      </VolumeSlider.Root>
    </>
  );
}

export function Fullscreen() {
  const isActive = useMediaState("fullscreen");
  return (
    <Tooltip
      title={isActive ? "Sair da tela inteira" : "Tela inteira"}
      placement="top"
    >
      <FullscreenButton className={`fullscreen-button button`}>
        <MaximizeIcon style={{ transform: "scale(0.6)" }} />
      </FullscreenButton>
    </Tooltip>
  );
}

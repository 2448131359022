import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Collapse, Grid } from "@mui/material";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

import MetricInfo from "../MetricInfo";
import {
  ChartArea,
  ChartGraph,
  ChartGrid,
  ChartInfo,
  ChartSubTitle,
  ChartTitle,
  InfoText,
  MetricsHeader,
  Title,
  ToggleButton,
} from "./styles";

const StudentMetrics = ({ employee = {} }: { employee: any }) => {
  ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

  const [open, setOpen] = useState<any>(true);
  const [chartInfo, setChartInfo] = useState<any>([0, 0, 0]);

  useEffect(() => {
    setChartInfo([
      employee?.totalDoneCourses,
      employee?.totalDoingCourses,
      employee?.totalNotStartedCourses,
    ]);
  }, [employee]);

  const data = {
    datasets: [
      {
        data: chartInfo,
        backgroundColor: ["#2ED9B8", "#4DA0FE", "#FEA034"],
        weight: 2,
      },
    ],
  };

  return (
    <div>
      <MetricsHeader>
        <Title variant="headline8">Insights</Title>
        <ToggleButton onClick={() => setOpen(!open)} variant="primary">
          {open ? "Ocultar insights" : "Exibir insights"}
          {open ? (
            <Icons name="IconChevronsUp" fill="transparent" />
          ) : (
            <Icons name="IconChevronsDown" fill="transparent" />
          )}
        </ToggleButton>
      </MetricsHeader>
      <Collapse in={open}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <MetricInfo
              iconName="IconSchool"
              title="Total de treinamentos"
              subtitle={employee?.totalCourses ?? 0}
            />
            <MetricInfo
              iconName="IconNotebook"
              title="Nota média"
              subtitle={`${employee?.averageGrade ?? 0}/10`}
            />
            <MetricInfo
              iconName="IconCertificate"
              title="Certificados emitidos"
              subtitle={employee?.totalCertificates ?? 0}
            />
          </Grid>
          <ChartGrid item sm={4}>
            <ChartArea>
              <ChartTitle variant="headline8">
                Status dos treinamentos
              </ChartTitle>
              <ChartSubTitle variant="body4">Total</ChartSubTitle>
              <ChartInfo>
                <ChartGraph>
                  <Doughnut
                    data={data}
                    options={{
                      cutout: 45,
                      responsive: false,
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                      },
                    }}
                  />
                </ChartGraph>
                <div>
                  <InfoText variant="body4">
                    <Icons name="IconPentagon" fill="#FEA034" />
                    <b>{chartInfo[2] ?? 0}%</b> Não iniciados
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconTriangle" fill="#4da0fe" />
                    <b>{chartInfo[1] ?? 0}%</b> Em andamento
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconPlayerStop" fill="#2ED9B8" />
                    <b>{chartInfo[0] ?? 0}%</b> Concluídos
                  </InfoText>
                </div>
              </ChartInfo>
            </ChartArea>
          </ChartGrid>
          <Grid item sm={4}>
            <MetricInfo
              iconName="IconUserCheck"
              title="Taxa média de aprovação"
              subtitle={`${employee?.passRate ?? 0}%`}
              studentsInfo={employee?.passRateCourses ?? 0}
              type="course"
              tooltipText="A taxa de aprovação representa a quantidade de treinamentos em que o aluno foi aprovado sobre a quantidade total de treinamentos com avaliação."
            />
            <MetricInfo
              iconName="IconUserX"
              title="Taxa média de reprovação"
              subtitle={`${employee?.failureRate ?? 0}%`}
              studentsInfo={employee?.failureRateCourses ?? 0}
              type="course"
              tooltipText="A taxa de reprovação representa a quantidade de treinamentos em que o aluno foi reprovado sobre a quantidade total de treinamentos com avaliação."
            />
            <MetricInfo
              iconName="IconUserOff"
              title="Taxa média de abandono"
              subtitle={`${employee?.abandonRate ?? 0}%`}
              studentsInfo={employee?.abandonRateCourses ?? 0}
              type="course"
              tooltipText="A taxa de abandono representa a quantidade de treinamentos que não foram acessados há mais de 5 dias pelo aluno sobre a quantidade total de treinamentos deste aluno."
            />
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
};

export default StudentMetrics;

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Footer,
  FooterArea,
  ProgressArea,
  ProgressBar,
  ProgressWrapper,
  TotalAnswers,
} from "./style";
import { IExamFooterBar } from "./types";

export const FooterBar = ({
  isDone,
  answeredQuestions = 0,
  allMandatoryAnswered,
  totalQuestions = 0,
  isLoading,
  isPreview,
  onClick,
}: IExamFooterBar) => {
  const totalDone = Math.round((answeredQuestions / totalQuestions) * 100);

  if (isDone) return <></>;
  return (
    <Footer>
      <FooterArea>
        <ProgressArea>
          <ProgressWrapper>
            <ProgressBar totalDone={totalDone}>
              <span />
            </ProgressBar>
          </ProgressWrapper>
          <TotalAnswers variant="body4">
            {answeredQuestions} de {totalQuestions} Respondidas
          </TotalAnswers>
        </ProgressArea>
        <Button
          disabled={!allMandatoryAnswered || isPreview}
          loading={isLoading}
          onClick={onClick}
          size="medium"
          variant="primary"
        >
          Enviar avaliação
        </Button>
      </FooterArea>
    </Footer>
  );
};

import { AddClassesSection } from "../AddClassesSection";
import { CloseButton, ModalHeader, Title } from "../AddModuleModal/styled";
import { StyledModal } from "./styled";

export const AddClassModal = ({
  open,
  closeModal,
  classData,
  courseHierachy,
}: {
  open: boolean;
  closeModal: () => any;
  classData: any;
  courseHierachy: any;
}) => {
  const {
    _id = "",
    courseId = "",
    name = "",
    description = "",
    type = "edit",
    parentId = "",
    learningObjectId = null,
    setAddClass = null,
    setExpanded = null,
    isShared = false,
  } = classData;

  return (
    <StyledModal onClose={closeModal} open={open}>
      <div>
        <ModalHeader>
          <CloseButton
            size="medium"
            variant="line"
            icon="IconX"
            onClick={closeModal}
          />
          <Title variant="headline6">
            {name ? "Editar" : "Adicionar"} aula
          </Title>
        </ModalHeader>
        <AddClassesSection
          _id={_id}
          courseId={courseId}
          name={name}
          description={description}
          learningObjectId={learningObjectId}
          parentId={parentId}
          setAddClass={setAddClass}
          closeModal={closeModal}
          type={type}
          courseHierachy={courseHierachy}
          isShared={isShared}
        />
      </div>
    </StyledModal>
  );
};

import { useState } from "react";

import { Content } from "./Components/Content";
import { StyledTitle } from "@utils";

import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

export const MyCourse = () => {
  const [myContent, setMyContent] = useState<any>([]);
  const [content, setContent] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [certificateContent, setCertificateContent] = useState<any>([]);
  const [certificateFilters, setCertificateFilters] = useState<any>([]);

  const { isFetching: isLoadingMyCourses } = trpc.course.getMyCourses.useQuery(
    undefined,
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const content = data?.courses || [];
        const filters = data?.filters || [];

        setFilters(filters);
        setContent(content);
        setMyContent(data);
      },
    }
  );

  const { isFetching: isLoadingCertificatesEmployeeFiltered } =
    trpc.certificate.getCertificatesByEmployee.useQuery(
      { filters: null },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const certificate = data?.certificate || [];
          const filters = data?.filters || [];

          setCertificateContent(certificate);
          setCertificateFilters(filters);
        },
      }
    );
  const isLoading = isLoadingMyCourses || isLoadingCertificatesEmployeeFiltered;

  return (
    <PageContainer>
      <div style={{ padding: "40px 0px" }}>
        <StyledTitle variant="headline6" setColor="neutral20">
          Meus treinamentos
        </StyledTitle>
      </div>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100vh" />
      ) : (
        <Content
          content={content}
          filters={filters}
          certificateContent={certificateContent}
          certificateFilters={certificateFilters}
          highlights={myContent?.highlights || []}
          isLoading={isLoading}
        />
      )}
    </PageContainer>
  );
};

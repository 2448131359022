import { useState, useMemo } from "react";
import { cloneDeep } from "lodash";

import { Search } from "./Search";
import { Filters } from "./Filters";
import { CertificateTable } from "./CertificateTable";

import { TableProps } from "./types";
import { getFromLS } from "@flash-tecnologia/hros-web-utility";
import { CertificateCardView } from "./CertificateCardView";
import { MobileFilters } from "./MobileFilters";

export const CertificateTab = ({
  loading,
  data = [],
  isMobile,
  filters,
  typeView,
  handleSetTypeView,
  refetch,
}: TableProps) => {
  const {
    attributes: { name = "" },
  } = getFromLS("userAuth") || {};

  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});
  const [openFilters, setOpenFilters] = useState<any>(false);

  const filteredData = useMemo(() => {
    if (!search && !Object.keys(filterStates)?.length) return data;

    let dataToFilter = cloneDeep(data);

    if (search) {
      dataToFilter = dataToFilter?.filter((item) => {
        const byName = item?.name?.toLowerCase().includes(search.toLowerCase());
        return byName;
      });
    }

    if (Object.keys(filterStates)?.length) {
      dataToFilter =
        dataToFilter?.filter((item) => {
          for (const key in filterStates) {
            const itemValue = item[key] !== undefined ? item[key] : undefined;

            if (itemValue === undefined) return false;

            if (Array.isArray(itemValue)) {
              if (!itemValue.some((el) => filterStates[key].includes(el))) {
                return false;
              }
            } else {
              const parsedItem =
                typeof item?.[key] === "object" && item?.[key] != null
                  ? item?.[key]?.name
                  : item?.[key];

              if (!filterStates[key].includes(parsedItem)) return false;
            }
          }
          return true;
        }) || [];
    }

    return dataToFilter;
  }, [data, search, filterStates]);

  return (
    <div>
      <Search
        onChange={setSearch}
        isMobile={isMobile}
        setOpenFilters={setOpenFilters}
        disabled={!Boolean((data || [])?.length)}
      />

      {isMobile ? (
        <MobileFilters
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          data={data || []}
          filters={filters}
          onFilter={setFilterStates}
        />
      ) : filters?.[0] ? (
        <Filters
          data={data || []}
          typeView={typeView}
          handleSetTypeView={handleSetTypeView}
          disabled={!Boolean((data || [])?.length)}
          filters={filters}
          onFilter={setFilterStates}
        />
      ) : (
        <></>
      )}

      {typeView === "list" && !isMobile ? (
        <CertificateTable
          data={filteredData || []}
          rawData={data || []}
          name={name}
          pageSize={10}
          loading={loading}
          refetch={() => refetch?.()}
        />
      ) : (
        <CertificateCardView data={filteredData || []} isLoading={loading} />
      )}
    </div>
  );
};

import { useEffect, useState } from "react";

import {
  Container,
  SubImageContainer,
  SubContainer,
  LabelTitle,
  PreviewArea,
  GenerateButton,
  StyledFlex,
  StyledIconButton,
  LabelSubTitle,
} from "./styles";

import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import CertificatePreview from "@components/CertificatePreview";

import { getCertificateAndDownload, track } from "@utils";
import CertificateBuilder from "@components/CertificateBuilder";

export const CertificateCard = ({
  info: { createdAt = null, course, certificate },
}) => {
  const [certificateInfo, setCertificateInfo] = useState<any>({});

  useEffect(() => {
    if (Object.keys(certificateInfo).length)
      getCertificateAndDownload({ name: certificateInfo?.name });
  }, [certificateInfo]);

  return (
    <>
      <Container>
        <SubImageContainer>
          <PreviewArea>
            <CertificatePreview
              data={{
                courseName: course?.name ?? "Sem nome",
                companyLogo: certificate?.companyLogo,
                companyName: certificate?.companyName,
                signText: certificate?.signText,
                signerName: certificate?.signerName,
                signerPostion: certificate?.signerPostion,
                expiresDate: certificate?.expiresDate,
              }}
            />
          </PreviewArea>
        </SubImageContainer>

        <SubContainer>
          <LabelTitle variant="body2">{course?.name ?? "Sem nome"}</LabelTitle>
          <StyledFlex>
            <StyledIconButton
              variant="line"
              size="small"
              icon={"IconFileCertificate"}
            />
            <LabelSubTitle variant="body4">
              Emitido em{" "}
              {createdAt ? dayjs(new Date(createdAt)).format("DD/MM/YYYY") : ""}
            </LabelSubTitle>
          </StyledFlex>
          <GenerateButton
            onClick={() => {
              setCertificateInfo({
                name: course?.name ?? "",
                totalHours: certificate?.totalHours,
                signText: certificate?.signText,
                signerName: certificate?.signerName,
                signerPostion: certificate?.signerPostion,
                expiresDate: certificate?.expiresDate,
                companyLogo: certificate?.companyLogo,
                companyName: certificate?.companyName,
                certificateDate: createdAt,
              });
              track({
                name: "employee_lms_mycourses_downloadcertificate_clicked",
              });
            }}
            size="large"
            variant="primary"
          >
            <Icons name="IconCertificate" fill="transparent" />
            Baixar certificado
          </GenerateButton>
        </SubContainer>
      </Container>
      <CertificateBuilder
        courseName={certificateInfo?.name}
        totalHours={certificateInfo?.totalHours}
        employeeName={name}
        signText={certificateInfo?.signText}
        signerName={certificateInfo?.signerName}
        signerPostion={certificateInfo?.signerPostion}
        expiresDate={certificateInfo?.expiresDate}
        companyLogo={certificateInfo?.companyLogo}
        companyName={certificateInfo?.companyName}
        certificateDate={certificateInfo?.certificateDate}
      />
    </>
  );
};

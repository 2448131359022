import { Grid } from "@mui/material";
import styled from "styled-components";

export const TabContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const AttatchmentGrid = styled(Grid)`
  justify-content: space-evenly;
  display: flex;
`;

export const TabArea = styled.div`
  border-top: 1px solid #ebe6e9;
  margin-top: 32px;
  padding-top: 32px;
`;

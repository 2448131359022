import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const WarnArea = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

export const WarnIcon = styled.div`
  background: ${({ theme }) => theme.colors.feedback.info[90]};
  padding: 8px;
  border-radius: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.feedback.info[50]};
  }
`;

export const WarnTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
  font-weight: bold !important;
  line-height: 22px;
`;

export const WarnDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
  line-height: 20px;
  margin-bottom: 6px;
`;

export const CloseButton = styled.div`
  align-self: self-start;
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.feedback.info[40]};
  }
`;

import EmptyContentState from "../../../../assets/emptyContentState.svg";

import { Container, TextContainer, LabelTitle, LabelSubTitle } from "./styles";

export const NoAnswerExam = () => {
  return (
    <Container>
      <EmptyContentState />
      <TextContainer>
        <LabelTitle variant="headline6">
          Avaliação ainda não realizada
        </LabelTitle>
        <LabelSubTitle variant="body2">
          Aguarde o aluno realizar a avaliação.
        </LabelSubTitle>
      </TextContainer>
    </Container>
  );
};

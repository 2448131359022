import styled from "styled-components";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 50px;
`;

const TextContainer = styled.div`
  max-width: 536px;
  text-align: center;
`;

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

const LabelTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    margin-bottom: 15px;
  }
`;

const LabelSubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

const StyledFlex = styled.div`
  display: flex;
`;

export {
  Container,
  TextContainer,
  LabelTitle,
  LabelSubTitle,
  StyledDescription,
  StyledFlex,
};

import { TagList } from "../TagsList";

import { DataCard } from "../DataCard";

interface StudentsTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const StudentsTab = ({ data, onNavigate }: StudentsTabProps) => {
  const peopleInCourse = (data?.peopleInCourse || []).map((people) => {
    return {
      id: people._id,
      label: people.name,
      variant: "primary",
    };
  });

  return (
    <DataCard
      title="Revisar alunos do treinamento"
      subTitle="Confira os alunos que irão realizar o treinamento."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Alunos selecionados",
              description: (
                <TagList
                  style={{ marginTop: "10px" }}
                  minTags={50}
                  tags={peopleInCourse}
                />
              ),
            },
          ],
        },
      ]}
    />
  );
};

import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const PlayerSubTitle = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

export const MobileTitleArea = styled.div`
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 8px 20px;
  position: relative;
  svg {
    fill: transparent;
    margin-right: 13px;
  }
`;

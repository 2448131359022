import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { AccordionSummary, TextField } from "@mui/material";

import { track } from "../../../../utils/segment";
import {
  SearchAccordionArea,
  IconSearch,
  IconInfoCircle,
  DescriptionArea,
  CheckIcon,
  StyledAccordion,
  StyledAccordionDetails,
  ModulesContainer,
  StyledAccordionTitleNumber,
  StyledDescriptionSubContainer,
  StyledFlexRow,
  LockIcon,
} from "./styled";

import { StyledText } from "../../../../utils/common-styled.utils";

export const Accordion = ({
  viewType,
  modules,
  moduleId,
  courseId,
  selected,
  setSelected,
  isMobile = false,
  isDescription,
  setIsDescription,
  isGettingCoursesModules,
  openMobileDrawer,
}: {
  viewType: string;
  modules: any;
  moduleId: string;
  courseId: string;
  selected: any;
  setSelected: any;
  isMobile?: boolean;
  isDescription: any;
  setIsDescription: any;
  isGettingCoursesModules: boolean;
  openMobileDrawer?: any;
}) => {
  const [search, setSearch] = useState<any>("");
  const [expanded, setExpanded] = useState<any>(null);
  const [filteredModules, setFilteredModules] = useState<any>(null);

  const accordeonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDescription) setExpanded(`accordion${moduleId}`);
  }, [moduleId, isDescription]);

  useEffect(() => {
    const cleanValue = search?.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
    const regex = new RegExp(cleanValue, "gi");
    const filteredModules = modules?.map((modules) => {
      return {
        ...modules,
        classes: modules?.classes?.filter(({ name }) => name?.match(regex)),
      };
    });
    setFilteredModules(filteredModules);
  }, [modules, search]);

  const renderAccordion = useMemo(() => {
    return filteredModules?.map((m, index) => {
      const quantitiesFinished = m?.classes?.filter(({ done }) => done)?.length;
      const expandedValidation =
        isMobile ||
        search?.length > 0 ||
        expanded === `accordion${m?.position}`;

      return (
        <StyledAccordion
          ref={accordeonRef}
          key={index}
          expanded={expandedValidation}
          onChange={() =>
            expanded === `accordion${m?.position}`
              ? setExpanded(false)
              : setExpanded(`accordion${m?.position}`)
          }
        >
          <AccordionSummary
            style={{
              flexDirection: "row-reverse",
              padding: "16px 24px",
              minHeight: "100%",
            }}
            className={isMobile ? "mobile-accordion" : ""}
            expandIcon={
              isMobile ? null : (
                <Icons name="IconChevronDown" fill="transparent" />
              )
            }
          >
            <StyledFlexRow
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: "16px",
              }}
            >
              <StyledText
                variant="body4"
                setColor="neutral30"
                style={{ fontWeight: 600 }}
              >
                {m?.name}
              </StyledText>

              <StyledAccordionTitleNumber variant="caption">
                {quantitiesFinished}/{m.classes.length}
              </StyledAccordionTitleNumber>
            </StyledFlexRow>
          </AccordionSummary>
          {m?.classes?.map((cl: any, clIndex: any) => {
            let iconType;

            switch (cl?.type) {
              case "podcast":
                iconType = "IconMicrophone";
                break;
              case "article":
                iconType = "IconBlockquote";
                break;
              case "pdf":
                iconType = "IconFile";
                break;
              case "exam":
                iconType = "IconListNumbers";
                break;
              case "question":
                iconType = "IconQuestionMark";
                break;
              default:
                iconType = "IconVideo";
                break;
            }

            return (
              <StyledAccordionDetails
                key={clIndex}
                onClick={() => {
                  if (
                    m?.position !== selected?.moduleId ||
                    cl?._id !== selected?._id
                  ) {
                    setSelected(null);
                  }
                  setIsDescription(false);

                  if (viewType === "preview") {
                    navigate(
                      `/lms/manage-courses/${courseId}/${m?.position}/${cl?._id}/preview`
                    );
                  } else {
                    navigate(
                      `/lms/my-courses/${courseId}/${m?.position}/${cl?._id}/view`
                    );
                  }
                  if (isMobile) openMobileDrawer(false);
                }}
                selected={
                  m?.position === selected?.moduleId &&
                  cl?._id === selected?._id
                }
                disabled={
                  viewType === "preview" ? false : cl?.disabled || false
                }
              >
                <StyledFlexRow
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <StyledFlexRow
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Icons name={iconType} size={16} fill={"transparent"} />

                    <StyledText
                      variant="body4"
                      setColor="neutral30"
                      style={{
                        marginLeft: "8px",
                        marginRight: "16px",
                      }}
                    >
                      {cl?.name}
                    </StyledText>
                  </StyledFlexRow>
                  <div>
                    {cl?.disabled ? (
                      <LockIcon
                        name={"IconLock"}
                        size={12}
                        fill={"transparent"}
                      />
                    ) : (
                      <CheckIcon
                        name={cl?.done ? "IconCircleCheck" : "IconCircle"}
                        checked={cl?.done}
                        size={12}
                        fill={"transparent"}
                      />
                    )}
                  </div>
                </StyledFlexRow>
              </StyledAccordionDetails>
            );
          })}
        </StyledAccordion>
      );
    });
  }, [filteredModules, selected, isMobile, search, expanded]);

  return (
    <div
      style={{
        flex: 1,
        overflow: "hidden",
      }}
    >
      <StyledDescriptionSubContainer>
        {isGettingCoursesModules ? (
          <Skeleton variant="rectangular" width="100%" height="100vh" />
        ) : (
          <>
            <SearchAccordionArea>
              <IconSearch size={24} name="IconSearch" />
              <TextField
                value={search}
                onChange={({ target: { value } }) => setSearch(value)}
                fullWidth
                placeholder="Busque por uma aula"
                onClick={() => {
                  track({ name: "employee_lms_course_searchclass_clicked" });
                }}
              />
            </SearchAccordionArea>
            <ModulesContainer>
              <DescriptionArea
                selected={!!isDescription}
                onClick={() => setIsDescription(true)}
              >
                <IconInfoCircle
                  size={24}
                  name="IconInfoCircle"
                  fill="transparent"
                />
                <StyledText
                  variant="body4"
                  setColor="neutral30"
                  style={{ marginLeft: "16px" }}
                >
                  Descrição do treinamento
                </StyledText>
              </DescriptionArea>
              {renderAccordion}
            </ModulesContainer>
          </>
        )}
      </StyledDescriptionSubContainer>
    </div>
  );
};

import styled from "styled-components";

export const Certificate = styled.div`
  border-radius: 10px;
  border: 10px solid ${({ theme }) => theme.colors.neutral.light.light2};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px;
  width: 100%;
`;

export const IconButton = styled.div`
  width: 34px;
  height: 34px;
  background: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

export const InfoText = styled.p`
  font-family: "Cabinet Grotesk";
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 700;
  font-size: 12px;
  margin: 8px 0;
`;

export const Title = styled.p`
  font-family: "Cabinet Grotesk";
  font-weight: 700;
  font-size: 20px;

  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-bottom: 8px;
`;

export const Duration = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 7px;

  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 20px;
`;

export const DurationIcon = styled.div`
  width: 10px;
  height: 10px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[60]};
  border-radius: 10px;
  align-items: center;
  display: flex;
  margin-right: 4px;
  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 70%;
      color: ${({ theme }) => theme.colors.neutral[40]};
    }
  }
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 12px;

  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-bottom: 10px;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 9px;
`;

export const Signature = styled.p`
  font-family: "Allura", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #000000;
`;

export const MiniDivider = styled.div`
  height: 1px;
  width: 30%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 12px;
`;

export const SignerName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const SignerPosition = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 7px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
`;

export const ExpireZone = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 8px;
  width: max-content;
  height: fit-content;
`;

export const DurationFooter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 600;
  font-size: 7px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  &:last-child {
    margin-right: 0;
  }
`;

export const CompanyDataArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 50%;
  img {
    max-width: 300px;
    max-height: 275px;
    width: 60%;
    display: inline-block;
  }
`;

export const CompanyInfo = styled.div`
  font-weight: 600;
  font-size: 7px;
  text-align: right;
  color: ${({ theme }) => theme.colors.neutral[40]};
  word-wrap: break-word;
  white-space: -moz-pre-wrap;
  white-space: pre-wrap;
  width: 100%;
  text-align: center;
`;

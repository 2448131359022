import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  Breadcrumbs,
  IconButton,
  Icons,
  Menu,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  DotSeparator,
  FlexBetween,
  FlexCenter,
  HasCertificateTag,
  PublishedTag,
  StyledFlex,
} from "./styled";

import { trpc } from "@api/client";
import { GenerateReportModal } from "@components/GenerateReportModal";
import { StyledText, StyledTitle, dispatchToast, track } from "@utils";
import { ConfirmModal } from "../Modal/ConfirmModal";
import { WarningDeleteModal } from "../Modal/WarningDeleteModal";

export const Header = ({ classe = {} }: { classe: any }) => {
  const navigate = useNavigate();
  const { courseId = "" } = useParams();

  const [openReport, setOpenReport] = useState(false);
  const [confirmModal, setConfirmModal] = useState<any>(false);
  const [warningDeleteModal, setWarningDeleteModal] = useState<any>(false);

  const utils = trpc.useContext();

  const { mutate: duplicateCourseByIdMutate } =
    trpc.course.duplicateCourseById.useMutation({});

  const editOption = {
    onClick: (e) => {
      navigate(`/lms/manage-courses/createEditCourse/edit/0/${courseId}`);
    },
    children: (
      <StyledFlex>
        <Icons
          name="IconPencil"
          fill="transparent"
          style={{ marginRight: "8px" }}
        />
        <StyledText variant="body3">Editar treinamento</StyledText>
      </StyledFlex>
    ),
  };

  const exportOption = {
    onClick: async (e) => {
      track({
        name: "company_lms_trainingdetail_exportreport_clicked",
      });
      setOpenReport(true);
    },
    children: (
      <StyledFlex>
        <Icons
          name="IconDownload"
          fill="transparent"
          style={{ marginRight: "8px" }}
        />
        <StyledText variant="body3">
          Exportar relatório do treinamento
        </StyledText>
      </StyledFlex>
    ),
  };

  const dupOption = {
    onClick: (e) => {
      duplicateCourseByIdMutate(
        {
          courseId,
        },
        {
          onSuccess: () => {
            dispatchToast({
              content:
                "O treinamento foi duplicado com sucesso e a cópia está disponível como rascunho.",
              type: "success",
            });
            utils.learningObject.getAllLearningObject.invalidate();
            utils.learningObject.getAllLearningObjectFiltered.invalidate();
            utils.course.getManageCourses.invalidate();
            utils.course.getCoursesForAdminFiltered.invalidate();
          },
        }
      );
    },
    children: (
      <StyledFlex>
        <Icons
          name="IconCopy"
          fill="transparent"
          style={{ marginRight: "8px" }}
        />
        <StyledText variant="body3">Duplicar treinamento</StyledText>
      </StyledFlex>
    ),
  };

  const deleteOption = {
    onClick: async (e) => {
      setWarningDeleteModal(true);
    },
    children: (
      <StyledFlex>
        <Icons
          name="IconTrash"
          fill="transparent"
          style={{ marginRight: "8px" }}
        />
        <StyledText variant="body3">Excluir treinamento</StyledText>
      </StyledFlex>
    ),
  };

  return (
    <>
      <Breadcrumbs
        separator={
          <Icons
            name={"IconChevronRight"}
            color="var(--color-neutral-dark5)"
            fill="var(--color-neutral-100)"
          />
        }
        breadcrumbs={[
          <Link to="/lms/manage-courses">
            <StyledText variant="body4" setColor="neutral50">
              Gerenciar treinamentos
            </StyledText>
          </Link>,
          <StyledText variant="body4" setColor="neutral50">
            Detalhe do treinamento
          </StyledText>,
        ]}
        style={{ marginTop: "50px", marginBottom: "25px" }}
      />

      <FlexBetween style={{ marginBottom: "25px" }}>
        <FlexCenter>
          <StyledTitle variant="headline6" setColor="neutral20">
            {classe?.name || "Curso LMS - Flash People"}
          </StyledTitle>
          <PublishedTag variant="primary" status={classe?.status}>
            {classe?.status === "published"
              ? "Disponível"
              : classe?.status === "draft"
              ? "Rascunho"
              : "Arquivado"}
          </PublishedTag>

          {classe?.certificateId ? (
            <HasCertificateTag variant="primary">
              <Icons name={"IconCertificate"} fill="transparent" />
              Emite certificado
            </HasCertificateTag>
          ) : null}
        </FlexCenter>

        <Menu
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          type="select"
          options={
            classe?.status === "finished"
              ? [editOption, exportOption, deleteOption]
              : [editOption, exportOption, dupOption]
          }
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton size="small" variant="line" icon="IconDotsVertical" />
          </div>
        </Menu>
      </FlexBetween>

      <StyledFlex style={{ alignItems: "center", marginBottom: "25px" }}>
        <StyledText variant="body4">
          {dayjs(classe?.start).isValid()
            ? `Início em ${dayjs(classe?.start).format("DD/MM/YYYY")}`
            : "Não informado"}
        </StyledText>
        <DotSeparator />
        <StyledText variant="body4">
          {dayjs(classe?.end).isValid()
            ? `Disponível até ${dayjs(classe?.end).format("DD/MM/YYYY")}`
            : "Sempre diponível"}
        </StyledText>
      </StyledFlex>

      <GenerateReportModal
        open={openReport}
        courseId={courseId}
        onClose={() => setOpenReport(false)}
      />

      <WarningDeleteModal
        type={"course"}
        _id={courseId}
        open={warningDeleteModal}
        setOpen={setWarningDeleteModal}
        setConfirmModal={setConfirmModal}
      />

      <ConfirmModal
        type={"course"}
        open={confirmModal}
        setOpen={setConfirmModal}
      />
    </>
  );
};

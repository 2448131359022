import { useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseButton,
  HeaderArea,
  StyledButtonContainer,
  StyledContainer,
  StyledIcon,
  StyledIconContainer,
  StyledModal,
  StyledSubTitle,
  StyledTitle,
  SuccessMessage,
} from "./styled";

import { trpc } from "@api/client";
import { track } from "../../utils/segment";

export const GenerateStudentReportModal = ({ open, onClose }) => {
  const { employeeId = "" } = useParams();

  const [employee, setEmployee] = useState<any>({});
  const [successs, setSuccess] = useState<any>(false);

  const handleClose = () => {
    setSuccess(false);
    onClose();
  };

  const { isFetching: isFetchingEmployee } =
    trpc.employee.getEmployeeByIdFromCompany.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setEmployee(data);
      },
    });

  const { isFetching, refetch } = trpc.reports.getEmployeeReportById.useQuery(
    { employeeId },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setSuccess(true);
      },
    }
  );

  return (
    <StyledModal
      isLoading={isFetching || isFetchingEmployee}
      onClose={handleClose}
      open={open}
    >
      <>
        <HeaderArea>
          <StyledIconContainer>
            <StyledIcon
              size={48}
              name={successs ? "IconCheck" : "IconTable"}
              fill="transparent"
            />
          </StyledIconContainer>
          {successs ? (
            <SuccessMessage variant="body3">Pronto!</SuccessMessage>
          ) : null}
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </HeaderArea>
        <StyledContainer>
          <StyledTitle variant="headline6">
            {successs
              ? "Seu relatório está sendo exportado!"
              : "Deseja exportar o relatório?"}
          </StyledTitle>
          {successs ? (
            <StyledSubTitle variant="body3">
              Para acessá-lo é só conferir o e-mail que será enviado em
              <br />
              alguns minutos para o endereço:
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>
                4 primeiros dígitos do <br />
                seu CPF
              </b>{" "}
              como senha.
            </StyledSubTitle>
          ) : (
            <StyledSubTitle variant="body3">
              O relatório apresentará somente os{" "}
              <b>
                dados dessa tabela
                <br />
              </b>{" "}
              convertidos em um arquivo .csv.
              <br /> O arquivo será enviado para o email:
              <br />
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
            </StyledSubTitle>
          )}
        </StyledContainer>
        <StyledButtonContainer>
          {successs ? (
            <Button size="large" variant="primary" onClick={handleClose}>
              Voltar para Detalhes do Aluno
            </Button>
          ) : (
            <>
              <LinkButton
                variant="primary"
                onClick={() => {
                  track({
                    name: "company_lms_studentreport_cancel_clicked",
                  });
                  handleClose();
                }}
              >
                Cancelar
              </LinkButton>
              <Button
                size="large"
                variant="primary"
                loading={isFetching}
                onClick={async () => {
                  track({
                    name: "company_lms_studentreport_exportreport_clicked",
                  });
                  refetch();
                }}
              >
                Exportar relatório
                <Icons name="IconDownload" fill="transparent" />
              </Button>
            </>
          )}
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

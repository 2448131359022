import { StyledTitle } from "@utils";
import { Content } from "./Components/Content";

import { PageContainer } from "@flash-tecnologia/hros-web-ui-v2";

import { FlexBetween } from "./styled";

export const Certificate = () => {
  return (
    <PageContainer>
      <FlexBetween>
        <StyledTitle variant="headline6" setColor="neutral20">
          Configurações gerais de treinamentos
        </StyledTitle>
      </FlexBetween>

      <Content />
    </PageContainer>
  );
};

import styled from "styled-components";
import { Grid } from "@mui/material";

export const AttatchmentArea = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 24px 24px 24px 16px;
  margin-bottom: 10px;
  cursor: pointer;
  svg {
    fill: transparent;
    &:first-child {
      padding-right: 8px;
    }
  }
`;

export const TextArea = styled.div`
  width: 100%;
  p.typography--variant-body3 {
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    font-weight: 600;
  }
  p.typography--variant-body4 {
    color: ${({ theme }) => theme.colors.neutral.light.light4};
    font-weight: 600;
    font-size: 12px;
  }
`;

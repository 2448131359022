import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  LabelSubTitle,
  LabelTitle,
  SharedTag,
  StatusTag,
  StyledFlex,
  StyledIconButton,
  SubContainer,
  SubImageContainer,
  SubImageIcon,
} from "./styled";

import { dayjs, Icons, Menu } from "@flash-tecnologia/hros-web-ui-v2";

import { ConfirmModal } from "../../../Modal/ConfirmModal";
import { ReplicateContentModal } from "../../../Modal/ReplicateContentModal";
import { VideoContentPreviewModal } from "../../../Modal/VideoContentPreviewModal";
import { WarningDeleteModal } from "../../../Modal/WarningDeleteModal";

import { StyledText } from "@utils";

import ThumbArticle from "../../../../../../assets/thumbArticle.png";
import ThumbPdf from "../../../../../../assets/thumbPdf.png";
import ThumbPodcast from "../../../../../../assets/thumbPodcast.png";
import ThumbVideo from "../../../../../../assets/thumbVideo.png";

export const Card = ({ classz }: { classz: any }) => {
  const navigate = useNavigate();

  const [confirmModal, setConfirmModal] = useState<any>(false);
  const [warningDeleteModal, setWarningDeleteModal] = useState<any>(false);
  const [viewContentPreviewModal, setViewContentPreviewModal] =
    useState<any>(false);

  const [replicateModal, setReplicateModal] = useState(false);

  const {
    _id,
    name = "-",
    createdAt = "",
    updatedAt = "",
    banner = {},
    type = "",
    associateCourses = 0,
    status = null,
    isShared = false,
    content,
    url,
    questions,
  } = classz;

  const thumbnailByType =
    type === "video"
      ? ThumbVideo
      : type === "podcast"
      ? ThumbPodcast
      : type === "pdf"
      ? ThumbPdf
      : ThumbArticle;

  const iconByType =
    type === "video"
      ? "IconVideo"
      : type === "podcast"
      ? "IconMicrophone"
      : type === "pdf"
      ? "IconFile"
      : type === "exam"
      ? "IconListNumbers"
      : "IconBlockquote";

  const textByType =
    type === "video"
      ? "Vídeo"
      : type === "podcast"
      ? "Podcast"
      : type === "pdf"
      ? "Pdf"
      : type === "exam"
      ? "Avaliação"
      : "Artigo";

  const validation =
    type === "article"
      ? content?.html || url?.path?.html
      : type === "exam"
      ? questions?.length
      : type === "podcast"
      ? url?.path
      : type === "video"
      ? url?.path
      : type === "pdf"
      ? url?.path
      : true;

  const menuOptions = [
    {
      onClick: () => {
        if (isShared) {
          setReplicateModal(true);
          return;
        }

        navigate(`/lms/manage-courses/edit/content/${type}/0/${_id}`);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconPencil"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <StyledText variant="body3">Editar conteúdo</StyledText>
        </StyledFlex>
      ),
    },
  ];

  if (validation)
    menuOptions.unshift({
      onClick: () => {
        setViewContentPreviewModal(true);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconEye"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <StyledText variant="body3">Visualizar conteúdo</StyledText>
        </StyledFlex>
      ),
    });

  if (associateCourses <= 0)
    menuOptions.push({
      onClick: () => {
        setWarningDeleteModal(true);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconTrash"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <StyledText variant="body3">Excluir conteúdo</StyledText>
        </StyledFlex>
      ),
    });

  return (
    <Container>
      <SubImageContainer url={banner?.url || thumbnailByType}>
        <Menu
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          options={menuOptions}
          type="select"
        >
          <div
            style={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              justifyContent: "flex-end",
              paddingLeft: "24px",
              paddingTop: "24px",
            }}
          >
            <SubImageIcon
              hasNoImage={true}
              size="small"
              variant="filled"
              icon="IconDotsVertical"
            />
          </div>
        </Menu>
      </SubImageContainer>

      <SubContainer>
        <LabelTitle variant="body2" style={{ marginBottom: "4px" }}>
          {name}
        </LabelTitle>
        <StyledFlex style={{ marginBottom: "16px", marginTop: "24px" }}>
          <StyledIconButton variant="line" size="small" icon={iconByType} />
          <LabelSubTitle variant="body4">{textByType}</LabelSubTitle>
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton variant="line" size="small" icon={"IconUsers"} />
          <LabelSubTitle variant="body4">
            {associateCourses} treinamentos associados
          </LabelSubTitle>
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton variant="line" size="small" icon={"IconCalendar"} />

          <LabelSubTitle variant="body4">
            Criado em {createdAt ? dayjs(createdAt).format("DD/MM/YYYY") : "-"}
          </LabelSubTitle>
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton
            variant="line"
            size="small"
            icon={"IconCalendarTime"}
          />
          <LabelSubTitle variant="body4">
            <LabelSubTitle variant="body4">
              Última atualização em{" "}
              {updatedAt ? dayjs(updatedAt).format("DD/MM/YYYY") : "-"}
            </LabelSubTitle>
          </LabelSubTitle>
        </StyledFlex>
        <StyledFlex>
          <StatusTag variant="body3" status={status}>
            {status === "published"
              ? "Disponível"
              : status === "draft"
              ? "Rascunho"
              : status === "processing"
              ? "Processando"
              : "Erro"}
          </StatusTag>
          {isShared ? (
            <SharedTag variant="body3">Compartilhado</SharedTag>
          ) : null}
        </StyledFlex>
      </SubContainer>

      <WarningDeleteModal
        type={"content"}
        _id={_id}
        open={warningDeleteModal}
        setOpen={setWarningDeleteModal}
        setConfirmModal={setConfirmModal}
      />
      <ConfirmModal
        type={"content"}
        open={confirmModal}
        setOpen={setConfirmModal}
      />

      <VideoContentPreviewModal
        open={viewContentPreviewModal}
        setOpen={setViewContentPreviewModal}
        data={classz}
      />

      <ReplicateContentModal
        open={replicateModal}
        onClose={() => setReplicateModal(false)}
        handleClick={() =>
          navigate(`/lms/manage-courses/edit/content/${type}/0/${_id}`)
        }
      />
    </Container>
  );
};

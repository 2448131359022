import { useState } from "react";
import {
  Button,
  Checkbox,
  DateRangePicker,
  IconButton,
  LinkButton,
  Typography,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  AccordionTitle,
  Divider,
  FilterArea,
  FilterLabel,
  Footer,
  StyledAccordion,
  StyledLinkButton,
  SwipeableIcon,
} from "./styled";

import { concat } from "lodash";

import { AccordionDetails, AccordionSummary } from "@mui/material";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const ResponsiveCourseFilters = ({
  data,
  filters,
  onFilter,
  setOpenFilters,
}) => {
  const [filterStates, setFilterStates] = useState<any>(undefined);

  const renderFilters = () => {
    return filters?.map((value) => {
      const renderOptions =
        value?.options?.map((e: any) => {
          return { label: e.label || "", value: e.value || "" };
        }) || [];

      switch (value.type) {
        case "date":
          return (
            <div style={{ marginBottom: "16px" }}>
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={
                    <IconButton
                      size="small"
                      variant="filled"
                      icon="IconChevronDown"
                      style={{ marginLeft: "12px" }}
                    />
                  }
                >
                  <AccordionTitle variant="headline8" children={value.label} />
                </AccordionSummary>
                <AccordionDetails style={{ padding: "16px" }}>
                  <DateRangePicker
                    onSubmit={(date) => {
                      const filteredOptions = (data || []).filter((item) => {
                        const isEndAcessor = value.accessor === "end";
                        const endValidation =
                          isEndAcessor && item[value.accessor] == null;

                        if (date?.to) {
                          return (
                            (dayjs(item[value.accessor]).isSameOrAfter(
                              date.from,
                              "day"
                            ) &&
                              dayjs(item[value.accessor]).isSameOrBefore(
                                date.to,
                                "day"
                              )) ||
                            endValidation
                          );
                        }

                        if (date?.from && !date?.to)
                          return (
                            dayjs(item[value.accessor]).isSameOrAfter(
                              date.from,
                              "day"
                            ) || endValidation
                          );

                        return true;
                      });

                      const parsedValue = filteredOptions.map(
                        (data) => data[value.accessor]
                      );

                      const stateUpdated = {
                        ...filterStates,
                        [value.accessor]: parsedValue,
                      };

                      if (!date.from) delete stateUpdated[value.accessor];

                      setFilterStates(stateUpdated);
                    }}
                  />
                </AccordionDetails>
              </StyledAccordion>
            </div>
          );

        case "checkbox":
        default:
          return (
            <div style={{ marginBottom: "16px" }}>
              <StyledAccordion>
                <AccordionSummary
                  expandIcon={
                    <IconButton
                      size="small"
                      variant="filled"
                      icon="IconChevronDown"
                      style={{
                        width: "32px",
                        height: "32px",
                        marginLeft: "12px",
                      }}
                    />
                  }
                >
                  <AccordionTitle variant="headline8" children={value?.label} />
                </AccordionSummary>
                <AccordionDetails style={{ padding: "16px" }}>
                  {renderOptions?.map((item, index) => {
                    const valueFromAccessor =
                      filterStates?.[value.accessor] || [];
                    const checked = !!valueFromAccessor?.find(
                      (g) => g == item.value
                    );

                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "16px",
                        }}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={(e: any) => {
                            const foundIndex = filterStates?.[
                              value.accessor
                            ]?.findIndex((f) => f === item.value);

                            let stateUpdated = {
                              ...filterStates,
                            };

                            if (foundIndex >= 0) {
                              const result = Array.from(valueFromAccessor);
                              result.splice(foundIndex, 1) as any;
                              stateUpdated = {
                                ...stateUpdated,
                                [value.accessor]: result,
                              };
                            } else {
                              stateUpdated = {
                                ...stateUpdated,
                                [value.accessor]: concat(valueFromAccessor, [
                                  item.value,
                                ]),
                              };
                            }

                            if (!stateUpdated?.[value.accessor]?.length)
                              delete stateUpdated[value.accessor];

                            setFilterStates(stateUpdated);
                          }}
                        />
                        <Typography
                          variant="body3"
                          children={item?.label}
                          style={{ marginLeft: "12px", fontWeight: 600 }}
                        />
                      </div>
                    );
                  })}
                  <Divider style={{ marginTop: "4px" }} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "24px 0px",
                    }}
                    role="presentation"
                    onClick={() => {
                      const stateUpdated = {
                        ...filterStates,
                      };
                      delete stateUpdated[value.accessor];

                      setFilterStates(stateUpdated);
                    }}
                  >
                    <StyledLinkButton variant="primary">
                      Limpar
                    </StyledLinkButton>
                  </div>
                </AccordionDetails>
              </StyledAccordion>
            </div>
          );
      }
    });
  };

  return (
    <FilterArea>
      <SwipeableIcon style={{ marginTop: "8px", marginBottom: "24px" }} />
      <div style={{ width: "100%", padding: "0px 24px" }}>
        <FilterLabel
          variant="headline8"
          style={{ fontSize: "16px", fontWeight: 700, marginBottom: "16px" }}
        >
          Filtrar por
        </FilterLabel>
        {renderFilters()}
      </div>

      <Footer>
        <LinkButton
          variant="secondary"
          style={{ alignSelf: "center" }}
          onClick={() => {
            if (filterStates === undefined) return;

            onFilter && onFilter({});
            setFilterStates({});
          }}
        >
          Limpar
        </LinkButton>
        <Button
          size="medium"
          variant="primary"
          onClick={() => {
            if (filterStates === undefined) return;

            onFilter && onFilter(filterStates);
            setOpenFilters(false);
          }}
        >
          Filtrar
        </Button>
      </Footer>
    </FilterArea>
  );
};

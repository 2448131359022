import { useNavigate } from "react-router-dom";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledIconButton,
} from "./styled";

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import { trpc } from "@api/client";

export const ConfirmModal = ({ open, setOpen, type }) => {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const onClose = () => {
    if (type === "course") {
      navigate(`/lms/manage-courses`);
      utils.course.getManageCourses.refetch();
    }
    if (type === "content") {
      navigate(`/lms/manage-courses`);
      utils.learningObject.getLearningObjects.refetch();
    }
    setOpen(false);
  };

  return (
    <StyledModal
      onClose={onClose}
      open={open}
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconMoodHappy" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <StyledFlexBetween>
          <StyledTitle variant="headline7">Pronto! </StyledTitle>
          <StyledIconButton
            onClick={onClose}
            size="small"
            variant="line"
            icon="IconX"
          />
        </StyledFlexBetween>
        <StyledContainer>
          <StyledSubTitle variant="body3">
            Seu {type === "course" ? "treinamento" : "conteúdo"} foi excluído
            com sucesso.
          </StyledSubTitle>
          <StyledButtonContainer>
            <Button size="large" variant="primary" onClick={onClose}>
              Voltar para Gerenciar Treinamentos
            </Button>
          </StyledButtonContainer>
        </StyledContainer>
      </>
    </StyledModal>
  );
};

import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Certificate,
  CompanyDataArea,
  CompanyInfo,
  Divider,
  Duration,
  DurationFooter,
  DurationIcon,
  ExpireZone,
  Footer,
  IconButton,
  InfoText,
  MiniDivider,
  Name,
  Signature,
  SignerName,
  SignerPosition,
  Title,
} from "./styled";

const CertificateBuilder = ({
  courseName = null,
  totalHours = null,
  employeeName = null as any,
  signText = null,
  signerName = null,
  signerPostion = null,
  expiresDate = null,
  companyLogo = null as any,
  companyName = null,
  certificateDate = null,
}) => {
  const image = `${companyLogo?.path}${companyLogo?.signature}`;
  const timestamp = new Date().getTime();
  const imageWithTimestamp = image.includes("?")
    ? `${image}&v=${timestamp}`
    : `${image}?v=${timestamp}`;

  return (
    <Certificate id="certificateToPng">
      <IconButton>
        <Icons name="IconCertificate" fill="transparent" />
      </IconButton>
      <InfoText>Certificado de conclusão</InfoText>
      <Title>{courseName}</Title>
      {totalHours ? (
        <Duration>
          <DurationIcon>
            <Icons name="IconClock" fill="transparent" />
          </DurationIcon>
          {totalHours > 0 ? (
            <>
              Duração de {totalHours} hora{totalHours > 1 ? "s" : null}
            </>
          ) : null}
        </Duration>
      ) : null}
      <Name>{employeeName}</Name>

      {signText && (
        <>
          <Divider />
          <Signature>{signText}</Signature>
          <MiniDivider />
        </>
      )}
      {signerName && <SignerName>{signerName}</SignerName>}
      {signerPostion && <SignerPosition>{signerPostion}</SignerPosition>}
      <Footer>
        {expiresDate ? (
          <ExpireZone>
            <DurationFooter>
              <DurationIcon>
                <Icons name="IconCalendar" fill="transparent" />
              </DurationIcon>
              Emitido em{" "}
              {dayjs(
                certificateDate ? new Date(certificateDate) : new Date()
              ).format("DD/MM/YYYY")}
            </DurationFooter>
            <DurationFooter>
              <DurationIcon>
                <Icons name="IconCalendar" fill="transparent" />
              </DurationIcon>
              Válido até{" "}
              {dayjs(certificateDate ? new Date(certificateDate) : new Date())
                .add(expiresDate, "year")
                .format("DD/MM/YYYY")}
            </DurationFooter>
          </ExpireZone>
        ) : (
          <div></div>
        )}
        <CompanyDataArea>
          {companyLogo && (
            <img
              src={imageWithTimestamp}
              alt="company logo"
              crossOrigin="anonymous"
            />
          )}
          {companyName && <CompanyInfo>{companyName}</CompanyInfo>}
        </CompanyDataArea>
      </Footer>
    </Certificate>
  );
};

export default CertificateBuilder;

import { useCallback } from "react";

import { Icons, LinkButton, Loader } from "@flash-tecnologia/hros-web-ui-v2";

import {
  ConfirmButton,
  IconContainer,
  ModalBody,
  ModalTitle,
  StyledModal,
  SubTitle,
  Title,
  WarnIcon,
  CloseButton,
  ModalFooterContainer,
  ModalHeader,
} from "./styled";

import { trpc } from "@api/client";
import dispatchToast from "src/utils/dispatchToast";

export const DeleteCertiticate = ({
  certificateId,
  open = false,
  onClose,
}: {
  certificateId?: string;
  open?: boolean;
  onClose?: any;
}) => {
  const utils = trpc.useContext();

  const { mutate: deleteCertificateByIdMutate, isLoading } =
    trpc.certificate.deleteCertificateById.useMutation({});

  const handleDelete = useCallback(() => {
    if (certificateId)
      deleteCertificateByIdMutate(
        {
          _id: certificateId,
        },
        {
          onSuccess: () => {
            onClose();
            utils.certificate.getCertificatesV2.invalidate();
          },
          onError: (e: any) => {
            const certificateNotExists =
              e?.data?.error === "CERTIFICATE_NOT_EXISTS_ERROR";

            const message = certificateNotExists
              ? "Certificado não existe."
              : "Erro ao tentar deletar o certificado, tente novamente mais tarde!";

            dispatchToast({
              type: "error",
              content: message,
            });
          },
        }
      );
  }, [certificateId]);

  return (
    <StyledModal onClose={onClose} open={open}>
      <>
        <ModalHeader>
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={onClose}
          />
          <IconContainer>
            <WarnIcon size={48} name="IconAlertCircle" fill="transparent" />
          </IconContainer>
          <ModalTitle variant="body3">Atenção!</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Title variant="headline6">
            Tem certeza que deseja excluir esse certificado?
          </Title>
          <SubTitle variant="body3">
            Todos os dados relacionados a ele serão perdidos e essa ação não
            poderá ser desfeita.
          </SubTitle>
        </ModalBody>
        <ModalFooterContainer>
          <LinkButton variant="secondary" onClick={onClose}>
            Cancelar
          </LinkButton>
          <ConfirmButton
            size="large"
            variant="primary"
            onClick={handleDelete}
            variantType="error"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>
                Confirmar exclusão
                <Icons name="IconTrash" fill="transparent" />
              </>
            )}
          </ConfirmButton>
        </ModalFooterContainer>
      </>
    </StyledModal>
  );
};

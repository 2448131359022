import { Avatar as AvatarM } from "@mui/material";
import { MainContainer, Container, Name, Email } from "./styles";
import { AvatarInterface } from "./types";

const Avatar = ({
  name,
  email,
  image,
  variants,
  avatarSize,
}: AvatarInterface) => {
  const getImage = () => {
    if (image) return { src: image };
    return {};
  };

  return (
    <MainContainer avatarSize={avatarSize}>
      <AvatarM alt={name} {...getImage()} style={{ position: "unset" }} />
      <Container>
        <Name variant={variants?.name || "body3"}>{name}</Name>
        <Email variant={variants?.email || "body4"}>{email}</Email>
      </Container>
    </MainContainer>
  );
};

export default Avatar;

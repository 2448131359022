import { trpc } from "@api/client";
import {
  Checkbox,
  Typography,
  dayjs,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { dispatchToast } from "@utils";

import { ExpandPdf } from "./Common";
import DoneCourse from "./Common/DoneModal";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import {
  DoneCheckArea,
  PreviewHeaderContainer,
  PreviewHeaderSubContainer,
  PreviewIcon,
  PreviewLabel,
} from "./styled";

const MainStudy = () => {
  const [selected, setSelected] = useState<any>(null);

  const [doneModal, setDoneModal] = useState<any>(false);

  const [isDone, setIsDone] = useState<any>(false);
  const [isDescription, setIsDescription] = useState<any>(true);
  const [mainStudy, setMainStudy] = useState<any>({ course: [], modules: [] });

  const [isExpanded, setIsExpanded] = useState(false);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const { course = {}, modules = [] } = mainStudy;

  const {
    courseId = "",
    moduleId = "",
    classId = "",
    viewType = "view",
  } = useParams();

  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { mutate: setMonitoringMutate } =
    trpc.monitorings.setMonitoring.useMutation({});

  useEffect(() => {
    const isManageCourses = window?.location?.href?.match?.("manage-courses");
    const isPreviewAndMyCourses = window?.location?.href?.match?.("my-courses");

    if (viewType === "view") {
      setMonitoringMutate({ courseId, params: { type: "view" } });
      setMainStudy({ course: [], modules: [] });

      if (isManageCourses) {
        navigate(`/lms/my-courses/${courseId}/${moduleId}/${classId}/view`);
      }
    }

    if (viewType === "preview" && isPreviewAndMyCourses) {
      navigate(
        `/lms/manage-courses/${courseId}/${moduleId}/${classId}/preview`
      );
    }
  }, [viewType]);

  useEffect(() => {
    if (modules?.length && moduleId && classId) {
      const moduleIndex = modules?.findIndex(
        (m) => m?.position?.toString() === moduleId.toString()
      );

      if (moduleIndex != -1) {
        let classes = (modules[moduleIndex].classes || []).find(
          (c) => c._id == classId
        );

        if (!classes && (modules[moduleIndex].classes || []).length) {
          classes = modules[moduleIndex].classes[0];

          navigate(
            `/lms/my-courses/${courseId}/${moduleIndex}/${classes._id}/view`
          );
          return;
        }

        if (isDescription) {
          setSelected(null);
        } else {
          setSelected({
            ...classes,
            ...getNextAndPreviosClass({
              currentClass: classes?._id,
              modules,
              isDescription,
            }),
            moduleId: modules[moduleIndex].position,
            moduleName: modules[moduleIndex].name,
          });
        }
      } else {
        if (modules?.length) {
          const classes = modules[0].classes;
          const position = modules[0].position;

          if (!classes || !Object.keys(classes).length) {
            navigate(`/lms/my-courses`);
            return;
          }

          const isManageCourses =
            window?.location?.href?.match?.("manage-courses");

          const isMyCourses = window?.location?.href?.match?.("my-courses");

          if (isManageCourses) {
            navigate(
              `/lms/manage-courses/${courseId}/${position}/${modules[0].classes[0]._id}/preview`
            );
            return;
          }

          if (isMyCourses) {
            navigate(
              `/lms/my-courses/${courseId}/${position}/${modules[0].classes[0]._id}/view`
            );
            return;
          }

          navigate(
            `/lms/my-courses/${courseId}/${position}/${modules[0].classes[0]._id}/view`
          );
        }
      }
    }
  }, [modules, moduleId, classId, isDescription]);

  useEffect(() => {
    if (
      !selected?.current?.done &&
      (selected?.type === "video" || selected?.type === "podcast")
    ) {
      if (
        Math.round((selected?.currentTime / selected?.duration) * 100) >= 80
      ) {
        setMonitoringMutate(
          {
            courseId,
            params: {
              type: selected?.type,
              learningObjectId: selected?.learningObjectId,
              done: true,
              watched: selected?.currentTime,
              contentDuration: selected?.duration ?? 1,
            },
          },
          {
            onSuccess: () => {
              handleDoneContent();
            },
            onError: (e: any) => {
              const employeeNotExists =
                e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";
              const courseNotExists =
                e?.data?.error === "COURSE_NOT_EXISTS_ERROR";
              const message = employeeNotExists
                ? "Não existe o funcionário."
                : courseNotExists
                ? "Não existe o curso."
                : "Erro ao tentar salvar o monitoramento, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
      }
    }
  }, [selected, viewType]);

  useEffect(() => {
    if (!selected?.done) {
      setIsDone(false);
    }
  }, [selected]);

  useEffect(() => {
    if (Object.keys(course).length) {
      const {
        start,
        end,
        classesTotal,
        doneClasses,
        hasShownDoneModal = false,
      } = course;

      const startDate = dayjs(start);
      const endDate = dayjs(end);

      let status = "doing";

      if (dayjs().isBefore(startDate)) {
        status = "beforeInit";
      } else if (doneClasses === classesTotal) {
        status = "done";
      }

      if (
        viewType != "preview" &&
        doneClasses === classesTotal &&
        !hasShownDoneModal
      ) {
        setDoneModal(true);
      }

      const hasToBackToMyCourse =
        status != "done" &&
        viewType != "preview" &&
        (dayjs().isBefore(startDate, "day") ||
          (endDate && dayjs().isAfter(endDate, "day")) ||
          classesTotal === 0);

      if (hasToBackToMyCourse) navigate(`/lms/my-courses`);
    }
  }, [course, viewType]);

  const { isLoading: isGettingCoursesModules } =
    trpc.course.getCoursesModules.useQuery(
      {
        courseId,
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!courseId,
        onSuccess: (data) => {
          const blockNavigation =
            !!data?.course?.blockNavigation && viewType === "view";

          const firstIndexModuleNotDone = data?.modules?.findIndex((m) =>
            m?.classes?.find(
              (c) =>
                !!!c?.done ||
                (m?.type === "exam" &&
                  m?.settings?.passingScore > 0 &&
                  !!!m?.examsAttempt?.approved)
            )
          );
          const firstIndexClassesNotDone = data?.modules?.[
            firstIndexModuleNotDone
          ]?.classes?.findIndex(
            (c) =>
              !!!c?.done ||
              (c?.type === "exam" &&
                c?.settings?.passingScore > 0 &&
                !!!c?.examsAttempt?.approved)
          );

          const newData = {
            course: data?.course,
            modules: data?.modules?.map((m, index) => {
              return {
                ...m,
                classes: m?.classes?.map((c, cIndex) => {
                  if (!blockNavigation) {
                    return { ...c, disabled: false };
                  }
                  if (
                    (firstIndexModuleNotDone >= 0 &&
                      index === firstIndexModuleNotDone &&
                      firstIndexClassesNotDone >= 0 &&
                      cIndex > firstIndexClassesNotDone) ||
                    (firstIndexModuleNotDone >= 0 &&
                      index > firstIndexModuleNotDone)
                  ) {
                    return { ...c, disabled: true };
                  }

                  return { ...c, disabled: false };
                }),
              };
            }),
          };

          setMainStudy(newData);
        },
        onError: (e: any) => {
          const courseNotExists = e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

          const message = courseNotExists
            ? "Não existe o curso."
            : "Erro ao tentar buscar o módulos do curso, tente novamente mais tarde!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );

  const getNextAndPreviosClass = useCallback(
    ({ currentClass, modules, isDescription }) => {
      if (currentClass && modules?.length) {
        const data = [] as any;
        modules?.map(({ classes, position }) => {
          classes?.map((i) => {
            data?.push({ ...i, module: position });
          });
        });

        if (data?.length) {
          const index = data?.map(({ _id }) => _id).indexOf(currentClass);
          if (isDescription) {
            return {
              previous: null,
              current: null,
              next: data?.[0],
              totalClasses: data?.length,
            };
          }

          return {
            previous: index === 0 ? null : data[index - 1],
            current: data[index],
            next: data[index + 1] ?? null,
            totalClasses: data?.length,
          };
        }
      }
    },
    []
  );

  const donePdfArticle = useCallback(() => {
    return (
      <DoneCheckArea>
        <div>
          <Checkbox
            checked={selected?.done}
            onChange={(e, checked) => {
              if (checked) {
                if (viewType != "view") return;

                setMonitoringMutate(
                  {
                    courseId,
                    params: {
                      type: selected?.type,
                      learningObjectId: selected?.learningObjectId,
                      done: true,
                    },
                  },
                  {
                    onSuccess: () => {
                      handleDoneContent();

                      selected?.next &&
                        navigate(
                          `/lms/my-courses/${courseId}/${selected?.next?.module}/${selected?.next?._id}/view`
                        );
                    },
                  }
                );
              }
            }}
          />
          <Typography
            variant="body3"
            children={
              selected?.done
                ? "Aula concluída"
                : `Marcar como concluída${selected?.next ? " e continuar" : ""}`
            }
          />
        </div>
      </DoneCheckArea>
    );
  }, [selected]);

  const handleDoneContent = () => {
    utils.course.getCoursesModules.invalidate();
    utils.monitorings.getCourseMonitoring.invalidate();
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      {viewType === "preview" ? (
        <PreviewHeaderContainer>
          <PreviewHeaderSubContainer>
            <PreviewIcon name="IconFileSearch" />
            <PreviewLabel variant="body4">
              Você está no modo de pré-visualização.
            </PreviewLabel>
          </PreviewHeaderSubContainer>
        </PreviewHeaderContainer>
      ) : (
        <></>
      )}
      {isMobile ? (
        <Mobile
          course={course}
          courseId={courseId}
          modules={modules}
          moduleId={moduleId}
          selected={selected}
          setSelected={setSelected}
          isDescription={isDescription}
          setIsDescription={setIsDescription}
          isDone={isDone}
          setIsDone={setIsDone}
          viewType={viewType}
          setIsExpanded={setIsExpanded}
          handleDoneContent={handleDoneContent}
          donePdfArticle={donePdfArticle}
          classId={classId}
          isMobile={isMobile}
          isGettingCoursesModules={isGettingCoursesModules}
        />
      ) : (
        <Desktop
          course={course}
          courseId={courseId}
          modules={modules}
          moduleId={moduleId}
          selected={selected}
          setSelected={setSelected}
          isDescription={isDescription}
          setIsDescription={setIsDescription}
          isDone={isDone}
          setIsDone={setIsDone}
          viewType={viewType}
          setIsExpanded={setIsExpanded}
          handleDoneContent={handleDoneContent}
          donePdfArticle={donePdfArticle}
          classId={classId}
          isGettingCoursesModules={isGettingCoursesModules}
        />
      )}
      <ExpandPdf
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        url={`${selected?.url?.path}${selected?.url?.signature ?? null}`}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
      />

      <DoneCourse
        onClose={() => {
          setDoneModal(false);
          handleDoneContent();
          navigate(`/lms/my-courses`);
        }}
        open={doneModal}
        courseId={courseId}
      />
    </div>
  );
};

export { MainStudy };

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import { Container } from "./styled";

export const Header = ({
  selected,
  viewType,
  exams: {
    mobileAnsweredQuestion,
    examInitiate,
    setExamInitiate,
    mobileKeyQuestion,
    setMobileKeyQuestion,
    doneButton,
    setIsDoneTrigger,
  },
  isDone,
  questionRef,
}) => {
  if (isDone) return <></>;

  return (
    <Container progress={mobileAnsweredQuestion}>
      {!examInitiate ? (
        <Button
          size="medium"
          variant="primary"
          disabled={viewType === "preview"}
          onClick={() => setExamInitiate(true)}
          style={{ width: "90%" }}
        >
          Iniciar
        </Button>
      ) : (
        <>
          <Button
            size="medium"
            variant="secondary"
            disabled={mobileKeyQuestion === 0}
            onClick={() => {
              setMobileKeyQuestion((prev) => (prev === 0 ? prev : prev - 1));
              questionRef?.current?.scrollIntoView();
            }}
          >
            Anterior
          </Button>
          <Button
            size="medium"
            variant="primary"
            disabled={
              viewType === "preview" ||
              (mobileKeyQuestion === selected?.questions?.length - 1 &&
                !doneButton)
            }
            onClick={() => {
              if (mobileKeyQuestion === selected?.questions?.length - 1) {
                setIsDoneTrigger(true);
              } else {
                questionRef?.current?.scrollIntoView();
                setMobileKeyQuestion((prev) =>
                  mobileKeyQuestion === selected?.questions?.length - 1
                    ? prev
                    : prev + 1
                );
              }
            }}
          >
            {mobileKeyQuestion === selected?.questions?.length - 1
              ? "Finalizar"
              : "Próxima"}
          </Button>
        </>
      )}
    </Container>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledTypography,
  StyledTitle,
  AddQuestionButton,
  Container,
  PageTitle,
} from "./styled";

import { CreateQuestionCard } from "./CreateQuestionCard";
import { track, dispatchToast } from "@utils";

import { trpc } from "@api/client";
import { PageTemplate } from "@components/PageTemplate";
import Banner from "@components/Banner";

import { Grid } from "@mui/material";

export const SecondStepExam = ({
  data,
  learningObject,
  type,
  contentType,
  steps,
  contentId,
  currentStep,
  isLoadingScreen,
}) => {
  const utils = trpc.useContext();

  const [questions, setQuestions] = useState<any>([]);
  const [associateCourses, setAssociateCourses] = useState(0);

  const { questions: examQuestions } = data || { questions: [] };

  const navigate = useNavigate();

  useEffect(() => {
    if (learningObject?.length) {
      const data = learningObject?.filter(({ _id }) => _id === contentId);
      setAssociateCourses(data[0]?.associateCourses ?? 0);
    }
  }, [learningObject]);

  useEffect(() => {
    if (examQuestions?.length) setQuestions(examQuestions);
    else {
      setQuestions([
        {
          question: null,
          mandatory: false,
          multiple: false,
          alternatives: [
            { value: null, rightAnswer: false },
            { value: null, rightAnswer: false },
          ],
        },
      ]);
    }
  }, [examQuestions]);

  const {
    mutate: addExamQuestionsMutate,
    isLoading: isLoadingAddExamQuestions,
  } = trpc.exams.addExamQuestions.useMutation({});

  const isEditing = type === `edit`;
  const titleByType = isEditing ? "Editar avaliação" : "Criar avaliação";

  const handleAddQuestion = useCallback(() => {
    setQuestions((previous) => [
      ...previous,
      {
        question: null,
        mandatory: false,
        multiple: false,
        alternatives: [
          { value: null, rightAnswer: false },
          { value: null, rightAnswer: false },
        ],
      },
    ]);
  }, []);

  const handleRemoveQuestion = useCallback(
    (removeIndex) => {
      const newQuestions = questions?.filter(
        (_, index) => index !== removeIndex
      );

      if (questions?.length > 1) {
        setQuestions(newQuestions);
      } else {
        dispatchToast({
          content: "É necessário pelo menos uma pergunta.",
          type: "error",
        });
      }
    },
    [questions]
  );

  const handleDuplicateQuestion = useCallback(
    (findIndex) => {
      const dupItem = questions[findIndex];

      setQuestions((previous) => [
        ...previous,
        {
          ...dupItem,
          _id: undefined,
          alternatives: dupItem?.alternatives?.map((item) => ({
            ...item,
            _id: undefined,
            rightAnswer: false,
          })),
        },
      ]);
    },
    [questions]
  );

  const handleAddQuestions = useCallback(() => {
    const emptyQuestion = questions?.filter(
      ({ question = "" }) => question === null || question?.length === 0
    );

    const emptyAlternatives = questions.filter(({ alternatives = [] }) => {
      return alternatives.some(
        ({ value = "" }) => value === null || value?.length === 0
      );
    });

    const checkRightAnswer = questions?.map(({ alternatives, _id }) => {
      return alternatives.some(
        ({ rightAnswer = false }) => rightAnswer === true
      );
    });

    const emptyRightAnswer = checkRightAnswer?.some(
      (answer) => answer === false
    );

    const checkDupAlternatives = questions?.map(({ alternatives }) => {
      const valueArr = alternatives.map(({ value = "" }) => {
        return value;
      });

      return valueArr.some((item, idx) => {
        return valueArr.indexOf(item) != idx;
      });
    });

    const dupAlternatives = checkDupAlternatives?.some(
      (answer) => answer === true
    );

    if (emptyQuestion?.length || emptyAlternatives?.length) {
      dispatchToast({
        content: `Não é possível criar uma avaliação com questões ou alternativas vazias. Adicione uma pergunta e pelo menos duas alternativas.`,
        type: "error",
      });
      return;
    }

    if (emptyRightAnswer) {
      dispatchToast({
        content: `Não é possível criar uma questão sem selecionar a alternativa correta.`,
        type: "error",
      });
      return;
    }

    if (dupAlternatives) {
      dispatchToast({
        content: `Não é possível criar uma questão com alternativas iguais.`,
        type: "error",
      });
      return;
    }

    addExamQuestionsMutate(
      {
        _id: contentId,
        params: questions,
      },
      {
        onSuccess: () => {
          utils.learningObject.getContentById.invalidate();
          utils.learningObject.getAllLearningObject.invalidate();

          navigate(
            `/lms/manage-courses/${type}/content/${contentType}/${
              currentStep + 1
            }/${contentId}`
          );
        },
        onError: (e: any) => {
          const learningObjectNotExists =
            e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

          const message = learningObjectNotExists
            ? "Objeto de aprendizado não existe."
            : "Erro ao tentar adicionar questões, tente novamente mais tarde!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );
  }, [questions]);

  const handleSaveAsDraft = useCallback(() => {
    addExamQuestionsMutate(
      {
        _id: contentId,
        params: questions,
      },
      {
        onSuccess: () => {
          navigate("/lms/manage-courses");
        },
      }
    );
  }, [questions]);

  const renderQuestions = useMemo(() => {
    return questions?.map((data, index) => (
      <CreateQuestionCard
        key={index}
        number={index}
        question={data}
        setQuestions={setQuestions}
        handleRemoveQuestion={handleRemoveQuestion}
        handleDuplicateQuestion={handleDuplicateQuestion}
        hasAssociateCourse={associateCourses > 0}
      />
    ));
  }, [questions, associateCourses]);

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: `company_lms_testquestions_exit_clicked`,
            });
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          callback: () => {
            track({
              name: `company_lms_testquestions_savedraft_clicked`,
            });
            handleSaveAsDraft();
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => {
            if (contentId) {
              navigate(
                `/lms/manage-courses/${type}/content/${contentType}/${
                  currentStep - 1
                }/${contentId}`
              );
            } else {
              navigate(
                `/lms/manage-courses/${type}/content/${contentType}/${
                  currentStep - 1
                }`
              );
            }
          },
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: !!isLoadingAddExamQuestions,
          callback: () => {
            track({
              name: `company_lms_testquestions_next_clicked`,
            });
            handleAddQuestions();
          },
        },
      }}
    >
      <Container>
        {isLoadingScreen ? (
          <Skeleton
            width="100%"
            height="80vh"
            animation="pulse"
            variant="rectangular"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          />
        ) : (
          <>
            <PageTitle variant="headline6">{titleByType}</PageTitle>

            {associateCourses > 0 ? (
              <Banner
                type="info"
                icon="IconAlertTriangle"
                title="Edição de conteúdo limitada"
                subTitle="Esse conteúdo está associado a treinamentos e sua edição poderia impactar o progresso dos alunos, portanto, é permitido editar somente as informações básicas. Se a alteração for fundamental, crie um novo conteúdo e associe à aula, mas lembre-se de informar os alunos."
                hasHideBanner={true}
                style={{ marginBottom: "32px" }}
              />
            ) : (
              <></>
            )}

            <Grid container>
              <Grid item sm={12} md={5} lg={4}>
                <div style={{ marginRight: "24px" }}>
                  <StyledTitle variant="headline7">Questões</StyledTitle>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <StyledTypography variant="body3">
                      Crie as questões e as alternativas da sua avaliação. Você
                      também pode selecionar qual é a alternativa correta de
                      cada questão e marcá-las como questão obrigatória.
                    </StyledTypography>
                  </div>
                </div>
              </Grid>

              <Grid item sm={12} md={7} lg={8}>
                {renderQuestions}
                <AddQuestionButton
                  onClick={() => handleAddQuestion()}
                  variant="secondary"
                  size="medium"
                  disabled={associateCourses > 0}
                >
                  <Icons name="IconPlus" fill={"transparent"} /> Adicionar nova
                  questão
                </AddQuestionButton>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </PageTemplate>
  );
};

import { useRef, useState, useEffect } from "react";
import { IconButton, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import { Container, StyledPdfHeaderContainer, CanvasContainer } from "./styles";

import useResizeObserver from "use-resize-observer";

interface PdfVisualizerProps {
  path: string;
}

const useFetchPDF = (url: string) => {
  const [state, setState] = useState({
    data: [],
    error: "",
    loading: url ? true : false,
  });

  useEffect(() => {
    if (!url) {
      setState({ error: "", data: [], loading: false });
      return;
    }

    const fetchPDF = async () => {
      try {
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

        const pdf = await pdfjsLib.getDocument(url).promise;

        const promises = Array(pdf.numPages)
          .fill(0)
          .map(async (_, index) => {
            const page = await pdf.getPage(index + 1);
            return page;
          });

        const pages = (await Promise.all(promises)) as any;

        setState({ error: "", data: pages, loading: false });
      } catch (err: any) {
        setState({ error: err.message, data: [], loading: false });
      }
    };

    fetchPDF();
  }, [url]);

  return { data: state.data, loading: state.loading, error: state.error };
};

const PdfVisualizer = ({ path }: PdfVisualizerProps) => {
  // const {
  //   ref,
  //   width: widthResize = 1,
  //   height: heightResize = 1,
  // } = useResizeObserver<HTMLDivElement>();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [pageIndex, setPageIndex] = useState(-1);
  const [pages, setPages] = useState<any>([]);

  const [scale, setScale] = useState(1);
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    if (pageIndex + 1 == pages.length) {
      setCurrentPage(pages[pageIndex]);
      return;
    }

    if (pages.length && pageIndex >= 0) {
      setCurrentPage(pages[pageIndex]);
      return;
    }
  }, [pages, pageIndex]);

  useEffect(() => {
    const renderPage = (p: any) => {
      const _page = p;

      if (_page) {
        const context = canvasRef.current?.getContext("2d");
        const viewport = _page.getViewport({ scale: scale });

        setWidth(viewport.width);
        setHeight(viewport.height);

        if (context) {
          _page.render({
            canvasContext: canvasRef.current?.getContext("2d"),
            viewport,
          });
        }
      }
    };

    if (currentPage !== null) {
      renderPage(currentPage);
    }
  }, [currentPage, scale]);

  const { data, loading } = useFetchPDF(path);

  useEffect(() => {
    if (!data.length && !pages.length) return;

    setPages(data);
    setPageIndex(data?.length ? 0 : -1);
  }, [data]);

  return (
    <Container>
      <StyledPdfHeaderContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconZoomOut"
            onClick={() => setScale(scale - 0.25)}
            style={{ marginRight: "16px" }}
            disabled={loading}
          />

          <IconButton
            size="small"
            variant="line"
            icon="IconZoomIn"
            style={{ marginRight: "16px" }}
            onClick={() => setScale(scale + 0.25)}
            disabled={loading}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronLeft"
            disabled={pageIndex - 1 < 0 || loading}
            onClick={() => {
              setCurrentPage(pages[pageIndex - 1]);
              setPageIndex(pageIndex - 1);
            }}
          />
          <span style={{ marginRight: "16px", marginLeft: "16px" }}>
            {pageIndex + 1}/{pages.length}
          </span>
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronRight"
            disabled={pageIndex + 2 > pages.length || loading}
            onClick={() => {
              setCurrentPage(pages[pageIndex + 1]);
              setPageIndex(pageIndex + 1);
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            visibility: "hidden",
            pointerEvents: "none",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconZoomOut"
            style={{ marginRight: "16px" }}
          />

          <IconButton
            size="small"
            variant="line"
            icon="IconZoomIn"
            style={{ marginRight: "16px" }}
          />
        </div>
      </StyledPdfHeaderContainer>
      {loading ? (
        <Skeleton variant="rounded" height={"100%"} />
      ) : (
        <CanvasContainer>
          <div style={{ position: "relative" }}>
            <canvas ref={canvasRef} width={width} height={height} />
          </div>
        </CanvasContainer>
      )}
    </Container>
  );
};

export default PdfVisualizer;

import EmptyCourseActive from "../../../../assets/emptyCourseActive.svg";

import { Container, TextContainer, LabelTitle, LabelSubTitle } from "./styles";

const NoMyCourse = () => {
  return (
    <Container>
      <EmptyCourseActive />

      <TextContainer>
        <LabelTitle variant="headline6">
          Você ainda não tem treinamentos ativos
        </LabelTitle>
        <LabelSubTitle variant="body2">
          Entre em contato com sua empresa ou aguarde até que novos treinamentos
          sejam publicados para você.
        </LabelSubTitle>
      </TextContainer>
    </Container>
  );
};

export { NoMyCourse };

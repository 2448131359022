import { useMemo } from "react";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { StudentPerformanceTab } from "../StudentPerformanceTab";
import { ExamPerformanceTab } from "../ExamPerformanceTab";
import { CourseDetails } from "../CourseDetails";

export const Content = ({
  classe,
  hasEvaluationsInCourse,
  isGettingCourseById,
}) => {
  const getTotalViews = (monitorings) =>
    monitorings?.reduce((o, c) => o + (c?.views || 0), 0);

  const getCourseProgress = ({ monitoring, totalContent, totalDone }) => {
    return monitoring?.reduce((o, c) => {
      if (c?.done && totalContent === totalDone) return "done";
      if (totalDone > 0) return "progress";
      return "notStarted";
    }, 0);
  };

  const tabItems = useMemo(() => {
    const items = [
      {
        label: "Desempenho dos alunos",
        component: (
          <StudentPerformanceTab
            data={
              classe?.peopleInCourse?.map((people) => ({
                ...people,
                mandatory: classe?.mandatory,
                status: getCourseProgress(people),
                access: getTotalViews(people?.monitoring) || 0,
              })) || []
            }
            isGettingCourseById={isGettingCourseById}
          />
        ),
      },
    ];

    if (hasEvaluationsInCourse) {
      items.push({
        label: "Avaliações",
        component: <ExamPerformanceTab />,
      });
    }
    items.push({
      label: "Detalhes",
      component: <CourseDetails classe={classe} />,
    });
    return items;
  }, [classe, isGettingCourseById]);

  return <Tab tabItens={tabItems} />;
};

import styled from "styled-components";

import { Typography, Icons } from "@flash-tecnologia/hros-web-ui-v2";

export const BreadCrumbItem = styled.div`
  position: relative;
  background: transparent;
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-right: 2px;
  padding-bottom: 4px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutral[50]};
    transition: all 0.2s ease-in;
  }

  &:hover {
    &:before {
      width: 65%;
    }
  }
`;

export const BreadcrumbText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  border-radius: 2px;
  font-weight: 700 !important;
`;

export const BreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral[50]};
  fill: transparent;
`;

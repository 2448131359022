import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  dayjs,
  Icons,
  Loader,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CharCounter,
  StyledTextField,
  StyledSubtitleContainer,
  StyledSectionContainer,
  StyledClassFooter,
  StyledButton,
  StyledLinkButton,
  StyledDataGrid,
  ContantContainer,
  ContentType,
  StyledSearchField,
  InfoTitle,
  SelectTitle,
  SearchArea,
  FilterArea,
  FiltersSection,
  FilterLabel,
  StatusFilter,
  ClearFilters,
  SharedTooltip,
} from "./styled";

import { Checkbox, Skeleton, Tooltip } from "@mui/material";

import thumbPdf from "../../assets/thumbPdf.png";
import thumbVideo from "../../assets/thumbVideo.png";
import thumbPodcast from "../../assets/thumbPodcast.png";

import dispatchToast from "../../utils/dispatchToast";

import { trpc } from "@api/client";

export const AddClassesSection = ({
  _id = "",
  courseId = "",
  name = "",
  description = "",
  type = "edit",
  learningObjectId = null,
  setAddClass,
  parentId = "",
  courseHierachy = [],
  filteredLearningObject = [],
  closeModal,
  isShared = false,
}) => {
  const utils = trpc.useContext();

  const initialValues = [
    {
      label: "Artigo",
      value: "article",
      checked: false,
    },
    {
      label: "Avaliação",
      value: "exam",
      checked: false,
    },
    {
      label: "PDF",
      value: "pdf",
      checked: false,
    },
    {
      label: "Podcast",
      value: "podcast",
      checked: false,
    },
    {
      label: "Vídeo",
      value: "video",
      checked: false,
    },
  ];

  const [filtersSelect, setFiltersSelect] = useState<any>(initialValues);
  const [learningObject, setLearningObject] = useState<any[]>([]);
  const [filteredContent, setFilteredContent] = useState<any>([]);

  const [allLearningObjects, setAllLearningObjects] = useState<any[]>([]);
  const [filters, setFilters] = useState<any>({
    type: null,
    isShared: isShared ? true : null,
  });

  useEffect(() => {
    if (!learningObject?.length) return;
    let selectedItem: any = null,
      allItens: any[] = [];

    const usedLearningObject: any[] = FilterUsedLearningObject(courseHierachy);

    let filteredLearningObjects: any = learningObject?.filter(
      ({ status, _id }) =>
        !usedLearningObject?.includes(_id) &&
        (status === "published" || status === "processing")
    );

    if (isShared) {
      const filteredSharedItens = filteredLearningObjects?.filter(
        ({ isShared }) => isShared
      );
      filteredLearningObjects = filteredSharedItens;
      setAllLearningObjects(filteredSharedItens);
    } else setAllLearningObjects(filteredLearningObjects);

    filteredLearningObjects?.map((obj) => {
      if (obj._id === learningObjectId) {
        selectedItem = { ...obj, checked: true };
        return;
      }
      allItens = [...allItens, obj];
      return;
    });

    if (selectedItem) {
      setFilteredContent([
        selectedItem,
        ...filteredLearningObjects?.sort((a, b) => {
          if (a?.name < b?.name) return -1;
        }),
      ]);
    } else {
      setFilteredContent([
        ...filteredLearningObjects?.sort((a, b) => {
          if (a?.name < b?.name) return -1;
        }),
      ]);
    }
  }, [learningObject, courseHierachy]);

  const { mutate: updateNodeCourseHierarchyMutate, isLoading: isUpdatingNode } =
    trpc.course.updateNodeCourseHierarchy.useMutation({
      onSuccess: () => {
        closeModal();
        utils.course.getCourseHierarchy.invalidate();
      },
    });

  const { mutate: addNodeToHierarchyMutate, isLoading: isAddingNode } =
    trpc.course.addNodeToHierarchy.useMutation({
      onSuccess: () => {
        closeModal();
        utils.course.getCourseHierarchy.invalidate();
      },
    });

  const { isFetching: isLoadingGetAllLearningFiltered } =
    trpc.learningObject.getAllLearningObjectFiltered.useQuery(
      { filters: filters },
      {
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setLearningObject(data);
        },
      }
    );

  const validationSchema = yup.object({
    name: yup.string().max(50).required("Favor digitar o nome da aula"),
    description: yup
      .string()
      .max(600)
      .required("Favor digitar a descrição da aula"),
    learningObjectId: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      name: name,
      description: description,
      learningObjectId: learningObjectId,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async ({ name, description, learningObjectId }) => {
      if (!name) return;

      if (type === "edit") {
        updateNodeCourseHierarchyMutate({
          params: {
            name,
            id: _id,
            description,
            learningObjectId,
            level: 2,
          },
          courseId,
        });
      } else {
        addNodeToHierarchyMutate({
          params: {
            name,
            parentId,
            description,
            learningObjectId,
            level: 1,
          },
          courseId,
        });
      }
    },
  });

  const handleCheck = (_id, status, dataStatus) => {
    if (dataStatus === "processing") {
      dispatchToast({
        content:
          "Conteúdo ainda está sendo processado, tente novamente mais tarde!",
        type: "error",
      });
    }
    if (status) {
      formik.handleChange({
        target: {
          id: "learningObjectId",
          value: _id,
        },
      });
    } else {
      formik.setFieldValue("learningObjectId", null);
    }

    setFilteredContent((current) =>
      current
        .map((obj) => {
          if (obj._id != _id) return { ...obj, checked: false };
          if (dataStatus === "processing") return { ...obj, checked: false };
          if (obj._id === _id) return { ...obj, checked: status };
          return obj;
        })
        .sort(({ checked }, b) => {
          return checked === b?.checked ? 0 : checked ? -1 : 1;
        })
    );
  };

  const columns = [
    {
      Header: "Conteúdo",
      accessor: "name",
      Cell: ({
        row: {
          original: {
            _id = null,
            checked = false,
            name = null,
            type = null,
            status = null,
          },
        },
      }) => {
        return (
          <ContantContainer>
            <Checkbox
              icon={<Icons name="IconCircle" />}
              checkedIcon={<Icons name="IconCircleCheck" />}
              checked={checked}
              onChange={(e) => {
                handleCheck(_id, e?.target?.checked, status);
              }}
            />
            <img
              width="15%"
              src={
                type === "video"
                  ? thumbVideo
                  : type === "podcast"
                  ? thumbPodcast
                  : thumbPdf
              }
              alt={type || ""}
            />
            <Typography variant="body3">{name}</Typography>
          </ContantContainer>
        );
      },
    },
    {
      Header: "Tipo",
      accessor: "type",
      Cell: ({
        row: {
          original: { type = null },
        },
      }) => {
        return (
          <ContentType variant="body3">
            <Icons
              size={26}
              name={
                type === "video"
                  ? "IconVideo"
                  : type === "podcast"
                  ? "IconMicrophone"
                  : type === "exam"
                  ? "IconListNumbers"
                  : "IconFile"
              }
            />
            {type === "video"
              ? "Vídeo"
              : type === "podcast"
              ? "Podcast"
              : type === "exam"
              ? "Avaliação"
              : type === "article"
              ? "Artigo"
              : "PDF"}
          </ContentType>
        );
      },
    },
    {
      Header: "Criado em",
      accessor: "createdAt",
      Cell: ({
        row: {
          original: { createdAt = "Não informado" },
        },
      }) => {
        return <div>{dayjs(createdAt).format("DD/MM/YYYY")}</div>;
      },
    },
  ];

  const FilterUsedLearningObject = (courseHierachy) => {
    const array = [] as any;

    courseHierachy?.classes?.map(({ chapters }) =>
      chapters?.map(({ learningObjectId }) => array.push(learningObjectId))
    );

    return array;
  };

  const handleSetFilter = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <StyledSectionContainer>
        <InfoTitle variant="headline8">Informações</InfoTitle>
        <StyledTextField
          id={"name"}
          name={"name"}
          label={"Nome da aula"}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && (formik.errors.name as string)}
          inputProps={{
            maxlength: 50,
          }}
        />

        <StyledSubtitleContainer>
          <CharCounter variant="caption">
            {formik.values.name?.length}/50
          </CharCounter>
        </StyledSubtitleContainer>

        <StyledTextField
          id={"description"}
          name={"description"}
          label={"Descrição da aula"}
          multiline
          rows={4}
          style={{ marginTop: "24px" }}
          value={formik?.values?.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={
            formik.touched.description && (formik.errors.description as string)
          }
          inputProps={{
            maxlength: 600,
          }}
        />

        <StyledSubtitleContainer>
          <CharCounter variant="caption">
            {formik.values.description?.length}/600
          </CharCounter>
        </StyledSubtitleContainer>
        <SharedTooltip>
          <SelectTitle variant="headline8">Selecionar conteúdo</SelectTitle>
          <Tooltip
            arrow
            className="sharedTooltip"
            title="O treinamento está sendo compartilhado com todas as empresas do grupo, portanto, somente conteúdos compartilhados são exibidos."
          >
            <div>
              <Icons name={"IconInfoCircle"} fill="transparent" />
            </div>
          </Tooltip>
        </SharedTooltip>
        <SearchArea>
          <StyledSearchField
            placeholder="Buscar conteúdo"
            label={"Buscar conteúdo"}
            fullWidth
            onChange={async ({ target: { value } }) => {
              if (value?.length) {
                const cleanValue = value.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
                const regex = new RegExp(cleanValue, "gi");
                const contentToFilter = filteredLearningObject?.length
                  ? filteredLearningObject
                  : allLearningObjects;
                setFilteredContent(() =>
                  contentToFilter?.filter(({ name }) => name?.match(regex))
                );
              } else {
                setFilteredContent(allLearningObjects);
              }
            }}
          />
        </SearchArea>
        <FilterArea>
          <FiltersSection>
            <FilterLabel variant="body3">Filtrar por</FilterLabel>
            <StatusFilter
              variant="secondary"
              filterLabel="Tipo"
              optionIconType="checkbox"
              disableOptionsSearch
              selectedOptions={
                filtersSelect
                  ?.filter(({ checked }) => checked)
                  ?.map(({ value }) => value) || []
              }
              options={filtersSelect}
              onClick={(value) => {
                const filteredItens = filtersSelect?.map((item) => {
                  if (item?.value === value) {
                    return { ...item, checked: !item?.checked };
                  }
                  return item;
                });
                setFiltersSelect(filteredItens);
              }}
              onApply={(value) => handleSetFilter("type", value)}
              onClear={() => {
                handleSetFilter("type", []);
                setFiltersSelect(initialValues);
              }}
            />
            <ClearFilters
              variant="secondary"
              onClick={() => {
                setFiltersSelect(initialValues);
                setFilteredContent(learningObject);
                handleSetFilter("type", []);
              }}
            >
              Limpar filtros
            </ClearFilters>
          </FiltersSection>
        </FilterArea>
        {isLoadingGetAllLearningFiltered ? (
          <Skeleton
            width="100%"
            height="100vh"
            animation="pulse"
            variant="rectangular"
          />
        ) : (
          <div
            style={{
              width: "100%",
            }}
          >
            <StyledDataGrid
              columns={columns}
              data={filteredContent}
              hasPagination={true}
              pageSizeOptions={[
                { label: "5 itens", value: 5 },
                { label: "10 itens", value: 10 },
                { label: "20 itens", value: 20 },
              ]}
              emptyState={{
                message: "Você ainda não criou conteúdos.",
              }}
            />
          </div>
        )}
      </StyledSectionContainer>

      <StyledClassFooter>
        <StyledLinkButton
          onClick={() => {
            closeModal();
            // if (setExpanded) setExpanded(undefined);
          }}
          variant="primary"
          style={{ alignSelf: "center" }}
        >
          Cancelar
        </StyledLinkButton>
        <StyledButton
          disabled={isUpdatingNode || isAddingNode}
          onClick={async () => {
            const isValidToSubmit = await formik.validateForm();
            if (Object.keys(isValidToSubmit).length) {
              if (
                Object.keys(isValidToSubmit).includes("learningObjectId") &&
                Object.keys(isValidToSubmit).length == 1
              ) {
                dispatchToast({
                  content:
                    "Não é possível adicionar uma aula sem selecionar/criar um conteúdo antes",
                  type: "error",
                });
              } else {
                dispatchToast({
                  content: "Alguns campos estão incorretos. Favor verificar",
                  type: "error",
                });
              }
            }
            formik.handleSubmit();
          }}
          size="medium"
          variant="primary"
          style={{ color: "#FFFFFF" }}
        >
          {isUpdatingNode || isAddingNode ? (
            <Loader size="extraSmall" variant="secondary" />
          ) : (
            <>{type === "add" ? "Adicionar" : "Editar"} aula</>
          )}
        </StyledButton>
      </StyledClassFooter>
    </form>
  );
};

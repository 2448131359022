import styled, { css } from "styled-components";

import { Grid } from "@mui/material";
import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledContainer = styled.div<{ hasMoreThanOne: boolean }>`
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding-left: 0px;
    padding-right: 0px;
  }

  ${({ hasMoreThanOne }) =>
    hasMoreThanOne
      ? css``
      : css`
          padding-left: 0px;
          padding-right: 0px;
        `}
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 8px;
  align-self: center;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const StyledGrid = styled(Grid)``;

export const StyledFlex = styled.div`
  display: flex;
`;

export const InfoArea = styled.div`
  display: flex;
  margin: 0 0 16px;
`;

export const StyledTitle = styled(Typography)`
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const StyledDescription = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
  display: flex;
  align-items: center;
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const StyledImageContainer = styled.div<{ url: string }>`
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f6f4f6;
  border-radius: 8px 0 0 8px;
  background-position: center;
  width: 50%;
  height: 350px;
  position: relative;
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSubContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 0px 8px 8px 0px;

  padding: 24px;
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
`;

export const StyledContainerItem = styled.div<{ hasMoreThanOne: boolean }>`
  display: flex;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    ${StyledImageContainer} {
      display: none;
    }
    ${StyledSubContainerItem} {
      margin-bottom: 16px;
      margin-left: 10px;
      margin-right: 10px;

      @media screen and (max-width: ${({ theme }) =>
          `${theme.breakpoints.values.md}px`}) {
        margin-left: 0px;
        margin-right: 0px;
      }

      ${({ hasMoreThanOne }) =>
        hasMoreThanOne
          ? css``
          : css`
              margin-left: 0px;
              margin-right: 0px;
            `}
    }
  }
`;

export const StyledArrowLeftContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  z-index: 12;
  left: -30px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    left: -20px;
  }
`;

export const StyledArrowRightContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  z-index: 12;
  right: -30px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    right: -20px;
  }
`;

export const SubImageHoverContainer = styled.div`
  background: rgba(29, 22, 27, 0.4);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  opacity: 0;
  z-index: 10;

  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const IconButtonHover = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
        opacity: 0.6;
      }
      svg {
        stroke: ${({ theme }) => theme.colors.neutral.light.pure};
        width: 12px;
        height: 12px;
      }
    }
  }
`;

import { useCallback, useMemo, useState } from "react";

import { Grid } from "@mui/material";

import { StyledText } from "@utils";
import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { Search } from "./Search";
import { Filters } from "./Filters";
import { LearningObjectTable } from "./LearningObjectTable";
import { CreateCard } from "./CreateCard";

import { trpc } from "@api/client";
import { getFromSS, setInSS } from "@flash-tecnologia/hros-web-utility";
import { NoLearningObject } from "./NoLearningObject";
import { LearningObjectCard } from "./LearningObjectCard";

export const LearningObjectTab = () => {
  const courseTypeView = useMemo(
    () => getFromSS("lms_manage_create_course_type_view"),
    []
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typeView, setTypeView] = useState<any>(courseTypeView || "list");

  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const handleSetTypeView = useCallback(
    (type: string) => {
      setInSS({ key: "lms_manage_create_course_type_view", value: type });
      setTypeView(type);
    },
    [typeView]
  );

  const { data, isLoading: isLoadingGetLearningObjects } =
    trpc.learningObject.getLearningObjects.useQuery(
      {
        limit: pageSize,
        page: page,
        order: "asc",
        sortBy: "_id",
        search: search,
        filters: filterStates,
      },
      {
        refetchOnWindowFocus: false,
        retry: 1,
      }
    );

  const filters = [
    {
      accessor: "type",
      label: "Tipo",
      options: [
        {
          label: "Artigo",
          value: "article",
        },
        {
          label: "Avaliação",
          value: "exam",
        },
        {
          label: "PDF",
          value: "pdf",
        },
        {
          label: "Podcast",
          value: "podcast",
        },
        {
          label: "Vídeo",
          value: "video",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "status",
      label: "Status",
      options: [
        {
          label: "Disponível",
          value: "published",
        },
        {
          label: "Em processamento",
          value: "processing",
        },
        {
          label: "Rascunho",
          value: "draft",
        },
        {
          label: "Erro",
          value: "beforeInit",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "isShared",
      label: "Compartilhamento",
      options: [
        {
          label: "Compartilhado",
          value: "true",
        },
        {
          label: "Não compartilhado",
          value: "false",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "createdAt",
      label: "Criado em",
      options: [],
      type: "date",
    },
    {
      accessor: "updatedAt",
      label: "Última atualização",
      options: [],
      type: "date",
    },
  ] as any;

  return (
    <div>
      <StyledText variant="body2" setColor="neutral40">
        Os conteúdos criados aqui podem ser associados a um treinamento depois.
      </StyledText>
      <div style={{ display: "flex", marginBottom: "24px", marginTop: "24px" }}>
        <Grid
          container
          spacing={2}
          flexGrow={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={6} lg={2.3}>
            <CreateCard type={"video"} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2.3}>
            <CreateCard type={"podcast"} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2.3}>
            <CreateCard type={"pdf"} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2.3}>
            <CreateCard type={"article"} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2.3}>
            <CreateCard type={"exam"} />
          </Grid>
        </Grid>
      </div>

      <Search onChange={setSearch} disabled={isLoadingGetLearningObjects} />

      <Filters
        disabled={isLoadingGetLearningObjects}
        typeView={typeView}
        handleSetTypeView={handleSetTypeView}
        filters={filters}
        onFilter={setFilterStates}
      />

      {!data?.learningObjects?.length &&
      !isLoadingGetLearningObjects &&
      !!!Object.keys(filterStates)?.length &&
      !!!search ? (
        <EmptyState
          buttonText={""}
          description={<NoLearningObject />}
          buttonProps={{ size: "small", hidden: true }}
        />
      ) : typeView === "list" ? (
        <LearningObjectTable
          data={data?.learningObjects || []}
          rawData={data?.learningObjects || []}
          total={data?.totalCount || 0}
          loading={isLoadingGetLearningObjects}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        <LearningObjectCard
          data={data?.learningObjects || []}
          rawData={data?.learningObjects || []}
          total={data?.totalCount || 0}
          loading={isLoadingGetLearningObjects}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

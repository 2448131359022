import { IDoneExam } from "./types";

import {
  ContentArea,
  ErrorDescription,
  FooterArea,
  FooterText,
  IconContainer,
  StyledButton,
  StyledIcon,
  SubTitle,
  Title,
} from "./style";

export const DoneExam = ({
  title,
  type = "success",
  rightAnswers = 0,
  totalAnswers = 0,
  minimunAnswers = 0,
  showAnswerSheet = false,
  onClick,
}: IDoneExam) => {
  const totalPercentage = Math.round((rightAnswers / totalAnswers) * 100);

  return (
    <ContentArea>
      <IconContainer type={type}>
        <StyledIcon
          size={60}
          name={type != "success" ? "IconMoodSad" : "IconMoodHappy"}
          fill="transparent"
          type={type}
        />
      </IconContainer>
      <Title variant="body1" type={type}>
        {title}
      </Title>
      <SubTitle variant="body3" type={type}>
        Seu resultado foi{" "}
        <span>
          {rightAnswers}/{totalAnswers} ({totalPercentage}%)
        </span>
      </SubTitle>
      {type != "success" && minimunAnswers ? (
        <ErrorDescription variant="body3">
          Para a aprovação, você precisa acertar{" "}
          <b>{minimunAnswers} questões ou mais.</b>
        </ErrorDescription>
      ) : null}
      {showAnswerSheet ? (
        <FooterArea>
          {onClick ? (
            <StyledButton onClick={onClick} variant="primary" size="medium">
              Voltar para Meus Treinamentos
            </StyledButton>
          ) : (
            <FooterText variant="body3">Confira o gabarito abaixo:</FooterText>
          )}
        </FooterArea>
      ) : null}
    </ContentArea>
  );
};

import { StyledModal, PlayerContainer } from "./styled";

import { IconButton, PDFViewer } from "@flash-tecnologia/hros-web-ui-v2";
import { ExamQuestion } from "@components/ExamQuestion";
import { IntroductionExam } from "@components/IntroductionExam";

import { Player } from "@components/Player";

export const VideoContentPreviewModal = ({ open, setOpen, data }) => {
  const closeModal = async () => {
    setOpen(false);
  };

  return (
    <StyledModal isLoading={false} onClose={closeModal} open={open}>
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <IconButton
            onClick={closeModal}
            size="medium"
            variant="line"
            icon="IconX"
          />
        </div>
        {data?.type === "article" ? (
          data?.content?.html ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data.content?.html ??
                  data.url?.path?.html ??
                  "Não foi possível visualizar",
              }}
            />
          ) : (
            <PDFViewer
              width="100%"
              height="400px"
              src={`${data.url?.path}${data.url?.signature ?? null}`}
              options={{
                showToolbar: true,
                fit: "height",
                page: 2,
              }}
            />
          )
        ) : data.type === "pdf" ? (
          <PDFViewer
            width="100%"
            height="400px"
            src={`${data.url?.path}${data.url?.signature ?? null}`}
            options={{
              showToolbar: true,
              fit: "height",
              page: 2,
            }}
          />
        ) : data.type === "exam" ? (
          <>
            <IntroductionExam
              title={data.contentName}
              description={data.description}
              minimunGrade={Math.round(
                data.settings?.passingScore * (10 / data.questions?.length)
              )}
              totalAttempts={data.settings?.attempts}
              totalQuestions={data.questions?.length}
              isView={true}
            />
            {data.questions?.map(
              ({ question = null, mandatory = false, alternatives }, index) => (
                <ExamQuestion
                  key={index}
                  number={index + 1}
                  title={question || ""}
                  alternatives={alternatives}
                  isMandatory={mandatory}
                  isDone={false}
                  isView={true}
                />
              )
            )}
          </>
        ) : (
          <PlayerContainer>
            <Player
              mediaToPlay={{
                uri:
                  data.type == "podcast" && data.url?.origin === "internal"
                    ? data.url?.path + data.url?.signature
                    : data.url?.path,
                signature:
                  data.url?.origin === "internal" ? data.url?.signature : null,
              }}
            />
          </PlayerContainer>
        )}
      </>
    </StyledModal>
  );
};

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import { PageTemplate } from "@components/PageTemplate";

import { routes } from "@routes";
import { StyledText, StyledTitle, dispatchToast } from "@utils";

import { trpc } from "@api/client";

import { DoneModal } from "../Modal/DoneModal";
import { DraftCourseModal } from "../Modal/DraftCourseModal";

import {
  BasicInfoTab,
  CourseContentTab,
  SettingsTab,
  StudentsTab,
} from "./components/Tabs";

import { Container, FlexColumn, PageTitle, StyledTab } from "./styled";

export const SixthStep = ({
  data,
  type,
  courseId,
  currentStep,
  updateCourseMutate,
  isFetching,
  isMutating,
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [done, setDone] = useState<boolean>(false);
  const [saveAsDraft, setSaveAsDraft] = useState<boolean>(false);

  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { selectedCompany } = useSelectedCompany();

  console.log("data ", data);

  const tabs = {
    [0]: (
      <BasicInfoTab
        data={data}
        onNavigate={() =>
          navigate(
            routes.pageCreateEditCourse
              .replace(":step", "0")
              .replace(":type", type)
              .replace(":courseId", courseId || "")
          )
        }
      />
    ),
    [1]: (
      <SettingsTab
        data={data}
        onNavigate={() =>
          navigate(
            routes.pageCreateEditCourse
              .replace(":step", "1")
              .replace(":type", type)
              .replace(":courseId", courseId || "")
          )
        }
      />
    ),
    [2]: (
      <CourseContentTab
        data={data}
        onNavigate={() =>
          navigate(
            routes.pageCreateEditCourse
              .replace(":step", "2")
              .replace(":type", type)
              .replace(":courseId", courseId || "")
          )
        }
      />
    ),
    [3]: (
      <StudentsTab
        data={data}
        onNavigate={() =>
          navigate(
            routes.pageCreateEditCourse
              .replace(":step", "3")
              .replace(":type", type)
              .replace(":courseId", courseId || "")
          )
        }
      />
    ),
  };

  return (
    <PageTemplate
      stepper={{
        steps: [
          "Informações básicas",
          "Configurações",
          "Conteúdo do treinamento",
          "Alunos",
          "Revisão",
        ],
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          callback: () => {
            updateCourseMutate(
              {
                courseId,
                companyId: selectedCompany?.id,
                status: "draft",
              },
              {
                onSuccess: () => {
                  utils.course.getManageCourses.invalidate();
                  utils.course.getCourseById.invalidate();

                  setSaveAsDraft(true);
                },
                onError: (e: any) => {
                  const courseNotExists =
                    e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

                  const message = courseNotExists
                    ? "Não existe o curso."
                    : "Erro ao tentar atualizar o curso, tente novamente mais tarde!";

                  dispatchToast({
                    type: "error",
                    content: message,
                  });
                },
              }
            );
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          callback: () => {
            const previousStep = currentStep - 1;

            navigate(
              routes.pageCreateEditCourse
                .replace(":step", previousStep?.toString())
                .replace(":type", type)
                .replace(":courseId", courseId || "")
            );
          },
        },
        confirmProps: {
          loading: isMutating,
          disabled: isFetching,
          title: (
            <>
              {type == "edit" ? "Salvar alterações" : "Criar treinamento"}
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          callback: () => {
            const peopleInCourse = data?.peopleInCourse || [];

            if (!peopleInCourse.length) {
              dispatchToast({
                type: "error",
                content:
                  "Selecione os alunos que deverão realizar o treinamento.",
              });

              return;
            }

            if (!!!data?.start) {
              dispatchToast({
                type: "error",
                content:
                  "Favor selecionar a data de início na etapa de Informações básicas",
              });

              return;
            }
            updateCourseMutate(
              {
                courseId,
                companyId: selectedCompany?.id,
                status: "published",
              },
              {
                onSuccess: () => {
                  utils.course.getManageCourses.refetch();

                  dispatchToast({
                    type: "success",
                    content: "Adicionado com sucesso!",
                  });

                  setDone(true);
                },
              }
            );
          },
        },
      }}
    >
      <Container>
        <PageTitle variant="headline6">
          {type === "create" ? "Criar" : "Editar"} treinamento
        </PageTitle>
        <Grid container>
          <Grid item sm={12} md={5} lg={4}>
            <FlexColumn style={{ marginRight: "24px" }}>
              <StyledTitle
                setColor="secondary50"
                variant="headline7"
                style={{ fontWeight: 700, marginBottom: "16px" }}
              >
                Revisão
              </StyledTitle>
              <StyledText
                setColor="neutral50"
                variant="body3"
                style={{ fontWeight: 400, marginBottom: "16px" }}
              >
                Revise os dados do seu treinamento e edite se necessário.
              </StyledText>
              {isFetching ? (
                <Skeleton
                  width="100%"
                  height="250px"
                  animation="pulse"
                  variant="rounded"
                />
              ) : (
                <StyledTab
                  selected={selectedTab}
                  onTabChanged={setSelectedTab}
                  variant="secondary"
                  tabItens={[
                    { label: "Informações básicas", component: undefined },
                    { label: "Configurações", component: undefined },
                    { label: "Conteúdo do treinamento", component: undefined },
                    { label: "Alunos", component: undefined },
                  ]}
                  type="fullWidth"
                />
              )}
            </FlexColumn>
          </Grid>
          <Grid item sm={12} md={7} lg={8}>
            {isFetching ? (
              <Skeleton
                width="100%"
                height="500px"
                animation="pulse"
                variant="rounded"
              />
            ) : (
              tabs[selectedTab || 0]
            )}
          </Grid>
        </Grid>
      </Container>

      <DraftCourseModal
        open={saveAsDraft}
        onClose={() => navigate("/lms/manage-courses")}
      />

      <DoneModal
        open={done}
        onBack={() => {
          setDone(false);
        }}
        onClose={async () => {
          navigate(`/lms/manage-courses`);
          setDone(false);
        }}
      />
    </PageTemplate>
  );
};

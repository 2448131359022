import { useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  Tag,
  dayjs,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";

import EmptyState from "../../../../assets/emptyState.jpg";
import { track } from "@utils";

import {
  SnapList,
  SnapItem,
  useVisibleElements,
  useScroll,
} from "react-snaplist-carousel";

import {
  StyledContainer,
  StyledIconButton,
  StyledFlex,
  StyledDescription,
  StyledTitle,
  StyledImageContainer,
  StyledSubContainerItem,
  StyledFlexColumn,
  StyledContainerItem,
  SubImageHoverContainer,
  IconButtonHover,
  StyledArrowRightContainer,
  StyledArrowLeftContainer,
  InfoArea,
} from "./styles";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface CardCarouselProps {
  items: { [key: string]: any }[];
}

const CardCarousel = ({ items }: CardCarouselProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const snapList = useRef(null);
  const goToSnapItem = useScroll({ ref: snapList });

  const navigate = useNavigate();

  const totalItems = useMemo(() => items.length, [items]);

  const visible = useVisibleElements(
    { debounce: 10, ref: snapList },
    ([element]) => element
  );

  const hasMoreThanOne = items?.length > 1;

  const renderCarouselItem = useMemo(() => {
    return items.map((item: any) => {
      const startDate = dayjs(item?.start);
      const endDate = dayjs(item?.end);

      let availability = item?.end
        ? `Disponível até ${endDate.format("DD/MM/YYYY")}`
        : "Sempre disponível";

      let status = "doing";
      let hasExpired = false;

      if (dayjs().isSameOrBefore(startDate, "day")) {
        availability = `Disponível em ${startDate.format("DD/MM/YYYY")}`;
      }

      if (dayjs().isAfter(endDate, "day")) {
        availability = `Encerrado em ${endDate.format("DD/MM/YYYY")}`;
        hasExpired = true;
      }

      if (dayjs().isBefore(startDate, "day") || item?.totalViews === 0)
        status = "beforeInit";

      if (item?.doneClassesTotal === item?.classesTotal) status = "done";

      const disabled =
        status !== "done" &&
        (dayjs().isBefore(startDate, "day") ||
          (endDate && dayjs().isAfter(endDate, "day")) ||
          item?.classesTotal === 0);

      const validationIconLock = status === "beforeInit" || hasExpired;

      return (
        <SnapItem key={item._id} snapAlign="start" width="100%">
          <StyledContainerItem
            hasMoreThanOne={hasMoreThanOne}
            style={{ width: "100%" }}
          >
            <StyledImageContainer
              url={
                item?.banner?.path
                  ? `${item?.banner?.path}${item?.banner?.signature}`
                  : EmptyState
              }
            >
              <SubImageHoverContainer>
                <IconButtonHover
                  size="large"
                  variant="filled"
                  onClick={() => {
                    if (!validationIconLock) {
                      navigate(`/lms/my-courses/${item._id}/0/class/view`);
                    }
                  }}
                  icon={validationIconLock ? "IconLock" : "IconPlayerPlay"}
                  style={{ alignSelf: "center" }}
                />
              </SubImageHoverContainer>
            </StyledImageContainer>

            <StyledSubContainerItem style={{ width: "50%" }}>
              <div style={{ width: "100%", height: "100%" }}>
                <StyledFlexColumn
                  style={{ justifyContent: "space-between", height: "100%" }}
                >
                  <StyledFlexColumn>
                    <StyledFlex style={{ marginBottom: "16px", gap: "10px" }}>
                      <Tag
                        variant={
                          status === "beforeInit"
                            ? "error"
                            : status === "done"
                            ? "success"
                            : "info"
                        }
                      >
                        {status === "beforeInit"
                          ? "Não iniciado"
                          : status === "done"
                          ? "Concluído"
                          : "Em andamento"}
                      </Tag>

                      {item?.mandatory ? (
                        <Tag variant="primary">Obrigatório</Tag>
                      ) : null}

                      {item?.certificate?._id ? (
                        <Tag variant="primary">Emite certificado</Tag>
                      ) : null}
                    </StyledFlex>

                    <StyledTitle variant="headline7">{item.name}</StyledTitle>

                    <InfoArea>
                      <StyledIconButton
                        size="small"
                        variant="line"
                        icon="IconCalendarTime"
                      />
                      <StyledDescription variant="body3">
                        {availability}
                      </StyledDescription>
                    </InfoArea>
                    <InfoArea>
                      <StyledIconButton
                        size="small"
                        variant="line"
                        icon="IconSchool"
                      />
                      <StyledDescription variant="body3">
                        {item?.modulesTotal} módulo
                        {item?.modulesTotal > 1 ? "s" : ""} •{" "}
                        {item?.classesTotal} aula
                        {item?.classesTotal > 1 ? "s" : ""}
                      </StyledDescription>
                    </InfoArea>
                  </StyledFlexColumn>

                  <div>
                    <Button
                      size="large"
                      variant="primary"
                      disabled={disabled}
                      onClick={() => {
                        track({
                          name: "employee_lms_mycourses_accesscourse_clicked",
                        });
                        navigate(`/lms/my-courses/${item._id}/0/class/view`);
                      }}
                    >
                      Acessar treinamento
                    </Button>
                  </div>
                </StyledFlexColumn>
              </div>
            </StyledSubContainerItem>
          </StyledContainerItem>
        </SnapItem>
      );
    });
  }, [items, hasMoreThanOne]);

  const renderArrow = useMemo(() => {
    if (!hasMoreThanOne) return <></>;

    return (
      <>
        <StyledArrowLeftContainer
          onClick={() =>
            goToSnapItem(visible === 0 ? totalItems - 1 : visible - 1)
          }
        >
          <IconButton
            size={isMobile ? "small" : "large"}
            variant="filled"
            icon="IconChevronLeft"
          />
        </StyledArrowLeftContainer>
        <StyledArrowRightContainer onClick={() => goToSnapItem(visible + 1)}>
          <IconButton
            size={isMobile ? "small" : "large"}
            variant="filled"
            icon="IconChevronRight"
          />
        </StyledArrowRightContainer>
      </>
    );
  }, [hasMoreThanOne, isMobile, visible, totalItems]);

  return (
    <StyledContainer hasMoreThanOne={hasMoreThanOne}>
      <div style={{ position: "relative" }}>
        <SnapList ref={snapList} direction="horizontal" tabIndex={0}>
          {renderCarouselItem}
          {renderArrow}
        </SnapList>
      </div>
    </StyledContainer>
  );
};

export { CardCarousel };

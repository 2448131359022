import { useState, useEffect } from "react";

import {
  Typography,
  TagCheckboxFilter,
  TagDateFilter,
  Tooltip,
  IconButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Container, FilterContainer } from "./styled";

import { Filter, Option } from "../types";
import { track } from "@utils";
import { GenerateTableReportModal } from "@components/GenerateTableReportModal";

interface TableFiltersProps {
  disabled?: boolean;
  filters?: Filter[];
  onFilter?: (el: { [key: string]: any[] }) => any;
}

export const Filters = ({ filters, onFilter, disabled }: TableFiltersProps) => {
  const [filterStates, setFilterStates] = useState<any>({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (filterStates === undefined) return;

    const timer = setTimeout(() => {
      onFilter && onFilter(filterStates);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [filterStates]);

  const renderFilters = () => {
    return filters?.map((value) => {
      const renderOptions =
        value?.options?.map((e: Option) => {
          return { label: e.label || "", value: e.value || "" };
        }) || [];

      switch (value.type) {
        case "date":
          return (
            <TagDateFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onSubmit={(date) => {
                if (date?.from && !date?.to)
                  return setFilterStates({
                    ...filterStates,
                    [value.accessor]: { from: date.from, to: null },
                  });

                if (date?.to) {
                  return setFilterStates({
                    ...filterStates,
                    [value.accessor]: { from: date.from, to: date.to },
                  });
                }

                if (!date.from) delete filterStates[value.accessor];

                return setFilterStates({ filterStates });
              }}
              key={(renderOptions || []).length + value.accessor}
            />
          );

        case "checkbox":
        default:
          return (
            <TagCheckboxFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onClick={(e) => {
                if (!e.length && !(filterStates?.[value.accessor]?.length || 0))
                  return;

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: e,
                };

                if (!e.length) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
              showClearButton={true}
              key={(renderOptions || []).length + value.accessor}
            />
          );
      }
    });
  };

  return (
    <Container>
      <FilterContainer disabled={disabled}>
        <Typography variant="body3">Filtrar por</Typography>
        {renderFilters()}
      </FilterContainer>

      <Tooltip arrow title="Exportar relatório">
        <div>
          <IconButton
            onClick={() => {
              track({
                name: "company_lms_trainingdetail_exportperformancereport_clicked",
              });
              setOpen(true);
            }}
            icon="IconDownload"
            size="small"
            variant="line"
          />
        </div>
      </Tooltip>

      <GenerateTableReportModal
        open={open}
        type="student"
        onClose={() => setOpen(false)}
      />
    </Container>
  );
};

import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledIconButton,
  Description,
  SubTitle,
  WarnArea,
  WarnIcon,
  WarnTitle,
  WarnDescription,
  Gap,
} from "./styled";

export const DoneModal = ({ open, onClose, onBack }) => {
  return (
    <StyledModal
      onClose={onClose}
      open={open}
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconMoodHappy" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <StyledFlexBetween>
          <StyledTitle variant="headline7">Pronto!</StyledTitle>
          <StyledIconButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={onBack}
          />
        </StyledFlexBetween>
        <StyledContainer>
          <SubTitle variant="headline6">
            Seu treinamento foi criado com sucesso! 😄
          </SubTitle>
          <Description variant="body3">
            Parabéns! Seu novo treinamento já está disponível para os seus
            alunos. Não esqueça que você pode acompanhar o nível de engajamento
            e personalizar os treinamentos sempre que necessário conforme sua
            necessidade.
          </Description>

          <Gap>
            <WarnArea>
              <WarnIcon className="warning-icon-area warning-icon-warning">
                <Icons name="IconAlertCircle" size={30} fill="transparent" />
              </WarnIcon>
              <div>
                <WarnTitle variant="body3">
                  Progresso e conclusão do treinamento
                </WarnTitle>
                <WarnDescription variant="body4">
                  As aulas são marcadas como concluídas após a visualização de
                  pelo menos 80% do conteúdo de vídeo e podcast. Para conteúdos
                  PDF e artigo, o aluno será responsável por marcar como
                  concluído. Para o aluno finalizar o treinamento, todas as
                  aulas precisam estar concluídas.
                </WarnDescription>
              </div>
            </WarnArea>

            <div id={"modal_create_course_survey"} />
          </Gap>

          <StyledButtonContainer>
            <Button size="large" variant="primary" onClick={onClose}>
              Voltar para Gerenciar Treinamentos
            </Button>
          </StyledButtonContainer>
        </StyledContainer>
      </>
    </StyledModal>
  );
};

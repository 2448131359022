import { useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Icons,
  LinkButton,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Checkbox, Grid } from "@mui/material";

import {
  ActionLinkButton,
  AlternativeArea,
  AnswerText,
  DragableIcon,
  FlexDiv,
  OptionSubtitleContainer,
  QuestionArea,
  QuestionDataArea,
  QuestionField,
  QuestionOptions,
  QuestionTitle,
  QuestionTitleArea,
  StyledIconContainer,
  StyledLinkButton,
  StyledSubtitleContainer,
  StyledTextField,
  ToggleArea,
} from "./styled";
import dispatchToast from "../../../../../utils/dispatchToast";
import { track } from "../../../../../utils/segment";

export const CreateQuestionCard = ({
  number,
  question,
  setQuestions,
  handleRemoveQuestion,
  handleDuplicateQuestion,
  hasAssociateCourse,
}) => {
  const handleAddOption = useCallback(() => {
    setQuestions((prev) =>
      prev?.map((quest, index) => {
        if (index === number) {
          return {
            ...quest,
            alternatives: [
              ...quest?.alternatives,
              {
                value: null,
                rightAnswer: false,
              },
            ],
          };
        }
        return quest;
      })
    );
  }, [question?.alternatives]);

  const handleRemoveOption = useCallback(
    (removeIndex) => {
      const newOptions = question?.alternatives?.filter(
        (_, index) => index !== removeIndex
      );

      if (question?.alternatives?.length > 2) {
        setQuestions((prev) =>
          prev?.map((quest, index) => {
            if (index === number) {
              return {
                ...quest,
                alternatives: newOptions,
              };
            }
            return quest;
          })
        );
      } else {
        dispatchToast({
          content: "É necessário pelo menos duas alternativas.",
          type: "error",
        });
      }
    },
    [question]
  );

  const handleChangeOption = useCallback((questionIndex, value) => {
    setQuestions((prev) =>
      prev?.map((quest, index) => {
        if (index === number) {
          quest.alternatives[questionIndex].value = value;
        }
        return quest;
      })
    );
  }, []);

  const handleChangeRightAnswer = useCallback((questionIndex, value) => {
    setQuestions((prev) =>
      prev?.map((quest, index) => {
        if (index === number) {
          quest?.alternatives?.map((_, key) => {
            quest.alternatives[key].rightAnswer = false;
          });
          quest.alternatives[questionIndex].rightAnswer = value;
        }
        return quest;
      })
    );
  }, []);

  const handleDropAlternative = (droppedItem) => {
    if (!droppedItem.destination) return;
    const updatedList = [...question?.alternatives];
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    setQuestions((prev) =>
      prev?.map((quest, index) => {
        if (index === number) {
          return {
            ...quest,
            alternatives: updatedList,
          };
        }
        return quest;
      })
    );
  };

  const QuestionOption = useCallback(
    ({
      index,
      value,
      id,
      rightAnswer,
      handleRemoveOption,
      hasAssociateCourse,
    }) => {
      const letter = String.fromCharCode(index + 65);

      return (
        <Draggable
          key={index}
          draggableId={id}
          index={index}
          isDragDisabled={hasAssociateCourse}
        >
          {(provided) => (
            <div
              className="item-container"
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
            >
              <Grid container>
                <AlternativeArea item xs={12}>
                  <DragableIcon
                    name="IconGripVertical"
                    fill={"transparent"}
                    size={18}
                  />
                  <Typography variant="body2">{letter}</Typography>
                  <AnswerText>
                    <StyledTextField
                      id={"question"}
                      name={"question"}
                      focused={value?.length ?? false}
                      label={`Alternativa ${letter}`}
                      inputProps={{ maxLength: 1000 }}
                      value={value}
                      onChange={({ target: { value } }) =>
                        handleChangeOption(index, value)
                      }
                      disabled={hasAssociateCourse}
                      multiline
                      rows={1}
                      style={{ marginTop: "24px" }}
                    />
                  </AnswerText>
                  <StyledIconContainer
                    onClick={() => handleRemoveOption(index)}
                    disabled={hasAssociateCourse}
                  >
                    <Icons name="IconTrash" fill={"transparent"} size={25} />
                  </StyledIconContainer>
                </AlternativeArea>
                <Grid item xs={12}>
                  <OptionSubtitleContainer>
                    <div>
                      <Checkbox
                        checked={rightAnswer}
                        disabled={hasAssociateCourse}
                        icon={<Icons name="IconCircle" fill={"transparent"} />}
                        checkedIcon={
                          <Icons name="IconCircleCheck" fill={"transparent"} />
                        }
                        onChange={({ target: { checked } }) =>
                          handleChangeRightAnswer(index, checked)
                        }
                      />
                      <Typography variant="caption">
                        Alternativa correta
                      </Typography>
                    </div>
                    <Typography variant="caption">
                      {value?.length ?? 0}/1000
                    </Typography>
                  </OptionSubtitleContainer>
                </Grid>
              </Grid>
            </div>
          )}
        </Draggable>
      );
    },
    [question?.alternatives]
  );

  return (
    <QuestionArea container>
      {/* <DraggableArea className="DragArea" item xs={1}>
        <Icons name="IconGripVertical" fill={"transparent"} size={16} />
      </DraggableArea> */}
      <Grid item xs={12}>
        <QuestionTitleArea>
          <QuestionTitle>
            <Typography variant="body2">Questão {number + 1}</Typography>
            <QuestionOptions>
              <FlexDiv>
                <ToggleArea>
                  <Toggle
                    checked={question?.mandatory ?? false}
                    disabled={hasAssociateCourse}
                    onChange={({ target: { checked } }) => {
                      if (checked) {
                        track({
                          name: "company_lms_testquestions_required_clicked",
                        });
                      }

                      setQuestions((prev) =>
                        prev?.map((quest, index) => {
                          if (index === number) {
                            return {
                              ...quest,
                              mandatory: checked,
                            };
                          }
                          return quest;
                        })
                      );
                    }}
                  />
                  <Typography variant="caption">Questão obrigatória</Typography>
                </ToggleArea>
              </FlexDiv>
              <FlexDiv>
                <ActionLinkButton
                  onClick={() => handleDuplicateQuestion(number)}
                  variant="secondary"
                  disabled={hasAssociateCourse}
                >
                  <Icons name="IconCopy" fill={"transparent"} size={16} />
                  <Typography variant="caption">Duplicar</Typography>
                </ActionLinkButton>
                <LinkButton
                  disabled={hasAssociateCourse}
                  onClick={() => handleRemoveQuestion(number)}
                  variant="secondary"
                >
                  <Icons name="IconTrash" fill={"transparent"} size={16} />
                  <Typography variant="caption">Remover</Typography>
                </LinkButton>
              </FlexDiv>
            </QuestionOptions>
          </QuestionTitle>
        </QuestionTitleArea>
        <QuestionDataArea>
          <QuestionField
            id={"question"}
            name={"question"}
            label={"Enunciado da questão"}
            inputProps={{ maxLength: 600 }}
            disabled={hasAssociateCourse}
            focused={question?.question?.length ?? false}
            value={question?.question}
            onChange={({ target: { value } }) =>
              setQuestions((prev) =>
                prev?.map((quest, index) => {
                  if (index === number) {
                    return {
                      ...quest,
                      question: value,
                    };
                  }
                  return quest;
                })
              )
            }
            multiline
            rows={4}
            style={{ marginTop: "24px" }}
          />
          <StyledSubtitleContainer>
            <Typography variant="caption">Máximo 600 caracteres</Typography>
            <Typography variant="caption">
              {question?.question?.length ?? 0}/600
            </Typography>
          </StyledSubtitleContainer>
          <DragDropContext
            onDragEnd={handleDropAlternative}
            isDragDisabled={hasAssociateCourse}
          >
            <Droppable
              droppableId="alternative-container"
              isDropDisabled={hasAssociateCourse}
            >
              {(provided) => (
                <div
                  className="alternative-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {question?.alternatives?.map(
                    ({ value, rightAnswer, _id }, index) => (
                      <QuestionOption
                        key={index}
                        index={index}
                        id={_id ?? index + 1}
                        value={value}
                        rightAnswer={rightAnswer}
                        handleRemoveOption={handleRemoveOption}
                        hasAssociateCourse={hasAssociateCourse}
                      />
                    )
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <StyledLinkButton
            disabled={
              question?.alternatives?.length === 5 || hasAssociateCourse
            }
            variant="primary"
            onClick={() => handleAddOption()}
          >
            <Icons name="IconPlus" fill={"transparent"} /> Adicionar nova
            alternativa
          </StyledLinkButton>
        </QuestionDataArea>
      </Grid>
    </QuestionArea>
  );
};

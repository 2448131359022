import styled from "styled-components";

import {
  Icons,
  LinkButton,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700 !important;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 16px;
`;

export const ContentArea = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`;

export const CounterArea = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutral[70]};
  border-radius: 12px;
  align-items: center;
  padding: 14px 16px;
  margin-right: 16px;
  button {
    padding: 5px !important;
  }
  span {
    padding: 0 16px;
  }
`;

export const StyledSelectField = styled(SelectField)`
  && {
    margin-right: 16px;

    .MuiFilledInput-root {
      border: 1px solid ${({ theme }) => theme.colors.neutral[70]} !important;
      width: 180px;
    }

    .MuiSelect-select {
      padding: 27px 12px 6px;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: left;
      color: ${({ theme }) => theme.colors.neutral.dark.dark4};

      &:focus {
        background-color: transparent;
      }
    }
  }
`;

export const InfoLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 400 !important;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const Link = styled(LinkButton)`
  font-size: 12px !important;
  line-height: 18px;
`;

export const DeleteButton = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  z-index: 1;
  cursor: pointer;
`;

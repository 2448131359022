import { useEffect } from "react";
import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

export const useLockHeaderMobile = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const contentsContainer =
      document.getElementById("contentsContainer") || "";

    if (!contentsContainer) return;

    if (isMobile) contentsContainer.style.overflow = "hidden";
    else {
      contentsContainer.style.overflow = "auto";
    }
    return () => {
      contentsContainer.style.overflow = "auto";
    };
  }, [isMobile]);
};

import {
  IconButton,
  Icons,
  Loader,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  ExpandButton,
  FlexContent,
  LoadingArea,
  PageCounter,
  PdfArea,
  PDFNavigator,
  Progress,
  ProgressBar,
  ZoomInButton,
} from "./styled";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export const PDFReader = ({
  url = null,
  defaultZoom = 1,
  isExpanded = false,
  setPdfModal = null,
}: {
  url: any;
  defaultZoom?: any;
  isExpanded?: any;
  setPdfModal?: any;
}) => {
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [zoom, setZoom] = useState(defaultZoom);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const isPhone = useMediaQuery("(max-width: 480px)");

  return (
    <>
      {url ? (
        <PdfArea isExpanded={isExpanded}>
          <PDFNavigator>
            <FlexContent>
              <IconButton
                size="small"
                variant="line"
                icon="IconZoomOut"
                onClick={() => setZoom((prev) => prev - 0.5)}
                disabled={zoom === 0.5}
              />
              <ZoomInButton
                size="small"
                variant="line"
                icon="IconZoomIn"
                onClick={() => setZoom((prev) => prev + 0.5)}
                disabled={zoom === 3}
              />
            </FlexContent>
            <FlexContent>
              <IconButton
                size="small"
                variant="line"
                icon="IconChevronLeft"
                onClick={goToPrevPage}
                disabled={pageNumber === 1}
              />
              <PageCounter variant="body3">
                {pageNumber} / {numPages}
              </PageCounter>
              <IconButton
                size="small"
                variant="line"
                icon="IconChevronRight"
                onClick={goToNextPage}
                disabled={pageNumber === numPages}
              />
            </FlexContent>
            <FlexContent>
              {setPdfModal ? (
                <ExpandButton onClick={() => setPdfModal(true)}>
                  <Icons name="IconArrowsDiagonal" />
                  <p>Expandir PDF</p>
                </ExpandButton>
              ) : null}
            </FlexContent>
          </PDFNavigator>
          <ProgressBar>
            <Progress progress={Math.round((pageNumber / numPages) * 100)} />
          </ProgressBar>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={() => (
              <LoadingArea>
                <Loader size="extraSmall" variant="secondary" />
              </LoadingArea>
            )}
          >
            <Page
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderAnnotationLayer={false}
              height={500}
              scale={zoom}
              width={isSmallScreen ? 550 : isPhone ? 400 : 700}
              loading={() => (
                <LoadingArea>
                  <Loader size="extraSmall" variant="secondary" />
                </LoadingArea>
              )}
            />
          </Document>
        </PdfArea>
      ) : (
        <div>Erro ao carregar pdf</div>
      )}
    </>
  );
};

import styled from "styled-components";

import { Icons, LinkButton, TextField } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

export const StyledTextField = styled(TextField)`
  width: 100%;
  margin-top: 0 !important;
`;

export const QuestionField = styled(TextField)`
  width: 100%;
  margin-top: 0 !important;
`;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const OptionSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const StyledIconContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  margin-top: -3px;
  &:hover {
    opacity: 0.5;
  }
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "")}
`;

export const QuestionTitle = styled.div``;

export const AlternativeArea = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const DraggableArea = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.neutral.light.light2};
  cursor: pointer;
`;

export const QuestionTitleArea = styled(Grid)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  padding: 25px 40px;
  p {
    font-weight: bold;
    margin-right: 5px;
  }
`;

export const QuestionArea = styled(Grid)`
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const QuestionDataArea = styled.div`
  padding: 40px 40px 24px;
  p {
    font-weight: bold;
    margin-left: 8px;
    margin-right: 5px;
  }
`;

export const AnswerText = styled.div`
  width: 100%;
  margin-left: 8px;
`;

export const DragableIcon = styled(Icons)`
  cursor: pointer;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin: 40px auto 0;
`;

export const QuestionOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 13px 0 0;
`;

export const FlexDiv = styled.div`
  display: flex;
`;

export const ToggleArea = styled.div`
  margin-right: 26px;
  span:first-child {
    margin-right: 12px;
  }
`;

export const ActionLinkButton = styled(LinkButton)`
  margin-right: 24px;
`;

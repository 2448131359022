import { useRef } from "react";
import { Content } from "./Content";
import { Header } from "./Header";

export const Mobile = ({
  selected: {
    learningObjectId,
    name,
    description,
    questions,
    done,
    settings: { passingScore, showAnswerSheet, attempts },
  },
  viewType,
  exams: {
    mobileAnsweredQuestion,
    examInitiate,
    setExamInitiate,
    mobileKeyQuestion,
    setMobileKeyQuestion,
    doneButton,
    setIsDoneTrigger,
  },
  isDone,
  aproved,
  examQuestions,
  handleSelectOption,
  attemptsLeft,
  totalCount,
}) => {
  const questionRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <Header
        selected={{
          questions,
        }}
        viewType={viewType}
        exams={{
          mobileAnsweredQuestion,
          examInitiate,
          setExamInitiate,
          mobileKeyQuestion,
          setMobileKeyQuestion,
          doneButton,
          setIsDoneTrigger,
        }}
        isDone={isDone}
        questionRef={questionRef}
      />
      <Content
        mobileKeyQuestion={mobileKeyQuestion}
        isDone={isDone}
        aproved={aproved}
        examQuestions={examQuestions}
        handleSelectOption={handleSelectOption}
        selected={{
          name,
          description,
          questions,
          settings: { passingScore, showAnswerSheet, attempts },
        }}
        attemptsLeft={attemptsLeft}
        examInitiate={examInitiate}
        totalCount={totalCount}
        ref={questionRef}
      />
    </>
  );
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";
import { ButtonGroup, Icons, Stepper } from "@flash-tecnologia/hros-web-ui-v2";
import {
  setInSS,
  getFromSS,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import { Context } from "../../../../context";
import { changeTab } from "../../../../api";
import { track } from "../../../../utils/segment";

import { FooterBar } from "../../../../components/FooterBar";
import { ContentChart } from "../../../../components/ContentChart";
import { AddingClassesModule } from "../../../../components/AddingClassesModule";

import {
  StyledPageContainer,
  StyledGrid,
  StyledIconToButton,
  StepperArea,
  Container,
  PageDescription,
  PageInfo,
  PageSubTitle,
  PageTitle,
} from "./styles";

import { DraftCourseModal } from "../../../../components/DraftCourseModal";
import WarnMessage from "../../../../components/WarnMessage";
import { trpc } from "@api/client";
import { dispatchToast } from "@utils";

export const CourseContent = ({
  data,
  learningObjectData,
  updateCourseMutate,
  isFetching,
  currentStep,
  courseId,
  type,
}) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();

  const { dispatch } = useContext(Context);
  const [modal, setModal] = useState<any>(false);
  const [courseHierachy, setCourseHierachy] = useState<any>({});
  const [learningObject, setLearningObject] = useState<any>({});
  const [typeView, setTypeView] = useState<any>("list");

  const steps = [
    "Informações básicas",
    "Configurações",
    "Conteúdo do treinamento",
    "Alunos",
    "Revisão",
  ];

  const { isFetching: isLoadingGetAllLearning } =
    trpc.learningObject.getAllLearningObject.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setLearningObject(data);
      },
    });

  const { isFetching: isLoadingGetCourseHierarchy } =
    trpc.course.getCourseHierarchy.useQuery(
      { id: courseId },
      {
        refetchOnWindowFocus: false,
        enabled: !!courseId,
        onSuccess: (data) => {
          setCourseHierachy(data);
        },
      }
    );

  const handleSetTypeView = useCallback(
    (type: string) => {
      setTypeView(type);
    },
    [typeView]
  );

  return (
    <Container>
      <StyledPageContainer>
        <StepperArea>
          <Stepper
            className="Stepper"
            steps={steps}
            activeStep={currentStep}
            disableClick
          />
          <div>
            <ButtonGroup>
              <StyledIconToButton
                selected={typeView === "list"}
                onClick={() => handleSetTypeView("list")}
              >
                <Icons name="IconList" />
              </StyledIconToButton>
              <StyledIconToButton
                selected={typeView === "chart"}
                onClick={() => handleSetTypeView("chart")}
              >
                <Icons name="IconSitemap" fill="transparent" />
              </StyledIconToButton>
            </ButtonGroup>
          </div>
        </StepperArea>
        <PageTitle variant="headline6">
          {type === "create" ? "Criar" : "Editar"} treinamento
        </PageTitle>

        {!learningObject?.length && !isFetching ? (
          <WarnMessage
            title="Você ainda não criou nenhum conteúdo."
            description="Para criar o treinamento e estruturar as aulas adicionando seus conteúdos é ideal que haja algum conteúdo (vídeo, podcast, PDF, artigo ou avaliação) criado. Por favor, adicione os conteúdos antes de prosseguir com a criação do treinamento."
            redirectAction={() => {
              changeTab({ tabNumber: 2, dispatch });
              navigate(`/lms/manage-courses`);
            }}
          />
        ) : null}

        <StyledGrid container>
          {isFetching &&
          isLoadingGetAllLearning &&
          isLoadingGetCourseHierarchy ? (
            <>
              <Grid item sm={12} md={5} lg={4}>
                <div style={{ marginRight: "10px" }}>
                  <Skeleton
                    width="100%"
                    height="100vh"
                    animation="pulse"
                    variant="rectangular"
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={7} lg={8}>
                <div>
                  <Skeleton
                    width="100%"
                    height="100vh"
                    animation="pulse"
                    variant="rectangular"
                  />
                </div>
              </Grid>
            </>
          ) : typeView === "chart" ? (
            <StyledGrid item md={12}>
              <ContentChart
                courseId={courseId}
                courseHierachy={courseHierachy}
              />
            </StyledGrid>
          ) : (
            <>
              <Grid item sm={12} md={5} lg={4}>
                <PageInfo>
                  <PageSubTitle variant="headline7">
                    Conteúdo do treinamento
                  </PageSubTitle>
                  <PageDescription variant="body3">
                    Configure o conteúdo e a estrutura do seu treinamento,
                    adicionando módulos e aulas.
                  </PageDescription>
                </PageInfo>
              </Grid>
              <StyledGrid item sm={12} md={7} lg={8}>
                <AddingClassesModule
                  courseId={courseId}
                  courseHierachy={courseHierachy}
                  learningObject={learningObject}
                  isShared={data?.isShared}
                />
              </StyledGrid>
            </>
          )}
        </StyledGrid>
      </StyledPageContainer>

      <FooterBar
        steps={steps}
        currentStep={currentStep}
        isLoading={isFetching}
        cancelHandleClick={async () => {
          track({
            name: "company_lms_content_exit_clicked",
          });
          navigate("/lms/manage-courses");
        }}
        canShowCancelAndSave={data?.status != "published"}
        cancelAndSaveHandleClick={() => {
          track({
            name: "company_lms_content_savedraft_clicked",
          });
          updateCourseMutate(
            {
              courseId,
              companyId: selectedCompany?.id,
              status: "draft",
            },
            {
              onSuccess: () => {
                utils.course.getManageCourses.refetch();

                setModal(true);
              },
              onError: (e: any) => {
                const courseNotExists =
                  e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

                const message = courseNotExists
                  ? "Não existe o curso."
                  : "Erro ao tentar atualizar o curso, tente novamente mais tarde!";

                dispatchToast({
                  type: "error",
                  content: message,
                });
              },
            }
          );
        }}
        previousHandleClick={() =>
          navigate(
            `/lms/manage-courses/createEditCourse/${type}/${
              currentStep - 1
            }/${courseId}`
          )
        }
        nextHandleClick={() => {
          track({
            name: "company_lms_content_next_clicked",
          });
          updateCourseMutate(
            {
              courseId,
              companyId: selectedCompany?.id,
              status: data?.status !== "published" ? "draft" : "published",
            },
            {
              onSuccess: () => {
                navigate(
                  `/lms/manage-courses/createEditCourse/${type}/${
                    currentStep + 1
                  }/${courseId}`
                );
              },
            }
          );
        }}
      />
      <DraftCourseModal
        open={modal}
        onClose={() => navigate("/lms/manage-courses")}
      />
    </Container>
  );
};

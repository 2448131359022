import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Collapse, Grid } from "@mui/material";
import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import MetricInfo from "../../../../components/MetricInfo";

import {
  ChartArea,
  ChartGraph,
  ChartGrid,
  ChartInfo,
  ChartSubTitle,
  ChartTitle,
  InfoText,
  MetricsHeader,
  Title,
  ToggleButton,
} from "./styles";

export const CourseMetrics = ({
  courses = [],
  certificates = [],
}: {
  courses: any;
  certificates: any;
}) => {
  ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

  const [open, setOpen] = useState<any>(true);
  const [chartInfo, setChartInfo] = useState<any>([0, 0, 0]);

  useEffect(() => {
    getCourseValues();
  }, [courses]);

  const getCourseValues = () => {
    let notStarted = 0;
    let ended = 0;
    let doing = 0;

    courses?.map(
      ({ start, end, doneClassesTotal, classesTotal, totalViews }) => {
        if (dayjs().isBefore(dayjs(start)) || totalViews === 0)
          notStarted = notStarted + 1;
        else if (
          dayjs().isAfter(dayjs(end)) ||
          doneClassesTotal === classesTotal
        ) {
          ended = ended + 1;
        } else doing = doing + 1;
      }
    );

    setChartInfo([
      Math.round((notStarted / courses?.length) * 100),
      Math.round((doing / courses?.length) * 100),
      Math.round((ended / courses?.length) * 100),
    ]);
  };

  const data = {
    datasets: [
      {
        data: chartInfo,
        backgroundColor: ["#FEA034", "#4DA0FE", "#2ED9B8"],
        weight: 2,
      },
    ],
  };

  return (
    <div style={{ width: "100%" }}>
      <MetricsHeader>
        <Title variant="headline8">Insights</Title>
        <ToggleButton onClick={() => setOpen(!open)} variant="primary">
          {open ? "Ocultar insights" : "Exibir insights"}
          {open ? (
            <Icons name="IconChevronsUp" fill="transparent" />
          ) : (
            <Icons name="IconChevronsDown" fill="transparent" />
          )}
        </ToggleButton>
      </MetricsHeader>
      <Collapse in={open}>
        <Grid
          container
          width={"100%"}
          sx={{ marginLeft: "0px" }}
          spacing={{
            sm: 0,
            md: 3,
          }}
        >
          <Grid item md={4} xs={12} width={"100%"}>
            <MetricInfo
              iconName="IconSchool"
              title="Total de treinamentos"
              subtitle={courses?.length ?? 0}
            />
            <MetricInfo
              iconName="IconNotebook"
              title="Nota média dos treinamentos"
              subtitle={`${courses[0]?.averageGrade ?? 0}/10`}
              tooltipText="A nota média dos treinamentos é a média entre as notas finais obtidas em cada um dos treinamentos que possuem atividades avaliativas."
            />
            <MetricInfo
              iconName="IconCertificate"
              title="Certificados emitidos"
              subtitle={certificates?.length ?? 0}
            />
          </Grid>

          <ChartGrid item md={8} xs={12} width={"100%"}>
            <ChartArea>
              <ChartTitle variant="headline8">
                Status dos treinamentos
              </ChartTitle>
              <ChartSubTitle variant="body4">Total</ChartSubTitle>
              <ChartInfo>
                <ChartGraph>
                  <Doughnut
                    data={data}
                    options={{
                      cutout: 45,
                      responsive: false,
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                      },
                    }}
                  />
                </ChartGraph>
                <div style={{ marginLeft: "24px", paddingRight: "24px" }}>
                  <InfoText variant="body4">
                    <Icons name="IconPentagon" fill="#FEA034" />
                    <b>{chartInfo[0] ?? 0}%</b> Não iniciados
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconTriangle" fill="#4da0fe" />
                    <b>{chartInfo[1] ?? 0}%</b> Em andamento
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconPlayerStop" fill="#2ED9B8" />
                    <b>{chartInfo[2] ?? 0}%</b> Encerrados
                  </InfoText>
                </div>
              </ChartInfo>
            </ChartArea>
          </ChartGrid>
        </Grid>
      </Collapse>
    </div>
  );
};

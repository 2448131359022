import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const getCertificateAndDownload = ({ name }) => {
  const node = document.getElementById("certificateToPng") as any;

  const formattedName = name
    ? `-` +
      name
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase()
    : "";

  html2canvas(node, {
    scale: 2,
    allowTaint: false,
    useCORS: true,
    onclone: (clone: any) => {
      const spanToUnhidden = clone.getElementById("certificateToPng");
      spanToUnhidden.style.display = "flex";
      spanToUnhidden.style.flexDirection = "column";
      spanToUnhidden.style.alignItems = "center";
    },
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "landscape",
    });

    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();

    pdf.addImage(imgData, "JPEG", 0, 0, width, height);
    pdf.save(`certificado${formattedName}.pdf`);
  });
};

import { useState } from "react";

import { Search } from "./Search";
import { EmployeesTable } from "./EmployeesTable";

import { trpc } from "@api/client";

export const EmployeesTab = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState<string>("");
  // const [filterStates, setFilterStates] = useState<{
  //   [key: string]: any[];
  // }>({});

  const { data, isLoading: isLoadingGetEmployees } =
    trpc.employee.getEmployees.useQuery(
      {
        limit: pageSize,
        page: page,
        order: "asc",
        sortBy: "name",
        search: search,
      },
      {
        refetchOnWindowFocus: false,
        retry: 1,
      }
    );

  return (
    <div>
      <Search onChange={setSearch} disabled={isLoadingGetEmployees} />

      <EmployeesTable
        data={data?.employees || []}
        rawData={data?.employees || []}
        total={data?.totalCount || 0}
        loading={isLoadingGetEmployees}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </div>
  );
};

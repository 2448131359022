import styled, { css } from "styled-components";

import { Accordion, AccordionDetails, Drawer, Grid } from "@mui/material";
import {
  Button,
  IconButton,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledDescriptionContainer = styled.div`
  height: 122px;
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 24px 16px 24px 24px;

  background: ${({ theme }) => theme.colors.neutral.light.light1};

  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral.light.light2};
`;

export const StyledDescriptionSubContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const StyledDescriptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

export const StyledDescriptionSubTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const DescriptionInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-bottom: 4px;
`;

export const DescriptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-bottom: 16px;
`;

export const DescriptionCalendar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const DescCalendarLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 600 !important;
  svg {
    border: 1px solid ${({ theme }) => theme.colors.neutral[70]};
    border-radius: 20px;
    padding: 3px;
    margin-right: 8px;
    stroke: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

export const DescriptionTags = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  button {
    margin-right: 8px;
  }
`;

export const DesciptionLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: 24px;
  line-height: 22px;
`;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import { Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledSectionContainer,
  StyledTypography,
  StyledTextField,
  StyledSubtitleContainer,
  StyledTitle,
  Container,
  PageTitle,
} from "./styled";

import { Grid } from "@mui/material";

import { PageTemplate } from "@components/PageTemplate";
import Banner from "@components/Banner";

import { trpc } from "@api/client";

import { track, dispatchToast, getObjDiffs } from "@utils";

export const FirstStep = ({
  data,
  learningObject,
  type,
  contentType,
  steps,
  contentId,
  currentStep,
  updateContentMutate,
  createExamMutate,
  createContentMutate,
  isLoadingButton,
  isLoadingScreen,
}) => {
  const utils = trpc.useContext();

  const navigate = useNavigate();

  const [associateCourses, setAssociateCourses] = useState<any>(0);
  const [saveAsDraft, setSaveAsDraft] = useState<any>(false);

  useEffect(() => {
    if (Object.keys(data).length) {
      Object.keys(data).map((cl) => {
        if ((cl === "name" || cl === "description") && data?.[cl])
          formik.setFieldValue(cl, data?.[cl], true);
        return;
      });
    }
  }, [data]);

  useEffect(() => {
    if (learningObject?.length) {
      const data = learningObject?.filter(({ _id }) => _id === contentId);
      setAssociateCourses(data[0]?.associateCourses ?? 0);
    }
  }, [learningObject]);

  const validationSchema = yup.object({
    name: yup.string().max(50).required("Favor digitar o nome do treinamento"),
    description: yup.string().max(600).notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values) => {
      const params = {
        ...values,
        type: contentType,
        status:
          associateCourses > 0
            ? data?.status
            : saveAsDraft
            ? "draft"
            : data?.status ?? "draft",
      };

      const hasChangedAnyValue = getObjDiffs(values, {
        name: data?.name,
        description: data?.description,
      });

      if (contentId) {
        if (!hasChangedAnyValue) {
          navigate(
            `/lms/manage-courses/${type}/content/${contentType}/${
              currentStep + 1
            }/${contentId}`
          );
          return;
        }
        updateContentMutate(
          {
            contentId,
            params,
          },
          {
            onSuccess: () => {
              utils.learningObject.getContentById.invalidate();
              utils.learningObject.getAllLearningObject.invalidate();

              if (saveAsDraft) {
                dispatchToast({
                  content: "Conteúdo salvo com sucesso!",
                  type: "success",
                });
                navigate("/lms/manage-courses");
              } else {
                navigate(
                  `/lms/manage-courses/${type}/content/${contentType}/${
                    currentStep + 1
                  }/${contentId}`
                );
              }
            },
            onError: (e: any) => {
              const learningObjectNotExists =
                e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

              const message = learningObjectNotExists
                ? "Objeto de aprendizado não existe."
                : "Erro ao tentar atualizar o conteúdo, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
        return;
      }

      if (contentType === "exam") {
        createExamMutate(
          {
            description: params?.description,
            name: params?.name,
          },
          {
            onSuccess: (data) => {
              const examId = data?._id;

              if (saveAsDraft) {
                navigate("/lms/manage-courses");
              } else {
                navigate(
                  `/lms/manage-courses/${type}/content/${contentType}/${
                    currentStep + 1
                  }/${examId}`
                );
              }
            },
            onError: (e: any) => {
              const learningObjectNotExists =
                e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

              const learningObjectAlreadyExists =
                e?.data?.error ===
                "LEARNING_OBJECT_WITH_SAME_NAME_ALREADY_EXISTS_ERROR";

              const message = learningObjectNotExists
                ? "Objeto de aprendizado não existe."
                : "Erro ao tentar criar o conteúdo, tente novamente mais tarde!";

              if (learningObjectAlreadyExists) {
                dispatchToast({
                  title: "Nome do conteúdo indisponível",
                  description:
                    "Existe um conteúdo com o nome usado e não é permitido criar conteúdos com mesmo nome. Altere para prosseguir com a criação.",
                  type: "error",
                  variant: "toast",
                });
                return;
              }

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
        return;
      }

      createContentMutate(
        {
          params,
        },
        {
          onSuccess: (data) => {
            const contentId = data?._id;

            if (saveAsDraft) {
              dispatchToast({
                content: "Rascunho salvo com sucesso!",
                type: "success",
              });
              navigate("/lms/manage-courses");
            } else {
              navigate(
                `/lms/manage-courses/${type}/content/${contentType}/${
                  currentStep + 1
                }/${contentId}`
              );
            }
          },
          onError: (e: any) => {
            const learningObjectNotExists =
              e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

            const learningObjectAlreadyExists =
              e?.data?.error ===
              "LEARNING_OBJECT_WITH_SAME_NAME_ALREADY_EXISTS_ERROR";

            const message = learningObjectNotExists
              ? "Objeto de aprendizado não existe."
              : "Erro ao tentar criar o conteúdo, tente novamente mais tarde!";

            if (learningObjectAlreadyExists) {
              dispatchToast({
                title: "Nome do conteúdo indisponível",
                description:
                  "Existe um conteúdo com o nome usado e não é permitido criar conteúdos com mesmo nome. Altere para prosseguir com a criação.",
                type: "error",
                variant: "toast",
              });
              return;
            }

            dispatchToast({
              type: "error",
              content: message,
            });
          },
        }
      );
      return;
    },
  });
  const isEditing = type === "edit";
  const titleByType =
    contentType === "video"
      ? `${isEditing ? "Editar" : "Criar"} vídeo`
      : contentType === "podcast"
      ? `${isEditing ? "Editar" : "Criar"} podcast`
      : contentType === "pdf"
      ? `${isEditing ? "Editar" : "Criar"} PDF`
      : contentType === "exam"
      ? `${isEditing ? "Editar" : "Criar"} avaliação`
      : `${isEditing ? "Editar" : "Criar"} artigo`;

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_cancel_clicked`,
            });
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          loading: !!isLoadingButton,
          callback: () => {
            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_savedraft_clicked`,
            });

            if (associateCourses === 0) setSaveAsDraft(true);
            formik.handleSubmit();
          },
        },
        confirmProps: {
          title: (
            <>
              Continuar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: !!isLoadingButton,
          callback: async () => {
            setSaveAsDraft(false);

            track({
              name: `company_lms_${
                contentType === "exam" ? "test" : contentType
              }information_next_clicked`,
            });

            const isValidToSubmit = await formik?.validateForm();

            if (Object.keys(isValidToSubmit)?.length) {
              dispatchToast({
                content: "Alguns campos estão incorretos. Favor verificar",
                type: "error",
              });
            }

            formik.handleSubmit();
          },
        },
      }}
    >
      <Container>
        {isLoadingScreen ? (
          <Skeleton
            width="100%"
            height="80vh"
            animation="pulse"
            variant="rectangular"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          />
        ) : (
          <>
            <PageTitle variant="headline6">{titleByType}</PageTitle>

            {associateCourses > 0 ? (
              <Banner
                type="info"
                icon="IconAlertTriangle"
                title="Edição de conteúdo limitada"
                subTitle="Esse conteúdo está associado a treinamentos e sua edição poderia impactar o progresso dos alunos, portanto, é permitido editar somente as informações básicas. Se a alteração for fundamental, crie um novo conteúdo e associe à aula, mas lembre-se de informar os alunos."
                hasHideBanner={true}
                style={{ marginBottom: "32px" }}
              />
            ) : (
              <></>
            )}

            <Grid container>
              <Grid item sm={12} md={5} lg={4}>
                <div style={{ marginRight: "24px" }}>
                  <StyledTitle variant="headline7">
                    Informações básicas
                  </StyledTitle>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <StyledTypography variant="body3">
                      Adicione as informações básicas do seu conteúdo, como nome
                      e descrição.
                    </StyledTypography>
                  </div>
                </div>
              </Grid>

              <Grid item sm={12} md={7} lg={8}>
                <form onSubmit={formik.handleSubmit}>
                  <StyledSectionContainer style={{ marginBottom: "20px" }}>
                    <StyledTypography variant="headline8">
                      Nome e descrição
                    </StyledTypography>
                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <StyledTypography variant="body4">
                        Dê um nome ao conteúdo, este será o principal texto
                        exibido nas listas e nos destaques. Na descrição, você
                        pode descrever um resumo do conteúdo e qual o objetivo
                        do conteúdo.
                      </StyledTypography>
                    </div>

                    <StyledTextField
                      id={"name"}
                      name={"name"}
                      label={"Nome do conteúdo"}
                      inputProps={{ maxLength: 50 }}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={
                        formik.touched.name && (formik.errors.name as string)
                      }
                    />

                    <StyledSubtitleContainer>
                      <StyledTypography variant="caption">
                        Máximo 50 caracteres
                      </StyledTypography>
                      <StyledTypography variant="caption">
                        {formik.values.name.length}/50
                      </StyledTypography>
                    </StyledSubtitleContainer>

                    <StyledTextField
                      id={"description"}
                      name={"description"}
                      label={"Descrição do conteúdo"}
                      inputProps={{ maxLength: 600 }}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.description &&
                        Boolean(formik.errors.description)
                      }
                      helperText={
                        formik.touched.description &&
                        (formik.errors.description as string)
                      }
                      multiline
                      rows={4}
                      style={{ marginTop: "24px" }}
                    />
                    <StyledSubtitleContainer>
                      <StyledTypography variant="caption">
                        Máximo 600 caracteres
                      </StyledTypography>
                      <StyledTypography variant="caption">
                        {formik.values.description.length}/600
                      </StyledTypography>
                    </StyledSubtitleContainer>
                  </StyledSectionContainer>
                </form>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </PageTemplate>
  );
};

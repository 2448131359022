import { ReactNode, useState } from "react";

import {
  Icons,
  LinkButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseButton,
  WarnArea,
  WarnDescription,
  WarnIcon,
  WarnTitle,
} from "./styled";

const WarnMessage = ({
  title,
  description,
  customDescription,
  redirectAction,
  linkLabel,
  hasClose = true,
}: {
  title: string;
  description?: string;
  customDescription?: ReactNode;
  redirectAction?: () => any;
  linkLabel?: string;
  hasClose?: boolean;
}) => {
  const [open, setOpen] = useState<any>(true);

  if (open) {
    return (
      <WarnArea>
        <WarnIcon className="warning-icon-area warning-icon-warning">
          <Icons name="IconAlertCircle" size={30} fill="transparent" />
        </WarnIcon>
        <div>
          <WarnTitle variant="body3">{title}</WarnTitle>
          <WarnDescription variant="body4">
            {description ?? customDescription}
          </WarnDescription>
          {redirectAction && (
            <LinkButton onClick={redirectAction} variant="secondary">
              <Typography variant="caption">
                {linkLabel ?? "Criar conteúdo"}
              </Typography>
              <Icons name="IconArrowUpRight" fill="transparent" size={16} />
            </LinkButton>
          )}
        </div>

        {hasClose ? (
          <CloseButton onClick={() => setOpen(false)}>
            <Icons name="IconX" fill="transparent" size={16} />
          </CloseButton>
        ) : null}
      </WarnArea>
    );
  } else return <></>;
};

export default WarnMessage;

import {
  Button,
  Icons,
  Loader,
  useMediaQuery,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledFooterBar,
  StyledButtonContainer,
  BackButton,
  BackArea,
  Separator,
} from "./style";

const FooterBar = ({
  steps,
  currentStep,
  loadingUrl = [],
  isLoading = false,
  cancelHandleClick,
  previousHandleClick,
  cancelAndSaveHandleClick,
  nextHandleClick,
  nextLabel,
  noSaveLabel = "Sair sem salvar",
  canShowCancelAndSave = true,
  disabledProgress = false,
}: {
  steps: any[];
  currentStep: number;
  loadingUrl?: string[];
  isLoading?: boolean;
  previousHandleClick?: any;
  cancelHandleClick?: any;
  cancelAndSaveHandleClick?: any;
  nextHandleClick: any;
  nextLabel?: string;
  noSaveLabel?: string;
  canShowCancelAndSave?: boolean;
  disabledProgress?: boolean;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <Separator />

      <StyledFooterBar>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <BackArea>
            <BackButton variant="secondary" onClick={cancelHandleClick}>
              {noSaveLabel}
            </BackButton>
            {cancelAndSaveHandleClick && canShowCancelAndSave && (
              <BackButton
                variant="secondary"
                onClick={cancelAndSaveHandleClick}
              >
                {"Sair e salvar rascunho"}
              </BackButton>
            )}
          </BackArea>
          <StyledButtonContainer>
            {previousHandleClick ? (
              <Button
                size={isMobile ? "small" : "large"}
                variant="secondary"
                disabled={currentStep - 1 < 0}
                onClick={previousHandleClick}
                style={{ alignSelf: "center" }}
              >
                <Icons name="IconArrowNarrowLeft" />
                Voltar
              </Button>
            ) : null}
            <Button
              size={isMobile ? "small" : "large"}
              variant="primary"
              disabled={
                isLoading || disabledProgress || currentStep > steps.length
              }
              style={{ marginLeft: "24px", alignSelf: "center" }}
              onClick={nextHandleClick}
            >
              {isLoading ? (
                <Loader size="extraSmall" variant="secondary" />
              ) : (
                <>
                  {nextLabel
                    ? nextLabel
                    : steps?.length === currentStep + 1
                    ? "Salvar"
                    : "Continuar"}
                  <Icons name="IconArrowNarrowRight" />
                </>
              )}
            </Button>
          </StyledButtonContainer>
        </div>
      </StyledFooterBar>
    </>
  );
};

export { FooterBar };

import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const PdfArea = styled.div<{ isExpanded?: boolean }>`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  height: ${({ isExpanded }) => (isExpanded ? "590px" : "550px")} !important;
  border-radius: 10px;
  margin-bottom: 15px;
  .react-pdf__Document {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 0px;
    max-height: ${({ isExpanded = false }) =>
      isExpanded ? "590px" : "550px"} !important;
  }
  .react-pdf__Page {
    background-color: ${({ theme }) => theme.colors.neutral[90]} !important;
    overflow: auto;
    padding: 16px 0;
    min-height: ${({ isExpanded = false }) => (isExpanded ? "600px" : "500px")};
    max-height: ${({ isExpanded = false }) => (isExpanded ? "600px" : "500px")};
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  canvas {
    height: ${({ isExpanded = false }) => (isExpanded ? "600px" : "500px")};
    width: 100%;
  }
`;

export const PDFNavigator = styled.div`
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 12px 12px;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export const FlexContent = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
`;

export const PageCounter = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral[50]};
  padding: 0 20px;
  letter-spacing: -2px;
`;

export const LoadingArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.light.light4};
  height: 2px;
`;

export const Progress = styled.div<{ progress?: number }>`
  width: ${({ progress }) => (progress ? `${progress}%` : "0%")};
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  height: 2px;
`;

export const ZoomInButton = styled(IconButton)`
  margin-left: 16px;
`;

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 40px;
  padding: 12px;
  p {
    color: ${({ theme }) => theme.colors.neutral[40]};
  }
  svg {
    stroke: ${({ theme }) => theme.colors.neutral[40]};
    margin-right: 8px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light.light2};
  }
`;

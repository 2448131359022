import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledFlexCenter,
  StyledIconButton,
  StyledLoader,
  StyledTextLoading,
} from "./styled";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

export const WarningDeleteModal = ({
  _id,
  open,
  setOpen,
  setConfirmModal,
  type,
}) => {
  const { mutate: deleteClassesByIdMutate, isLoading: isLoadingDeleteClasses } =
    trpc.course.deleteClassesById.useMutation({});

  const { mutate: deleteContentByIdMutate, isLoading: isLoadingDeleteContent } =
    trpc.learningObject.deleteContentById.useMutation({});

  const onClose = async () => {
    setOpen(false);
  };

  return (
    <StyledModal
      isLoading={isLoadingDeleteClasses || isLoadingDeleteContent}
      onClose={onClose}
      open={open}
      icon={
        isLoadingDeleteClasses || isLoadingDeleteContent ? (
          <></>
        ) : (
          <StyledIconContainer>
            <StyledIcon size={48} name="IconAlertCircle" fill="transparent" />
          </StyledIconContainer>
        )
      }
    >
      {isLoadingDeleteClasses || isLoadingDeleteContent ? (
        <StyledFlexCenter style={{ flexDirection: "column" }}>
          <StyledLoader variant="primary" size="large" />
          <StyledTextLoading variant="body2">
            Excluindo {type === "course" ? "treinamento" : "conteúdo"}...
          </StyledTextLoading>
        </StyledFlexCenter>
      ) : (
        <>
          <StyledFlexBetween>
            <StyledTitle variant="headline7">
              Tem certeza que deseja excluir este{" "}
              {type === "course" ? "treinamento" : "conteúdo"}?
            </StyledTitle>
            <StyledIconButton
              size="small"
              variant="line"
              icon="IconX"
              onClick={onClose}
            />
          </StyledFlexBetween>
          <StyledContainer>
            {type === "course" ? (
              <StyledSubTitle variant="body3">
                Os módulos e aulas contidos neste treinamento também serão
                excluídos.
              </StyledSubTitle>
            ) : (
              <StyledSubTitle variant="body3">
                Se este conteúdo estiver associado a aulas, as aulas também
                serão excluídas.
              </StyledSubTitle>
            )}
            <StyledButtonContainer>
              <Button size="large" variant="secondary" onClick={onClose}>
                Não excluir
              </Button>
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  if (type === "course") {
                    deleteClassesByIdMutate(
                      {
                        classId: _id,
                      },
                      {
                        onSuccess: () => {
                          setConfirmModal(true);
                          setOpen(false);
                        },
                        onError: (e: any) => {
                          const courseNotExists =
                            e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

                          const message = courseNotExists
                            ? "Não existe o curso."
                            : "Erro ao tentar deletar o curso, tente novamente mais tarde!";

                          dispatchToast({
                            type: "error",
                            content: message,
                          });
                        },
                      }
                    );
                  }
                  if (type === "content") {
                    deleteContentByIdMutate(
                      {
                        contentId: _id,
                      },
                      {
                        onSuccess: () => {
                          setConfirmModal(true);
                          setOpen(false);
                        },
                        onError: (e: any) => {
                          const learningObjectNotExists =
                            e?.data?.error ===
                            "LEARNING_OBJECT_NOT_EXISTS_ERROR";

                          const message = learningObjectNotExists
                            ? "Objeto de aprendizado não existe."
                            : "Erro ao tentar deletar o conteúdo, tente novamente mais tarde!";

                          dispatchToast({
                            type: "error",
                            content: message,
                          });
                        },
                      }
                    );
                  }
                }}
              >
                Sim, excluir {type === "course" ? "treinamento" : "conteúdo"}
              </Button>
            </StyledButtonContainer>
          </StyledContainer>
        </>
      )}
    </StyledModal>
  );
};

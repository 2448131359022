import { useMemo } from "react";

import { CourseTab } from "../CourseTab";
import { LearningObjectTab } from "../LearningObjectTab";
import { EmployeesTab } from "../EmployeesTab";
// import { Metrics } from "../Metrics";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";

export const Content = () => {
  const tabItems = useMemo(() => {
    const items = [
      {
        label: "Treinamentos",
        component: <CourseTab />,
      },
      {
        label: "Alunos",
        component: <EmployeesTab />,
      },
      {
        label: "Conteúdos do treinamento",
        component: <LearningObjectTab />,
      },
    ];

    return items;
  }, []);

  return (
    <>
      {/* <Metrics /> */}
      <Tab tabItens={tabItems} />
    </>
  );
};

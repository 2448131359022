import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const OptionContentArea = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  p {
    margin-left: 20px;
  }
`;

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const SubTitle = styled(Typography)`
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

import styled from "styled-components";

import { MenuItem, Select } from "@mui/material";

export const Grid = styled.table`
  width: 100%;
`;

export const Header = styled.thead`
  width: 100%;
`;

type CellProps = {
  sticky?: boolean;
};

export const Th = styled.th<CellProps>`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  padding: 24px;
  text-align: left;
`;

export const Td = styled.td<CellProps>`
  padding: 24px;
  text-align: left;
  background-color: ${(p) => p.theme.colors.neutral[100]};
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral[90]};
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  border-top: 1px solid ${(p) => p.theme.colors.neutral[90]};
  border-left: 1px solid ${(p) => p.theme.colors.neutral[90]};
  border-right: 1px solid ${(p) => p.theme.colors.neutral[90]};
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const SubContainerItens = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const SubContainerPagination = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;
export const StyledMenuItem = styled(MenuItem)``;

export const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const TrBulkActionContainer = styled.tr`
  display: table-caption;
  background-color: var(--color-neutral-light1);
`;

export const ThBulkAction = styled.th`
  padding: 24px;
`;

export const EmptyGrid = styled.div`
  background-color: ${(p) => p.theme.colors.neutral[95]};
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledIconButton,
  SubTitle,
  Description,
  ModalBody,
  WarnButton,
} from "./styled";

export const ReplicateContentModal = ({ open, onClose, handleClick }) => {
  return (
    <StyledModal onClose={onClose} open={open}>
      <>
        <StyledIconButton
          size="small"
          variant="line"
          icon="IconX"
          onClick={onClose}
        />
        <ModalBody>
          <StyledFlexBetween>
            <div>
              <StyledIconContainer>
                <StyledIcon
                  size={48}
                  name="IconAlertCircle"
                  fill="transparent"
                />
              </StyledIconContainer>
            </div>
            <StyledTitle variant="headline7">Atenção!</StyledTitle>
          </StyledFlexBetween>
          <StyledContainer>
            <SubTitle variant="headline6">
              Tem certeza que deseja editar esse conteúdo?
            </SubTitle>
            <Description variant="body3">
              As alterações realizadas afetam todas as empresas que possuem esse
              conteúdo.
            </Description>
          </StyledContainer>
        </ModalBody>
        <StyledButtonContainer>
          <LinkButton variant="secondary" onClick={onClose}>
            Cancelar
          </LinkButton>
          <WarnButton
            size="large"
            variant="primary"
            onClick={handleClick}
            variantType="error"
          >
            Confirmar edição
          </WarnButton>
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

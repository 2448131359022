import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledPlayerTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.light.light5};
`;

export const StyledPlayerSubTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
`;

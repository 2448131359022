import { useNavigate } from "react-router-dom";

import {
  IconButton,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { CardArea, CardButtons, CardContent, CardTitleArea } from "./styles";

export const ContentChartCard = ({ params }) => {
  const navigate = useNavigate();
  const {
    _id,
    title,
    description,
    subtitle,
    canEdit,
    canDelete,
    canAdd,
    openModal,
    level,
    parentId,
    courseId,
    learningObjectId,
    setClassData,
    setAddClass,
  } = params;

  return (
    <CardArea>
      <CardTitleArea>
        <Typography variant="body4">
          <b>{level === 0 ? "Treinamento" : level === 1 ? "Módulo" : "Aula"}</b>
        </Typography>
        <Typography variant="body4">{subtitle}</Typography>
      </CardTitleArea>
      <CardContent variant="body4">{title}</CardContent>
      <CardButtons>
        {canAdd && level !== 2 ? (
          <IconButton
            onClick={() => {
              if (level === 1) {
                setClassData({
                  courseId,
                  parentId: _id,
                  setAddClass,
                  type: "add",
                });
                setAddClass(true);
                return;
              }
              openModal({
                open: true,
                type: "add",
                variant:
                  level === 0
                    ? "addModule"
                    : level === 1
                    ? "addClass"
                    : "openAddPage",
                parentId: parentId,
                level: level,
                _id: _id,
                title: title,
              });
            }}
            variant="filled"
            size="medium"
          >
            <Icons name="IconPlus" />
          </IconButton>
        ) : null}
        {canEdit && level !== 0 && (
          <IconButton
            onClick={() => {
              if (level === 2) {
                setClassData({
                  _id,
                  courseId,
                  name: title,
                  description,
                  learningObjectId,
                  parentId: _id,
                  setAddClass,
                  type: "edit",
                });
                setAddClass(true);
                return;
              }
              openModal({
                open: true,
                type: "edit",
                variant:
                  level === 0
                    ? "editCourse"
                    : level === 1
                    ? "editModule"
                    : "editClass",
                parentId: parentId,
                level: level,
                _id: _id,
                title: title,
              });
            }}
            variant="filled"
            size="medium"
          >
            <Icons name="IconPencil" />
          </IconButton>
        )}
        {canDelete && level !== 0 && (
          <IconButton
            onClick={() =>
              openModal({
                open: true,
                type: "delete",
                variant:
                  level === 0
                    ? "deleteCourse"
                    : level === 1
                    ? "deleteModule"
                    : "deleteClass",
                parentId: parentId,
                level: level,
                _id: _id,
                title: title,
              })
            }
            variant="filled"
            size="medium"
          >
            <Icons name="IconTrash" />
          </IconButton>
        )}
      </CardButtons>
    </CardArea>
  );
};

import styled, { css } from "styled-components";
import { SelectField, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const OptionArea = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const StatusTag = styled(Tag)<{ status: string }>`
  ${({ status, theme }) =>
    status === "done"
      ? css`
          background-color: ${theme.colors.feedback.success[90]} !important;
          color: ${theme.colors.feedback.success[10]} !important;
        `
      : status === "doing"
      ? css`
          background-color: ${theme.colors.feedback.info[90]} !important;
          color: ${theme.colors.feedback.info[10]} !important;
        `
      : css`
          background-color: ${theme.colors.feedback.error[90]} !important;
          color: ${theme.colors.feedback.error[10]} !important;
        `}
  white-space: nowrap;
  position: unset;
`;

export const DateArea = styled.div`
  width: max-content;
`;

export const GradeArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  .icon-container {
    margin-right: 16px;
  }
`;

export const GradeTitle = styled(Typography)`
  font-weight: 600 !important;
  line-height: 22px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const GradeDescription = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[60]};
`;

export const CounterTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.neutral[90]};
  color: ${({ theme }) => theme.colors.neutral[10]};
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral[90]};
    color: ${({ theme }) => theme.colors.neutral[10]};
  }
`;

export const StyledBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSelect = styled(SelectField)`
  min-width: 200px;
  margin-right: 24px !important;
`;

import { useNavigate } from "react-router-dom";

import EmptyState from "../../assets/emptyState.jpg";

import {
  DataGrid,
  dayjs,
  IconButton,
  Tag,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  Label,
  ProgressArea,
  ProgressBar,
  ProgressPercent,
  ProgressWrapper,
  StyledDot,
  StyledFlexColumn,
  StyledFlexRow,
  StyledIconButtonExtraSmall,
  StyledImageContainer,
  StyledSubTitleCard,
  StyledTitleCard,
  TitleCardContainer,
} from "./styles";

import { Tooltip } from "@mui/material";

export const StudentDetailTableView = ({ content }) => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: "Treinamento",
      accessor: "name",
      Cell: ({
        row: {
          original: {
            name = null,
            banner = {} as any,
            modules = [],
            totalClasses = null,
          },
        },
      }) => (
        <div>
          <StyledFlexRow>
            <div>
              <StyledImageContainer
                url={
                  banner?.path
                    ? `${banner?.path}${banner?.signature}`
                    : EmptyState
                }
              />
            </div>
            <StyledFlexColumn>
              <TitleCardContainer>
                <StyledTitleCard variant="body3" tag="p">
                  {name}
                </StyledTitleCard>
              </TitleCardContainer>

              <StyledFlexRow>
                <StyledIconButtonExtraSmall
                  size="small"
                  variant="line"
                  icon="IconSchool"
                  style={{ alignSelf: "center" }}
                />
                <StyledSubTitleCard variant="body3">
                  {modules?.length} módulo{modules?.length !== 1 && "s"}{" "}
                  <StyledDot /> {totalClasses} aula
                  {totalClasses !== 1 && "s"}
                </StyledSubTitleCard>
              </StyledFlexRow>
            </StyledFlexColumn>
          </StyledFlexRow>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({
        row: {
          original: { status },
        },
      }) => {
        switch (status) {
          case "doing":
            return <Tag variant="blue">Em andamento</Tag>;
          case "done":
            return <Tag variant="green">Concluído</Tag>;
          case "notStarted":
            return <Tag variant="yellow">Não iniciado</Tag>;
        }
      },
    },
    {
      Header: "Obrigatório",
      accessor: "mandatory",
      Cell: ({
        row: {
          original: { mandatory },
        },
      }) => (
        <Tag variant={mandatory ? "primary" : "secondary"}>
          {mandatory ? "Sim" : "Não"}
        </Tag>
      ),
    },
    {
      Header: "Emissão de certificado",
      accessor: "certificateId",
      Cell: ({
        row: {
          original: { certificateId },
        },
      }) => (
        <Tag variant={certificateId ? "primary" : "secondary"}>
          {certificateId ? "Sim" : "Não"}
        </Tag>
      ),
    },
    {
      Header: "Início em",
      accessor: "start",
      Cell: ({
        row: {
          original: { start = 0 },
        },
      }) => (
        <Label variant="body3">
          {start ? dayjs(start).format("DD/MM/YYYY") : "Não informado"}
        </Label>
      ),
    },
    {
      Header: "Disponível até",
      accessor: "end",
      Cell: ({
        row: {
          original: { end = 0 },
        },
      }) => (
        <Label variant="body3">
          {end ? dayjs(end).format("DD/MM/YYYY") : "Sempre disponível"}
        </Label>
      ),
    },
    {
      Header: "Progresso",
      accessor: "donePercent",
      Cell: ({
        row: {
          original: { totalClasses = 0, totalDoneClasses = 0 },
        },
      }) => {
        const percentValidation =
          Math.round((totalDoneClasses / totalClasses) * 100) || 0;
        const donePercent = percentValidation > 100 ? 100 : percentValidation;

        return (
          <ProgressArea>
            <ProgressWrapper>
              <ProgressBar totalDone={donePercent}>
                <span />
              </ProgressBar>
            </ProgressWrapper>
            <ProgressPercent variant="body4">
              {donePercent >= 100 ? 100 : donePercent}%
            </ProgressPercent>
          </ProgressArea>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { _id = null },
        },
      }) => (
        <Tooltip arrow title="Ver detalhes do treinamento">
          <div>
            <IconButton
              onClick={() =>
                navigate(`/lms/manage-courses/detail-course/${_id}`)
              }
              size="small"
              variant="line"
              icon="IconEye"
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        columns={columns}
        data={content || []}
        hasPagination={true}
        pageSizeOptions={[
          { label: "5 itens", value: 5 },
          { label: "10 itens", value: 10 },
          { label: "20 itens", value: 20 },
        ]}
        emptyState={{
          message: "O filtro aplicado não apresenta resultados.",
        }}
      />
    </div>
  );
};

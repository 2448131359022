import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";

import {
  Container,
  StyledSectionContainer,
  StyledBoxAdornment,
  SelectArea,
  Select,
  PreviewArea,
  AccordionSubTitle,
  AccordionTitle,
  ScaleCertificate,
  PageInfo,
  PageSubTitle,
  PageDescription,
  PageTitle,
} from "./styled";

import {
  Icons,
  Skeleton,
  Toggle,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Grid } from "@mui/material";
import { dispatchToast, getObjDiffs, track } from "@utils";
import { PageTemplate } from "@components/PageTemplate";

import { useFormik } from "formik";
import * as yup from "yup";

import { trpc } from "@api/client";
import CertificatePreview from "@components/CertificatePreview";
import WarnMessage from "@components/WarnMessage";
import { routes } from "@routes";
import { DraftCourseModal } from "../Modal/DraftCourseModal";

export const SecondStep = ({
  data,
  type,
  updateCourseMutate,
  courseId,
  currentStep,
  isFetching,
}) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();

  const [saveAsDraft, setSaveAsDraft] = useState(false);

  const [certificates, setCertificates] = useState<any>([]);
  const [certificate, setCertificate] = useState<any>(null);
  const [options, setOptions] = useState<any>([]);

  const { selectedCompany } = useSelectedCompany();

  const { isFetching: isGettingCertificate } =
    trpc.certificate.getCertificates.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        const certificates = data?.map((d) => d?.certificate);
        setCertificates(certificates);
      },
    });

  useEffect(() => {
    if (Object.keys(data).length) {
      Object.keys(data).map((cl) => {
        if (
          cl === "certificateId" ||
          cl === "isShared" ||
          cl === "blockNavigation"
        ) {
          if (cl === "certificateId" && data?.[cl]) {
            formik.setFieldValue("hasCertificate", true);
            formik.setFieldValue("certificateId", data?.[cl]);
          }
          if (cl === "isShared") {
            formik.setFieldValue("isShared", data?.[cl]);
          }
          if (cl === "blockNavigation") {
            formik.setFieldValue("blockNavigation", data?.[cl]);
          }
        }

        return;
      });
    }

    if (data?.certificateId && certificates?.length) {
      setCertificate(
        certificates?.filter(({ _id }) => _id === data?.certificateId)[0]
      );
    }

    if (certificates?.length) {
      setOptions(() =>
        certificates?.map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))
      );
    }
  }, [data, certificates]);

  const validationSchema = yup.object({
    hasCertificate: yup.boolean().notRequired(),
    certificateId: yup.string().when("hasCertificate", {
      is: true,
      then: yup.string().required("Este campo é obrigatório."),
    }),
    isShared: yup.boolean().notRequired(),
    blockNavigation: yup.boolean().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      hasCertificate: false,
      certificateId: "",
      isShared: false,
      blockNavigation: false,
      saveAsDraft: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      delete values["saveAsDraft"];

      const hasChangedAnyValue = getObjDiffs(values, {
        hasCertificate: !!data?.certificateId,
        certificateId: data?.certificateId,
        isShared: data?.isShared,
        blockNavigation: data?.blockNavigation,
        saveAsDraft: data?.saveAsDraft,
      });

      if (hasChangedAnyValue)
        updateCourseMutate(
          {
            courseId,
            companyId: selectedCompany?.id,
            ...values,
            status: saveAsDraft
              ? "draft"
              : data?.status !== "published"
              ? "draft"
              : "published",
          },
          {
            onSuccess: (data) => {
              const courseId = data?._id;
              utils.course.getManageCourses.invalidate();
              utils.course.getCourseById.invalidate();

              if (saveAsDraft) {
                setSaveAsDraft(true);
                return;
              }

              navigate(
                `/lms/manage-courses/createEditCourse/${type}/${
                  currentStep + 1
                }/${courseId}`
              );
            },
            onError: (e: any) => {
              const courseNotExists =
                e?.data?.error === "COURSE_NOT_EXISTS_ERROR";

              const message = courseNotExists
                ? "Não existe o curso."
                : "Erro ao tentar atualizar o curso, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
      else
        navigate(
          `/lms/manage-courses/createEditCourse/${type}/${
            currentStep + 1
          }/${courseId}`
        );
    },
  });

  return (
    <>
      <PageTemplate
        stepper={{
          steps: [
            "Informações básicas",
            "Configurações",
            "Conteúdo do treinamento",
            "Alunos",
            "Revisão",
          ],
          activeStep: currentStep,
        }}
        footer={{
          cancelProps: {
            title: "Sair sem salvar",
            callback: () => {
              navigate("/lms/manage-courses");
            },
          },
          draftProps: {
            title: "Sair e salvar rascunho",
            hasToShow: data?.status != "published",
            callback: () => {
              formik.setFieldValue("saveAsDraft", true);
              setSaveAsDraft(true);

              formik.handleSubmit();
            },
          },
          goBackProps: {
            title: (
              <>
                <Icons name="IconArrowLeft" fill="transparent" />
                Voltar
              </>
            ),
            callback: () => {
              const previousStep = currentStep - 1;

              navigate(
                routes.pageCreateEditCourse
                  .replace(":step", previousStep?.toString())
                  .replace(":type", type)
                  .replace(":courseId", courseId || "")
              );
            },
          },
          confirmProps: {
            title: (
              <>
                Continuar
                <Icons name="IconArrowRight" fill="transparent" />
              </>
            ),
            callback: async () => {
              formik.setFieldValue("saveAsDraft", false);
              setSaveAsDraft(false);
              const isValidToSubmit = await formik?.validateForm();

              if (
                Object.keys(isValidToSubmit)?.length ||
                (formik?.values?.hasCertificate &&
                  !formik?.values?.certificateId)
              ) {
                dispatchToast({
                  content: "Alguns campos estão incorretos. Favor verificar",
                  type: "error",
                });
              }

              formik.handleSubmit();
            },
          },
        }}
      >
        <Container>
          <PageTitle variant="headline6">
            {type === "create" ? "Criar" : "Editar"} treinamento
          </PageTitle>

          <Grid container>
            {isFetching ? (
              <>
                <Grid item sm={12} md={5} lg={4}>
                  <div style={{ marginRight: "10px" }}>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <div>
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  </div>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={12} md={5} lg={4}>
                  <PageInfo>
                    <PageSubTitle variant="headline7">
                      Configurações
                    </PageSubTitle>
                    <PageDescription variant="body3">
                      Adicione as configurações do treinamento, como certificado
                      e compartilhamento entre empresas.
                    </PageDescription>
                  </PageInfo>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <form onSubmit={formik?.handleSubmit}>
                    <StyledSectionContainer style={{ marginBottom: "20px" }}>
                      <AccordionTitle variant="headline8">
                        Certificado
                      </AccordionTitle>

                      <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                        <AccordionSubTitle variant="body4">
                          Selecione se este treinamento apresentará certificado
                          de conclusão.
                        </AccordionSubTitle>
                      </div>

                      {isGettingCertificate ? (
                        <Skeleton
                          width="100%"
                          height="160px"
                          animation="pulse"
                          variant="rectangular"
                          style={{ marginBottom: "24px" }}
                        />
                      ) : certificates?.length === 0 ? (
                        <WarnMessage
                          title="Você ainda não criou nenhum certificado"
                          description="Para selecionar o modelo de certificado exibido ao término deste treinamento é necessário apresentar modelos criados previamente. Por favor, crie um modelo antes de prosseguir com a criação do treinamento."
                          linkLabel="Criar certificado"
                          redirectAction={() =>
                            navigate(`/lms/manage-courses/certificates`)
                          }
                        />
                      ) : (
                        <WarnMessage
                          title="Certificado emitido após conclusão de 100% e aprovação"
                          description="Para emitir o certificado é necessário que o aluno tenha concluído 100% do treinamento e seja aprovado em todas as atividades avaliativas."
                        />
                      )}

                      <div
                        style={{
                          marginBottom: formik.values.hasCertificate
                            ? "32px"
                            : 0,
                          width: "100%",
                        }}
                      >
                        <StyledBoxAdornment
                          title={
                            "Habilitar recebimento do certificado de conclusão."
                          }
                          description={
                            "Ao selecionar esta opção o aluno receberá um certificado ao concluir o treinamento."
                          }
                          leftAdornment={
                            <Toggle
                              checked={formik.values.hasCertificate}
                              disabled={
                                certificates?.length === 0 ? true : false
                              }
                              onChange={({ target: { checked } }) => {
                                if (checked === false) {
                                  formik.handleChange({
                                    target: {
                                      id: "certificateId",
                                      value: "",
                                    },
                                  });
                                } else {
                                  track({
                                    name: "company_lms_courseinformation_enablecertificate_clicked",
                                  });
                                }

                                formik.handleChange({
                                  target: {
                                    id: "hasCertificate",
                                    value: checked,
                                  },
                                });
                              }}
                            />
                          }
                        />
                      </div>

                      <>
                        {formik.values.hasCertificate ? (
                          <div style={{ marginBottom: "32px", width: "100%" }}>
                            <SelectArea>
                              <Select
                                label="Selecionar modelo de certificado"
                                fullWidth
                                value={formik.values.certificateId ?? null}
                                onSelectChange={(e, { value }) => {
                                  formik.handleChange({
                                    target: {
                                      id: "certificateId",
                                      value: value,
                                    },
                                  });
                                  setCertificate(
                                    certificates?.filter(
                                      ({ _id }) => _id === value
                                    )[0]
                                  );
                                }}
                                error={
                                  formik?.touched?.certificateId &&
                                  Boolean(formik?.errors?.certificateId)
                                }
                                helperText={
                                  formik.touched.certificateId &&
                                  (formik.errors.certificateId as string)
                                }
                                options={options ?? []}
                              />
                            </SelectArea>

                            {formik.values.certificateId ? (
                              <PreviewArea>
                                <ScaleCertificate>
                                  <CertificatePreview
                                    data={{
                                      courseName: data?.name,
                                      companyLogo: certificate?.companyLogo,
                                      companyName: certificate?.companyName,
                                      signText: certificate?.signText,
                                      signerName: certificate?.signerName,
                                      signerPostion: certificate?.signerPostion,
                                      expiresDate: certificate?.expiresDate,
                                      totalHours: data?.totalHours,
                                    }}
                                  />
                                </ScaleCertificate>
                              </PreviewArea>
                            ) : null}
                          </div>
                        ) : null}
                      </>
                    </StyledSectionContainer>

                    <StyledSectionContainer style={{ marginBottom: "20px" }}>
                      <AccordionTitle variant="headline8">
                        Configurações de navegação
                      </AccordionTitle>

                      <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                        <AccordionSubTitle variant="body4">
                          Habilite como deseja que seja realizada a navegação
                          entre módulos e aulas do treinamento.
                        </AccordionSubTitle>
                      </div>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <StyledBoxAdornment
                          title="Bloquear navegação"
                          description="Ao selecionar esta opção o treinamento será bloqueado e o aluno precisará seguir a sequência de módulos, aulas e avaliações para acessar todo o conteúdo."
                          leftAdornment={
                            <Toggle
                              checked={formik.values.blockNavigation}
                              disabled={data?.companyId != selectedCompany?.id}
                              onChange={({ target: { checked } }) => {
                                formik.handleChange({
                                  target: {
                                    id: "blockNavigation",
                                    value: checked,
                                  },
                                });
                              }}
                            />
                          }
                        />
                      </div>
                    </StyledSectionContainer>

                    <StyledSectionContainer style={{ marginBottom: "20px" }}>
                      <AccordionTitle variant="headline8">
                        Compartilhar o treinamento com todas as empresas do
                        grupo
                      </AccordionTitle>

                      <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                        <AccordionSubTitle variant="body4">
                          Habilite o botão abaixo caso deseje que o treinamento
                          seja compartilhado e adicionado para todas as empresas
                          vinculadas a sua organização.
                        </AccordionSubTitle>
                      </div>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <StyledBoxAdornment
                          title="Habilitar compartilhamento."
                          description="Ao ativar esta opção, o treinamento será automaticamente compartilhado com as contas de todas as empresas do grupo. Isso significa que todas as empresas terão acesso e permissão para editar este treinamento. O treinamento estará acessível na seção “Gerenciar Treinamentos”, e cada empresa será responsável por adicionar alunos conforme necessário."
                          leftAdornment={
                            <Toggle
                              checked={formik.values.isShared}
                              disabled={data?.companyId != selectedCompany?.id}
                              onChange={({ target: { checked } }) => {
                                formik.handleChange({
                                  target: {
                                    id: "isShared",
                                    value: checked,
                                  },
                                });
                              }}
                            />
                          }
                        />
                      </div>
                    </StyledSectionContainer>
                  </form>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </PageTemplate>
      <DraftCourseModal
        open={saveAsDraft}
        onClose={() => navigate("/lms/manage-courses")}
      />
    </>
  );
};

import { Tag, TagProps, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

type TagListProps = Array<
  {
    id: string | number;
    label: string;
    variant?: TagProps["variant"];
  } & Omit<TagProps, "variant">
>;

export const TagList = ({
  tags,
  minTags = 12,
  style,
}: {
  tags: TagListProps;
  minTags?: number;
  style?: React.CSSProperties;
}) => {
  const visibleTags = tags?.slice(0, minTags);
  const remainingTags = tags?.slice(minTags);

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "12px", ...style }}>
      {visibleTags?.map((tag) => (
        <Tag
          key={tag?.id}
          {...tag}
          variant={tag?.variant ? tag?.variant : "primary"}
          disabled
        >
          {tag?.label}
        </Tag>
      ))}
      {remainingTags?.length > 0 && (
        <Tooltip
          title={remainingTags?.map((tag) => tag?.label).join(", ")}
          placement="top-start"
        >
          <Tag key="remainingCountTag" variant="primary">
            {`+${remainingTags?.length}`}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

import styled from "styled-components";
import { Button as ButtonMui, Grid } from "@mui/material";
import {
  Icons,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StepperArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const StyledIconToButton = styled(ButtonMui)<{ selected: boolean }>`
  svg {
    stroke: ${({ theme, selected }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : theme.colors.neutral.dark.dark5};
  }
`;

export const FooterArea = styled.div`
  width: -webkit-fill-available;
`;

export const StyledTypography = styled(Typography)``;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

const StyledFlex = styled.div`
  display: flex;
`;

export const StyledGrid = styled(Grid)``;

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

export const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

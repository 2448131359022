import styled from "styled-components";
import { SearchField } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledSearch = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }

  width: 100%;
  height: 60px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;

import {
  BlockButton,
  BlockDivider,
  ContainerBlock,
  IconButtonLock,
  SubContainerBlock,
} from "./styled";

import { StyledText, StyledTitle } from "../../../../utils/common-styled.utils";
import { useNavigate } from "react-router-dom";

const BlockNavigation = ({
  modules,
  blockNavigation,
  moduleId,
  courseId,
  classId,
  selected,
  viewType,
}) => {
  const navigate = useNavigate();

  const moduleIndex = modules?.findIndex(
    (m) => m?.position?.toString() === moduleId.toString()
  );

  const firstIndexModuleNotDone = modules?.findIndex((m) =>
    m?.classes?.find(
      (c) =>
        !!!c?.done ||
        (m?.type === "exam" &&
          m?.settings?.passingScore > 0 &&
          !!!m?.examsAttempt?.approved)
    )
  );

  const firstIndexClassesNotDone = modules?.[
    firstIndexModuleNotDone
  ]?.classes?.findIndex(
    (c) =>
      !!!c?.done ||
      (c?.type === "exam" &&
        c?.settings?.passingScore > 0 &&
        !!!c?.examsAttempt?.approved)
  );

  const firstClassesNotDone =
    modules?.[firstIndexModuleNotDone]?.classes?.[firstIndexClassesNotDone];

  const routeIndexModule = firstIndexModuleNotDone + 1;

  const currentClassesIndex = (modules?.[moduleIndex]?.classes || []).findIndex(
    (c) => c._id == classId
  );

  if (!!!selected?.disabled || viewType === "preview") return <></>;

  return (
    <ContainerBlock>
      <SubContainerBlock>
        <div>
          <IconButtonLock size="large" variant="filled" icon="IconLock" />
        </div>
        <StyledTitle
          variant="headline6"
          setColor="neutral20"
          style={{ margin: "16px 0px" }}
        >
          Aula ainda não disponível
        </StyledTitle>
        <StyledText variant="body3" setColor="neutral50">
          Conclua as aulas anteriores para que essa seja liberada.
        </StyledText>
        <BlockDivider />
        <div>
          <BlockButton
            size="large"
            variant="primary"
            onClick={() => {
              if (
                blockNavigation &&
                parseInt(firstIndexModuleNotDone) >= 0 &&
                parseInt(moduleId) > routeIndexModule
              ) {
                navigate(
                  `/lms/my-courses/${courseId}/${routeIndexModule}/${firstClassesNotDone._id}/view`
                );
                return;
              }
              if (
                blockNavigation &&
                parseInt(firstIndexModuleNotDone) >= 0 &&
                parseInt(moduleId) === routeIndexModule &&
                currentClassesIndex > firstIndexClassesNotDone
              ) {
                navigate(
                  `/lms/my-courses/${courseId}/${routeIndexModule}/${firstClassesNotDone._id}/view`
                );
                return;
              }
            }}
          >
            <StyledText
              variant="body3"
              setColor="neutral100"
              style={{ fontWeight: 700 }}
            >
              Continuar última aula
            </StyledText>
          </BlockButton>
        </div>
      </SubContainerBlock>
    </ContainerBlock>
  );
};

export { BlockNavigation };

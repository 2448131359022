import "./styles.css";

import { TimeSlider } from "@vidstack/react";

export const SliderTime = () => {
  return (
    <TimeSlider.Root className={`time-slider slider`}>
      <TimeSlider.Track className={"track"} />
      <TimeSlider.TrackFill className={`trackFill track`} />
      <TimeSlider.Progress className={`progress track`} />

      <TimeSlider.Thumb className={"thumb"} />

      <TimeSlider.Preview className={"preview"}>
        <TimeSlider.Value className={"timeValue"} />
      </TimeSlider.Preview>
    </TimeSlider.Root>
  );
};

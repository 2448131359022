import { useState } from "react";

import "./styles.css";

import {
  useMediaState,
  PlayButton,
  SeekButton,
  FullscreenButton,
  usePlaybackRateOptions,
  useMediaRemote,
} from "@vidstack/react";

import PlayIcon from "../../../../../assets/mobile-player-play.svg";
import PauseIcon from "../../../../../assets/mobile-player-pause.svg";
import Backward5Icon from "../../../../../assets/mobile-player-backward-5.svg";
import Forward5Icon from "../../../../../assets/mobile-player-forward-5.svg";
import MinimizeIcon from "../../../../../assets/mobile-player-minimize.svg";
import SpeedTestIcon from "../../../../../assets/mobile-player-speed-test.svg";
import {
  StyledMenu,
  StyledMenuItem,
  MenuItemContainer,
  MenuSubContainer,
  MenuTitleContainer,
} from "./styled";
import { StyledText } from "@utils";

export function Play() {
  const isPaused = useMediaState("paused");
  return (
    <PlayButton className={`playButton`}>
      {isPaused ? <PlayIcon /> : <PauseIcon />}
    </PlayButton>
  );
}

export function Forward5() {
  return (
    <SeekButton className={`seekButton`} seconds={5}>
      <Forward5Icon />
    </SeekButton>
  );
}

export function Backward5() {
  return (
    <SeekButton className={`seekButton`} seconds={-5}>
      <Backward5Icon />
    </SeekButton>
  );
}

export function Fullscreen() {
  return (
    <FullscreenButton className={`fullscreenButton`}>
      <MinimizeIcon />
    </FullscreenButton>
  );
}

export function SpeedButton() {
  const options = usePlaybackRateOptions(),
    label = options.selectedValue + "x";

  const remote = useMediaRemote();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        role="presentation"
        className={`fullscreenButton`}
        onClick={handleClick}
      >
        <SpeedTestIcon />
      </div>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <StyledMenuItem onClick={handleClose}>
          <MenuTitleContainer>
            <StyledText variant="body3">Velocidade</StyledText>
          </MenuTitleContainer>
        </StyledMenuItem>
        {options.map(({ label, value }) => (
          <MenuSubContainer
            isSelected={options.selectedValue == value}
            onClick={() => {
              remote.changePlaybackRate(parseFloat(value));
              handleClose();
            }}
          >
            <MenuItemContainer isSelected={options.selectedValue == value}>
              <StyledText variant="body3">
                {label === "Normal" ? "1x" : label}
              </StyledText>
            </MenuItemContainer>
          </MenuSubContainer>
        ))}
      </StyledMenu>
    </>
  );
}

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { trpc } from "@api/client";
import dispatchToast from "src/utils/dispatchToast";

import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Mobile } from "./Mobile";
import { Desktop } from "./Desktop";

export const ExamView = ({
  selected: {
    learningObjectId,
    name,
    description,
    questions = [],
    done = undefined,
    settings: { passingScore = 0, showAnswerSheet, attempts },
  },
  isMobile = false,
  handleDoneContent,
  setIsDone = null,
  isDone = false,
  viewType,
}: any) => {
  const utils = trpc.useContext();
  const isPreview = viewType === "preview";

  const [examInitiate, setExamInitiate] = useState<any>(false);
  const [mobileKeyQuestion, setMobileKeyQuestion] = useState<any>(0);
  const [isDoneTrigger, setIsDoneTrigger] = useState<any>(false);
  const [doneButton, setDoneButton] = useState<any>(false);
  const [mobileAnsweredQuestion, setMobileAnsweredQuestion] = useState<any>(0);

  const [examQuestions, setExamQuestions] = useState<any>(questions);
  const [answeredCounter, setAnsweredCounter] = useState<any>(0);
  const [answeredCounterNotMandatory, setAnsweredCounterNotMandatory] =
    useState<any>(0);
  const [notMandatoryCounter, setNotMandatoryCounter] = useState<any>(0);
  const [totalCount, setTotalCount] = useState<any>({
    correctCount: 0,
    incorrectCount: 0,
    questionCount: 0,
  });

  const [allMandatoryAnswered, setAllMandatoryAnswered] = useState<any>(false);

  const [aproved, setAproved] = useState<any>(false);
  const [attemptsLeft, setAttemptsLeft] = useState<any>(null);

  const { courseId = "" } = useParams();

  useEffect(() => {
    if (!examQuestions?.length && questions?.length)
      setExamQuestions(questions);
  }, [questions]);

  useEffect(() => {
    let count = 0;
    let notMandatoryCount = 0;
    let countNotMandatory = 0;

    examQuestions?.map(({ mandatory, alternatives }) => {
      if (!mandatory) {
        notMandatoryCount = notMandatoryCount + 1;
        alternatives?.map(({ checked = false }) => {
          if (checked === true) countNotMandatory = countNotMandatory + 1;
          else return;
        });
        return;
      }
      alternatives?.map(({ checked = false }) => {
        if (checked === true) count = count + 1;
        else return;
      });
    });

    const allMandatoryHasBennAnswered =
      examQuestions.every((item) => {
        if (item.mandatory) {
          return item.alternatives.some((alt) => alt.checked);
        }

        return true;
      }) || examQuestions.every((item) => !item.mandatory);

    setAnsweredCounterNotMandatory(countNotMandatory);
    setNotMandatoryCounter(notMandatoryCount);
    setAnsweredCounter(count);
    setAllMandatoryAnswered(allMandatoryHasBennAnswered);

    if (setDoneButton) setDoneButton(allMandatoryHasBennAnswered);
  }, [examQuestions]);

  useEffect(() => {
    if (isDoneTrigger) {
      handleSendExam({ examQuestions });
      setIsDoneTrigger(false);
    }
  }, [isDoneTrigger]);

  useEffect(() => {
    if (setMobileAnsweredQuestion) {
      setMobileAnsweredQuestion(
        Math.round(
          (answeredCounter / (questions?.length - notMandatoryCounter)) * 100
        )
      );
    }
  }, [answeredCounter, answeredCounterNotMandatory]);

  const { isFetching: isGettingCertificateById } =
    trpc.exams.getAnswerSheet.useQuery(
      { examId: learningObjectId, courseId: courseId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!learningObjectId && !!courseId && !!!isPreview,
        onSuccess: (data) => {
          const { total, approved, attemptsLeft, answeerSheet } = data;

          setTotalCount(total);
          setAttemptsLeft(attemptsLeft);
          if (approved) {
            setIsDone(true);
            setAproved(true);
            handleDoneContent();
            if (answeerSheet?.length) setExamQuestions(answeerSheet);
          }
          if (attemptsLeft === 0) {
            setIsDone(true);
            setAproved(false);
            handleDoneContent();
          }
        },
        onError: (e: any) => {
          const employeeNotExists =
            e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";

          const learningObjectNotExists =
            e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";

          const monitoringsNotExists =
            e?.data?.error === "MONITORING_NOT_EXISTS_ERROR";

          if (monitoringsNotExists) return;

          const message = learningObjectNotExists
            ? "Já existe objeto de aprendizado com esse nome."
            : employeeNotExists
            ? "Não existe o funcionário"
            : "Erro ao tentar buscar as respostas do exame, tente novamente mais tarde!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );

  const { mutate: sendExamsAnswersMutate, isLoading: isSendingExamsAnsers } =
    trpc.exams.sendExamsAnswers.useMutation({});

  const handleSelectOption = ({ questionIndex, optionIndex }) => {
    if (isDone) return;
    if (questionIndex >= 0 && optionIndex >= 0) {
      const uptObj = examQuestions?.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            alternatives: question?.alternatives?.map((option, optIndex) => {
              if (optIndex === optionIndex) {
                return {
                  ...option,
                  checked: true,
                };
              } else {
                return { ...option, checked: false };
              }
            }),
          };
        } else return question;
      });
      setExamQuestions(uptObj);
    } else return;
  };

  const handleSendExam = ({ examQuestions = [] }) => {
    let payload = [] as any;

    examQuestions?.map(({ alternatives, _id: questionId }: any) => {
      return alternatives?.map(({ checked, _id }) => {
        if (checked === true) {
          payload = [
            ...payload,
            {
              _id: questionId,
              chosenAnswer: [_id],
            },
          ];
        }
      });
    });

    sendExamsAnswersMutate(
      {
        payload,
        examId: learningObjectId,
        courseId,
      },
      {
        onSuccess: (data) => {
          if (!!!data?.passed) {
            dispatchToast({
              content: "Nota miníma não atingida!",
              type: "warning",
            });
          }

          setExamQuestions(questions);
          utils.exams.getAnswerSheet.invalidate();
        },
        onError: (e: any) => {
          const employeeNotExists =
            e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";
          const courseNotExists = e?.data?.error === "COURSE_NOT_EXISTS_ERROR";
          const exceedExamAttempt = "EXAM_ATTEMPT_LIMIT_EXCEEDED_ERROR";
          const learningObjectNotExists =
            e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

          const message = employeeNotExists
            ? "Não existe o funcionário."
            : courseNotExists
            ? "Não existe o curso."
            : learningObjectNotExists
            ? "Não existe o objeto de aprendizado"
            : exceedExamAttempt
            ? "Limite de tentativas excedidas"
            : "Erro ao tentar salvar as respostas do exame, tente novamente mais tarde!";

          dispatchToast({
            type: "error",
            content: message,
          });
        },
      }
    );
  };

  if (isGettingCertificateById)
    return (
      <Skeleton
        width="100%"
        height="100vh"
        animation="pulse"
        variant="rectangular"
      />
    );

  return (
    <>
      {isMobile ? (
        <Mobile
          selected={{
            learningObjectId,
            name,
            description,
            questions,
            done,
            settings: { passingScore, showAnswerSheet, attempts },
          }}
          viewType={viewType}
          exams={{
            mobileAnsweredQuestion,
            examInitiate,
            setExamInitiate,
            mobileKeyQuestion,
            setMobileKeyQuestion,
            doneButton,
            setIsDoneTrigger,
          }}
          isDone={isDone}
          aproved={aproved}
          examQuestions={examQuestions}
          handleSelectOption={handleSelectOption}
          attemptsLeft={attemptsLeft}
          totalCount={totalCount}
        />
      ) : (
        <Desktop
          selected={{
            learningObjectId,
            name,
            description,
            questions,
            done,
            settings: { passingScore, showAnswerSheet, attempts },
          }}
          isDone={isDone}
          aproved={aproved}
          examQuestions={examQuestions}
          handleSelectOption={handleSelectOption}
          attemptsLeft={attemptsLeft}
          answeredCounter={answeredCounter}
          answeredCounterNotMandatory={answeredCounterNotMandatory}
          allMandatoryAnswered={allMandatoryAnswered}
          isSendingExamsAnsers={isSendingExamsAnsers}
          isPreview={isPreview}
          handleSendExam={handleSendExam}
          totalCount={totalCount}
          examInitiate={examInitiate}
        />
      )}
    </>
  );
};

import { Typography, Tab } from "@flash-tecnologia/hros-web-ui-v2";

import styled from "styled-components";

const Container = styled.div`
  margin: 40px 0px;
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTab = styled(Tab)`
  width: 100%;

  > div {
    flex: 1;
  }

  > div button div {
    margin-right: 0px;
  }
`;

export { Container, PageTitle, FlexColumn, StyledTab };

import { useEffect, useRef, useState } from "react";
import { trpc } from "@api/client";
import { Link, useParams } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";

import {
  Breadcrumbs,
  Button,
  dayjs,
  Icons,
  PageContainer,
  Skeleton,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";

import {
  BreadcrumbLastText,
  BreadcrumbText,
  DetailBox,
  DetailInfoArea,
  DetailInfoText,
  DetailLabelTitle,
  DetailTitle,
  ExtraInfoLabel,
  HeaderArea,
  IndexArea,
  IndexItem,
  IndexTitle,
  InfoArea,
  InfoBox,
  InfoBoxArea,
  InfoIconArea,
  InfoLabel,
  InfoText,
  PublishedTag,
  QuestionArea,
  Separator,
  SideMenuArea,
  SubTitle,
  Title,
  TitleContainer,
  TitleText,
} from "./styles";

import { GenerateExamAnswersheetReportModal } from "../../components";

import { ExamQuestion } from "@components/ExamQuestion";

import { RedoExamModal } from "@components/Modals/RedoExamModal";
import { dispatchToast } from "@utils";

import { NoAnswerExam } from "./Components/NoAnswerExam";

export const DetailExam = () => {
  const utils = trpc.useContext();

  const { courseId = "", examId = "", studentId = "" } = useParams();
  const [examData, setExamData] = useState<any>({});
  const [employee, setEmployee] = useState<any>({});
  const [learningObject, setLearningObject] = useState<any>({});
  const [open, setOpen] = useState(false);

  const [redoExamModal, setRedoExamModal] = useState(false);

  const itensArea = useRef<any>(null);

  const { isFetching: isGettingCourseExamAnswersById } =
    trpc.exams.getEmployeeExamsAnswersById.useQuery(
      { courseId, examId, employeeId: studentId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!courseId && !!examId && !!studentId,
        onSuccess: (data) => {
          setExamData(data);
        },
      }
    );

  const { isFetching: isGettingEmployeeById } =
    trpc.employee.getEmployeeById.useQuery(
      { employeeId: studentId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!studentId,
        onSuccess: (data) => {
          setEmployee(data);
        },
      }
    );

  const { isFetching: isGettingContentById } =
    trpc.learningObject.getContentById.useQuery(
      { contentId: examId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!examId,
        onSuccess: (data) => {
          setLearningObject(data);
        },
      }
    );

  const { mutate: redoExamMutate, isLoading: isRedoExam } =
    trpc.monitorings.redoExam.useMutation({});

  return (
    <PageContainer>
      <>
        <Separator height={"50px"} />
        <Breadcrumbs
          separator={
            <Icons
              name={"IconChevronRight"}
              color="var(--color-neutral-dark5)"
              fill="var(--color-neutral-100)"
            />
          }
          breadcrumbs={[
            <Link to="/lms/manage-courses">
              <BreadcrumbText variant="body4">
                Gerenciar treinamentos
              </BreadcrumbText>
            </Link>,
            <Link to={`/lms/manage-courses/detail-course/${courseId}`}>
              <BreadcrumbText variant="body4">
                Detalhe do treinamento
              </BreadcrumbText>
            </Link>,
            <BreadcrumbLastText variant="body4">
              Gabarito do aluno
            </BreadcrumbLastText>,
          ]}
        />
        <Separator height={"25px"} />
        <HeaderArea>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TitleContainer>
                <TitleText>
                  <Title variant="headline6">{learningObject?.name}</Title>
                  <Separator height={"40px"} />
                  <PublishedTag
                    variant={examData?.doneDate ? "success" : "error"}
                  >
                    {examData?.doneDate ? "Concluído" : "Não iniciado"}
                  </PublishedTag>
                </TitleText>
              </TitleContainer>
              <SubTitle variant="body4">
                {employee?.courses?.filter(({ _id }) => _id === courseId)[0]
                  ?.name ?? ""}
              </SubTitle>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                size="medium"
                variant="primary"
                onClick={() => {
                  setRedoExamModal(true);
                }}
              >
                Reiniciar avaliação do aluno
              </Button>
            </div>
          </div>
        </HeaderArea>
        <Separator height={"40px"} />
        <Tab
          tabItens={[
            {
              label: `Respostas da avaliação`,
              component: (
                <>
                  {isGettingCourseExamAnswersById ||
                  isGettingEmployeeById ||
                  isGettingContentById ? (
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  ) : (
                    <>
                      <InfoBoxArea>
                        <InfoBox>
                          <InfoIconArea>
                            <Icons
                              name="IconUser"
                              fill="transparent"
                              size={24}
                            />
                          </InfoIconArea>
                          <InfoArea>
                            <InfoLabel variant="body3">Aluno</InfoLabel>
                            <InfoText variant="headline5">
                              {employee?.name ?? "Não consta"}
                            </InfoText>
                          </InfoArea>
                        </InfoBox>
                        <InfoBox>
                          <InfoIconArea>
                            <Icons
                              name="IconReportAnalytics"
                              fill="transparent"
                              size={24}
                            />
                          </InfoIconArea>
                          <InfoArea>
                            <InfoLabel variant="body3">Nota</InfoLabel>
                            <InfoText variant="headline5">
                              {examData?.total?.correctCount
                                ? Math.round(
                                    examData?.total?.correctCount *
                                      (10 / examData?.total?.questionCount)
                                  )
                                : 0}
                              /10 <span>•</span>
                              <PublishedTag
                                variant={
                                  examData?.approved ? "success" : "error"
                                }
                              >
                                {examData?.approved == true
                                  ? "Aprovado"
                                  : examData?.approved == false
                                  ? "Reprovado"
                                  : "Não iniciado"}
                              </PublishedTag>
                            </InfoText>
                          </InfoArea>
                        </InfoBox>
                        <InfoBox style={{ marginRight: "0px" }}>
                          <InfoIconArea>
                            <Icons
                              name="IconCalendar"
                              fill="transparent"
                              size={24}
                            />
                          </InfoIconArea>
                          <InfoArea>
                            <InfoLabel variant="body3">
                              Data de conclusão
                            </InfoLabel>
                            {examData?.doneDate ? (
                              <InfoText variant="headline5">
                                {dayjs(examData?.doneDate)?.format("DD/MM/YY")}
                                <ExtraInfoLabel variant="headline8">
                                  • {dayjs(examData?.doneDate)?.format("HH:MM")}
                                  h
                                </ExtraInfoLabel>
                              </InfoText>
                            ) : (
                              <>-</>
                            )}
                          </InfoArea>
                        </InfoBox>
                      </InfoBoxArea>
                      {Object.keys(examData).length ? (
                        <Grid container spacing={3}>
                          <Grid item xs={8}>
                            <QuestionArea ref={itensArea}>
                              <ScrollSpy parentScrollContainerRef={itensArea}>
                                {examData?.answeerSheet?.map(
                                  (
                                    {
                                      question = "",
                                      alternatives,
                                      mandatory = false,
                                    },
                                    index
                                  ) => {
                                    return (
                                      <div id={`item${index}`}>
                                        <ExamQuestion
                                          key={index}
                                          number={index + 1}
                                          title={question}
                                          alternatives={alternatives}
                                          isMandatory={mandatory}
                                          isDone={true}
                                          isMobile={false}
                                          totalQuestions={question?.length}
                                          disabledMargin={true}
                                        />
                                      </div>
                                    );
                                  }
                                )}
                                <div style={{ height: "300px" }} />
                              </ScrollSpy>
                            </QuestionArea>
                          </Grid>
                          <Grid item xs={4}>
                            <SideMenuArea>
                              <IndexTitle variant="headline8">
                                Navegue entre as perguntas
                              </IndexTitle>
                              <IndexArea>
                                {examData?.answeerSheet?.map(
                                  (
                                    { question = "", mandatory = false },
                                    index
                                  ) => (
                                    <IndexItem
                                      variant="body4"
                                      key={index}
                                      data-to-scrollspy-id={`item${index}`}
                                    >
                                      <a href={`#item${index}`}>
                                        {index + 1}. {question}{" "}
                                        <span>{mandatory ? "*" : null}</span>
                                      </a>
                                    </IndexItem>
                                  )
                                )}
                              </IndexArea>
                            </SideMenuArea>
                          </Grid>
                        </Grid>
                      ) : (
                        <NoAnswerExam />
                      )}
                    </>
                  )}
                </>
              ),
            },
            {
              label: `Detalhe da avaliação`,
              component: (
                <>
                  {isGettingCourseExamAnswersById ||
                  isGettingEmployeeById ||
                  isGettingContentById ? (
                    <Skeleton
                      width="100%"
                      height="100vh"
                      animation="pulse"
                      variant="rectangular"
                    />
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <DetailBox>
                          <DetailTitle variant="headline8">
                            Informações básicas
                          </DetailTitle>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Nome da avaliação
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.name || " - "}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>Descrição</DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.description || " - "}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <div>
                            <DetailLabelTitle>
                              Quantidade de perguntas
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.questions?.length || " - "}
                            </DetailInfoText>
                          </div>
                        </DetailBox>
                        <DetailBox>
                          <DetailTitle variant="headline8">
                            Informações de resposta
                          </DetailTitle>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Data de conclusão
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {examData?.doneDate ? (
                                <>
                                  {dayjs(examData?.doneDate)?.format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  {dayjs(examData?.doneDate)?.format("HH:MM")}h
                                </>
                              ) : (
                                " - "
                              )}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>Nota</DetailLabelTitle>
                            <DetailInfoText>
                              {examData?.total ? (
                                <>
                                  {" "}
                                  {Math.round(
                                    examData?.total?.correctCount *
                                      (10 / examData?.total?.questionCount)
                                  )}
                                  /10
                                </>
                              ) : (
                                " - "
                              )}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Quantidade de acertos
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {examData?.total?.correctCount || " - "}{" "}
                              <span>/</span>{" "}
                              {examData?.total?.questionCount || " - "}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <div>
                            <DetailLabelTitle>Aprovação</DetailLabelTitle>
                            <DetailInfoText>
                              {examData?.approved === undefined
                                ? " - "
                                : examData?.approved
                                ? "Sim"
                                : "Não"}
                            </DetailInfoText>
                          </div>
                        </DetailBox>
                      </Grid>
                      <Grid item xs={6}>
                        <DetailBox>
                          <DetailTitle variant="headline8">
                            Configurações
                          </DetailTitle>
                          <DetailInfoArea>
                            <DetailLabelTitle>Nota mínima</DetailLabelTitle>
                            <DetailInfoText>
                              {Math.round(
                                learningObject?.settings?.passingScore *
                                  (10 / learningObject?.questions?.length)
                              )}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Quantidade de acertos mínima
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.settings?.passingScore}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>Exibir gabarito</DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.settings?.showAnswerSheet ===
                              undefined
                                ? " - "
                                : learningObject?.settings?.showAnswerSheet
                                ? "Habilitado"
                                : "Não Habilitado"}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Ordenar as questões de forma aleatória
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.settings?.randomizeQuestions ===
                              undefined
                                ? " - "
                                : learningObject?.settings?.randomizeQuestions
                                ? "Habilitado"
                                : "Não Habilitado"}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Ordenar as alternativas de forma aleatória
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.settings?.randomizeOptions ===
                              undefined
                                ? " - "
                                : learningObject?.settings?.randomizeOptions
                                ? "Habilitado"
                                : "Não Habilitado"}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <DetailInfoArea>
                            <DetailLabelTitle>
                              Número de tentativas
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.settings?.attempts === undefined
                                ? " - "
                                : learningObject?.settings?.attempts > 1
                                ? `${learningObject?.settings?.attempts} tentativas`
                                : "Apenas uma tentativa"}
                            </DetailInfoText>
                          </DetailInfoArea>
                          <div>
                            <DetailLabelTitle>
                              Compartilhar com todas as empresas do grupo
                            </DetailLabelTitle>
                            <DetailInfoText>
                              {learningObject?.isShared === undefined
                                ? " - "
                                : learningObject?.isShared
                                ? "Habilitado"
                                : "Não Habilitado"}
                            </DetailInfoText>
                          </div>
                        </DetailBox>
                      </Grid>
                    </Grid>
                  )}
                </>
              ),
            },
          ]}
        />
      </>
      <GenerateExamAnswersheetReportModal
        open={open}
        employeeName={employee?.name}
        onClose={() => setOpen(false)}
      />

      <RedoExamModal
        open={redoExamModal}
        onClose={() => setRedoExamModal(false)}
        employeesQuantity={1}
        loading={isRedoExam}
        handleClick={() => {
          redoExamMutate(
            {
              courseId: courseId,
              examId: examId,
              employees: [studentId],
            },
            {
              onError: (e: any) => {
                const monitoringsNotExists =
                  e?.data?.error === "MONITORING_NOT_EXISTS_ERROR";

                setRedoExamModal(false);

                if (monitoringsNotExists) return;

                dispatchToast({
                  type: "error",
                  content: "Erro ao tentar reiniciar avaliação",
                });
              },
              onSuccess: () => {
                setRedoExamModal(false);
                setExamData({});
                utils.exams.getEmployeeExamsAnswersById.invalidate();
                utils.employee.getEmployeeById.invalidate();
                utils.learningObject.getContentById.invalidate();
              },
            }
          );
        }}
      />
    </PageContainer>
  );
};

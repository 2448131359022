import { useEffect, useRef, useState } from "react";
import HLS from "hls.js";

import {
  MediaPlayer,
  MediaProvider,
  isHLSProvider,
  isYouTubeProvider,
  type MediaProviderAdapter,
  type MediaPlayerInstance,
  MediaViewType,
} from "@vidstack/react";

import "./theme.css";

import { VideoLayout } from "./VideoLayout/video-layout";
import { AudioLayout } from "./AudioLayout/audio-layout";
import { MobileLayout } from "./MobileLayout/mobile-layout";

import { useMediaQuery } from "@flash-tecnologia/hros-web-ui-v2";

export type PlayerProps = {
  mediaToPlay: any;
  selected?: any;
  setSelected?: any;
  onCanPlay?: any;
};

export const Player = ({
  mediaToPlay,
  selected,
  setSelected,
  onCanPlay,
}: PlayerProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [viewType, setViewType] = useState<MediaViewType>("unknown");

  const player = useRef<MediaPlayerInstance>(null);

  useEffect(() => {
    if (player?.current) {
      const interval = setInterval(() => {
        const currentTime = player?.current?.currentTime || 0;
        const duration = player?.current?.duration || 0;
        const selectedCurrentTime = selected?.currentTime || 0;
        const selectedDuration = selected?.duration || 1;

        if (Math.round((selectedCurrentTime / selectedDuration) * 100) <= 80) {
          selected &&
            setSelected &&
            setSelected({
              ...selected,
              currentTime: currentTime,
              duration: duration,
            });
        }
      }, 2000);

      return () => clearInterval(interval);
    }
  }, [player, selected]);

  useEffect(() => {
    return player.current!.subscribe(({ viewType }) => {
      setViewType(viewType);
    });
  }, []);

  function onProviderChange(provider: MediaProviderAdapter | null) {
    if (isYouTubeProvider(provider)) {
      provider.cookies = false;
    }

    if (isHLSProvider(provider)) {
      provider.library = HLS;

      provider.config = {
        xhrSetup: function (xhr: any, url: any) {
          const signature = mediaToPlay?.signature || "";
          const newurl = url + signature;

          xhr.open("GET", newurl, true);
        },
      };
    }
  }

  return (
    <>
      <MediaPlayer
        key={mediaToPlay?.uri || ""}
        src={mediaToPlay?.uri || ""}
        crossOrigin
        playsInline
        onProviderChange={onProviderChange}
        onCanPlay={onCanPlay}
        ref={player}
      >
        <MediaProvider></MediaProvider>

        {viewType === "audio" ? (
          <AudioLayout />
        ) : viewType === "video" ? (
          isMobile ? (
            <MobileLayout />
          ) : (
            <VideoLayout />
          )
        ) : null}
      </MediaPlayer>
    </>
  );
};

import { CircularProgress, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  CirculaProgressContainer,
  StyledDescriptionContainer,
  StyledDescriptionSubTitle,
  StyledDescriptionTitle,
  StyledFlexRow,
} from "./styled";

import GenerateCertificate from "../../GenerateCertificate";

import { StyledPLine } from "../../../../utils/common-styled.utils";
import { OverflowCheck } from "@components/OverflowCheck";
import { useEffect } from "react";

export const CourseDescription = ({
  viewType,
  course,
  isGettingCoursesModules,
}) => {
  const hasCertificate = !!course?.certificateId;
  const isPreview = viewType === "preview";

  return (
    <>
      <StyledDescriptionContainer>
        {isGettingCoursesModules ? (
          <Skeleton variant="rectangular" width="100%" height="100vh" />
        ) : (
          <StyledFlexRow>
            <CirculaProgressContainer>
              <CircularProgress
                value={
                  isPreview
                    ? 0
                    : course?.classesTotal
                    ? Math.round(
                        (course?.doneClasses / course?.classesTotal) * 100
                      )
                    : 0
                }
                size={60}
              />
            </CirculaProgressContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <div>
                <StyledDescriptionTitle
                  variant="headline8"
                  children={OverflowCheck({
                    text: course?.name || "",
                    width: "350px",
                  })}
                />
              </div>
              <StyledPLine numberOfLines={1}>
                <StyledDescriptionSubTitle
                  variant="caption"
                  children={`${course?.doneClasses} de ${course?.classesTotal} aulas completas`}
                />
              </StyledPLine>
            </div>
          </StyledFlexRow>
        )}
      </StyledDescriptionContainer>
      {hasCertificate ? (
        <GenerateCertificate course={course} isPreview={isPreview} />
      ) : (
        <></>
      )}
    </>
  );
};

import { StyledSwipeableDrawer } from "./styled";
import { ResponsiveCourseFilters } from "./ResponsiveCourseFilters";

export const MobileFilters = ({
  data,
  filters,
  onFilter,
  openFilters,
  setOpenFilters,
}) => {
  return (
    <>
      <StyledSwipeableDrawer
        anchor="bottom"
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        onOpen={() => setOpenFilters(true)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <ResponsiveCourseFilters
          data={data}
          filters={filters}
          onFilter={onFilter}
          setOpenFilters={setOpenFilters}
        />
      </StyledSwipeableDrawer>
    </>
  );
};

import { useState } from "react";
import { useParams } from "react-router-dom";

import { Grid } from "@mui/material";
import { ExamAnalysisPerformance } from "./ExamAnalysisPerformance";
import { ExamAnswersPerformance } from "./ExamAnswersPerformance";

import { Icons, Table } from "@flash-tecnologia/hros-web-ui-v2";
import { ExamTypeButton } from "./styled";

import { trpc } from "@api/client";
import { dispatchToast } from "@utils";

export const ExamPerformanceTab = () => {
  const { courseId = "" } = useParams();

  const [tabView, setTabView] = useState(0);
  const [reportType, setReportType] = useState<string>("analysis");
  const [search, setSearch] = useState<string>("");

  const [exam, setExam] = useState("");
  const [exams, setExams] = useState([]);
  const [rawExams, setRawExams] = useState([]);
  const [learningObjects, setLearningObjects] = useState<any>([]);

  const {
    data: examsMonitorings,
    isLoading: isGettingEmployeeExamsMonitoring,
  } = trpc.exams.getEmployeeExamsMonitoring.useQuery(
    { courseId, examId: exam },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: !!courseId && !!exam,
      onSuccess: (data) => {
        if (!data?.length) return;

        const orderedExams = data?.sort((a, b) => {
          if (a?.name < b?.name) return -1;
        });

        setExams(orderedExams);
        setRawExams(orderedExams);
      },
      onError: (e: any) => {
        const employeeNotExists =
          e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";
        const courseNotExists = e?.data?.error === "COURSE_NOT_EXISTS_ERROR";
        const message = employeeNotExists
          ? "Não existe o funcionário."
          : courseNotExists
          ? "Não existe o curso."
          : "Erro ao tentar buscar o monitoramento, tente novamente mais tarde!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    }
  );

  const { isLoading: isGettingExamHierarchy } =
    trpc.exams.getExamHierarchy.useQuery(
      { courseId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!courseId,
        onSuccess: (data) => {
          const options = data?.map((d) => {
            return { value: d?._id, label: d?.name };
          });

          const mappedOptions = options
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t?.value === value?.value)
            )
            ?.sort((a, b) => {
              if (a?.label < b?.label) return -1;
            });

          setLearningObjects(mappedOptions);
          setExam(mappedOptions?.[0]?.value);
        },
        onError: (e: any) => {
          dispatchToast({
            type: "error",
            content:
              "Erro ao tentar buscar a hierarquia de avaliação, tente novamente mais tarde!",
          });
        },
      }
    );

  return (
    <>
      <Grid container>
        <Table.Search
          onChange={(e) => setSearch(e.target.value)}
          label="Buscar por avaliação"
          style={{ marginBottom: "25px" }}
        />

        <Grid item xs={2} paddingRight={"24px"}>
          <ExamTypeButton
            variant="secondary"
            size="small"
            selected={tabView === 0}
            onClick={() => {
              setTabView(0);
              setReportType("analysis");
            }}
          >
            <Icons name="IconChartBar" fill="transparent" />
            Análise
          </ExamTypeButton>
          <ExamTypeButton
            variant="secondary"
            size="small"
            selected={tabView === 1}
            onClick={() => {
              setTabView(1);
              setReportType("answers");
            }}
          >
            <Icons name="IconNotes" fill="transparent" />
            Respostas
          </ExamTypeButton>
        </Grid>

        <Grid item xs={10}>
          {reportType == "answers" ? (
            <ExamAnswersPerformance
              exam={exam}
              setExam={setExam}
              learningObjects={learningObjects}
              search={search}
              loading={
                isGettingEmployeeExamsMonitoring || isGettingExamHierarchy
              }
            />
          ) : (
            <ExamAnalysisPerformance
              exams={exams}
              exam={exam}
              setExam={setExam}
              setExams={setExams}
              rawExams={rawExams}
              learningObjects={learningObjects}
              search={search}
              loading={
                isGettingEmployeeExamsMonitoring || isGettingExamHierarchy
              }
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

import "./styles.css";

import { Time } from "@vidstack/react";

export const TimeGroup = () => {
  return (
    <div className={"mobileGroup"}>
      <Time className={"mobileTime"} type="current" />
      <div className={"mobileDivider"}>/</div>
      <Time className={"mobileTime"} type="duration" />
    </div>
  );
};

import styled, { css } from "styled-components";
import { Typography, IconButton, Tag } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 8px 32px rgba(254, 43, 143, 0.16);
  }
`;

export const SubImageContainer = styled.div<{
  url: string;
  hasBanner: boolean;
}>`
  position: relative;
  height: 210px;
  background-color: ${({ theme }) => theme.colors.neutral.light.light1};
  background-image: url(${({ url }) => url});
  background-repeat: no-repeat;
  background-size: ${({ hasBanner }) => (hasBanner ? "cover" : "contain")};
  background-position: center;
  position: relative;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
`;

export const SubImageIcon = styled(IconButton)<{ hasNoImage?: boolean }>`
  position: absolute;
  top: 24px;
  right: 24px;
  ${({ hasNoImage }) =>
    hasNoImage
      ? css`
          && {
            &.MuiButtonBase-root {
              background-color: ${({ theme }) =>
                theme.colors.neutral.light.light2};
            }
          }
        `
      : ""}
`;

export const SubContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
`;

export const TextContainer = styled.div`
  max-width: 536px;
  text-align: center;
`;

export const LabelTitle = styled(Typography)`
  && {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
    margin-bottom: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }
`;

export const LabelSubTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark4};
    display: flex;
    align-items: center;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const TagContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-y: overlay;
  margin-bottom: 16px;
  margin-top: 16px;
  gap: 8px;
`;

export const StyledTag = styled(Tag)`
  white-space: nowrap;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  margin-bottom: 30px;
`;

export const ProgressSubContainer = styled.div<{
  progressValue: number;
}>`
  width: 100%;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.secondary.dark.dark5} 0%,
    ${({ theme }) => theme.colors.secondary.dark.dark5}
      ${({ progressValue }) => progressValue}%,
    ${({ theme }) => theme.colors.secondary.light.light2}
      ${({ progressValue }) => progressValue}%,
    ${({ theme }) => theme.colors.secondary.light.light2} 100%
  );

  height: 8px;
  border-radius: 6px;
`;

export const SubImageHoverContainer = styled.div`
  background: rgba(29, 22, 27, 0.4);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  opacity: 0;
  z-index: 99;

  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const IconButtonHover = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
        opacity: 0.6;
      }
      svg {
        stroke: ${({ theme }) => theme.colors.neutral.light.pure};
        width: 12px;
        height: 12px;
      }
    }
  }
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  margin-right: 12px;
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const ProgressPercent = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const EmptyContent = styled.div`
  height: 36px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const IntroductionArea = styled.div`
  padding: 40px;
  background: ${({ theme }) => theme.colors.neutral.light.pure};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin: 12px 0;
`;

export const Title = styled(Typography)`
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral.dark.dark2};
  margin-bottom: 16px;
  text-transform: capitalize;
`;

export const InfoArea = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: block;
  }
`;

export const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary.dark.dark5};
  font-weight: 600 !important;
  display: flex;
  align-items: center;
  svg {
    border: 1px solid ${({ theme }) => theme.colors.secondary[90]};
    border-radius: 100px;
    padding: 3px;
    margin-right: 4px;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 16px;
  }
`;

export const DescriptionText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const Divider = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary[90]};
  margin: 0 12px;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
`;

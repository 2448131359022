import { useState } from "react";

import {
  Icons,
  Tag,
  IconButton,
  TextField,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledTypography,
  StyledTitle,
  StyledFlex,
  StyledGrid,
  StyledPageContainer,
  StyledAccordionContainer,
  StyledFlexBetween,
  StyledIconContainer,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledButton,
  StyledAddTextButton,
  StyledAddIconButton,
  StyledAddModuleContainer,
} from "./styled";

import { AddClassesSection } from "../../../components/AddClassesSection";
import { trpc } from "@api/client";
import dispatchToast from "../../../utils/dispatchToast";

export const AccordionAddingEdit = ({
  courseId,
  chapterId,
  courseHierachy,
}) => {
  const utils = trpc.useContext();

  const [addClass, setAddClass] = useState(false);
  const [expanded, setExpanded] = useState<any>(undefined);
  const [learningObject, setLearningObject] = useState<any>({});

  const { mutate: deleteMutate, isLoading } =
    trpc.course.deleteNodeCourseHierarchy.useMutation({});

  const { isFetching: isLoadingGetAllLearningFiltered } =
    trpc.learningObject.getAllLearningObject.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setLearningObject(data);
      },
    });

  const renderClassesAccordion = ({ chapters, moduleId }) => {
    return chapters?.map(
      ({ _id, name, description, learningObjectId }, index) => (
        <StyledAccordionContainer
          key={index}
          expanded={expanded === index}
          style={{ marginBottom: "24px" }}
        >
          <StyledAccordionSummary
            onClick={() =>
              expanded === index ? setExpanded(undefined) : setExpanded(index)
            }
          >
            <StyledFlexBetween>
              <StyledFlex
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <StyledTypography variant="headline7">{name}</StyledTypography>
                <StyledIconContainer
                  onClick={() => {
                    deleteMutate(
                      {
                        params: { nodeId: _id, level: 2 },
                        courseId,
                      },
                      {
                        onSuccess: () => {
                          utils.course.getCourseHierarchy.invalidate();
                        },
                        onError: (e: any) => {
                          const courseHierarchyNotExists =
                            e?.data?.error ===
                            "COURSE_HIERARCHY_NOT_EXISTS_ERROR";

                          const message = courseHierarchyNotExists
                            ? "Não existe o curso."
                            : "Erro ao tentar deletar a hierarquia do curso, tente novamente mais tarde!";

                          dispatchToast({
                            type: "error",
                            content: message,
                          });
                        },
                      }
                    );
                  }}
                >
                  <Icons name="IconTrash" fill={"transparent"} size={16} />
                </StyledIconContainer>
              </StyledFlex>

              <StyledFlex
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <IconButton
                    size="medium"
                    variant="filled"
                    icon="IconChevronDown"
                  />
                </div>
              </StyledFlex>
            </StyledFlexBetween>
          </StyledAccordionSummary>
          <StyledAccordionDetails>
            <AddClassesSection
              _id={_id}
              courseId={courseId}
              name={name}
              description={description}
              learningObjectId={learningObjectId}
              parentId={moduleId}
              setAddClass={setAddClass}
              type="edit"
              courseHierachy={courseHierachy}
              closeModal={() => setAddClass(false)}
            />
          </StyledAccordionDetails>
        </StyledAccordionContainer>
      )
    );
  };
  return (
    <div style={{ marginBottom: "20px" }}>
      {renderClassesAccordion({
        chapters: courseHierachy?.classes?.find((f) => f._id === chapterId)
          ?.chapters,
        moduleId: chapterId,
      })}
      {addClass ? (
        <div style={{ marginBottom: "24px" }}>
          <AddClassesSection
            type="add"
            courseId={courseId}
            parentId={chapterId}
            setAddClass={setAddClass}
            closeModal={() => setAddClass(false)}
          />
        </div>
      ) : (
        <></>
      )}

      <StyledButton
        size="large"
        variant="secondary"
        style={{ width: "100%" }}
        onClick={() => setAddClass(true)}
      >
        <StyledAddIconButton name="Add" />
        <StyledAddTextButton variant="body3">
          Adicionar nova aula
        </StyledAddTextButton>
      </StyledButton>
    </div>
  );
};

import styled from "styled-components";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const DoneCheckArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 32px 0 0;
  div:first-child {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid #d1c7ce;
    border-radius: 8px;
  }
`;

export const PreviewHeaderContainer = styled.div`
  padding: 12px 20px;
  background-color: #53464f;
`;

export const PreviewHeaderSubContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PreviewLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[100]};
`;

export const PreviewIcon = styled(Icons)`
  fill: transparent;
  color: ${({ theme }) => theme.colors.neutral[100]};
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Checkbox,
  DatePicker,
  IconButton,
  Icons,
  LinkButton,
  Menu,
  Table,
  Tag,
  TagCheckboxFilter,
  Tooltip,
  dayjs,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledText,
  StyledTitle,
  dispatchToast,
  pageSizeOptions,
} from "@utils";
import {
  CounterTag,
  DateArea,
  GradeArea,
  GradeDescription,
  GradeTitle,
  OptionArea,
  StatusTag,
  StyledBetween,
  StyledSelect,
} from "./styled";

import { trpc } from "@api/client";
import { GenerateExamsReportModal } from "@components/GenerateExamsReportModal";
import { RedoExamModal } from "../../../../../components/Modals/RedoExamModal";
import Avatar from "./Avatar";

export const ExamAnalysisPerformance = ({
  exams,
  exam,
  setExam,
  setExams,
  rawExams,
  learningObjects,
  search,
  loading,
}) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();
  const { courseId = "" } = useParams();

  const [selectedAll, setSelectedAll] = useState(false);
  const [selected, setSelected] = useState<any>([]);

  const [redoExamModal, setRedoExamModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<any>({});

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { mutate: redoExamMutate, isLoading: isRedoExam } =
    trpc.monitorings.redoExam.useMutation({});

  const columns = [
    {
      header: (
        <>
          <Checkbox
            onChange={() => {
              if (!selectedAll) {
                const allIds = rawExams?.map((r) => r?._id);
                setSelected(allIds);
              } else {
                setSelected([]);
              }

              setSelectedAll(!selectedAll);
            }}
            checked={selected?.length == rawExams?.length}
          />
        </>
      ),
      accessorKey: "check",
      cell: ({ row: { original } }) => {
        const foundChecked = !!selected?.find((s) => s == original?._id);

        return (
          <Checkbox
            key={selected}
            onChange={() => {
              const allItems = foundChecked
                ? selected.filter((s) => s != original?._id)
                : [...selected, original?._id];

              const allItemsChecked = allItems?.length == rawExams?.length;

              setSelected(allItems);

              if (allItemsChecked) setSelectedAll(true);
              else setSelectedAll(false);
            }}
            checked={foundChecked}
          />
        );
      },
    },
    {
      header: "Nome",
      accessorKey: "name",
      cell: ({ row: { original } }) => <Avatar name={original?.name} />,
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row: { original } }) => {
        const status = original?.status || "";
        return (
          <StatusTag status={status} variant="primary">
            {status === "done"
              ? "Concluído"
              : status === "doing"
              ? "Em andamento"
              : "Não iniciado"}
          </StatusTag>
        );
      },
    },
    {
      header: "Data da conclusão",
      accessorKey: "answerDate",
      cell: ({ row: { original } }) => {
        const date = original?.date || "";
        return (
          <DateArea>
            {date ? (
              <>
                <GradeTitle variant="body3">
                  {dayjs(date)?.format("DD/MM/YYYY")}
                </GradeTitle>
                <GradeDescription variant="body3">
                  {dayjs(date)?.format("HH:mm")}
                </GradeDescription>
              </>
            ) : (
              <>-</>
            )}
          </DateArea>
        );
      },
    },
    {
      header: "Quantidade de acertos",
      accessorKey: "qtdeOfRights",
      cell: ({ row: { original } }) => {
        const {
          status,
          totalRightQuestions = 0,
          totalQuestions = 0,
        } = original;
        return status !== "beforeInit" ? (
          <GradeArea>
            <Icons name="IconReportAnalytics" fill="transparent" />
            <div>
              <GradeTitle variant="body3">
                {totalRightQuestions}/{totalQuestions}
              </GradeTitle>
              <GradeDescription variant="body3">
                {totalQuestions} {totalQuestions > 1 ? "questões" : "questão"}
              </GradeDescription>
            </div>
          </GradeArea>
        ) : (
          <>-</>
        );
      },
    },
    {
      header: "Aprovação",
      accessorKey: "approval",
      cell: ({ row: { original } }) => {
        const status = original?.approved ? "done" : "";

        return (
          <StatusTag status={status} variant="primary">
            {original?.approved ? "Sim" : "Não"}
          </StatusTag>
        );
      },
    },
    {
      header: "Ações",
      sticky: "right",
      accessorKey: "actions",
      cell: ({ row: { original } }) => {
        const { _id, learningObjectId, status } = original;
        const options = [
          {
            onClick: () => {
              navigate(`/lms/manage-courses/detail-student/${_id}`);
            },
            children: (
              <OptionArea>
                <Icons fill="transparent" name="IconUser" />
                <StyledText variant="body3">Ver detalhes do aluno</StyledText>
              </OptionArea>
            ),
          },
        ];

        if (status != "beforeInit") {
          options.push(
            {
              onClick: () => {
                navigate(
                  `/lms/manage-courses/detail-exam/${courseId}/${learningObjectId}/${_id}`
                );
              },
              children: (
                <OptionArea>
                  <Icons fill="transparent" name="IconReportAnalytics" />
                  <StyledText variant="body3">Ver gabarito do aluno</StyledText>
                </OptionArea>
              ),
            },
            {
              onClick: () => {
                setRedoExamModal(true);
                setSelected([_id]);
              },
              children: (
                <OptionArea>
                  <Icons fill="transparent" name="IconRestore" size={24} />
                  <StyledText variant="body3">
                    Reiniciar avaliação do aluno
                  </StyledText>
                </OptionArea>
              ),
            }
          );
        } else {
          options.push({
            onClick: () => {
              setRedoExamModal(true);
              setSelected([_id]);
            },
            children: (
              <OptionArea>
                <Icons fill="transparent" name="IconRestore" size={24} />
                <StyledText variant="body3">
                  Reiniciar avaliação do aluno
                </StyledText>
              </OptionArea>
            ),
          });
        }

        return (
          <>
            <Menu
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              type="select"
              options={options}
            >
              <IconButton size="small" variant="line" icon="IconDotsVertical" />
            </Menu>
          </>
        );
      },
    },
  ] as any;

  const table = tableControllers.useTableColumns<any>({
    columns: columns,
    data: exams || [],
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  const finalRows = table.rows.slice(
    (pagination?.pageNumber - 1) * pagination?.pageSize,
    pagination?.pageNumber * pagination?.pageSize
  );

  useEffect(() => {
    const filtered = rawExams?.filter(({ learningObjectId }) =>
      filters?.learningObjectId ? learningObjectId === exam : true
    );

    const orderedExams = filtered?.sort((a, b) => {
      if (a?.name < b?.name) return -1;
    });

    let filteredArray = orderedExams;

    if (search) {
      const cleanValue = search.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
      const regex = new RegExp(cleanValue, "gi");

      filteredArray = orderedExams?.filter(({ name = "" }) =>
        name
          ?.toLowerCase()
          ?.normalize("NFD")
          ?.replace(/[\u0300-\u036f]/g, "")
          ?.match(regex)
      );

      setExams(filteredArray);
    }

    if (Object.keys(filters)?.length) {
      if (filters?.status) {
        const selectedStatus = filters?.status;

        filteredArray = filteredArray?.filter(({ status }) =>
          selectedStatus?.includes(status)
        );
      }

      if (filters?.date) {
        filteredArray = filteredArray?.filter(
          ({ date }) =>
            dayjs(date)?.format("DD/MM/YYYY") ===
            dayjs(filters?.date)?.format("DD/MM/YYYY")
        );
      }

      setExams(filteredArray);
    }
  }, [filters, search, exam]);

  const handleFilterExams = (value) => {
    const filteredArray = rawExams?.filter(
      ({ learningObjectId }) => learningObjectId === value
    );

    setExam(value);
    setExams(filteredArray);
  };

  const emptyMessage =
    filters?.date || filters?.status?.length
      ? "O filtro aplicado não apresenta resultados."
      : "Nenhuma avaliação foi realizada.";

  return (
    <>
      <Table.Root>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StyledTitle variant="headline8" setColor="neutral30">
            Análise
          </StyledTitle>
          <CounterTag variant="primary">
            {exams?.length} iten{exams?.length > 1 ? "s" : ""}
          </CounterTag>
        </div>

        <StyledBetween>
          <StyledSelect
            label="Selecionar avaliação"
            options={learningObjects ?? []}
            onSelectChange={(e, { value }) => handleFilterExams(value)}
            value={exam}
          />

          <Table.Filters
            label="Filtrar por"
            filters={[
              <TagCheckboxFilter
                variant="secondary"
                badgeNumber={filters?.["status"]?.length || 0}
                hasLeftIcon={false}
                filterLabel={"Status"}
                options={[
                  {
                    label: "Concluído",
                    value: "done",
                  },
                  {
                    label: "Em andamento",
                    value: "doing",
                  },
                  {
                    label: "Não iniciado",
                    value: "beforeInit",
                  },
                ]}
                onClick={(e) => {
                  if (!e.length && !(filters?.["status"]?.length || 0)) return;

                  const stateUpdated = {
                    ...filters,
                    ["status"]: e,
                  };

                  if (!e.length) delete stateUpdated["status"];

                  setFilters(stateUpdated);
                }}
                showClearButton={true}
              />,
              <DatePicker
                name={"date"}
                label={"Data de conclusão"}
                onDateChange={(value) => {
                  const date =
                    value && dayjs(value).isValid() ? dayjs(value) : null;

                  if (!date) return;

                  const parsedValue = dayjs(value).isValid()
                    ? date.toISOString()
                    : null;

                  const stateUpdated = {
                    ...filters,
                    ["date"]: parsedValue,
                  };

                  setFilters(stateUpdated);
                }}
                fullWidth
              />,
            ]}
          />

          <Tooltip arrow title="Exportar relatório">
            <div>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
                icon="IconDownload"
                size="small"
                variant="line"
              />
            </div>
          </Tooltip>
        </StyledBetween>

        <Table.Grid.Root
          empty={{ message: emptyMessage }}
          loading={isRedoExam || loading}
        >
          {selected?.length > 0 ? (
            <tr
              className="data-grid-table-header-bulk-actions-container"
              role="row"
            >
              <th className="data-grid-table-header-bulk-actions">
                <Checkbox
                  onChange={() => {
                    if (!selectedAll) {
                      const allIds = rawExams?.map((r) => r?._id);
                      setSelected(allIds);
                    } else {
                      setSelected([]);
                    }

                    setSelectedAll(!selectedAll);
                  }}
                  checked={selected?.length == rawExams?.length}
                />
              </th>
              <th className="data-grid-table-header-bulk-actions">
                <Tag
                  variant="primary"
                  onClick={() => table.selectionHandler(console.log)}
                >
                  {selected.length} de {table.rows.length} selecionado
                  {table.rows.length > 1 ? "s" : ""}
                </Tag>
              </th>

              <th className="data-grid-table-header-bulk-actions">
                <LinkButton
                  variant="secondary"
                  onClick={() => {
                    setRedoExamModal(true);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icons name="IconRestore" size={16} />
                    <StyledText
                      variant="body4"
                      style={{ fontWeight: 700, marginLeft: "5px" }}
                    >
                      Reiniciar avaliação{" "}
                      {table.rows.length > 1 ? "dos alunos" : "do aluno"}
                    </StyledText>
                  </div>
                </LinkButton>
              </th>
            </tr>
          ) : (
            <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          )}

          {finalRows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>

        <Table.Pagination
          count={exams?.length || 0}
          onPaginationChange={({ pageSize, pageNumber }) =>
            setPagination({ ...pagination, pageSize, pageNumber })
          }
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>

      <GenerateExamsReportModal
        open={open}
        examId={exam}
        exams={learningObjects}
        onClose={() => setOpen(false)}
        reportType={"analysis"}
      />
      <RedoExamModal
        open={redoExamModal}
        onClose={() => setRedoExamModal(false)}
        employeesQuantity={selected?.length}
        loading={isRedoExam}
        handleClick={() => {
          redoExamMutate(
            {
              courseId: courseId,
              examId: exam,
              employees: selected,
            },
            {
              onError: (e: any) => {
                const monitoringsNotExists =
                  e?.data?.error === "MONITORING_NOT_EXISTS_ERROR";

                setSelected([]);
                setSelectedAll(false);
                setRedoExamModal(false);

                if (monitoringsNotExists) return;

                dispatchToast({
                  type: "error",
                  content: "Erro ao tentar reiniciar avaliação",
                });
              },
              onSuccess: () => {
                setSelected([]);
                setSelectedAll(false);
                setRedoExamModal(false);
                utils.exams.getEmployeeExamsMonitoring.invalidate();
                utils.exams.getEmployeeExamsAnswers.invalidate();
              },
            }
          );
        }}
      />
    </>
  );
};

import { Modal as ModalMui, ModalProps as ModalPropsMui } from "@mui/material";

import { ReactNode } from "react";

import "./styles.css";

export type ModalProps = {
  onClose: () => void;
  headerTitle?: string;
  headerSubTitle?: string;
  header?: ReactNode;
  footer?: ReactNode;
  icon?: ReactNode;
  allowBackdropClick?: boolean;
} & ModalPropsMui;

export const Modal = ({
  open,
  onClose,
  children,
  className,
  header,
  footer,
  icon,
  style,
  allowBackdropClick = true,
}: ModalProps) => {
  return (
    <ModalMui
      className={`${className} modal-custom-theme`}
      open={open}
      onClose={() => {
        allowBackdropClick && onClose();
      }}
      style={style}
    >
      <div className="modal-container">
        {icon && <div className="modal-icon-container">{icon}</div>}
        {header && <>{header}</>}
        <div className="modal-content-area">{children}</div>
        {footer && <>{footer}</>}
      </div>
    </ModalMui>
  );
};

import {
  CertificateModalArea,
  CloseButton,
  ModalPreviewArea,
  PreviewModal,
} from "./styled";

import CertificatePreview from "../../../CertificatePreview";

const PreviewCertificate = ({ onClose, open, data }) => {
  return (
    <PreviewModal onClose={onClose} open={open}>
      <>
        <CloseButton
          size="medium"
          variant="line"
          icon="IconX"
          onClick={onClose}
        />
        <CertificateModalArea>
          <ModalPreviewArea>
            <CertificatePreview data={data} />
          </ModalPreviewArea>
        </CertificateModalArea>
      </>
    </PreviewModal>
  );
};

export default PreviewCertificate;

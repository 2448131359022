import { IContext } from "./types";

const initialState: IContext = {
  loading: { urls: [] },
  modalInfo: {},
  classe: {},
  classes: [],
  learningObject: [],
  filteredClasses: [],
  filteredLearningObject: [],
  courseHierachy: [],
  companyGroups: [],
  companyEmployees: [],
  classToCreate: {},
  classUrl: {},
  examsMonitorings: [],
  createStepParam: {},
  mainStudy: { course: {}, modules: [] },
  myCourses: {
    courses: [],
    highlights: [],
  },
  myCoursesFiltered: {
    courses: [],
  },
  adminCourses: {
    filteredCourses: [],
  },
  certificatesFiltered: [],
  manageCourses: {
    metrics: {},
    courseMetrics: {},
  },
  employees: [],
  employee: {},
  employeeFiltered: {},
  certificates: [],
  certificate: {},
  defaultNotifications: {},
  manageTab: 0,
  dispatchAction: () => null,
};

export { initialState };

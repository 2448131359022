import { useSelectedCompany } from "@flash-tecnologia/hros-web-utility";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Icons,
  Toggle,
  Tooltip,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  AccordionSubTitle,
  AccordionTitle,
  Container,
  OptionArea,
  OptionContentArea,
  OptionTitleArea,
  PageTitle,
  RightQuestionsArea,
  Select,
  SelectArea,
  StyledBoxAdornment,
  StyledSectionContainer,
  StyledTitle,
  StyledTypography,
  TextArea,
} from "./styled";

import { trpc } from "@api/client";
import { PageTemplate } from "@components/PageTemplate";
import { Grid, IconButton, Skeleton } from "@mui/material";
import dispatchToast from "../../../../utils/dispatchToast";
import { track } from "../../../../utils/segment";
import { ConfirmCreateContent } from "../Modals/ConfirmCreateContentModal";

const OptionSwitch = ({
  title,
  info,
  optionTitle,
  optionDescription,
  settings,
  onChange,
  type,
  questions,
  associateCourses,
}) => {
  const listItens = questions?.map((_, index) => ({
    label: `${index + 1} de ${questions?.length}`,
    value: index + 1,
  }));

  return (
    <OptionArea>
      <OptionTitleArea>
        <Typography variant="body2">{title}</Typography>

        {info && (
          <Tooltip title={info} arrow placement="top">
            <IconButton>
              <Icons name="IconInfoCircle" size={18} fill={"transparent"} />
            </IconButton>
          </Tooltip>
        )}
      </OptionTitleArea>
      <OptionContentArea>
        <div>
          <Toggle
            checked={settings?.[type]}
            disabled={associateCourses > 0}
            onChange={({ target: { checked } }) => {
              let trackName = "";

              if (type === "passingScore")
                trackName =
                  "company_lms_testconfiguration_minimumgrade_clicked";
              else if (type === "showAnswerSheet")
                trackName = "company_lms_testconfiguration_testanswers_clicked";
              else if (type === "randomizeQuestions")
                trackName =
                  "company_lms_testconfiguration_randomquestions_clicked";
              else
                trackName =
                  "company_lms_testconfiguration_randomalternatives_clicked";

              track({
                name: trackName,
              });

              if (type === "passingScore")
                onChange((previous) => ({
                  ...previous,
                  [type]: checked === true ? 1 : 0,
                }));
              else {
                onChange((previous) => ({
                  ...previous,
                  [type]: checked,
                }));
              }
            }}
          />
        </div>
        <div>
          <Typography variant="body3">{optionTitle}</Typography>
          <Typography variant="body4">{optionDescription}</Typography>
        </div>
      </OptionContentArea>
      {type === "passingScore" && settings?.[type] > 0 ? (
        <RightQuestionsArea>
          <SelectArea>
            <Select
              label="Quantidade de questões corretas"
              fullWidth
              value={settings?.passingScore ?? 1}
              disabled={associateCourses > 0}
              onSelectChange={(e, { value }) =>
                onChange((previous) => ({
                  ...previous,
                  passingScore: value,
                }))
              }
              options={listItens}
            />
          </SelectArea>
        </RightQuestionsArea>
      ) : null}
    </OptionArea>
  );
};

export const ThirdStepExam = ({
  data,
  learningObject,
  type,
  contentType,
  steps,
  contentId,
  currentStep,
  updateContentMutate,
  isLoadingButton,
  isLoadingScreen,
}) => {
  const navigate = useNavigate();

  const { selectedCompany } = useSelectedCompany();

  const [associateCourses, setAssociateCourses] = useState(0);

  const [isShared, setIsShared] = useState(false);
  const [confirmCreateModal, setConfirmCreateModal] = useState<any>(false);

  const [classToCreate, setClassToCreate] = useState<any>({
    companyId: "",
    settings: {},
    questions: [],
    isShared: false,
  });

  const [settings, setSettings] = useState<any>({
    passingScore: 0,
    attempts: 1,
    showAnswerSheet: false,
    randomizeQuestions: false,
    randomizeOptions: false,
  });

  const { mutate: addExamConfigMutate, isLoading: isAddExamConfig } =
    trpc.exams.addExamConfig.useMutation({});

  useEffect(() => {
    if (Object.keys(data)?.length) {
      setClassToCreate({
        companyId: data?.companyId || "",
        settings: data?.settings || {},
        questions: data?.questions || [],
        isShared: data?.isShared || false,
      });
    }
  }, [data]);

  useEffect(() => {
    if (learningObject?.length) {
      const data = learningObject?.filter(({ _id }) => _id === contentId);
      setAssociateCourses(data[0]?.associateCourses ?? 0);
    }
  }, [learningObject]);

  useEffect(() => {
    if (Object.keys(classToCreate?.settings)?.length) {
      setSettings({
        ...classToCreate?.settings,
      });
    }

    setIsShared(classToCreate?.isShared);
  }, [classToCreate]);

  const isEditing = type === `edit`;
  const titleByType = isEditing ? "Editar avaliação" : "Criar avaliação";

  const options = [
    {
      title: "Nota mínima",
      info: "O valor mínimo são as questões obrigatórias.",
      optionTitle: "Exigir nota mínima de aprovação",
      optionDescription:
        "Ao selecionar esta opção, você exige quantas questões o aluno precisa acertar para ser aprovado na avaliação.",
      optionType: "passingScore",
    },
    {
      title: "Gabarito",
      info: "Mesmo selecionando esta opção, o gabarito não será exibido para alunos que não foram aprovados na avaliação.",
      optionTitle: "Exibir gabarito após a finalização",
      optionDescription:
        "Ao selecionar esta opção, as respostas das questões serão exibidas aos alunos que foram aprovados na avaliação.",
      optionType: "showAnswerSheet",
    },
    {
      title: "Questões",
      info: null,
      optionTitle: "Ordenar as questões de forma aleatória",
      optionDescription:
        "Evite a disseminação de respostas e colete dados mais precisos ordenando aleatoriamente as questões.",
      optionType: "randomizeQuestions",
    },
    {
      title: "Alternativas",
      info: null,
      optionTitle: "Ordenar as alternativas aleatoriamente",
      optionDescription:
        "Evite a disseminação de respostas e colete dados mais precisos ordenando aleatoriamente as alternativas.",
      optionType: "randomizeOptions",
    },
  ];

  const handleAddSettings = useCallback(
    (status) => {
      addExamConfigMutate(
        {
          _id: contentId,
          params: settings,
        },
        {
          onSuccess: () => {
            updateContentMutate(
              {
                contentId,
                params: {
                  isShared,
                  status,
                },
              },
              {
                onSuccess: async () => {
                  setConfirmCreateModal(true);
                },
                onError: (e: any) => {
                  const learningObjectNotExists =
                    e?.data?.error === "LEARNING_OBJECT_NOT_EXISTS_ERROR";

                  const message = learningObjectNotExists
                    ? "Objeto de aprendizado não existe."
                    : "Erro ao tentar atualizar o conteúdo, tente novamente mais tarde!";

                  dispatchToast({
                    type: "error",
                    content: message,
                  });
                },
              }
            );
          },
        }
      );
    },
    [settings, isShared]
  );

  return (
    <PageTemplate
      stepper={{
        steps: steps,
        activeStep: currentStep,
      }}
      footer={{
        cancelProps: {
          title: "Sair sem salvar",
          callback: () => {
            track({
              name: `company_lms_testconfiguration_exit_clicked`,
            });
            navigate("/lms/manage-courses");
          },
        },
        draftProps: {
          title: "Sair e salvar rascunho",
          hasToShow: data?.status != "published",
          disabled: !!isLoadingButton,
          callback: () => {
            track({
              name: `company_lms_testconfiguration_savedraft_clicked`,
            });
            handleAddSettings("draft");
          },
        },
        goBackProps: {
          title: (
            <>
              <Icons name="IconArrowLeft" fill="transparent" />
              Voltar
            </>
          ),
          disabled: !!isLoadingButton,
          callback: () => {
            navigate(
              `/lms/manage-courses/${type}/content/${contentType}/${
                currentStep - 1
              }/${contentId}`
            );
          },
        },
        confirmProps: {
          title: (
            <>
              Salvar
              <Icons name="IconArrowRight" fill="transparent" />
            </>
          ),
          loading: !!isLoadingButton,
          callback: () => {
            track({
              name: `company_lms_testconfiguration_createtest_clicked`,
            });
            handleAddSettings("published");
          },
        },
      }}
    >
      <Container>
        {isLoadingScreen ? (
          <Skeleton
            width="100%"
            height="80vh"
            animation="pulse"
            variant="rectangular"
            style={{ marginTop: "40px", marginBottom: "40px" }}
          />
        ) : (
          <>
            <PageTitle variant="headline6">{titleByType}</PageTitle>

            <Grid container>
              <Grid item sm={12} md={5} lg={4}>
                <div style={{ marginRight: "24px" }}>
                  <StyledTitle variant="headline7">Configurações</StyledTitle>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <StyledTypography variant="body3">
                      Configure a forma como deseja que o aluno realize a
                      avaliação.
                    </StyledTypography>
                  </div>
                </div>
              </Grid>

              <Grid item sm={12} md={7} lg={8}>
                <>
                  {options?.map(
                    (
                      {
                        title,
                        info,
                        optionTitle,
                        optionDescription,
                        optionType,
                      },
                      index
                    ) => (
                      <OptionSwitch
                        key={index}
                        title={title}
                        info={info}
                        optionTitle={optionTitle}
                        optionDescription={optionDescription}
                        settings={settings}
                        onChange={setSettings}
                        type={optionType}
                        questions={classToCreate?.questions}
                        associateCourses={associateCourses}
                      />
                    )
                  )}
                  <OptionArea>
                    <OptionTitleArea>
                      <Typography variant="body2">Tentativas</Typography>
                      <Tooltip
                        title="Caso a avaliação não tenha nota mínima para aprovação, o aluno será aprovado independente da quantidade de tentativas."
                        arrow
                        placement="top"
                      >
                        <IconButton>
                          <Icons
                            name="IconInfoCircle"
                            size={18}
                            fill={"transparent"}
                          />
                        </IconButton>
                      </Tooltip>
                    </OptionTitleArea>
                    <OptionContentArea>
                      <SelectArea>
                        <TextArea>
                          <Typography variant="body3">
                            Quantidade de tentativas
                          </Typography>
                          <Typography variant="body4">
                            Configure a quantidade de tentativas que o aluno
                            poderá realizar a avaliação. Por padrão, a
                            quantidade mínima de tentativas é de <b>apenas</b>{" "}
                            uma tentativa.
                          </Typography>
                        </TextArea>
                        <Select
                          label="Selecione"
                          fullWidth
                          value={settings?.attempts ?? 1}
                          disabled={associateCourses > 0}
                          onSelectChange={(e, { value }) =>
                            setSettings((previous) => ({
                              ...previous,
                              attempts: value,
                            }))
                          }
                          options={[
                            { label: "1 tentativa", value: 1 },
                            { label: "2 tentativas", value: 2 },
                            { label: "3 tentativas", value: 3 },
                            { label: "4 tentativas", value: 4 },
                            { label: "5 tentativas", value: 5 },
                          ]}
                        />
                      </SelectArea>
                    </OptionContentArea>
                  </OptionArea>
                  <StyledSectionContainer style={{ marginBottom: "20px" }}>
                    <AccordionTitle variant="headline8">
                      Compartilhar{" "}
                      {contentType === "exam"
                        ? "avaliação"
                        : contentType === "pdf"
                        ? "PDF"
                        : contentType === "article"
                        ? "artigo"
                        : contentType}{" "}
                      com todas as empresas do grupo
                    </AccordionTitle>

                    <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                      <AccordionSubTitle variant="body4">
                        Habilite o botão abaixo caso deseje que{" "}
                        {contentType === "exam"
                          ? "a avaliação"
                          : contentType === "pdf"
                          ? "o PDF"
                          : contentType === "article"
                          ? "o artigo"
                          : `o ${contentType}`}{" "}
                        seja compartilhad{contentType === "exam" ? "a" : "o"} e
                        adicionad
                        {contentType === "exam" ? "a" : "o"} para todas as
                        empresas vinculadas a sua organização.
                      </AccordionSubTitle>
                    </div>

                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <StyledBoxAdornment
                        title="Habilitar compartilhamento."
                        description={`Ao ativar esta opção, ${
                          contentType === "exam"
                            ? "a avaliação"
                            : contentType === "article"
                            ? "o artigo"
                            : contentType === "pdf"
                            ? "o PDF"
                            : `o ${contentType}`
                        } será automaticamente compartilhado com as contas de todas as empresas do grupo. Isso significa que todas as empresas terão acesso e permissão para editar este conteúdo. ${
                          contentType === "exam"
                            ? "A avaliação"
                            : contentType === "article"
                            ? "O artigo"
                            : contentType === "pdf"
                            ? "o PDF"
                            : `O ${contentType}`
                        } estará acessível na seção “Gerenciar Treinamentos”, na aba de conteúdos.`}
                        leftAdornment={
                          <Toggle
                            checked={isShared}
                            disabled={
                              classToCreate?.companyId != selectedCompany?.id ||
                              associateCourses > 0
                            }
                            onChange={({ target: { checked } }) => {
                              setIsShared(checked);
                            }}
                          />
                        }
                      />
                    </div>
                  </StyledSectionContainer>
                </>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      <ConfirmCreateContent
        open={!!confirmCreateModal}
        onClose={() => {
          setConfirmCreateModal(false);
        }}
      />
    </PageTemplate>
  );
};

import styled, { css } from "styled-components";

export const Container = styled.div<{ progress?: number }>`
  display: flex;
  justify-content: space-around;
  background: ${({ theme }) => theme.colors.neutral[100]};
  padding: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]} !important;
  border-bottom: 4px solid;
  border-image: linear-gradient(90deg, #f20d7a 10%, #ffcce4 0%, #ffcce4 100%) 1;

  ${({ theme, progress }) =>
    css`
      border-image: linear-gradient(
          90deg,
          ${theme.colors.secondary[50]} ${progress}%,
          ${theme.colors.secondary[90]} 0%,
          ${theme.colors.secondary[90]} 100%
        )
        1;
    `}
`;

import styled from "styled-components";

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const PreviewModal = styled(Modal)`
  .modal-content-area {
    overflow-x: hidden !important;
    padding: 0 !important;
  }
`;

export const ModalPreviewArea = styled.div`
  scale: 0.7;
  width: max-content;
  height: max-content;
  background-color: white;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 12px;
  z-index: 1;
  display: block;
`;

export const CertificateArea = styled.div`
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const CertificateModalArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

import styled from "styled-components";
import { Grid, MenuItem, Select } from "@mui/material";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledGrid = styled(Grid)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    .MuiGrid-root {
      flex-grow: 1;
    }
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const StyledMenuItem = styled(MenuItem)``;

export const EmptyZone = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  padding: 20px;
  background-color: ${({ theme }) => theme?.colors?.neutral[95]};
  svg {
    path:first-child {
      fill: none;
    }
  }
`;

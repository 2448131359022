import { track } from "@utils";
import { Content } from "./Content";
import { FooterBar } from "./Footer";
import { Container, ContentWrapper } from "./styled";
import { DoneExam } from "../components/Common";

export const Desktop = ({
  selected: {
    learningObjectId,
    name,
    description,
    questions,
    done,
    settings: { passingScore, showAnswerSheet, attempts },
  },
  isDone,
  aproved,
  examQuestions,
  handleSelectOption,
  attemptsLeft,
  answeredCounter,
  answeredCounterNotMandatory,
  allMandatoryAnswered,
  isSendingExamsAnsers,
  isPreview,
  handleSendExam,
  examInitiate,
  totalCount,
}) => {
  return (
    <Container>
      <ContentWrapper>
        <Content
          isDone={isDone}
          aproved={aproved}
          examQuestions={examQuestions}
          handleSelectOption={handleSelectOption}
          selected={{
            name,
            description,
            questions,
            settings: { passingScore, showAnswerSheet, attempts },
          }}
          attemptsLeft={attemptsLeft}
          examInitiate={examInitiate}
          totalCount={totalCount}
        />
      </ContentWrapper>

      <FooterBar
        isDone={isDone}
        totalQuestions={questions?.length}
        answeredQuestions={answeredCounter + answeredCounterNotMandatory}
        allMandatoryAnswered={allMandatoryAnswered}
        isLoading={isSendingExamsAnsers}
        isPreview={isPreview}
        onClick={() => {
          track({
            name: "employee_lms_course_test_sendtest_clicked",
          });
          handleSendExam({ examQuestions });
        }}
      />
    </Container>
  );
};

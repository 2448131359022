import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ButtonContainer,
  CloseButton,
  Description,
  Divider,
  Gap,
  StyledContainer,
  StyledFlexBetween,
  StyledIcon,
  StyledIconContainer,
  SubTitle,
  SuccessModal,
  Title,
} from "./styled";

const SuccessCertificate = ({ onClose, open, type }) => {
  return (
    <SuccessModal onClose={onClose} open={open}>
      <>
        <CloseButton
          size="medium"
          variant="line"
          icon="IconX"
          onClick={onClose}
        />
        <StyledContainer>
          <StyledFlexBetween>
            <StyledIconContainer>
              <StyledIcon size={48} name="IconMoodHappy" fill="transparent" />
            </StyledIconContainer>
            <Title variant="headline7">Pronto!</Title>
          </StyledFlexBetween>

          <SubTitle variant="headline6">
            Seu certificado foi {type === "edit" ? "editado" : "criado"} com
            sucesso! 😄
          </SubTitle>
          <Gap>
            <Description variant="body3">
              Parabéns! Seu novo modelo de certificado já está disponível para
              utilização.
            </Description>

            <div id={"modal_create_certificate_survey"} />
          </Gap>
        </StyledContainer>
        <Divider />
        <ButtonContainer>
          <Button onClick={onClose} size="large" variant="primary">
            Voltar para Configurações gerais de treinamentos
          </Button>
        </ButtonContainer>
      </>
    </SuccessModal>
  );
};

export default SuccessCertificate;

import { NavigateOptions } from "react-router-dom";

import { Container, StyledStepper } from "./styled";

interface RoutesProps {
  route?: string;
  label: string;
  options?: NavigateOptions;
}

interface StepperProps {
  steps: string[];
  activeStep: number;
}

interface HeaderProps {
  routes?: RoutesProps[];
  customRightHeader?: any;
  stepper?: StepperProps;
}

export const Header = ({ stepper, customRightHeader = <></> }: HeaderProps) => {
  return (
    <Container>
      <StyledStepper
        steps={stepper?.steps || []}
        activeStep={stepper?.activeStep}
      />
      {customRightHeader}
    </Container>
  );
};

import styled from "styled-components";

import { IconButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const StyledModal = styled(Modal)`
  .modal-container {
    min-width: 80% !important;
  }
  .modal-content-area {
    padding: 0 !important;
  }
`;

export const ModalHeader = styled.div`
  padding: 40px 112px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 40px;
  position: relative;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 12px;
  z-index: 1;
  display: block;
`;

export const Title = styled(Typography)`
  font-weight: 700 !important;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-bottom: 8px;
`;

export const Description = styled(Typography)`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const ModalBody = styled.div`
  padding: 0 112px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const ModalFooter = styled.div`
  padding: 24px 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    align-self: center !important;
  }
`;

import { Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledContainer,
  StyledFlexCenter,
  StyledLoader,
  StyledTextLoading,
  ModalHeader,
  ModalDeleteTitle,
  CloseButton,
  ModalTitle,
  ModalSubTitle,
  DeleteButton,
  ModalFooterContainer,
  ModalBody,
  DeleteArea,
} from "./styled";

import { trpc } from "@api/client";

import { dispatchToast } from "@utils";

export const WarningDeleteModal2 = ({
  modal: { open, level = "", _id = "", courseId = "", setModal, variant },
}) => {
  const utils = trpc.useContext();
  const closeModal = () => {
    setModal({
      open: false,
      type: null,
      parentId: null,
      variant: null,
      level: null,
      _id: null,
      title: null,
    });
  };

  const { mutate: deleteMutate, isLoading } =
    trpc.course.deleteNodeCourseHierarchy.useMutation({
      onSuccess: () => {
        utils.course.getCourseHierarchy.invalidate();
        closeModal();
        dispatchToast({ content: "Apagado com sucesso", type: "success" });
      },
    });

  return (
    <StyledModal isLoading={isLoading} onClose={closeModal} open={open}>
      {isLoading ? (
        <DeleteArea>
          <StyledFlexCenter style={{ flexDirection: "column" }}>
            <StyledLoader variant="primary" size="large" />
            <StyledTextLoading variant="body2">Excluindo...</StyledTextLoading>
          </StyledFlexCenter>
        </DeleteArea>
      ) : (
        <>
          <ModalHeader>
            <CloseButton
              size="small"
              variant="line"
              icon="IconX"
              onClick={closeModal}
            />
            <StyledIconContainer>
              <StyledIcon size={48} name="IconAlertCircle" fill="transparent" />
            </StyledIconContainer>
            <ModalDeleteTitle variant="body3">Atenção!</ModalDeleteTitle>
          </ModalHeader>
          <ModalBody>
            <ModalTitle variant="headline6">
              Tem certeza que deseja excluir est
              {variant === "deleteCourse"
                ? "e treinamento"
                : variant === "deleteModule"
                ? "e módulo"
                : "a aula"}
              ?
            </ModalTitle>
          </ModalBody>
          <StyledContainer>
            <ModalSubTitle variant="body3">
              {variant === "deleteCourse" ? (
                <>
                  Os módulos e aulas contidos neste treinamento também serão
                  excluídos.
                </>
              ) : variant === "deleteModule" ? (
                <>Todas as aulas contidas no módulo também serão excluídas.</>
              ) : null}
            </ModalSubTitle>
            <ModalFooterContainer>
              <LinkButton variant="secondary" onClick={closeModal}>
                Cancelar
              </LinkButton>
              <DeleteButton
                size="large"
                variant="primary"
                onClick={() => {
                  deleteMutate(
                    {
                      params: { nodeId: _id, level },
                      courseId,
                    },
                    {
                      onError: (e: any) => {
                        const courseHierarchyNotExists =
                          e?.data?.error ===
                          "COURSE_HIERARCHY_NOT_EXISTS_ERROR";

                        const message = courseHierarchyNotExists
                          ? "Não existe o curso."
                          : "Erro ao tentar deletar a hierarquia do curso, tente novamente mais tarde!";

                        dispatchToast({
                          type: "error",
                          content: message,
                        });
                      },
                    }
                  );
                }}
              >
                {variant === "deleteCourse" ? (
                  <>Sim, excluir treinamento</>
                ) : variant === "deleteModule" ? (
                  <>Sim, excluir módulo</>
                ) : (
                  <>Sim, excluir aula</>
                )}
                <Icons name="IconTrash" fill="transparent" />
              </DeleteButton>
            </ModalFooterContainer>
          </StyledContainer>
        </>
      )}
    </StyledModal>
  );
};

import styled from "styled-components";

import { Button, IconButton } from "@flash-tecnologia/hros-web-ui-v2";

export const ContainerBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  padding: 0 40px;
  width: 100%;
  height: 100%;
`;

export const SubContainerBlock = styled.div`
  max-width: 647px;
  display: flex;
  padding: 40px 88px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: 12px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.06);
`;

export const IconButtonLock = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.secondary[95]};

      svg {
        stroke: ${({ theme }) => theme.colors.secondary[50]};
        width: 60px !important;
        height: 60px !important;
      }
    }
  }
`;

export const BlockButton = styled(Button)`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const BlockDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
  margin-top: 24px;
  margin-bottom: 24px;
`;

import styled from "styled-components";
import { Typography, IconButton } from "@flash-tecnologia/hros-web-ui-v2";

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-radius: 12px;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  cursor: pointer;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.secondary.light.light1};

  &:hover {
    opacity: 0.5;
  }
`;

const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LabelTitle = styled(Typography)`
  && {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary.dark.dark5};
    margin-top: 16px;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 48px;
  height: 48px;

  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.secondary.light.light2};

      &.size-medium {
        svg {
          width: 24px;
          height: 24px;
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export { Container, StyledFlexCenter, LabelTitle, StyledIconButton };

import {
  ExamView,
  PDFVisualizer,
  BlockNavigation,
  Description,
  Tabs,
} from "../../../Common";

import { ContentContainer, ArticleViewer } from "./styled";
import { Player } from "@components/Player";

export const Content = ({
  classId,
  course,
  viewType,
  modules,
  moduleId,
  courseId,
  selected,
  setSelected,
  isDescription,
  setIsExpanded,
  donePdfArticle,
  exams: { handleDoneContent, isDone, setIsDone },
  isMobile,
}) => {
  const isNotDisabled = selected && !!!selected?.disabled;

  const renderComponent = {
    pdf: (
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          height: "640px",
        }}
      >
        <PDFVisualizer
          path={`${selected?.url?.path}${selected?.url?.signature ?? null}`}
          setIsExpanded={setIsExpanded}
        />
        {donePdfArticle()}
      </div>
    ),
    exam: (
      <ExamView
        selected={selected}
        handleDoneContent={handleDoneContent}
        setIsDone={setIsDone}
        isDone={isDone}
        viewType={viewType}
        isMobile={isMobile}
      />
    ),
    article: (
      <>
        <ArticleViewer
          dangerouslySetInnerHTML={{
            __html:
              selected?.content?.html ||
              selected?.url?.path?.html ||
              "Não foi possível visualizar",
          }}
        />
        {donePdfArticle()}
      </>
    ),
    media: (
      <Player
        mediaToPlay={{
          uri:
            selected?.type == "podcast" && selected?.url?.origin === "internal"
              ? selected?.url?.path + selected?.url?.signature
              : selected?.url?.origin === "internal"
              ? selected?.url?.signedMp4
              : selected?.url?.path || "",
          signature: selected?.url?.signature || "",
        }}
        selected={selected}
        setSelected={setSelected}
      />
    ),
  };

  const isNotExam = selected?.type != "exam";

  return (
    <>
      <BlockNavigation
        viewType={viewType}
        modules={modules}
        moduleId={moduleId}
        courseId={courseId}
        blockNavigation={!!course?.blockNavigation}
        classId={classId}
        selected={selected}
      />

      {isDescription && (
        <ContentContainer>
          <Description course={course} />
        </ContentContainer>
      )}

      {isNotDisabled ? (
        <ContentContainer type={selected?.type}>
          {
            renderComponent[
              ["podcast", "video"].includes(selected?.type)
                ? "media"
                : selected?.type
            ]
          }
          {isNotExam && <Tabs selected={selected} defaultTab={0} />}
        </ContentContainer>
      ) : (
        <></>
      )}
    </>
  );
};

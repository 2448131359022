import styled from "styled-components";

import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { IAnswer, IQuestion } from "./types";

export const ContentArea = styled.div<{ disabledMargin?: boolean }>`
  padding: 40px 40px 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  margin: ${({ disabledMargin }) => (disabledMargin ? "0px 0 24px" : "24px 0")};
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0;
    border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
    box-shadow: none;
  }
`;

export const InfoQuestionArea = styled.div<IQuestion>`
  display: flex;
  margin-bottom: ${({ isFeedback, isMandatory }) =>
    isFeedback || isMandatory ? "16px" : "0"};
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    margin-bottom: 0;
  }
`;

export const FeedbackInfo = styled(Typography)<IAnswer>`
  font-weight: bold !important;
  color: ${({ rightAnswer, theme }) =>
    rightAnswer ? theme.colors.feedback.success.dark2 : "#C96C01"};
  background: ${({ rightAnswer, theme }) =>
    rightAnswer
      ? theme.colors.feedback.success.light1
      : theme.colors.feedback.error.light1};
  padding: 4px 8px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  svg {
    margin-right: 4px;
  }
`;

export const MandatoryInfo = styled(Typography)`
  color: #c96c01;
  font-weight: bold !important;
`;

export const QuestionTitle = styled.div`
  font-weight: 700;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  display: flex;
  p {
    font-weight: bold !important;
  }
  p:first-child {
    margin-right: 20px;
  }
`;

export const AnswerArea = styled.div<IAnswer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 12px 32px;
  margin: 4px 0;
  background: ${({ rightAnswer, wrongAnswer, theme }) =>
    rightAnswer
      ? theme.colors.feedback.success.light1
      : wrongAnswer
      ? theme.colors.feedback.error.light1
      : "none"};
  border-radius: 12px;
  div:first-child {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 12px 16px 12px 16px;
  }
`;

export const AnswerText = styled(Typography)<IAnswer>`
  color: ${({ rightAnswer, wrongAnswer, theme }) =>
    rightAnswer
      ? theme.colors.feedback.success.dark2
      : wrongAnswer
      ? "#C96C01"
      : theme.colors.neutral.dark.dark4};
  font-weight: bold !important;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    line-break: anywhere;
  }
`;

export const AnswerIcon = styled(Icons)<IAnswer>`
  color: ${({ rightAnswer, wrongAnswer, theme }) =>
    rightAnswer
      ? theme.colors.feedback.success.dark2
      : wrongAnswer
      ? "#C96C01"
      : theme.colors.neutral.dark.dark4};
`;

export const MobileQuestionCount = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding: 12px 16px;
`;

export const MobileQuestionArea = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 16px;
  }
`;

export const MobileQuestionNumber = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary[50]};
  line-height: 12px;
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 24px;
  padding: 4px 8px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid #ebe6e9;
`;

export const AnswerLabel = styled(Typography)`
  color: var(--neutral-neutral-30, #53464f);
  font-weight: 700 !important;
  margin-top: 16px;
  margin-bottom: 12px;
`;

export const RightAnswerArea = styled(Typography)`
  color: var(--neutral-neutral-40, #6b5b66);
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  background: var(--neutral-neutral-95, #f8f6f8);
  border-radius: 12px;
  padding: 16px 24px;
`;

import { useState, useEffect } from "react";

import { SearchContainer, StyledSearch } from "./styled";

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

export const Search = ({ onChange, disabled, isMobile, setOpenFilters }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inputValue === undefined) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [inputValue]);

  const handleOnChange = (value) => {
    if (onChange) {
      setInputValue(value);
    }
  };

  const handleOnBlur = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };
  const handleOnInput = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };

  return (
    <SearchContainer>
      <StyledSearch
        onChange={(e) => handleOnChange(e.target.value)}
        onSearchChange={handleOnBlur}
        onInputChange={handleOnInput}
        label="Buscar por treinamento"
        disabled={disabled}
      />
      {isMobile ? (
        <IconButton
          variant="line"
          size="medium"
          icon="IconFilter"
          style={{ marginLeft: "16px", alignSelf: "center" }}
          onClick={() => setOpenFilters(true)}
          disabled={disabled}
        />
      ) : (
        <></>
      )}
    </SearchContainer>
  );
};

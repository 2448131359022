import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  dayjs,
  IconButton,
  PageContainer,
  PageHeader,
  Skeleton,
  Tab,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Tooltip } from "@mui/material";

import {
  EmployeeInfoArea,
  EmployeeName,
  EmployeePic,
  FilterArea,
  HeaderArea,
  LoadArea,
  NameInfoArea,
  StatusTag,
  StyledContainer,
  StyledSearchField,
} from "./styles";

import StudentMetrics from "../../components/StudentMetrics";
import { StudentDetailTableView } from "../../components/StudentDetailTableView";
import { track } from "../../utils/segment";
import { CourseFilter } from "./CourseFilter";
import { trpc } from "@api/client";
import { GenerateStudentReportModal } from "@components/GenerateStudentReportModal";
import { StudentExamTableView } from "@components/StudentExamTableView";
import { ExamFilter } from "./ExamFilter";

export const DetailStudent = () => {
  const [content, setContent] = useState([]);
  const [open, setOpen] = useState(false);
  const [employee, setEmployee] = useState<any>({});
  const [employeeFiltered, setEmployeeFiltered] = useState<any>([]);
  const [exams, setExams] = useState<any>([]);
  const [filteredExams, setFilteredExams] = useState<any>([]);
  const [coursesToFilter, setCoursesToFilter] = useState<any>([]);

  const [filters, setFilters] = useState({
    status: null,
    mandatory: null,
    certificate: null,
    start: null,
    end: null,
  });

  const [examFilters, setExamFilters] = useState<any>({
    status: [],
    course: [],
    doneAt: [],
  });

  const { employeeId = "" } = useParams();

  const { isFetching: isGettingEmployeeById } =
    trpc.employee.getEmployeeById.useQuery(
      { employeeId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!employeeId,
        onSuccess: (data) => {
          setEmployee(data);
          setContent(data?.courses);
        },
      }
    );

  const { isFetching: isGettingExams } =
    trpc.exams.getEmployeeExamsById.useQuery(
      { employeeId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!employeeId,
        onSuccess: (data) => {
          setExams(data);
          setFilteredExams(data);
        },
      }
    );

  const {
    isFetching: isGettingEmployeeByIdFiltered,
    refetch: refetchEmployeeByIdFiltered,
  } = trpc.employee.getEmployeeByIdFiltered.useQuery(
    { employeeId, filters },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!employeeId,
      onSuccess: (data) => {
        setEmployeeFiltered(data);
      },
    }
  );

  useEffect(() => {
    setContent(employee?.courses);
  }, [employee]);

  useEffect(() => {
    refetchEmployeeByIdFiltered();
  }, [filters]);

  useEffect(() => {
    if (
      !examFilters?.status?.length &&
      !examFilters?.course?.length &&
      !examFilters?.doneAt?.length
    ) {
      setFilteredExams(exams);
      return;
    }

    if (examFilters?.status?.length) {
      setFilteredExams(
        exams?.filter(({ status }) => examFilters?.status?.includes(status))
      );
    }
    if (examFilters?.course?.length) {
      setFilteredExams(
        exams?.filter(({ courseId }) => examFilters?.course?.includes(courseId))
      );
    }
    if (examFilters?.doneAt?.length) {
      setFilteredExams(
        exams?.filter(({ doneDate }) =>
          examFilters?.doneAt?.includes(dayjs(doneDate)?.format("MM/DD/YYYY"))
        )
      );
    }
  }, [examFilters]);

  useEffect(() => {
    const courses = exams?.map(({ courseId, courseName }) => ({
      value: courseId,
      label: courseName,
    }));

    const removeDup = courses?.filter(
      (value, index, self) =>
        index ===
        self?.findIndex(({ value: itemValue }) => itemValue === value?.value)
    );

    setCoursesToFilter(removeDup);
  }, [exams]);

  useEffect(() => {
    const courses = employeeFiltered?.courses;
    setContent(courses);
  }, [employeeFiltered]);

  const handleFilterContent = useCallback(
    (value) => {
      if (value?.length) {
        const cleanValue = value.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
        const regex = new RegExp(cleanValue, "gi");
        setContent(() =>
          employee?.courses?.filter(({ name }) => name?.match(regex))
        );
        return;
      } else {
        setContent(employee?.courses);
        return;
      }
    },
    [content]
  );

  const handleFilterExam = useCallback(
    (value) => {
      if (value?.length) {
        const cleanValue = value.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
        const regex = new RegExp(cleanValue, "gi");
        setFilteredExams(() =>
          exams.filter(({ examName }) => examName?.match(regex))
        );
        return;
      } else {
        setFilteredExams(exams);
        return;
      }
    },
    [exams]
  );

  const handleSetFilter = (filterName, value) => {
    setFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  const handleSetExamFilter = (filterName, value) => {
    setExamFilters((prev) => ({ ...prev, [filterName]: value }));
  };

  return isGettingEmployeeById || isGettingExams ? (
    <>
      <PageContainer>
        <LoadArea>
          <Skeleton variant="rectangular" width={"100%"} height={"100vh"} />
        </LoadArea>
      </PageContainer>
    </>
  ) : (
    <>
      <PageHeader>
        <HeaderArea>
          <EmployeeInfoArea>
            <EmployeePic>
              {employee?.name?.charAt(0)?.toUpperCase()}
            </EmployeePic>
            <div>
              <NameInfoArea>
                <EmployeeName variant="headline6">
                  {employee?.name}
                </EmployeeName>
                <StatusTag variant="green">
                  <p>•</p>Ativo
                </StatusTag>
              </NameInfoArea>
            </div>
          </EmployeeInfoArea>
        </HeaderArea>
      </PageHeader>
      <PageContainer>
        <StudentMetrics employee={employee} />
        <Tab
          tabItens={[
            {
              label: `Treinamentos (${content?.length})`,
              component: (
                <StyledContainer>
                  <>
                    <StyledSearchField
                      placeholder="Buscar por treinamento"
                      label={"Buscar por treinamento"}
                      onChange={async ({ target: { value } }) => {
                        handleFilterContent(value);
                      }}
                      style={{ marginBottom: "24px" }}
                    />
                    <FilterArea>
                      <CourseFilter
                        handleSetFilter={handleSetFilter}
                        optionsFilters={filters}
                      />
                      <Tooltip arrow title="Exportar relatório">
                        <div>
                          <IconButton
                            onClick={() => {
                              track({
                                name: "company_lms_studentdetail_exportreport_clicked",
                              });
                              setOpen(true);
                            }}
                            icon="IconDownload"
                            size="small"
                            variant="line"
                          />
                        </div>
                      </Tooltip>
                    </FilterArea>
                    <StudentDetailTableView content={content} />
                  </>
                </StyledContainer>
              ),
            },
            {
              label: `Avaliações (${exams?.length})`,
              component: (
                <StyledContainer>
                  <>
                    <StyledSearchField
                      placeholder="Buscar por avaliação"
                      label={"Buscar por avaliação"}
                      onChange={async ({ target: { value } }) => {
                        handleFilterExam(value);
                      }}
                      style={{ marginBottom: "24px" }}
                    />
                    <FilterArea>
                      <ExamFilter
                        handleSetFilter={handleSetExamFilter}
                        optionsFilters={examFilters}
                        coursesToFilter={coursesToFilter}
                      />
                    </FilterArea>
                    <StudentExamTableView content={filteredExams} />
                  </>
                </StyledContainer>
              ),
            },
          ]}
        />
      </PageContainer>

      <GenerateStudentReportModal open={open} onClose={() => setOpen(false)} />
    </>
  );
};

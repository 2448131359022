import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Icons,
  LinkButton,
  Loader,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  CloseButton,
  HeaderArea,
  StyledButtonContainer,
  StyledContainer,
  StyledIcon,
  StyledIconContainer,
  StyledModal,
  StyledSubTitle,
  StyledTitle,
  SuccessMessage,
} from "./styled";

import { trpc } from "@api/client";
import { track } from "../../utils/segment";

export const GenerateTableReportModal = ({ open, onClose, type = "exams" }) => {
  const { courseId = "" } = useParams();

  const [employee, setEmployee] = useState<any>({});
  const [successs, setSuccess] = useState<any>(false);
  const [hasClicked, setHasClicked] = useState<any>(false);

  const handleClose = () => {
    setSuccess(false);
    onClose();
  };

  const { isFetching: isFetchingEmployee } =
    trpc.employee.getEmployeeByIdFromCompany.useQuery(undefined, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setEmployee(data);
      },
    });

  const { isFetching: isGettingExamReport } =
    trpc.reports.getExamCourseReportById.useQuery(
      { courseId },
      {
        enabled: type === "exams" && hasClicked,
        onSuccess: () => {
          setSuccess(true);
          setHasClicked(false);
        },
      }
    );

  const { isFetching: isGettingCourseReport } =
    trpc.reports.getCourseReportById.useQuery(
      { courseId },
      {
        enabled: type === "student" && hasClicked,
        onSuccess: () => {
          setSuccess(true);
          setHasClicked(false);
        },
      }
    );

  return (
    <StyledModal
      isLoading={isFetchingEmployee}
      onClose={handleClose}
      open={open}
    >
      <>
        <HeaderArea>
          <StyledIconContainer>
            <StyledIcon
              size={48}
              name={successs ? "IconCheck" : "IconTable"}
              fill="transparent"
            />
          </StyledIconContainer>
          {successs ? (
            <SuccessMessage variant="body3">Pronto!</SuccessMessage>
          ) : null}
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </HeaderArea>
        <StyledContainer>
          <StyledTitle variant="headline6">
            {successs
              ? "Seu relatório está sendo exportado!"
              : "Deseja exportar o relatório?"}
          </StyledTitle>
          {successs ? (
            <StyledSubTitle variant="body3">
              Para acessá-lo é só conferir o e-mail que será enviado em
              <br />
              alguns minutos para o endereço:
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>
                4 primeiros dígitos do <br />
                seu CPF
              </b>{" "}
              como senha.
            </StyledSubTitle>
          ) : (
            <StyledSubTitle variant="body3">
              O relatório apresentará somente os{" "}
              <b>
                dados dessa tabela
                <br />
              </b>{" "}
              convertidos em um arquivo .csv.
              <br /> O arquivo será enviado para o email:
              <br />
              <br />
              <b>{employee?.corporateEmail || employee?.email || ""}</b>
            </StyledSubTitle>
          )}
        </StyledContainer>
        <StyledButtonContainer>
          {successs ? (
            <Button size="large" variant="primary" onClick={handleClose}>
              Voltar para Detalhes do Treinamento
            </Button>
          ) : (
            <>
              <LinkButton
                variant="primary"
                onClick={() => {
                  if (type === "exams") {
                    track({
                      name: "company_lms_testsreport_cancel_clicked",
                    });
                  } else {
                    track({
                      name: "company_lms_performancereport_cancel_clicked",
                    });
                  }
                  handleClose();
                }}
              >
                Cancelar
              </LinkButton>
              <Button
                size="large"
                variant="primary"
                loading={false}
                onClick={async () => {
                  if (type === "exams") {
                    track({
                      name: "company_lms_testsreport_export_clicked",
                    });
                  } else {
                    track({
                      name: "company_lms_performancereport_export_clicked",
                    });
                  }
                  setHasClicked(true);
                }}
              >
                {isGettingCourseReport || isGettingExamReport ? (
                  <Loader size="extraSmall" variant="secondary" />
                ) : (
                  <>
                    Exportar relatório
                    <Icons name="IconDownload" fill="transparent" />
                  </>
                )}
              </Button>
            </>
          )}
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

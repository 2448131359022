import "./styles.css";

import { Controls } from "@vidstack/react";
import { Sound, SoundSlider, Play } from "./components/Buttons";
import { SliderTimeWithTimeGroup } from "./components/SliderTimeWithTimeGroup";
import { SpeedMenu } from "./components/Menu";

export function AudioLayout() {
  return (
    <>
      <Controls.Root style={{ width: "100%", padding: "24px" }}>
        <Controls.Group className={`audioControlsGroup`}>
          <Play />
          <SliderTimeWithTimeGroup />
          <SpeedMenu placement="bottom end" />
          <Sound />
          <SoundSlider />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

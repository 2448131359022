import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  IconButton,
  Menu,
  Pagination,
  Tooltip,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";

import { StyledText, pageSizeOptions } from "@utils";

import {
  PaginationArea,
  ItensPerPageArea,
  StyledSelectField,
  PaginationContainer,
  StyledMenuItem,
  StyledTypography,
  BannerArea,
  PreviewArea,
  BannerTitle,
  StatusTag,
  OptionArea,
  StyledIconButton,
} from "./styled";

import CertificatePreview from "@components/CertificatePreview";
import { DeleteCertiticate } from "../Modals/DeleteCertificate";
import { PreviewCertificate } from "../Modals/PreviewCertificate";

interface TableGridProps {
  data: any[];
  rawData: any[];
  page: number;
  setPage: any;
  pageSize: number;
  setPageSize: any;
  total: number;
  loading: boolean;
}

export const CertificateTable = ({
  data,
  rawData,
  loading,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
}: TableGridProps) => {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [previewData, setPreviewData] = useState<any>({});

  const columns = [
    {
      Header: "Certificados",
      accessor: "certificates",
      disableSortBy: true,
      Cell: ({
        row: {
          original: {
            name = "",
            companyLogo = null,
            companyName = null,
            signText = null,
            signerName = null,
            signerPostion = null,
            expiresDate = null,
          },
        },
      }) => {
        return (
          <BannerArea>
            <PreviewArea>
              <CertificatePreview
                data={{
                  companyLogo,
                  companyName,
                  signText,
                  signerName,
                  signerPostion,
                  expiresDate,
                }}
              />
            </PreviewArea>
            <BannerTitle variant="body3">{name}</BannerTitle>
          </BannerArea>
        );
      },
    },
    {
      Header: "Treinamentos associados",
      accessor: "associateCourses",
      disableSortBy: true,
      Cell: ({ value = 0 }) => {
        return <div>{value}</div>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value = "" }) => (
        <StatusTag variant="body3" status={value}>
          {value === "published" ? "Publicado" : "Rascunho"}
        </StatusTag>
      ),
    },
    {
      Header: "Criado em",
      accessor: "createdAt",
      disableSortBy: true,
      Cell: ({ value }) => (
        <BannerTitle variant="body3">
          {value ? dayjs(value).format("DD/MM/YYYY") : "-"}
        </BannerTitle>
      ),
    },
    {
      Header: "Ações",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({
        row: {
          original: {
            _id,
            name = "",
            companyLogo = null,
            companyName = null,
            signText = null,
            signerName = null,
            signerPostion = null,
            expiresDate = null,
            associateCourses = 0,
          },
        },
      }) => {
        return (
          <>
            {associateCourses > 0 ? (
              <Tooltip title="Ver certificado">
                <IconButton
                  onClick={() => {
                    setPreviewModal(true);
                    setPreviewData({
                      name,
                      companyLogo,
                      companyName,
                      signText,
                      signerName,
                      signerPostion,
                      expiresDate,
                    });
                  }}
                  size="small"
                  variant="line"
                  icon="IconEye"
                />
              </Tooltip>
            ) : (
              <Menu
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                type="select"
                options={[
                  {
                    onClick: (e: any) => {
                      setPreviewModal(true);
                      setPreviewData({
                        name,
                        companyLogo,
                        companyName,
                        signText,
                        signerName,
                        signerPostion,
                        expiresDate,
                      });
                    },
                    children: (
                      <OptionArea>
                        <StyledIconButton
                          size="small"
                          variant="line"
                          icon="IconEye"
                        />
                        <StyledText variant="body3">
                          Exibir certificado
                        </StyledText>
                      </OptionArea>
                    ),
                  },
                  {
                    onClick: (e: any) =>
                      navigate(`/lms/manage-courses/certificates/edit/${_id}`),
                    children: (
                      <OptionArea>
                        <StyledIconButton
                          size="small"
                          variant="line"
                          icon="IconPencil"
                        />
                        <StyledText variant="body3">
                          Editar certificado
                        </StyledText>
                      </OptionArea>
                    ),
                  },
                  {
                    onClick: () => {
                      setId(_id);
                      setDeleteModal(true);
                    },
                    children: (
                      <OptionArea>
                        <StyledIconButton
                          size="small"
                          variant="line"
                          icon="IconTrash"
                        />
                        <StyledText variant="body3">
                          Excluir certificado
                        </StyledText>
                      </OptionArea>
                    ),
                  },
                ]}
              >
                <IconButton
                  size="small"
                  variant="line"
                  icon="IconDotsVertical"
                />
              </Menu>
            )}
          </>
        );
      },
    },
  ];

  const totalPages = Math.ceil(total / pageSize);

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum certificado encontrado.",
        }}
        customPagination={({ gotoPage, setPageSize: setComponentPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > total ? total : page * pageSize}
                &nbsp;de&nbsp;{total || 0}&nbsp;
                {total === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={totalPages}
                onChange={(_, value) => {
                  const currentPage = value;

                  setPage(currentPage);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
      <DeleteCertiticate
        certificateId={id}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
      <PreviewCertificate
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        data={previewData}
      />
    </>
  );
};

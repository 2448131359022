import { Tag } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  button:first-child {
    align-self: center;
    margin-right: 24px;
    svg {
      color: ${({ theme }) => theme.colors.neutral[40]};
    }
  }
`;

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PublishedTag = styled(Tag)<{ status?: string }>`
  background-color: ${({ status }) =>
    status === "published"
      ? "var(--color-feedback-success-light1)"
      : "var(--color-feedback-error-light1)"} !important;
  color: ${({ status }) =>
    status === "published"
      ? "var(--color-feedback-success-dark2)"
      : "var(--color-feedback-error-dark2)"} !important;
  padding: 4px 12px;
  font-size: 14px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;

export const HasCertificateTag = styled(Tag)`
  color: ${({ theme }) => theme.colors.secondary[50]} !important;
  background-color: ${({ theme }) => theme.colors.secondary[95]} !important;
  padding: 4px 12px;
  height: 28px;
  margin-left: 10px;
  font-size: 14px;
  align-self: center;
`;

export const StyledFlex = styled.div`
  display: flex;
`;

export const DotSeparator = styled.div`
  border-radius: 100%;
  width: 5px;
  height: 5px;
  margin: 0 10px;
  background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

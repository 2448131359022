import styled from "styled-components";
import { Grid } from "@mui/material";

import {
  Button,
  Typography,
  SearchField,
  Modal,
  TextField,
  PageContainer,
  DatePicker,
  Icons,
  LinkButton,
} from "@flash-tecnologia/hros-web-ui-v2";

const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const StyledTypography = styled(Typography)``;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

const StyledFlex = styled.div`
  display: flex;
`;

const StyledGrid = styled(Grid)`
  z-index: 1;
`;

const StyledDatePicker = styled(DatePicker)``;

const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin: 40px 0px;
`;

const StepperArea = styled.div`
  margin-bottom: 40px;
`;

const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

export {
  Container,
  StepperArea,
  PageTitle,
  StyledContainer,
  StyledTextField,
  StyledTypography,
  StyledSectionContainer,
  StyledSubtitleContainer,
  StyledTitle,
  StyledFlex,
  StyledGrid,
  StyledPageContainer,
  StyledDatePicker,
  StyledBreadcrumbSeparator,
  StyledPageHeaderColumn,
};

import { EActions } from "../context/actions";
import dispatchToast from "../utils/dispatchToast";

export const saveClasseToCreate = async ({
  params,
  dispatch,
  callback,
}: {
  params: any;
  dispatch: any;
  callback?: any;
}) => {
  try {
    dispatch({
      type: EActions.SAVE_CLASS_TO_CREATE,
      payload: params,
    });

    if (typeof callback === "function") callback.call();

    return;
  } catch (error: any) {
    return error;
  }
};

export const saveClassUrl = async ({
  params,
  dispatch,
  callback,
}: {
  params: any;
  dispatch: any;
  callback?: any;
}) => {
  try {
    dispatch({
      type: EActions.SAVE_CLASS_URL,
      payload: params,
    });

    if (typeof callback === "function") callback.call();

    return;
  } catch (error: any) {
    return error;
  }
};

export const clearClasseToCreate = async ({
  dispatch,
  callback,
}: {
  dispatch: any;
  callback?: any;
}) => {
  try {
    dispatch({
      type: EActions.SAVE_CLASS_TO_CREATE,
      payload: {},
    });

    if (typeof callback === "function") callback.call();

    return;
  } catch (error: any) {
    return error;
  }
};

export const changeTab = async ({
  tabNumber,
  dispatch,
}: {
  tabNumber: number;
  dispatch: any;
}) => {
  try {
    dispatch({
      type: EActions.UPDATE_MANAGE_TAB,
      payload: tabNumber ?? 0,
    });

    return;
  } catch (error: any) {
    const customMessage = error?.response?.data?.message || "";

    if (customMessage) {
      dispatchToast({
        content: customMessage,
        type: "error",
      });
    }
  }
};

export const updateModal = async ({
  modal,
  dispatch,
  callback,
}: {
  modal: any;
  dispatch: any;
  callback?: any;
}) => {
  try {
    dispatch({
      type: EActions.UPDATE_MODAL,
      payload: modal,
    });

    if (typeof callback === "function") callback.call();

    return;
  } catch (error: any) {
    return error;
  }
};

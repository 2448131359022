import styled from "styled-components";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const AccordionContainer = styled(Accordion)`
  padding: 16px;
  margin-bottom: 24px;
  margin: 32px 0 !important;

  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;
    padding: 0;
  }
  &:before {
    background-color: transparent !important;
  }
  .MuiAccordionSummary-content {
    margin: 12px 0px !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  text-align: left;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    padding: 0;
  }
`;

export const StyledFlexBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button:last-child {
    margin-left: 16px;
  }
`;

export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 4px;
  text-align: left !important;
`;

export const AccordionDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  line-height: 20px;
  width: 98%;
  text-align: left !important;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const ContentArea = styled.div`
  padding: 24px;
`;

export const SectionTitle = styled(Typography)`
  font-weight: 600 !important;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 16px;
`;

export const SectionDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin: 32px 0;
`;

export const AreaDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 24px;
`;

export const InfAreaDivider = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-top: 24px;
`;

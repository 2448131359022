import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const StyledFooterBar = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  z-index: 99;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  width: -webkit-fill-available;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BackButton = styled(LinkButton)`
  align-self: center;
  margin-right: 42px;
`;

export const BackArea = styled.div`
  display: flex;
  align-items: center;
`;

export const Separator = styled.div`
  height: 100px;
`;

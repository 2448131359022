import styled from "styled-components";
import {
  BoxAdornment,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  margin: 40px 0px;
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
`;

export const StyledBoxAdornment = styled(BoxAdornment)`
  width: 100% !important;
`;

export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const AccordionSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const PageInfo = styled.div`
  margin-right: 24px;
`;

export const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

export const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const PreviewArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScaleCertificate = styled.div`
  scale: 0.7;
  width: max-content;
  height: max-content;
  background-color: white;
  margin-top: -80px;
  margin-bottom: -120px;
`;

export const SelectArea = styled.div`
  width: 100%;
  p {
    margin-left: 0;
  }
  .select-field-popper-custom {
    z-index: 0 !important;
  }
`;

export const Select = styled(SelectField)`
  margin-top: 10px;
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

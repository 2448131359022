import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Container,
  SubImageContainer,
  SubContainer,
  SubImageIcon,
  LabelTitle,
  LabelSubTitle,
  StyledIconButton,
  StyledFlex,
  StatusTagArea,
  PreviewArea,
  MenuArea,
  IconArea,
  StatusTag,
} from "./styled";

import { dayjs, Icons, Menu, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import CertificatePreview from "@components/CertificatePreview";
import { StyledText } from "@utils";
import { DeleteCertiticate } from "../../Modals/DeleteCertificate";
import { PreviewCertificate } from "../../Modals/PreviewCertificate";

export const Card = ({
  info: {
    _id,
    associateCourses = 0,
    name = "-",
    createdAt = "",
    status = "",
    companyLogo,
    companyName,
    signText,
    signerName,
    signerPostion,
    expiresDate,
  },
}) => {
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [deleteModal, setDeleteModal] = useState<any>(false);
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [previewData, setPreviewData] = useState<any>({});

  return (
    <>
      <Container>
        <SubImageContainer>
          <PreviewArea>
            <CertificatePreview
              data={{
                companyLogo,
                companyName,
                signText,
                signerName,
                signerPostion,
                expiresDate,
              }}
            />
          </PreviewArea>
          <MenuArea>
            {associateCourses > 0 ? (
              <IconArea>
                <Tooltip title="Ver certificado">
                  <SubImageIcon
                    onClick={() => {
                      setPreviewModal(true);
                      setPreviewData({
                        name,
                        companyLogo,
                        companyName,
                        signText,
                        signerName,
                        signerPostion,
                        expiresDate,
                      });
                    }}
                    size="small"
                    variant="line"
                    icon="IconEye"
                  />
                </Tooltip>
              </IconArea>
            ) : (
              <Menu
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                options={[
                  {
                    onClick: () => {
                      setPreviewModal(true);
                      setPreviewData({
                        name,
                        companyLogo,
                        companyName,
                        signText,
                        signerName,
                        signerPostion,
                        expiresDate,
                      });
                    },

                    children: (
                      <StyledFlex>
                        <Icons
                          name="IconEye"
                          fill="transparent"
                          style={{ marginRight: "8px" }}
                        />
                        <StyledText variant="body3">
                          Exibir certificado
                        </StyledText>
                      </StyledFlex>
                    ),
                  },
                  {
                    onClick: () =>
                      navigate(`/lms/manage-courses/certificates/edit/${_id}`),
                    children: (
                      <StyledFlex>
                        <Icons
                          name="IconPencil"
                          fill="transparent"
                          style={{ marginRight: "8px" }}
                        />
                        <StyledText variant="body3">
                          Editar certificado
                        </StyledText>
                      </StyledFlex>
                    ),
                  },
                  {
                    onClick: () => {
                      setDeleteModal(true);
                      setId(_id);
                    },
                    children: (
                      <StyledFlex>
                        <Icons
                          name="IconTrash"
                          fill="transparent"
                          style={{ marginRight: "8px" }}
                        />
                        <StyledText variant="body3">
                          Excluir certificado
                        </StyledText>
                      </StyledFlex>
                    ),
                  },
                ]}
                type="select"
              >
                <IconArea>
                  <SubImageIcon
                    hasNoImage={true}
                    size="small"
                    variant="filled"
                    icon="IconDotsVertical"
                  />
                </IconArea>
              </Menu>
            )}
          </MenuArea>
        </SubImageContainer>

        <SubContainer>
          <StatusTagArea>
            <StatusTag variant="body3" status={status}>
              {status === "published" ? "Disponível" : "Rascunho"}
            </StatusTag>
          </StatusTagArea>
          <LabelTitle variant="body2" style={{ marginBottom: "4px" }}>
            {name}
          </LabelTitle>

          <StyledFlex style={{ marginBottom: "16px", marginTop: "24px" }}>
            <StyledIconButton variant="line" size="small" icon="IconSchool" />
            <LabelSubTitle variant="body4">{`${associateCourses} treinamentos associados`}</LabelSubTitle>
          </StyledFlex>
          <StyledFlex style={{ marginBottom: "16px" }}>
            <StyledIconButton
              variant="line"
              size="small"
              icon={"IconCalendar"}
            />

            <LabelSubTitle variant="body4">
              Criado em{" "}
              {createdAt ? dayjs(createdAt).format("DD/MM/YYYY") : "-"}
            </LabelSubTitle>
          </StyledFlex>
        </SubContainer>
      </Container>
      <DeleteCertiticate
        certificateId={id}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
      <PreviewCertificate
        open={previewModal}
        onClose={() => setPreviewModal(false)}
        data={previewData}
      />
    </>
  );
};

import { useNavigate } from "react-router-dom";
import { track } from "@utils";

import {
  Container,
  LabelTitle,
  StyledIconButton,
  StyledFlexCenter,
} from "./styles";
import { OverflowCheck } from "@components/OverflowCheck";

export const CreateCard = ({
  type,
  disabled = false,
  customHandleClick,
}: {
  type: string;
  disabled?: boolean;
  customHandleClick?: () => void;
}) => {
  const navigate = useNavigate();

  const iconByType =
    type === "video"
      ? "IconVideo"
      : type === "podcast"
      ? "IconMicrophone"
      : type === "pdf"
      ? "IconFile"
      : type === "exam"
      ? "IconListNumbers"
      : type === "certificate"
      ? "IconCertificate"
      : "IconBlockquote";

  const labelByType =
    type === "video"
      ? "Criar vídeo"
      : type === "podcast"
      ? "Criar podcast"
      : type === "pdf"
      ? "Criar PDF"
      : type === "exam"
      ? "Criar avaliação"
      : type === "certificate"
      ? "Criar certificado"
      : "Criar artigo";

  const handleClick = () => {
    if (customHandleClick) {
      customHandleClick();
      return;
    }
    track({
      name: `company_lms_content_create${
        type === "exam" ? "test" : type
      }_clicked`,
    });

    navigate(`/lms/manage-courses/create/content/${type}/0`);
  };

  return (
    <Container onClick={handleClick} disabled={disabled}>
      <StyledFlexCenter>
        <StyledIconButton size="medium" variant="filled" icon={iconByType} />
      </StyledFlexCenter>

      <LabelTitle variant="body2">
        {OverflowCheck({ text: labelByType })}
      </LabelTitle>
    </Container>
  );
};

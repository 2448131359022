import {
  BoxAdornment,
  DatePicker,
  IconButton,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MandatoryAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const Container = styled.div`
  margin: 40px 0px;
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledBoxAdornment = styled(BoxAdornment)`
  width: 100% !important;
`;

export const PreviewBanner = styled.div`
  p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 14px;
  }
`;

export const StyledBannerContainer = styled.div`
  position: relative;
`;

export const SubImageHoverContainer = styled.div`
  background: rgba(29, 22, 27, 0.4);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  opacity: 0;
  z-index: 10;

  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const IconButtonHover = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
        opacity: 0.6;
      }
      svg {
        stroke: ${({ theme }) => theme.colors.neutral.light.pure};
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

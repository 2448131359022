import { useEffect, useMemo, useState } from "react";

import {
  StyledGrid,
  PaginationArea,
  ItensPerPageArea,
  StyledSelectField,
  PaginationContainer,
  StyledTypography,
  StyledMenuItem,
} from "./styled";

import { Pagination, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { pageSizeOptions } from "@utils";
import { Card } from "./Card";

interface GridViewProps {
  data: any[];
  rawData: any[];
  page: number;
  setPage: any;
  pageSize: number;
  setPageSize: any;
  total: number;
  loading: boolean;
}

export const CertificateCard = ({
  data,
  rawData,
  loading,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
}: GridViewProps) => {
  const renderComponent = useMemo(() => {
    if (data?.length) {
      return data.map((c, index) => (
        <StyledGrid key={index} item sm={12} md={6} lg={4}>
          <Card info={c} />
        </StyledGrid>
      ));
    }
  }, [data]);

  const totalPages = Math.ceil(total / pageSize);

  if (loading)
    return (
      <StyledGrid container spacing={2} direction="row" alignItems="center">
        {Array(6)
          .fill("")
          .map((_, index) => (
            <StyledGrid key={index} item sm={12} md={6} lg={4}>
              <Skeleton variant="rectangular" width="100%" height="500px" />
            </StyledGrid>
          ))}
      </StyledGrid>
    );
  return (
    <>
      <StyledGrid container spacing={2} direction="row" flexGrow={1}>
        {renderComponent}
      </StyledGrid>
      <PaginationArea>
        <ItensPerPageArea>
          <StyledSelectField
            value={pageSize ?? 5}
            onChange={(e) => {
              const newPageSize = Number(e?.target?.value);

              setPageSize(newPageSize);
            }}
          >
            {pageSizeOptions?.map((opt) => (
              <StyledMenuItem key={opt?.value} value={opt?.value}>
                {opt?.label}
              </StyledMenuItem>
            ))}
          </StyledSelectField>
          <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
            Resultados:&nbsp;
            {page === 1 ? 1 : page * pageSize - pageSize + 1}-
            {page * pageSize > total ? total : page * pageSize}
            &nbsp;de&nbsp;{total || 0}&nbsp;
            {total === 1 ? "item" : "itens"}
          </StyledTypography>
        </ItensPerPageArea>
        <PaginationContainer>
          <Pagination
            defaultPage={1}
            page={page}
            count={totalPages}
            onChange={(_, value) => {
              const currentPage = value;

              setPage(currentPage);
            }}
          />
        </PaginationContainer>
      </PaginationArea>
    </>
  );
};

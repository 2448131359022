import styled, { css } from "styled-components";
import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const BreadcrumbText = styled(Typography)`
  color: var(--neutral-neutral-40);
  font-weight: 700 !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
`;

export const BreadcrumbLastText = styled(Typography)`
  color: var(--neutral-neutral-40);
  font-weight: 700 !important;
`;

export const Separator = styled.div<{ width?: string; height?: string }>`
  display: inline-block;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "25px"};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  * {
    align-self: center !important;
  }
`;

export const Title = styled(Typography)`
  color: var(--color-neutral20);
  white-space: nowrap;
  margin-right: 12px;
`;

export const SubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  white-space: nowrap;
`;

export const PublishedTag = styled(Tag)`
  padding: 4px 12px;
  font-size: 14px;
  height: 28px;
  align-self: center;
`;

export const InfoBox = styled.div`
  display: flex;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 100%;
  min-width: 250px;
  margin-right: 24px;
  align-items: center;
`;

export const InfoBoxArea = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-bottom: 40px;
  overflow-x: scroll;
`;

export const InfoLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-style: normal;
  font-weight: 600 !important;
  line-height: 22px;
`;

export const InfoText = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 700 !important;
  line-height: 48px;
  align-items: center;
  display: flex;
  text-transform: capitalize;
  button {
    font-weight: 600 !important;
  }
  span {
    color: ${({ theme }) => theme.colors.neutral[50]};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    padding: 0 8px;
  }
`;

export const InfoArea = styled.div`
  margin-left: 16px;
  align-items: center;
`;

export const ExtraInfoLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-left: 8px;
`;

export const InfoIconArea = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  svg {
    stroke: ${({ theme }) => theme.colors.secondary[50]};
  }
`;

export const IndexTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const IndexArea = styled.div`
  margin-top: 16px;
  padding: 16px 16px 0px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  p {
    padding: 14px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      padding-top: 0;
    }
  }
`;

export const IndexItem = styled(Typography)`
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[30]};
  a {
    padding-left: 10px;
  }
  span {
    color: #c96c01;
  }
  &.active-scroll-spy {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 700;
    a {
      border-left: 2px solid rgb(254, 43, 143);
      padding-left: 8px;
    }
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const DetailBox = styled.div`
  padding: 40px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 24px;
`;

export const DetailTitle = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const DetailLabelTitle = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export const DetailInfoText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const DetailInfoArea = styled.div`
  margin-bottom: 24px;
`;

export const SideMenuArea = styled.div`
  position: sticky;
  top: 20px;
`;

export const QuestionArea = styled.div`
  scroll-behavior: smooth;
  overflow: auto;
  max-height: 550px;
  scroll-padding-top: 120px;
  margin-bottom: 50px;
`;

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

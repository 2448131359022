import styled from "styled-components";

import { Avatar } from "@mui/material";
import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const Container = styled.div`
  margin: 40px 0px;
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  width: 100%;
`;

export const PageInfo = styled.div`
  margin-bottom: 56px;
`;

export const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

export const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const EmployeeName = styled(Typography)`
  text-transform: capitalize;
`;

export const TableDataArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledAvatar = styled(Avatar)`
  text-transform: uppercase;
`;

export const TransferListArea = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  height: 800px;
  .transfer-list-menu-container-visible {
    z-index: 100;
  }
  .transfer-list-filter-search-field-container {
    div:first-child {
      width: -webkit-fill-available;
    }
    div.MuiFilledInput-root {
      background-color: transparent;
      border: 1px solid rgb(210, 198, 206);
      border-radius: 150px;
    }
  }
`;

export const OptionContentArea = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  margin-top: 32px;
  width: 100%;
  p {
    margin-left: 20px;
  }
  p:first-child {
    font-weight: 700;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
  p:last-child {
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const TransferSectionTitle = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 40px;
  width: 100%;
  p:first-child {
    font-weight: 700;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
  p:last-child {
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
    margin-bottom: 40px;
  }
`;

export const TableData = styled.div`
  display: flex;
  margin: 30px 0px;
  .MuiAvatar-root {
    margin-right: 12px;
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export const EmailField = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const SharedTooltip = styled.div`
  display: flex;
  align-items: center;
  .sharedTooltip {
    margin-left: 4px;
    cursor: pointer;
    svg {
      stroke: ${({ theme }) => theme.colors.neutral[30]};
    }
  }
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

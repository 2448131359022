import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled, { css } from "styled-components";

export const ExamTypeButton = styled(Button)<{ selected?: boolean }>`
  width: 100%;
  margin-bottom: 16px;
  text-align: left;
  justify-content: flex-start !important;
  ${({ selected }) =>
    selected
      ? css`
          font-weight: 700 !important;
          background: ${({ theme }) => theme.colors.secondary[95]} !important;
        `
      : css`
          font-weight: 600;
          color: ${({ theme }) => theme.colors.neutral[50]} !important;
          border: 1px solid ${({ theme }) => theme.colors.neutral[90]} !important;
        `};
`;

export const AnswerText = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  min-width: 300px;
  max-width: fit-content;
`;

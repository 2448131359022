import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  background: #ebe6e9;
`;

const StyledPdfHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8f6f8;
  border: 1px solid #ebe6e9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  user-select: none;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
`;

const StyledButton = styled(Button)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.primary};
      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        box-shadow: 0px 16px 40px ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

const CanvasContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: auto;
  margin: 12px;
  width: 100%;
  height: 100%;
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.neutral[80]};
  border-radius: 40px;
  padding: 12px;
  p {
    color: ${({ theme }) => theme.colors.neutral[40]};
  }
  svg {
    stroke: ${({ theme }) => theme.colors.neutral[40]};
    margin-right: 8px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.neutral.light.light2};
  }
`;

export {
  Container,
  StyledPdfHeaderContainer,
  StyledButton,
  CanvasContainer,
  ExpandButton,
};

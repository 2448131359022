import {
  DescCalendarLabel,
  DesciptionLabel,
  DescriptionCalendar,
  DescriptionInfo,
  DescriptionTags,
  DescriptionTitle,
} from "./styled";

import { Divider, Icons, Tag, dayjs } from "@flash-tecnologia/hros-web-ui-v2";

const Description = ({ course }) => {
  return (
    <div>
      <DescriptionInfo variant="body4">
        Descrição do treinamento
      </DescriptionInfo>
      <DescriptionTitle variant="headline7">{course?.name}</DescriptionTitle>
      <DescriptionCalendar>
        <DescCalendarLabel variant="body4">
          <Icons name="IconCalendar" fill="transparent" size={22} />
          {dayjs(course?.start).isValid()
            ? `Início em ${dayjs(course?.start).format("DD/MM/YYYY")}`
            : "Não informado"}
        </DescCalendarLabel>
        <DescCalendarLabel variant="body4">
          <Icons name="IconCalendar" fill="transparent" size={22} />
          {dayjs(course?.end).isValid()
            ? `Disponível até ${dayjs(course?.end).format("DD/MM/YYYY")}`
            : "Sempre disponível"}
        </DescCalendarLabel>
      </DescriptionCalendar>
      <DescriptionTags>
        {course?.mandatory && <Tag variant="primary">Obrigatório</Tag>}
        {course?.certificate?._id && (
          <Tag variant="primary">
            <Icons name="IconCertificate" fill="transparent" size={16} />
            Emite certificado
          </Tag>
        )}
      </DescriptionTags>
      <Divider />
      <DesciptionLabel variant="body3">{course?.description}</DesciptionLabel>
    </div>
  );
};

export { Description };

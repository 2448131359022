import { DataCard } from "../DataCard";

interface SettingsTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const SettingsTab = ({ data, onNavigate }: SettingsTabProps) => {
  const formatEnable = (enabled: boolean | undefined, gender: "f" | "m") => {
    const isBoolean = typeof enabled === "boolean";
    const end = gender === "f" ? "a" : "o";

    if (!isBoolean) return "-";
    if (enabled) return "Habilitad" + end;

    return "Não habilitad" + end;
  };

  return (
    <DataCard
      title="Revisar configurações"
      subTitle="Confira as configurações do seu treinamento."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          items: [
            {
              title: "Certificado",
              description: data?.certificateId
                ? "Habilitado"
                : "Não habilitado",
            },
            {
              title: "Configurações de navegação",
              description: formatEnable(data?.blockNavigation, "m"),
            },
            {
              title:
                "Compartilhar o treinamento com todas as empresas do grupo",
              description: formatEnable(data?.isShared, "m"),
            },
          ],
        },
      ]}
    />
  );
};

import { useEffect, useState } from "react";

import {
  StyledGrid,
  PaginationArea,
  ItensPerPageArea,
  StyledSelectField,
  PaginationContainer,
  StyledTypography,
  StyledMenuItem,
  EmptyZone,
} from "./styles";

import EmptyContentState from "../../../../../assets/emptyContentState.svg";

import { Pagination, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { CertificateCard } from "./CertificateCard";
import { StyledText, pageSizeOptions } from "@utils";

export const CertificateCardView = ({
  data = [],
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) => {
  const [cloneContent, setCloneContent] = useState<any>([]);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [pageSize, setPageSize] = useState<any>(5);
  const [page, setPage] = useState<any>(1);

  useEffect(() => {
    if (data?.length) setCloneContent(handlePaginate(data, pageSize, 1));
  }, [data, pageSize]);

  const handlePaginate = (array, pageSize, pageNumber) => {
    if (array?.length) {
      setPage(pageNumber);
      setTotalPages(Math.ceil(data?.length / pageSize));
      return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    } else {
      setPage(1);
      setTotalPages(1);
      return [];
    }
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          style={{ width: "100%", height: "400px" }}
        />
      ) : (
        <>
          <StyledGrid container spacing={2} direction="row" alignItems="center">
            {cloneContent?.length ? (
              cloneContent.map((c, index) => {
                return (
                  <StyledGrid key={index} item sm={12} md={6} lg={4}>
                    <CertificateCard info={c} />
                  </StyledGrid>
                );
              })
            ) : (
              <EmptyZone className="data-grid-empty-table-container">
                <EmptyContentState />
                <StyledText variant="body3">
                  O filtro aplicado não apresenta resultados.
                </StyledText>
              </EmptyZone>
            )}
          </StyledGrid>
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > data?.length
                  ? cloneContent?.length
                  : page * pageSize}
                &nbsp;de&nbsp;{cloneContent?.length ?? 0}&nbsp;
                {cloneContent.length === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                count={totalPages}
                onChange={(e, value) =>
                  setCloneContent(handlePaginate(data, pageSize, value))
                }
              />
            </PaginationContainer>
          </PaginationArea>
        </>
      )}
    </>
  );
};

import { trpc } from "@api/client";
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import DoneImage from "../../../../assets/done_course.svg";
import {
  Body,
  DoneModal,
  Flex,
  Footer,
  Gap,
  Header,
  StyledButton,
} from "./styled";

const DoneCourse = ({ onClose, open, courseId }) => {
  const theme = useTheme();

  const { mutate: setMonitoringMutate } =
    trpc.monitorings.setMonitoring.useMutation({});

  useEffect(() => {
    if (open) {
      setMonitoringMutate({
        courseId,
        params: {
          type: "courseDone",
          done: true,
        },
      });
    }
  }, [open]);

  return (
    <DoneModal onClose={onClose} open={open}>
      <>
        <Header>
          <DoneImage />
        </Header>

        <Body>
          <Flex style={{ marginBottom: "12px" }}>
            <Icons name="IconSparkles" stroke={theme.colors.secondary[50]} />
            <Typography
              variant="body4"
              children="Parabéns!"
              style={{ color: theme.colors.secondary[50] }}
            />
          </Flex>

          <Typography
            variant="headline8"
            children="Você concluiu o treinamento com sucesso!"
            style={{ color: theme.colors.neutral[10], marginBottom: "8px" }}
          />

          <Gap>
            <Typography
              variant="body4"
              children="Sua dedicação e esforço valeram a pena. Continue aplicando o conhecimento adquirido e aproveite para impulsionar sua jornada!"
              style={{ color: theme.colors.neutral[40] }}
            />

            <div id={"modal_done_course_survey"} />
          </Gap>
        </Body>

        <Footer>
          <StyledButton
            size="large"
            variant="primary"
            onClick={() => {
              onClose();
            }}
          >
            Voltar para Meus treinamentos
          </StyledButton>
        </Footer>
      </>
    </DoneModal>
  );
};

export default DoneCourse;

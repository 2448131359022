import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { useEffect, useState } from "react";
import {
  ClearFilters,
  DateField,
  DateRangePicker,
  FilterLabel,
  FiltersSection,
  StatusFilter,
} from "./styles";

export const ExamFilter = ({
  handleSetFilter,
  optionsFilters,
  coursesToFilter = [],
}) => {
  const [statusSelect, setStatusSelect] = useState<any>([]);
  const [courseSelect, setCourseSelect] = useState<any>([]);

  useEffect(() => {
    setCourseSelect(coursesToFilter);
  }, []);

  const enableClearAll =
    optionsFilters?.status?.length ||
    statusSelect?.length ||
    courseSelect?.length ||
    optionsFilters?.doneAt;

  return (
    <FiltersSection>
      <FilterLabel variant="body3">Filtrar por</FilterLabel>

      <StatusFilter
        variant="secondary"
        filterLabel="Status"
        optionIconType="none"
        disableOptionsSearch
        selectedOptions={statusSelect}
        options={[
          {
            label: "Concluído",
            value: "done",
          },
          {
            label: "Não iniciado",
            value: "notStarted",
          },
        ]}
        onClick={(value) => {
          setStatusSelect([value]);
        }}
        onApply={(value) => handleSetFilter("status", value)}
        onClear={() => {
          setStatusSelect([]);
          handleSetFilter("status", null);
        }}
      />
      <StatusFilter
        variant="secondary"
        filterLabel="Treinamento"
        optionIconType="checkbox"
        disableOptionsSearch
        selectedOptions={
          courseSelect
            ?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || []
        }
        options={courseSelect}
        onClick={(value) => {
          const filteredItens = courseSelect?.map((item) => {
            if (item?.value === value) {
              return { ...item, checked: !item?.checked };
            }
            return item;
          });
          setCourseSelect(filteredItens);
        }}
        onApply={(value) => handleSetFilter("course", value)}
        onClear={() => {
          const initialValue = courseSelect?.map((c) => {
            return { ...c, checked: false };
          });
          setCourseSelect(initialValue);
          handleSetFilter("course", null);
        }}
      />
      <div>
        <DateField
          id="input_reset_start"
          onDateChange={(value) =>
            handleSetFilter("doneAt", [dayjs(value)?.format("MM/DD/YYYY")])
          }
          label="Data da conclusão"
        />
      </div>

      {enableClearAll ? (
        <ClearFilters
          variant="secondary"
          onClick={() => {
            handleSetFilter("status", []);
            handleSetFilter("course", []);
            handleSetFilter("doneAt", []);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            document.getElementById("input_reset_start").value = "";
            setStatusSelect([]);

            const initialValue = courseSelect?.map((c) => {
              return { ...c, checked: false };
            });
            setCourseSelect(initialValue);
          }}
        >
          Limpar filtros
        </ClearFilters>
      ) : null}
    </FiltersSection>
  );
};

import styled from "styled-components";

import { Button, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledFlexRow = styled.div`
  display: flex;
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledDescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 122px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  padding: 24px 16px 24px 24px;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const StyledDescriptionSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-color: ${({ theme }) => theme.colors.neutral.light.light2};
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  width: 100%;
  height: calc(100% - 65px);
  height: -webkit-calc(100% - 65px);
`;

export const StyledDescriptionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700 !important;
  margin-bottom: 8px;
`;

export const StyledDescriptionSubTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const CirculaProgressContainer = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
`;

export const GenerateButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid #ebe6e9;
`;

export const GenerateButton = styled(Button)`
  align-self: center !important;
`;

import styled from "styled-components";

import {
  Icons,
  Typography,
  IconButton,
  Loader,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const StyledModal = styled(Modal)<{ isLoading: boolean }>`
  && {
    .modal-icon-container {
      top: 0px;
    }
    .modal-content-area {
      padding: 0;
    }
  }
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error.dark1};
`;

export const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.feedback.error.light1};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const ModalTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral[20]};
  text-align: center;
`;

export const ModalSubTitle = styled(Typography)`
  font-weight: 600;
  text-align: center;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledContainer = styled.div``;

export const ModalFooterContainer = styled.div`
  padding: 0 112px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    align-self: center !important;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 112px;
`;

export const StyledLoader = styled(Loader)``;

export const StyledFlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextLoading = styled(Typography)`
  font-weight: 700;
  text-align: center;
  margin-top: 16px;

  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 112px 0;
`;

export const ModalDeleteTitle = styled(Typography)`
  font-weight: bold !important;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
`;

export const DeleteButton = styled(Button)`
  background-color: ${({ theme }) =>
    theme.colors.feedback.error[90]} !important;
  color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
  &:hover {
    box-shadow: 0 16px 40px ${({ theme }) => theme.colors.feedback.error[90]} !important;
  }
`;

export const DeleteArea = styled.div`
  padding: 40px 112px;
`;

import styled from "styled-components";
import { Grid } from "@mui/material";
import { IconButton, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledIconButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const EmployeeName = styled(Typography)`
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  text-transform: capitalize;
`;

export const EmployeeDataArea = styled.div`
  display: flex;
  align-items: center;
`;

export const EmployeePic = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.colors.secondary[50]};
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
`;

export const NumberInfo = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600;
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  margin-right: 12px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressPercent = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 8px;
`;

export const StyledImageContainer = styled.div<{ url: string }>`
  width: 64px;
  height: 64px;
  background: ${({ url }) => `url(${url})`};
  background-color: ${({ theme }) => theme.colors.neutral.light.light1};
  border-radius: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 311px;
  white-space: normal;
  margin-left: 16px;
`;

export const TitleCardContainer = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledTitleCard = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
`;

export const StyledIconButtonExtraSmall = styled(IconButton)`
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const StyledSubTitleCard = styled(Typography)`
  font-weight: 600;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral.dark.dark4};
`;

export const Label = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

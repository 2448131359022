import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";

import dispatchToast from "../../utils/dispatchToast";

import {
  uploadHelper,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";

import {
  CertificateArea,
  Container,
  DropzoneArea,
  ExpandButton,
  FixedPreview,
  PreviewArea,
  SectionContainer,
  SelectArea,
  StyledBannerContainer,
  StyledTextField,
  SubtitleContainer,
  ToggleArea,
  PageTitle,
  SubImageHoverContainer,
  IconButtonHover,
  StyledPageContainer,
  StyledGrid,
  StyledTitle,
  StyledTypography,
} from "./styled";

import {
  Button,
  Dropzone,
  Icons,
  SelectField,
  Skeleton,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { FooterBar } from "../../components/FooterBar";
import CertificatePreview from "../../components/CertificatePreview";

import PreviewCertificate from "../../components/Certificates/Modals/PreviewCertificate";
import SuccessCertificate from "../../components/Certificates/Modals/SuccessCertificate";

import { track } from "../../utils/segment";
import { trpc } from "@api/client";

export const CreateEditCertificate = () => {
  const navigate = useNavigate();

  const { selectedCompany } = useSelectedCompany();
  const { certificateId = "", type } = useParams();

  const [modalOpen, setModalOpen] = useState(false);
  const [successsModal, setSuccesssModal] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [certificate, setCertificate] = useState({});

  const { isFetching: isGettingCertificateById } =
    trpc.certificate.getCertificateById.useQuery(
      { _id: certificateId },
      {
        enabled: !!certificateId && type === "edit",
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setCertificate(data);
        },
      }
    );

  const { mutate: createCertificateMutate, isLoading: isCreatingCertificate } =
    trpc.certificate.createCertificate.useMutation({});

  const { mutate: editCertificateMutate, isLoading: isEditingCertificate } =
    trpc.certificate.editCertificate.useMutation({});

  useEffect(() => {
    if (certificateId && type === "edit") {
      if (Object.keys(certificate).length) {
        Object.keys(certificate).map((cl) => {
          if (
            cl === "_id" ||
            cl === "active" ||
            cl === "status" ||
            cl === "companyId" ||
            cl === "updatedAt" ||
            cl === "createdAt"
          )
            return;
          if (certificate?.[cl])
            formik.setFieldValue(cl, certificate?.[cl], true);
        });
      }
    }
  }, [certificate]);

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(50)
      .required("Favor digitar o nome de identificação"),
    companyName: yup
      .string()
      .max(200)
      .when("saveAsDraft", {
        is: false,
        then: yup
          .string()
          .max(200)
          .required("Favor digitar o nome da instituição"),
      }),
    expiresDate: yup.date().notRequired(),
    haveSignature: yup.boolean().notRequired(),
    signText: yup.string().when("haveSignature", {
      is: true,
      then: yup.string().required("Este campo é obrigatório."),
    }),
    signerName: yup.string().when("haveSignature", {
      is: true,
      then: yup.string().required("Este campo é obrigatório."),
    }),
    signerPostion: yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyLogo: { key: null, path: null, signature: null },
      companyName: "",
      expiresDate: 0,
      haveSignature: false,
      signText: "",
      signerName: "",
      signerPostion: "",
      saveAsDraft: false,
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: async (values: any) => {
      delete values["saveAsDraft"];

      const file = values?.companyLogo?.file;
      let companyLogo = null;

      if (file) {
        const randomNumber = Math.round(Math.random() * 100000000000000);
        const res = await uploadHelper({
          key: `certificates/${selectedCompany?.id}`,
          file: file,
          fileName: randomNumber?.toString(),
          module: "learning-management-system",
        });
        companyLogo = { ...res, origin: "internal" };
      }

      delete values["companyLogo"];

      if (certificateId && type === "edit") {
        let params = {
          ...values,
          status: saveAsDraft ? "draft" : "published",
        };

        if (companyLogo) params = { ...params, companyLogo };

        editCertificateMutate(
          {
            _id: certificateId,
            companyId: selectedCompany?.id,
            params,
          },
          {
            onSuccess: () => {
              setCertificate({});
              setSuccesssModal(true);
            },
          }
        );
      } else {
        const params = {
          ...values,
          companyLogo,
          status: saveAsDraft ? "draft" : "published",
        };

        createCertificateMutate(
          {
            params,
            companyId: selectedCompany?.id,
          },
          {
            onSuccess: () => {
              setCertificate({});
              setSuccesssModal(true);
            },
            onError: (e: any) => {
              const certificateExists =
                e?.data?.error ===
                "CERTIFICATE_WITH_SAME_NAME_ALREADY_EXISTS_ERROR";

              const message = certificateExists
                ? "Você já criou um certificado com esse nome."
                : "Erro ao tentar criar o certificado, tente novamente mais tarde!";

              dispatchToast({
                type: "error",
                content: message,
              });
            },
          }
        );
      }
    },
  });

  const handleDeleteImage = useCallback(() => {
    formik.setFieldValue("companyLogo", {
      key: null,
      path: null,
      signature: null,
    });
  }, []);

  return (
    <>
      {isGettingCertificateById ? (
        <Skeleton
          width="100%"
          height="100%"
          animation="pulse"
          variant="rectangular"
        />
      ) : (
        <Container>
          <StyledPageContainer>
            <PageTitle variant="headline6">
              {type === "edit" ? "Editar" : "Criar"} certificado
            </PageTitle>
            <StyledGrid container>
              <StyledGrid item sm={12} md={12} lg={3}>
                <div style={{ marginRight: "24px" }}>
                  <StyledTitle variant="headline7">
                    Dados do certificado
                  </StyledTitle>
                  <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                    <StyledTypography variant="body3">
                      Adicione as informações básicas do seu modelo de
                      certificado, como nome de identificação e personalização.
                    </StyledTypography>
                  </div>
                </div>
              </StyledGrid>

              <StyledGrid item sm={12} md={12} lg={5}>
                {isGettingCertificateById ? (
                  <Skeleton
                    width="100%"
                    height="100%"
                    animation="pulse"
                    variant="rectangular"
                  />
                ) : (
                  <form onSubmit={formik.handleSubmit}>
                    <SectionContainer>
                      <StyledTypography variant="headline8">
                        Nome de identificação
                      </StyledTypography>
                      <div style={{ marginBottom: "32px", marginTop: "4px" }}>
                        <StyledTypography variant="body4">
                          Dê um nome para identificar o modelo do certificado.
                        </StyledTypography>
                      </div>

                      <StyledTextField
                        id={"name"}
                        name={"name"}
                        label={"Nome de identificação do certificado"}
                        inputProps={{ maxLength: 50 }}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={
                          formik.touched.name && (formik.errors.name as string)
                        }
                      />

                      <SubtitleContainer>
                        <StyledTypography variant="caption">
                          Este campo é obrigatório.
                        </StyledTypography>
                        <StyledTypography variant="caption">
                          {formik.values.name.length}/50
                        </StyledTypography>
                      </SubtitleContainer>
                    </SectionContainer>
                    <SectionContainer>
                      <StyledTypography variant="headline8">
                        Personalização do certificado
                      </StyledTypography>
                      {formik?.values?.companyLogo?.path ? (
                        <StyledBannerContainer>
                          <img
                            src={`${formik?.values?.companyLogo?.path}${formik?.values?.companyLogo?.signature}`}
                            alt="company logo"
                          />
                          <SubImageHoverContainer>
                            <IconButtonHover
                              size="large"
                              style={{ alignSelf: "center" }}
                              onClick={handleDeleteImage}
                            >
                              <Icons name="IconTrash" />
                            </IconButtonHover>
                          </SubImageHoverContainer>
                        </StyledBannerContainer>
                      ) : (
                        <DropzoneArea>
                          <Dropzone
                            title="Imagem do logo"
                            infoContent="Resolução mínima: 300 x 275 pixels. Peso máximo: 10 MB"
                            maxSize={10906316}
                            onFileSizeError={() =>
                              dispatchToast({
                                content: "A imagem deve ser menor que 10 MB.",
                                type: "error",
                              })
                            }
                            onChange={(file) => {
                              formik.handleChange({
                                target: {
                                  id: "companyLogo",
                                  value: file[0],
                                },
                              });
                            }}
                            accept={["png", "jpeg"]}
                          />
                        </DropzoneArea>
                      )}
                      <StyledTextField
                        id={"companyName"}
                        name={"companyName"}
                        label={"Nome da instituição"}
                        inputProps={{ maxLength: 200 }}
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.companyName &&
                          Boolean(formik.errors.companyName)
                        }
                        helperText={
                          formik.touched.companyName &&
                          (formik.errors.companyName as string)
                        }
                      />

                      <SubtitleContainer>
                        <StyledTypography variant="caption">
                          Este campo é obrigatório.
                        </StyledTypography>
                        <StyledTypography variant="caption">
                          {formik.values.companyName.length}/200
                        </StyledTypography>
                      </SubtitleContainer>

                      <SelectArea>
                        <SelectField
                          label="Período de validade"
                          fullWidth
                          value={formik.values.expiresDate ?? 0}
                          onSelectChange={(e, { value }) => {
                            track({
                              name:
                                value === 0
                                  ? "company_lms_courseinformation_noexpirationdate_clicked"
                                  : `company_lms_courseinformation_expirationdate${
                                      value === 1
                                        ? "one"
                                        : value === 2
                                        ? "two"
                                        : value === 3
                                        ? "tree"
                                        : value === 4
                                        ? "four"
                                        : value === 5
                                        ? "five"
                                        : "zero"
                                    }year${value === 1 ? "" : "s"}_clicked`,
                            });
                            formik.handleChange({
                              target: {
                                id: "expiresDate",
                                value: value,
                              },
                            });
                          }}
                          options={[
                            { label: "Sem validade", value: 0 },
                            {
                              label: "1 ano",
                              value: 1,
                            },
                            {
                              label: "2 anos",
                              value: 2,
                            },
                            {
                              label: "3 anos",
                              value: 3,
                            },
                            {
                              label: "4 anos",
                              value: 4,
                            },
                            {
                              label: "5 anos",
                              value: 5,
                            },
                          ]}
                        />
                      </SelectArea>
                      <SubtitleContainer>
                        <StyledTypography variant="caption">
                          O período de validade só contará a partir da conclusão
                          do treinamento.
                        </StyledTypography>
                      </SubtitleContainer>

                      <ToggleArea>
                        <div>
                          <Toggle
                            checked={formik.values.haveSignature ?? false}
                            onChange={({ target: { checked } }) => {
                              if (checked === false) {
                                formik.handleChange({
                                  target: {
                                    id: "signText",
                                    value: "",
                                  },
                                });
                                formik.handleChange({
                                  target: {
                                    id: "signerName",
                                    value: "",
                                  },
                                });
                                formik.handleChange({
                                  target: {
                                    id: "signerPostion",
                                    value: "",
                                  },
                                });
                              }
                              track({
                                name: "company_lms_certificateinformation_signature_clicked",
                              });
                              formik.handleChange({
                                target: {
                                  id: "haveSignature",
                                  value: checked,
                                },
                              });
                            }}
                          />
                        </div>
                        <div>
                          <Typography variant="body3">Assinatura</Typography>
                          <Typography variant="body4">
                            Selecione esta opção para inserir os dados da
                            assinatura.
                          </Typography>
                        </div>
                      </ToggleArea>

                      {formik?.values?.haveSignature ? (
                        <>
                          <StyledTextField
                            id={"signText"}
                            name={"signText"}
                            label={"Texto da assinatura"}
                            inputProps={{ maxLength: 50 }}
                            value={formik.values.signText}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.signText &&
                              Boolean(formik.errors.signText)
                            }
                            helperText={
                              formik.touched.signText &&
                              (formik.errors.signText as string)
                            }
                          />

                          <SubtitleContainer>
                            <StyledTypography variant="caption">
                              Este campo é obrigatório.
                            </StyledTypography>
                            <StyledTypography variant="caption">
                              {formik.values.signText.length}/50
                            </StyledTypography>
                          </SubtitleContainer>

                          <StyledTextField
                            id={"signerName"}
                            name={"signerName"}
                            label={"Nome do assinante"}
                            inputProps={{ maxLength: 50 }}
                            value={formik.values.signerName}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.signerName &&
                              Boolean(formik.errors.signerName)
                            }
                            helperText={
                              formik.touched.signerName &&
                              (formik.errors.signerName as string)
                            }
                            className="field-margin"
                          />

                          <SubtitleContainer>
                            <StyledTypography variant="caption">
                              Este campo é obrigatório.
                            </StyledTypography>
                            <StyledTypography variant="caption">
                              {formik.values.signerName.length}/100
                            </StyledTypography>
                          </SubtitleContainer>

                          <StyledTextField
                            id={"signerPostion"}
                            name={"signerPostion"}
                            label={"Cargo do assinante"}
                            inputProps={{ maxLength: 50 }}
                            value={formik.values.signerPostion}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.signerPostion &&
                              Boolean(formik.errors.signerPostion)
                            }
                            helperText={
                              formik.touched.signerPostion &&
                              (formik.errors.signerPostion as string)
                            }
                            className="field-margin"
                          />

                          <SubtitleContainer>
                            <StyledTypography variant="caption">
                              Este campo é opcional.
                            </StyledTypography>
                            <StyledTypography variant="caption">
                              {formik.values.signerPostion.length}/100
                            </StyledTypography>
                          </SubtitleContainer>
                        </>
                      ) : null}
                    </SectionContainer>
                  </form>
                )}
              </StyledGrid>

              <StyledGrid item sm={12} md={12} lg={4}>
                <FixedPreview>
                  <CertificateArea>
                    <PreviewArea>
                      <CertificatePreview data={formik.values} />
                    </PreviewArea>
                  </CertificateArea>
                  <ExpandButton>
                    <Button
                      onClick={() => setModalOpen(true)}
                      variant="secondary"
                      size="medium"
                    >
                      <Icons name="IconArrowsDiagonal" />
                      Expandir certificado
                    </Button>
                  </ExpandButton>
                </FixedPreview>
              </StyledGrid>
            </StyledGrid>
          </StyledPageContainer>
          <FooterBar
            isLoading={
              isGettingCertificateById ||
              isEditingCertificate ||
              isCreatingCertificate
            }
            cancelHandleClick={async () => {
              track({
                name: "company_lms_certificateinformation_exit_clicked",
              });
              navigate("/lms/manage-courses/certificates");
            }}
            nextHandleClick={async () => {
              track({
                name: "company_lms_certificateinformation_create_clicked",
              });
              setSaveAsDraft(false);
              await formik.setFieldValue("saveAsDraft", false);
              const isValidToSubmit = await formik.validateForm();

              if (Object.keys(isValidToSubmit).length)
                dispatchToast({
                  content: "Alguns campos estão incorretos. Favor verificar",
                  type: "error",
                });
              formik.handleSubmit();
            }}
            nextLabel={`${type === "edit" ? "Salvar" : "Criar"} certificado`}
            cancelAndSaveHandleClick={async () => {
              track({
                name: "company_lms_certificateinformation_savedraft_clicked",
              });
              setSaveAsDraft(true);
              await formik.setFieldValue("saveAsDraft", true);
              const isValidToSubmit = await formik.validateForm();

              if (Object.keys(isValidToSubmit).length)
                dispatchToast({
                  content: "Alguns campos estão incorretos. Favor verificar",
                  type: "error",
                });

              formik.handleSubmit();
            }}
            steps={["Dados do certificado"]}
            currentStep={0}
            noSaveLabel="Sair sem salvar"
            disabledProgress={false}
          />
          <PreviewCertificate
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            data={formik.values}
          />
          <SuccessCertificate
            onClose={() => {
              setCertificate({});
              navigate(`/lms/manage-courses/certificates`);
            }}
            open={successsModal}
            type={type}
          />
        </Container>
      )}
    </>
  );
};

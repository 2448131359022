import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledIconButton,
  SubTitle,
  Description,
  ModalBody,
  WarnButton,
} from "./styled";

export const RedoExamModal = ({
  open,
  onClose,
  handleClick,
  employeesQuantity = 0,
  loading = false,
}) => {
  const hasMoreThanOne = employeesQuantity > 1;

  return (
    <StyledModal onClose={onClose} open={open}>
      <>
        <StyledIconButton
          size="small"
          variant="line"
          icon="IconX"
          onClick={onClose}
        />
        <ModalBody>
          <StyledFlexBetween>
            <div>
              <StyledIconContainer>
                <StyledIcon
                  size={48}
                  name="IconAlertCircle"
                  fill="transparent"
                />
              </StyledIconContainer>
            </div>
            <StyledTitle variant="headline7">Atenção!</StyledTitle>
          </StyledFlexBetween>
          <StyledContainer>
            <SubTitle variant="headline6">
              {hasMoreThanOne
                ? "Tem certeza que deseja reiniciar a avaliação?"
                : "Tem certeza que deseja reiniciar a avaliação deste aluno?"}
            </SubTitle>
            <Description variant="body3">
              {hasMoreThanOne
                ? `A nota dos alunos será zerada e substituída pela nota da nova avaliação. Alunos que já obtiveram certificado permanecerão com o certificado emitido.`
                : "O aluno terá sua nota zerada. Se ele já tiver emitido certificado, permanecerá com o certificado emitido."}
            </Description>
          </StyledContainer>
        </ModalBody>
        <StyledButtonContainer>
          <LinkButton variant="secondary" onClick={onClose}>
            Não refazer
          </LinkButton>
          <WarnButton
            size="large"
            variant="primary"
            onClick={handleClick}
            variantType="error"
            loading={loading}
          >
            Reiniciar avaliação
            <Icons name="IconRestore" size={24} />
          </WarnButton>
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

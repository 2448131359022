import {
  ArticleViewer,
  ContentArea,
  ContentContainer,
  SubContainer,
} from "./styled";

import { Grid } from "@mui/material";

import { NextPreviousMenu } from "../Common/NextPreviousMenu";

import { Accordion } from "../Common/Accordion";
import { CourseDescription } from "../Common/CourseDescription";
import { useTheme } from "styled-components";

import { Player } from "../../../components/Player";

import { PlayerTitle } from "./components/PlayerTitle";

import {
  Description,
  BlockNavigation,
  Tabs,
  ExamView,
  PDFVisualizer,
} from "../Common";

const Desktop = ({
  course,
  courseId,
  modules,
  moduleId,
  selected,
  setSelected,
  isDescription,
  setIsDescription,
  isDone,
  setIsDone,
  viewType,
  setIsExpanded,
  handleDoneContent,
  donePdfArticle,
  classId,
  isGettingCoursesModules,
}) => {
  const theme = useTheme();

  const isNotDisabled = selected && !!!selected?.disabled;

  const renderComponent = {
    pdf: (
      <ContentContainer container>
        <SubContainer>
          <PlayerTitle selected={selected} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              height: "640px",
            }}
          >
            <PDFVisualizer
              path={`${selected?.url?.path}${selected?.url?.signature ?? null}`}
              setIsExpanded={setIsExpanded}
            />
            {donePdfArticle()}
          </div>
          <Tabs selected={selected} defaultTab={0} />
        </SubContainer>
      </ContentContainer>
    ),
    exam: (
      <ExamView
        selected={selected}
        handleDoneContent={handleDoneContent}
        setIsDone={setIsDone}
        isDone={isDone}
        viewType={viewType}
        isMobile={false}
      />
    ),
    article: (
      <ContentContainer container>
        <SubContainer>
          <PlayerTitle selected={selected} />
          <ArticleViewer
            dangerouslySetInnerHTML={{
              __html:
                selected?.content?.html ||
                selected?.url?.path?.html ||
                "Não foi possível visualizar",
            }}
          />
          {donePdfArticle()}
          <Tabs selected={selected} defaultTab={0} />
        </SubContainer>
      </ContentContainer>
    ),
    media: (
      <ContentContainer container>
        <SubContainer>
          <PlayerTitle selected={selected} />
          <Player
            mediaToPlay={{
              uri:
                selected?.type == "podcast" &&
                selected?.url?.origin === "internal"
                  ? selected?.url?.path + selected?.url?.signature
                  : selected?.url?.path || "",
              signature: selected?.url?.signature || "",
            }}
            selected={selected}
            setSelected={setSelected}
          />
          <Tabs selected={selected} defaultTab={0} />
        </SubContainer>
      </ContentContainer>
    ),
  };

  return (
    <ContentArea>
      <Grid container width={"100%"} height={"100%"}>
        <Grid
          item
          md={4}
          width={"100%"}
          height={"100%"}
          display={"flex"}
          flexDirection={"column"}
          border={`1px solid ${theme.colors.neutral[90]}`}
          bgcolor={theme.colors.neutral[100]}
        >
          <CourseDescription
            viewType={viewType}
            course={course}
            isGettingCoursesModules={isGettingCoursesModules}
          />

          <Accordion
            viewType={viewType}
            modules={modules}
            moduleId={moduleId}
            courseId={courseId}
            selected={selected}
            setSelected={setSelected}
            isDescription={isDescription}
            setIsDescription={setIsDescription}
            isGettingCoursesModules={isGettingCoursesModules}
            openMobileDrawer={null}
          />
        </Grid>
        <Grid
          item
          md={8}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            border: `1px solid ${theme.colors.neutral[90]}`,
            borderLeft: "0px",
          }}
        >
          <NextPreviousMenu
            viewType={viewType}
            selected={selected}
            courseId={courseId}
            setSelected={setSelected}
            isDescription={isDescription}
            setIsDescription={setIsDescription}
          />
          <BlockNavigation
            viewType={viewType}
            modules={modules}
            moduleId={moduleId}
            courseId={courseId}
            blockNavigation={!!course?.blockNavigation}
            classId={classId}
            selected={selected}
          />
          {isDescription ? (
            <ContentContainer container>
              <SubContainer>
                <Description course={course} />
              </SubContainer>
            </ContentContainer>
          ) : (
            <></>
          )}

          {isNotDisabled ? (
            renderComponent[
              ["podcast", "video"].includes(selected?.type)
                ? "media"
                : selected?.type
            ]
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </ContentArea>
  );
};

export { Desktop };

import styled from "styled-components";
import { Button as ButtonMui, Grid } from "@mui/material";
import {
  IconButton,
  Icons,
  PageContainer,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StepperArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

export const StyledIconToButton = styled(ButtonMui)<{ selected: boolean }>`
  svg {
    stroke: ${({ theme, selected }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : theme.colors.neutral.dark.dark5};
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    .MuiGrid-root {
      flex-grow: 1;
    }
  }
`;

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
  padding-top: 60px !important;
`;

export const Container = styled.div`
  margin: 40px 0px;
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

export const PageInfo = styled.div`
  margin-right: 24px;
`;

export const PageSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.secondary[50]};
  margin-bottom: 16px;
  font-weight: 700;
`;

export const PageDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;

export const SubImageHoverContainer = styled.div`
  background: rgba(29, 22, 27, 0.4);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  opacity: 0;
  z-index: 10;

  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const IconButtonHover = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
        opacity: 0.6;
      }
      svg {
        stroke: ${({ theme }) => theme.colors.neutral.light.pure};
        width: 24px;
        height: 24px;
      }
    }
  }
`;

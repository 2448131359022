import { Controls, Gesture } from "@vidstack/react";

import { TimeGroup } from "./components/TimeGroup";
import { SliderTime } from "./components/SliderTime";
import {
  Play,
  Backward5,
  Forward5,
  Mute,
  Fullscreen,
} from "./components/Buttons";

import { SpeedMenu } from "./components/Menu";

import "./styles.css";

export interface VideoLayoutProps {
  thumbnails?: string;
}

export function VideoLayout() {
  return (
    <>
      <Gestures />

      <Controls.Root className={"controls"}>
        <div className={"spacer"} />

        <Controls.Group className={"controlsGroup"}>
          <div className={"spacer"} />
          <TimeGroup />
        </Controls.Group>

        <Controls.Group className={"controlsGroup"}>
          <SliderTime />
        </Controls.Group>

        <Controls.Group className={"controlsGroup"}>
          <Play />
          <Backward5 />
          <Forward5 />

          <div className={"spacer"} />

          <SpeedMenu placement="top end" />

          <Mute />
          <Fullscreen />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

const Gestures = () => {
  return (
    <>
      <Gesture className={"gesture"} event="pointerup" action="toggle:paused" />
      <Gesture
        className={"gesture"}
        event="dblpointerup"
        action="toggle:fullscreen"
      />
      <Gesture
        className={"gesture"}
        event="pointerup"
        action="toggle:controls"
      />
      <Gesture className={"gesture"} event="dblpointerup" action="seek:-10" />
      <Gesture className={"gesture"} event="dblpointerup" action="seek:10" />
    </>
  );
};

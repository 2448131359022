import { useEffect, useRef, useState } from "react";
import { SnapList, useScroll } from "react-snaplist-carousel";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import { Collapse } from "@mui/material";
import { IconButton, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import MetricInfo from "../MetricInfo";
import {
  CarouselDot,
  ChartArea,
  ChartGraph,
  ChartInfo,
  ChartSubTitle,
  ChartTitle,
  ChevronArea,
  DotsArea,
  EmptyArea,
  InfoText,
  MetricsHeader,
  SnapGrid,
  SnapGrid8,
  StepArea,
  Title,
  ToggleButton,
} from "./styles";

export const CourseMetrics = ({ courseMetrics }) => {
  ChartJS.register(ArcElement, Tooltip, Legend, LinearScale);

  const snapList = useRef(null);
  const goToSnapItem = useScroll({ ref: snapList });
  const [snapItem, setSnapItem] = useState<any>(0);
  const [open, setOpen] = useState<any>(true);
  const [chartInfo, setChartInfo] = useState<any>([0, 0, 0]);

  useEffect(() => {
    setChartInfo([
      courseMetrics?.notStarted,
      courseMetrics?.doing,
      courseMetrics?.done,
    ]);
  }, [courseMetrics]);

  const data = {
    datasets: [
      {
        data: chartInfo,
        backgroundColor: ["#FEA034", "#4DA0FE", "#2ED9B8"],
        weight: 2,
      },
    ],
  };

  const handlePrevious = () => {
    setSnapItem(0);
    goToSnapItem(0);
  };

  const handleNext = () => {
    setSnapItem(3);
    goToSnapItem(3);
  };

  return (
    <div>
      <MetricsHeader>
        <Title variant="headline8">Insights</Title>
        <ToggleButton onClick={() => setOpen(!open)} variant="primary">
          {open ? "Ocultar insights" : "Exibir insights"}
          {open ? (
            <Icons name="IconChevronsUp" fill="transparent" />
          ) : (
            <Icons name="IconChevronsDown" fill="transparent" />
          )}
        </ToggleButton>
      </MetricsHeader>
      <Collapse in={open}>
        <SnapList
          direction="horizontal"
          ref={snapList}
          tabIndex={0}
          role="region"
          aria-label="my awesome snaplist"
        >
          <SnapGrid snapAlign="start">
            <MetricInfo
              iconName="IconUsers"
              title="Total de alunos"
              subtitle={courseMetrics?.totalPeople ?? 0}
            />
            <MetricInfo
              iconName="IconBook"
              title="Quantidade de aulas"
              subtitle={`${courseMetrics?.totalClasses ?? 0}`}
            />
            <MetricInfo
              iconName="IconClock"
              title="Carga horária"
              subtitle={`${courseMetrics?.totalHours ?? 0}h`}
            />
          </SnapGrid>
          <SnapGrid8 snapAlign="start">
            <ChartArea>
              <ChartTitle variant="headline8">
                Status dos alunos nos treinamentos
              </ChartTitle>
              <ChartSubTitle variant="body4">Total</ChartSubTitle>
              <ChartInfo>
                <ChartGraph>
                  <Doughnut
                    data={data}
                    options={{
                      cutout: 45,
                      responsive: false,
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                      },
                    }}
                  />
                </ChartGraph>
                <div>
                  <InfoText variant="body4">
                    <Icons name="IconPentagon" fill="#FEA034" />
                    <b>{chartInfo[0] ?? 0}%</b> Não iniciado
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconTriangle" fill="#4da0fe" />
                    <b>{chartInfo[1] ?? 0}%</b> Em andamento
                  </InfoText>
                  <InfoText variant="body4">
                    <Icons name="IconPlayerStop" fill="#2ED9B8" />
                    <b>{chartInfo[2] ?? 0}%</b> Concluído
                  </InfoText>
                </div>
              </ChartInfo>
            </ChartArea>
          </SnapGrid8>
          {courseMetrics?.hasExams ? (
            <>
              <SnapGrid snapAlign="start">
                <MetricInfo
                  iconName="IconClipboardCheck"
                  title="Taxa de conclusão"
                  subtitle={`${courseMetrics?.doneCourseRate ?? 0}%`}
                  studentsInfo={courseMetrics?.doneCourseStudents ?? 0}
                />
                <MetricInfo
                  iconName="IconNotebook"
                  title="Nota média"
                  subtitle={`${courseMetrics?.averageGrade ?? 0}/10`}
                />
                <MetricInfo
                  iconName="IconUserCheck"
                  title="Taxa de aprovação"
                  subtitle={`${courseMetrics?.passRate ?? 0}%`}
                  studentsInfo={courseMetrics?.passRateStudents ?? 0}
                  tooltipText="A taxa de aprovação representa a quantidade de alunos aprovados sobre o total de alunos participantes."
                />
              </SnapGrid>
              <SnapGrid snapAlign="start">
                <MetricInfo
                  iconName="IconUserX"
                  title="Taxa de reprovação"
                  subtitle={`${courseMetrics?.failureRate ?? 0}%`}
                  studentsInfo={courseMetrics?.failureRateStudents ?? 0}
                  tooltipText="A taxa de reprovação representa a quantidade de alunos reprovados sobre o total de alunos participantes."
                />
                <MetricInfo
                  iconName="IconUserOff"
                  title="Taxa de abandono"
                  subtitle={`${courseMetrics?.abandonRate ?? 0}%`}
                  studentsInfo={courseMetrics?.abandonRateStudents ?? 0}
                  tooltipText="A taxa de abandono representa a quantidade de alunos que passam mais de 5 dias sem acessar os treinamentos sobre o total de alunos do treinamento."
                />
              </SnapGrid>
            </>
          ) : (
            <SnapGrid snapAlign="start">
              <MetricInfo
                iconName="IconClipboardCheck"
                title="Taxa de conclusão"
                subtitle={`${courseMetrics?.doneCourseRate ?? 0}%`}
                studentsInfo={courseMetrics?.doneCourseStudents ?? 0}
              />
              <MetricInfo
                iconName="IconUserOff"
                title="Taxa de abandono"
                subtitle={`${courseMetrics?.abandonRate ?? 0}%`}
                studentsInfo={courseMetrics?.abandonRateStudents ?? 0}
                tooltipText="A taxa de abandono representa a quantidade de alunos que passam mais de 5 dias sem acessar os treinamentos sobre o total de alunos do treinamento."
              />
            </SnapGrid>
          )}
        </SnapList>
        <StepArea>
          <EmptyArea />
          <DotsArea>
            <CarouselDot onClick={handlePrevious} active={snapItem === 0} />
            <CarouselDot onClick={handleNext} active={snapItem === 3} />
          </DotsArea>
          <ChevronArea>
            <IconButton
              variant="line"
              size="medium"
              icon="IconChevronLeft"
              onClick={handlePrevious}
              disabled={snapItem === 0}
            />
            <IconButton
              variant="line"
              size="medium"
              icon="IconChevronRight"
              onClick={handleNext}
              disabled={snapItem === 3}
            />
          </ChevronArea>
        </StepArea>
      </Collapse>
    </div>
  );
};

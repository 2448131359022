import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  CloseButton,
  HeaderArea,
  SuccessMessage,
} from "./styled";

import { trpc } from "@api/client";
import { getFromLS } from "@flash-tecnologia/hros-web-utility";

export const GenerateExamAnswersheetReportModal = ({
  open,
  onClose,
  employeeName = "",
  employeeId = "",
  learningObjectId = "",
}) => {
  const { courseId = "", examId = null, studentId = null } = useParams();
  const [successs, setSuccess] = useState<any>(false);
  const [hasClicked, setHasClicked] = useState<any>(false);

  const {
    attributes: { email = "" },
  } = getFromLS("userAuth");

  const handleClose = () => {
    setSuccess(false);
    setHasClicked(false);
    onClose();
  };

  const { isFetching } = trpc.reports.getEmployeeExamAnswersheetById.useQuery(
    {
      courseId,
      examId: examId?.length ? examId : learningObjectId,
      employeeId: studentId?.length ? studentId : employeeId,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!hasClicked,
      onSuccess: () => {
        setSuccess(true);
        setHasClicked(false);
      },
    }
  );

  return (
    <StyledModal isLoading={isFetching} onClose={handleClose} open={open}>
      <>
        <HeaderArea>
          <StyledIconContainer>
            <StyledIcon
              size={48}
              name={successs ? "IconCheck" : "IconTable"}
              fill="transparent"
            />
          </StyledIconContainer>
          {successs ? (
            <SuccessMessage variant="body3">Pronto!</SuccessMessage>
          ) : null}
          <CloseButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={handleClose}
          />
        </HeaderArea>
        <StyledContainer>
          <StyledTitle variant="headline6">
            {successs
              ? "Sua avaliação está sendo exportada!"
              : "Deseja exportar a avaliação?"}
          </StyledTitle>
          {successs ? (
            <StyledSubTitle variant="body3">
              Para acessá-la é só conferir o e-mail que será enviado em
              <br />
              alguns minutos para o endereço:
              <br />
              <b>{email}</b>
              <br />
              <br />
              Para acessar os dados utilize os{" "}
              <b>
                4 primeiros dígitos do <br />
                seu CPF
              </b>{" "}
              como senha.
            </StyledSubTitle>
          ) : (
            <StyledSubTitle variant="body3">
              As respostas da avaliação do aluno {employeeName}
              <br />
              será convertida em um arquivo csv e enviada para o email:
              <br />
              <br />
              <b>{email}</b>
            </StyledSubTitle>
          )}
        </StyledContainer>
        <StyledButtonContainer>
          {successs ? (
            <Button size="large" variant="primary" onClick={handleClose}>
              Voltar para Detalhe da Avaliação
            </Button>
          ) : (
            <>
              <LinkButton
                variant="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                Cancelar
              </LinkButton>
              <Button
                size="large"
                variant="primary"
                loading={false}
                onClick={async () => {
                  setHasClicked(true);
                }}
              >
                Exportar avaliação
                <Icons name="IconDownload" fill="transparent" />
              </Button>
            </>
          )}
        </StyledButtonContainer>
      </>
    </StyledModal>
  );
};

import { Controls, Gesture } from "@vidstack/react";

import { TimeGroup } from "./components/TimeGroup";
import { SliderTime } from "./components/SliderTime";
import {
  Play,
  Backward5,
  Forward5,
  Fullscreen,
  SpeedButton,
} from "./components/Buttons";

import "./styles.css";

export interface VideoLayoutProps {
  thumbnails?: string;
}

export function MobileLayout() {
  return (
    <>
      <Gestures />
      <Controls.Root className={"controls"} hideDelay={0}>
        <Controls.Group className={"controlsGroup"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
              gap: "8px",
              marginTop: "10px",
            }}
          >
            <SpeedButton />
            <Fullscreen />
          </div>
        </Controls.Group>

        <div className={"spacer"} />

        <Controls.Group className={"controlsGroup"}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "12px",
            }}
          >
            <Backward5 />
            <Play />
            <Forward5 />
          </div>
        </Controls.Group>

        <div className={"spacer"} />

        <Controls.Group className={"controlsGroup"}>
          <div className={"spacer"} />
          <TimeGroup />
        </Controls.Group>

        <Controls.Group className={"controlsGroup"}>
          <SliderTime />
        </Controls.Group>
      </Controls.Root>
    </>
  );
}

const Gestures = () => {
  return (
    <>
      <Gesture className={"gesture"} event="pointerup" action="toggle:paused" />
      <Gesture
        className={"gesture"}
        event="dblpointerup"
        action="toggle:fullscreen"
      />
      <Gesture
        className={"gesture"}
        event="pointerup"
        action="toggle:controls"
      />
      <Gesture className={"gesture"} event="dblpointerup" action="seek:-5" />
      <Gesture className={"gesture"} event="dblpointerup" action="seek:5" />
    </>
  );
};

import styled from "styled-components";
import {
  LinkButton,
  SearchField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { SwipeableDrawer, Accordion } from "@mui/material";

export const StyledSearchField = styled(SearchField)`
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 30px;
  }
  margin-bottom: 24px;
  width: 100%;
  height: 60px;
`;

export const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    max-height: calc(100% - 65px);
  }
`;

export const AccordionTitle = styled(Typography)`
  font-size: 14px !important;
  font-weight: 700 !important;
  color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid rgb(235, 230, 233);
`;

export const FilterArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const FilterLabel = styled(Typography)`
  width: fit-content;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  white-space: nowrap;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid rgb(235, 230, 233);
  padding: 24px;
`;

export const StyledAccordion = styled(Accordion)`
  z-index: 0;
  width: 100%;
  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 8px;
    box-shadow: none;
    margin: 0;
  }
  .mobile-accordion .MuiAccordionSummary-content div {
    margin-left: 0px !important;
  }
`;

export const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.primary} !important;
`;

export const SwipeableIcon = styled.div`
  width: 40px;
  height: 4px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.neutral[60]};
`;

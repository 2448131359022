import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Checkbox,
  IconButton,
  Icons,
  LinkButton,
  Menu,
  Table,
  Tag,
  TagDateFilter,
  Tooltip,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledText,
  StyledTitle,
  dispatchToast,
  pageSizeOptions,
} from "@utils";
import {
  OptionArea,
  StyledBetween,
  StyledSelect,
  CounterTag,
  AnswerText,
} from "./styled";

import Avatar from "./Avatar";
import { trpc } from "@api/client";
import { GenerateExamsReportModal } from "@components/GenerateExamsReportModal";
import { GenerateExamAnswersheetReportModal } from "@components/GenerateExamAnswersheetReportModal";
import { RedoExamModal } from "../../../../../components/Modals/RedoExamModal";

export const ExamAnswersPerformance = ({
  exam,
  setExam,
  learningObjects,
  search,
  loading,
}) => {
  const utils = trpc.useContext();
  const navigate = useNavigate();
  const { courseId = "" } = useParams();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const [rawExamAnswers, seRawExamAnswers] = useState<any>([]);
  const [examAnswers, setExamAnswers] = useState<any>([]);
  const [examsQuestions, setExamsQuestions] = useState<any>([]);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selected, setSelected] = useState<any>([]);

  const [redoExamModal, setRedoExamModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [reportData, setReportData] = useState({
    name: "",
    employeeId: "",
    learningObjectId: "",
  });

  const { mutate: redoExamMutate, isLoading: isRedoExam } =
    trpc.monitorings.redoExam.useMutation({});

  const { isLoading: isGettingCourseExamAnswers } =
    trpc.exams.getEmployeeExamsAnswers.useQuery(
      { courseId, examId: exam },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!courseId && !!exam,
        onSuccess: ({ value }) => {
          setExamAnswers(value?.students);
          seRawExamAnswers(value?.students);
          setExamsQuestions(
            value?.questions?.map(({ question = "", _id }, index) => ({
              header: question,
              accessorKey: `${index}`,
              cell: ({ row: { original } }) => {
                const { answers } = original;
                return (
                  <AnswerText variant="body3">
                    {
                      answers?.filter(({ questionId }) => questionId === _id)[0]
                        ?.answerText
                    }
                  </AnswerText>
                );
              },
            })) || []
          );
        },
      }
    );

  useEffect(() => {
    if (search) {
      const cleanValue = search.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
      const regex = new RegExp(cleanValue, "gi");

      const orderedExamsAnswers = rawExamAnswers?.sort((a, b) => {
        if (a?.name < b?.name) return -1;
      });

      const filteredExamsAnswers = orderedExamsAnswers?.filter(
        ({ learningObjectId }) => learningObjectId === exam
      );

      setExamAnswers(() =>
        filteredExamsAnswers?.filter(({ name = "" }) =>
          name
            ?.toLowerCase()
            ?.normalize("NFD")
            ?.replace(/[\u0300-\u036f]/g, "")
            ?.match(regex)
        )
      );
    } else setExamAnswers(rawExamAnswers);
  }, [search, exam]);

  const table = tableControllers.useTableColumns<any>({
    columns: [
      {
        header: (
          <>
            <Checkbox
              onChange={() => {
                if (!selectedAll) {
                  const allIds = rawExamAnswers?.map((r) => r?._id);
                  setSelected(allIds);
                } else {
                  setSelected([]);
                }

                setSelectedAll(!selectedAll);
              }}
              checked={selected?.length == rawExamAnswers?.length}
            />
          </>
        ),
        accessorKey: "check",
        cell: ({ row: { original } }) => {
          const foundChecked = !!selected?.find((s) => s == original?._id);

          return (
            <Checkbox
              key={selected}
              onChange={() => {
                const allItems = foundChecked
                  ? selected.filter((s) => s != original?._id)
                  : [...selected, original?._id];

                const allItemsChecked =
                  allItems?.length == rawExamAnswers?.length;

                setSelected(allItems);

                if (allItemsChecked) setSelectedAll(true);
                else setSelectedAll(false);
              }}
              checked={foundChecked}
            />
          );
        },
      },
      {
        header: "Aluno",
        accessorKey: "name",
        cell: ({ row: { original } }) => {
          return <Avatar name={original?.name || ""} />;
        },
      },
      ...examsQuestions,
      {
        header: "Ações",
        sticky: "right",
        accessorKey: "actions",
        cell: ({ row: { original } }) => {
          const { status, name, _id, learningObjectId } = original;

          const options = [
            {
              onClick: () => {
                navigate(`/lms/manage-courses/detail-student/${_id}`);
              },
              children: (
                <OptionArea>
                  <Icons fill="transparent" name="IconUser" />
                  <StyledText variant="body3">Ver detalhes do aluno</StyledText>
                </OptionArea>
              ),
            },
          ];

          if (status != "beforeInit") {
            options.push(
              {
                onClick: () => {
                  navigate(
                    `/lms/manage-courses/detail-exam/${courseId}/${learningObjectId}/${_id}`
                  );
                },
                children: (
                  <OptionArea>
                    <Icons fill="transparent" name="IconReportAnalytics" />
                    <StyledText variant="body3">
                      Ver gabarito do aluno
                    </StyledText>
                  </OptionArea>
                ),
              },
              {
                onClick: () => {
                  setRedoExamModal(true);
                  setSelected([_id]);
                },
                children: (
                  <OptionArea>
                    <Icons fill="transparent" name="IconRestore" size={24} />
                    <StyledText variant="body3">
                      Reiniciar avaliação do aluno
                    </StyledText>
                  </OptionArea>
                ),
              },
              {
                onClick: () => {
                  setReportData((prev) => ({
                    ...prev,
                    name,
                    employeeId: _id,
                    learningObjectId,
                  }));
                  setOpenExamModal(true);
                },
                children: (
                  <OptionArea>
                    <Icons fill="transparent" name="IconDownload" />
                    <StyledText variant="body3">
                      Exportar respostas do aluno
                    </StyledText>
                  </OptionArea>
                ),
              }
            );
          } else {
            options.push({
              onClick: () => {
                setRedoExamModal(true);
                setSelected([_id]);
              },
              children: (
                <OptionArea>
                  <Icons fill="transparent" name="IconRestore" size={24} />
                  <StyledText variant="body3">
                    Reiniciar avaliação do aluno
                  </StyledText>
                </OptionArea>
              ),
            });
          }

          return (
            <>
              <Menu
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                type="select"
                options={options}
              >
                <IconButton
                  size="small"
                  variant="line"
                  icon="IconDotsVertical"
                />
              </Menu>
            </>
          );
        },
      },
    ],
    data: (examAnswers as any) || [],
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  const finalRows = table.rows.slice(
    (pagination?.pageNumber - 1) * pagination?.pageSize,
    pagination?.pageNumber * pagination?.pageSize
  );

  return (
    <>
      <Table.Root>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <StyledTitle variant="headline8" setColor="neutral30">
            Respostas
          </StyledTitle>
          <CounterTag variant="primary">
            {examAnswers?.length} iten{examAnswers?.length > 1 ? "s" : ""}
          </CounterTag>
        </div>

        <StyledBetween>
          <StyledSelect
            label="Selecionar avaliação"
            options={learningObjects ?? []}
            onSelectChange={(e, { value }) => setExam(value)}
            value={exam}
          />

          <Tooltip arrow title="Exportar relatório">
            <div>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
                icon="IconDownload"
                size="small"
                variant="line"
              />
            </div>
          </Tooltip>
        </StyledBetween>

        <Table.Grid.Root
          loading={isGettingCourseExamAnswers || isRedoExam || loading}
        >
          {selected?.length > 0 ? (
            <tr
              className="data-grid-table-header-bulk-actions-container"
              role="row"
            >
              <th className="data-grid-table-header-bulk-actions">
                <Checkbox
                  onChange={() => {
                    if (!selectedAll) {
                      const allIds = rawExamAnswers?.map((r) => r?._id);
                      setSelected(allIds);
                    } else {
                      setSelected([]);
                    }

                    setSelectedAll(!selectedAll);
                  }}
                  checked={selected?.length == rawExamAnswers?.length}
                />
              </th>
              <th className="data-grid-table-header-bulk-actions">
                <Tag
                  variant="primary"
                  onClick={() => table.selectionHandler(console.log)}
                >
                  {selected.length} de {table.rows.length} selecionado
                  {table.rows.length > 1 ? "s" : ""}
                </Tag>
              </th>

              <th className="data-grid-table-header-bulk-actions">
                <LinkButton
                  variant="secondary"
                  onClick={() => {
                    setRedoExamModal(true);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Icons name="IconRestore" size={16} />
                    <StyledText
                      variant="body4"
                      style={{ fontWeight: 700, marginLeft: "5px" }}
                    >
                      Reiniciar avaliação{" "}
                      {table.rows.length > 1 ? "dos alunos" : "do aluno"}
                    </StyledText>
                  </div>
                </LinkButton>
              </th>
            </tr>
          ) : (
            <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          )}

          {finalRows.map((row, index) => (
            <Table.Grid.Row key={index + row.id} row={row} />
          ))}
        </Table.Grid.Root>

        <Table.Pagination
          count={examAnswers?.length || 0}
          onPaginationChange={({ pageSize, pageNumber }) =>
            setPagination({
              ...pagination,
              pageSize,
              pageNumber,
            })
          }
          pagination={pagination}
          pageSizeOptions={pageSizeOptions}
        />
      </Table.Root>

      <GenerateExamsReportModal
        open={open}
        examId={exam}
        exams={learningObjects}
        onClose={() => setOpen(false)}
        reportType={"answers"}
      />
      <GenerateExamAnswersheetReportModal
        open={openExamModal}
        employeeName={reportData?.name}
        employeeId={reportData?.employeeId}
        learningObjectId={reportData?.learningObjectId}
        onClose={() => setOpenExamModal(false)}
      />

      <RedoExamModal
        open={redoExamModal}
        onClose={() => setRedoExamModal(false)}
        loading={isRedoExam}
        employeesQuantity={selected?.length}
        handleClick={() => {
          redoExamMutate(
            {
              courseId: courseId,
              examId: exam,
              employees: selected,
            },
            {
              onError: (e: any) => {
                const monitoringsNotExists =
                  e?.data?.error === "MONITORING_NOT_EXISTS_ERROR";

                setSelected([]);
                setSelectedAll(false);
                setRedoExamModal(false);

                if (monitoringsNotExists) return;

                dispatchToast({
                  type: "error",
                  content: "Erro ao tentar reiniciar avaliação",
                });
              },
              onSuccess: () => {
                setSelected([]);
                setSelectedAll(false);
                setRedoExamModal(false);
                utils.exams.getEmployeeExamsMonitoring.invalidate();
                utils.exams.getEmployeeExamsAnswers.invalidate();
              },
            }
          );
        }}
      />
    </>
  );
};

import { LinkButton, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const InfoArea = styled.div`
  padding: 24px;
  background: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 8px;
  text-align: left;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  font-weight: bold !important;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
`;

export const Link = styled(LinkButton)`
  font-size: 12px !important;
`;

import styled, { css } from "styled-components";
import {
  Button,
  SelectField,
  Tag,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

export const OptionArea = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`;

export const AnswerText = styled(Typography)`
  text-transform: capitalize;
  font-weight: 600;
  min-width: 300px;
  max-width: fit-content;
`;

export const StyledBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSelect = styled(SelectField)`
  min-width: 200px;
  margin-right: 24px !important;
`;

export const CounterTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.neutral[90]};
  color: ${({ theme }) => theme.colors.neutral[10]};
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral[90]};
    color: ${({ theme }) => theme.colors.neutral[10]};
  }
`;

export const ExamTypeButton = styled(Button)<{ selected?: boolean }>`
  width: 100%;
  margin-bottom: 16px;
  text-align: left;
  justify-content: flex-start !important;
  ${({ selected }) =>
    selected
      ? css`
          font-weight: 700 !important;
          background: ${({ theme }) => theme.colors.secondary[95]} !important;
        `
      : css`
          font-weight: 600;
          color: ${({ theme }) => theme.colors.neutral[50]} !important;
          border: 1px solid ${({ theme }) => theme.colors.neutral[90]} !important;
        `};
`;

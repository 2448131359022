import { useState } from "react";

import { StyledText } from "@utils";
import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";

import { Search } from "./Search";
import { Filters } from "./Filters";
import { CertificateTable } from "./CertificateTable";

import { trpc } from "@api/client";

import { NoCertificateCreated } from "./NoCertificateCreated";
import { CertificateCard } from "./CertificateCard";

export const CertificateTab = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typeView, setTypeView] = useState<any>("list");

  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const { data, isLoading: isLoadingCertificate } =
    trpc.certificate.getCertificatesV2.useQuery(
      {
        limit: pageSize,
        page: page,
        order: "asc",
        sortBy: "_id",
        search: search,
        filters: filterStates,
      },
      {
        refetchOnWindowFocus: false,
        retry: 1,
      }
    );

  const filters = [
    {
      accessor: "createdAt",
      label: "Criado em",
      options: [],
      type: "date",
    },
    {
      accessor: "status",
      label: "Status",
      options: [
        {
          label: "Publicado",
          value: "published",
        },
        {
          label: "Rascunho",
          value: "draft",
        },
      ],
      type: "checkbox",
    },
  ] as any;

  return (
    <div>
      <StyledText
        variant="body3"
        setColor="neutral40"
        style={{ marginBottom: "24px" }}
      >
        Os certificados criados aqui podem ser associados a um treinamento
        depois.
      </StyledText>

      <Search onChange={setSearch} disabled={isLoadingCertificate} />

      <Filters
        disabled={isLoadingCertificate}
        typeView={typeView}
        filters={filters}
        handleSetTypeView={(type) => setTypeView(type)}
        onFilter={setFilterStates}
      />

      {!data?.certificates?.length &&
      !isLoadingCertificate &&
      !!!Object.keys(filterStates)?.length &&
      !!!search ? (
        <NoCertificateCreated />
      ) : typeView === "list" ? (
        <CertificateTable
          data={data?.certificates || []}
          rawData={data?.certificates || []}
          total={data?.totalCount || 0}
          loading={isLoadingCertificate}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        <CertificateCard
          data={data?.certificates || []}
          rawData={data?.certificates || []}
          total={data?.totalCount || 0}
          loading={isLoadingCertificate}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import { Content } from "./Components/Content";
import { Header } from "./Components/Header";

import { trpc } from "@api/client";
import { useParams } from "react-router-dom";
import { CourseMetrics } from "@components/CourseMetrics";

export const DetailCourse = () => {
  const { courseId = "" } = useParams();

  const { data, isLoading: isGettingCourseById } =
    trpc.course.getCourseById.useQuery(
      { courseId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!courseId,
      }
    );

  const { data: metricsData, isLoading: isGettingCourseMetricsById } =
    trpc.metrics.getCourseMetricById.useQuery(
      { courseId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!courseId,
      }
    );

  const { data: examHierarchy, isLoading: isGettingExamHierarchy } =
    trpc.exams.getExamHierarchy.useQuery(
      { courseId },
      {
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: !!courseId,
      }
    );

  return (
    <PageContainer>
      {isGettingCourseById ||
      isGettingCourseMetricsById ||
      isGettingExamHierarchy ? (
        <>
          <Skeleton
            variant="rectangular"
            width="400px"
            height="80px"
            style={{ marginTop: "50px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="50px"
            style={{ marginTop: "15px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="calc(100vh - 320px)"
            style={{ marginTop: "15px" }}
          />
        </>
      ) : (
        <>
          <Header classe={data} />
          <CourseMetrics courseMetrics={metricsData} />
          <Content
            classe={data}
            isGettingCourseById={isGettingCourseById}
            hasEvaluationsInCourse={!!examHierarchy?.length}
          />
        </>
      )}
    </PageContainer>
  );
};

import {
  DatePicker,
  IconButton,
  LinkButton,
  SearchField,
  SelectField,
  Tag,
  TagDateFilter,
  TagFilter,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const EmployeePic = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  color: ${({ theme }) => theme.colors.secondary[50]};
  background: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100%;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 24px;
`;

export const LoadArea = styled.div`
  margin-top: 20px;
`;

export const EmployeeName = styled(Typography)`
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.neutral[20]};
  text-transform: capitalize;
  margin-right: 12px;
`;

export const EmployeeInfoArea = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusTag = styled(Tag)`
  font-weight: 600;
  font-size: 14px;
  p {
    color: ${({ theme }) => theme.colors.feedback.success[50]};
    font-size: 20px;
  }
`;

export const NameInfoArea = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral[50]};
  margin-top: 4px;
`;

export const DotDivider = styled(Typography)`
  margin: 0 10px 0 8px;
`;

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const OptionsButton = styled(IconButton)`
  align-self: center !important;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: 24px;
`;

export const FilterArea = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  .select-custom .select-field-custom-theme {
    border-radius: 0px;
  }
  .select-custom .select-field-custom-theme div.MuiFilledInput-root,
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 50px !important;
    border: 1px solid ${({ theme }) => theme.colors.neutral[80]} !important;
  }
`;

export const FiltersSection = styled.div`
  display: flex;
  align-items: center;
`;

export const FilterLabel = styled(Typography)`
  width: fit-content;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  white-space: nowrap;
`;

export const StatusSelect = styled(SelectField)`
  width: 40% !important;
  margin-right: 12px;
`;

export const StatusFilter = styled(TagFilter)`
  align-self: center;
  margin-right: 12px;
  svg.icon-tabler-square-dot {
    display: none;
  }
`;

export const ClearFilters = styled(LinkButton)`
  align-self: center !important;
  white-space: nowrap;
`;

export const DateRangePicker = styled(TagDateFilter)`
  .icon-tabler-square-dot {
    display: none;
  }
  margin-right: 12px;
`;

export const DateField = styled(DatePicker)`
  margin-right: 12px;
  width: 198px !important;
  label {
    font-weight: 400 !important;
    top: -5px !important;
  }
  .MuiInputBase-input {
    padding: 10px 0px 8px 12px;
  }
  .MuiFilledInput-root {
    border-radius: 100px !important;
  }
`;

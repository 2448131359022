import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, dayjs, Loader } from "@flash-tecnologia/hros-web-ui-v2";

import WarnMessage from "../WarnMessage";
import {
  ButtonContainer,
  Container,
  FooterBar,
  TabDescription,
} from "./styles";
import { DoneCourseAccordion } from "./subComponents/DoneCourseAccordion";
import { EnableAllNotifications } from "./subComponents/EnableAllNotifications";
import { NewCourseAccordion } from "./subComponents/NewCourseAccordion";
import {
  AreaDivider,
  InfAreaDivider,
} from "./subComponents/NewCourseAccordion/styles";
import { ReminderCourseAccordion } from "./subComponents/ReminderCourseAccordion";
import { UndoneCourseAccordion } from "./subComponents/UndoneCourseAccordion";
import { UpdateCourseAccordion } from "./subComponents/UpdateCourseAccordion";
import { trpc } from "@api/client";
import { dispatchToast } from "@utils";

const Notifications = () => {
  const navigate = useNavigate();

  const [defaultNotifications, setDefaultNotifications] = useState<any>({});
  const [allNotifications, setAllNotifications] = useState<any>(false);
  const [notifications, setNotifications] = useState<any>({
    newCourse: false,
    undoneCourse: false,
    reminderCourse: false,
    doneCourse: false,
    updateCourse: false,
  });
  const [reminderItens, setReminderItens] = useState<any>([]);
  const [undoneItens, setUndoneItens] = useState<any>([]);

  useEffect(() => {
    setNotifications({
      newCourse: allNotifications,
      undoneCourse: allNotifications,
      reminderCourse: allNotifications,
      doneCourse: allNotifications,
      updateCourse: allNotifications,
    });
  }, [allNotifications]);

  const { mutate: addNotificationMutate, isLoading: isAddNotification } =
    trpc.notifications.addNotification.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Notificação salva com sucesso!",
          type: "success",
        });
        navigate("/lms/manage-courses");
      },
      onError: (e: any) => {
        const employeeNotExists =
          e?.data?.error === "EMPLOYEE_NOT_EXISTS_ERROR";

        const message = employeeNotExists
          ? "Não existe o funcionário."
          : "Erro ao tentar adicionar a notificação, tente novamente mais tarde!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  const { mutate: updateNotificationMutate } =
    trpc.notifications.updateNotification.useMutation({
      onSuccess: () => {
        dispatchToast({
          content: "Notificação atualizada com sucesso!",
          type: "success",
        });
        navigate("/lms/manage-courses");
      },
      onError: (e: any) => {
        const notificationNotExists =
          e?.data?.error === "NOTIFICATION_NOT_EXISTS_ERROR";

        const message = notificationNotExists
          ? "Não existe a notificação."
          : "Erro ao tentar atualizar a notificação, tente novamente mais tarde!";

        dispatchToast({
          type: "error",
          content: message,
        });
      },
    });

  useEffect(() => {
    if (defaultNotifications?.templates?.length === 5) {
      setAllNotifications(true);
    } else {
      setAllNotifications(false);
      setNotifications({
        newCourse: defaultNotifications?.templates?.filter(
          ({ templateId }) => templateId === "NewCourse"
        )?.length
          ? true
          : false,
        undoneCourse: defaultNotifications?.templates?.filter(
          ({ templateId }) => templateId === "PendingCourse"
        )?.length
          ? true
          : false,
        reminderCourse: defaultNotifications?.templates?.filter(
          ({ templateId }) => templateId === "ReminderCourse"
        )?.length
          ? true
          : false,
        doneCourse: defaultNotifications?.templates?.filter(
          ({ templateId }) => templateId === "DoneCourse"
        )?.length
          ? true
          : false,
        updateCourse: defaultNotifications?.templates?.filter(
          ({ templateId }) => templateId === "UpdatedCourse"
        )?.length
          ? true
          : false,
      });
    }
  }, [defaultNotifications]);

  const handleCheck = useCallback(
    (field, status) => {
      setNotifications({ ...notifications, [field]: status });
    },
    [notifications]
  );

  const handleSave = useCallback(() => {
    const payload: any = [];
    if (notifications?.doneCourse) {
      payload.push({
        templateId: "DoneCourse",
        type: "default",
        dates: [dayjs().toISOString()],
      });
    }
    if (notifications?.newCourse) {
      payload.push({
        templateId: "NewCourse",
        type: "default",
        dates: [dayjs().toISOString()],
      });
    }
    if (notifications?.reminderCourse) {
      payload.push({
        templateId: "ReminderCourse",
        type: "default",
        dates: [
          reminderItens?.map(({ period, value }) => {
            if (period) {
              return { period, value };
            } else return;
          }),
        ],
      });
    }
    if (notifications?.undoneCourse) {
      payload.push({
        templateId: "PendingCourse",
        type: "default",
        dates: [
          undoneItens?.map(({ period, value }) => {
            if (period) {
              return { period, value };
            }
          }),
        ],
      });
    }
    if (notifications?.updateCourse) {
      payload.push({
        templateId: "UpdatedCourse",
        type: "default",
        dates: [dayjs().toISOString()],
      });
    }

    const params = {
      type: "default",
      templates: payload,
    };

    if (defaultNotifications?._id) {
      updateNotificationMutate({
        courseId: defaultNotifications?._id,
      });
    } else {
      addNotificationMutate({
        params,
      });
    }
  }, [notifications, reminderItens, undoneItens]);

  return (
    <>
      <Container>
        <TabDescription variant="body3">
          Ative as opções de notificações que deseja enviar aos alunos no{" "}
          <b>modelo padrão</b> de personalização.
          <br />
          As notificações serão exibidas no feed de notificações, na aba de
          "minha empresa”.
        </TabDescription>
        <WarnMessage
          title="Alterações impactam todos os treinamento"
          customDescription={
            <>
              Caso sejam realizadas alterações, elas impactarão todos os
              treinamentos que tiverem o <b>modelo padrão</b> selecionado.
            </>
          }
          hasClose={false}
        />
        <AreaDivider />
        <EnableAllNotifications
          allNotifications={allNotifications}
          setAllNotifications={setAllNotifications}
        />
        <InfAreaDivider />
        <NewCourseAccordion
          checked={notifications?.newCourse}
          onChange={() => handleCheck("newCourse", !notifications?.newCourse)}
        />
        <UndoneCourseAccordion
          checked={notifications?.undoneCourse}
          onChange={() =>
            handleCheck("undoneCourse", !notifications?.undoneCourse)
          }
          setUndoneItens={setUndoneItens}
        />
        <ReminderCourseAccordion
          checked={notifications?.reminderCourse}
          onChange={() =>
            handleCheck("reminderCourse", !notifications?.reminderCourse)
          }
          setReminderItens={setReminderItens}
        />
        <DoneCourseAccordion
          checked={notifications?.doneCourse}
          onChange={() => handleCheck("doneCourse", !notifications?.doneCourse)}
        />
        <UpdateCourseAccordion
          checked={notifications?.updateCourse}
          onChange={() =>
            handleCheck("updateCourse", !notifications?.updateCourse)
          }
        />
      </Container>

      <FooterBar>
        <ButtonContainer>
          <Button
            variant="primary"
            size="large"
            disabled={isAddNotification}
            onClick={handleSave}
          >
            {isAddNotification ? (
              <Loader size="extraSmall" variant="secondary" />
            ) : (
              <>Salvar</>
            )}
          </Button>
        </ButtonContainer>
      </FooterBar>
    </>
  );
};

export default Notifications;

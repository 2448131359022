import { useState, useEffect } from "react";

import { StyledSearch, SearchContainer } from "./styled";
import { track } from "@utils";
import { useNavigate } from "react-router-dom";
import { Button, Icons } from "@flash-tecnologia/hros-web-ui-v2";

export const Search = ({ onChange, disabled }) => {
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (inputValue === undefined) return;

    const timer = setTimeout(() => {
      onChange(inputValue);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [inputValue]);

  const handleOnChange = (value) => {
    if (onChange) {
      setInputValue(value);
    }
  };

  const handleOnBlur = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };
  const handleOnInput = (value) => {
    const target = value?.target?.value;
    if (onChange) {
      setInputValue(target || "");
    }
  };

  return (
    <SearchContainer>
      <StyledSearch
        onChange={(e) => handleOnChange(e.target.value)}
        onSearchChange={handleOnBlur}
        onInputChange={handleOnInput}
        label="Buscar por certificado"
        disabled={disabled}
      />

      <Button
        onClick={() => {
          track({
            name: "company_lms_coursessettings_createcertificate_clicked",
          });
          navigate(`/lms/manage-courses/certificates/create/0`);
        }}
        variant="primary"
        size="large"
        style={{ marginLeft: "24px", width: "20%" }}
      >
        <Icons name="IconPlus" />
        Criar certificado
      </Button>
    </SearchContainer>
  );
};

import { PageHeader } from "@flash-tecnologia/hros-web-ui-v2";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Breadcrumb } from "../../components/Breadcrumb";
import { FooterBar } from "../../components/FooterBar";
import { useLockHeaderMobile } from "../../utils/hook";
import { handleFooterBarTrack, track } from "../../utils/segment";
import { AccordionAddingEdit } from "./Accordion";
import {
  StyledBreadcrumbSeparator,
  StyledGrid,
  StyledPageContainer,
  StyledTitle,
  StyledTypography,
} from "./styled";
import { trpc } from "@api/client";

const AddingEditClassesModules = () => {
  const {
    courseId = "",
    chapterId = undefined,
    classId = undefined,
    currentStep = "",
    type = "create",
  } = useParams();

  const navigate = useNavigate();

  const [courseHierachy, setCourseHierachy] = useState({});

  useLockHeaderMobile();

  useEffect(() => {
    if (!currentStep || !courseId || !chapterId)
      navigate(`/lms/manage-courses`);
  }, [currentStep]);

  const { isFetching: isGettingCourseHierarchy } =
    trpc.course.getCourseHierarchy.useQuery(
      { id: courseId },
      {
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess: (data) => {
          setCourseHierachy(data);
        },
      }
    );

  const steps = ["Informações básicas", "Conteúdo do treinamento"];

  const parsedStep = parseInt(currentStep);

  return isGettingCourseHierarchy ? (
    <Skeleton
      width="100%"
      height="100%"
      animation="pulse"
      variant="rectangular"
    />
  ) : (
    <div
      style={{
        overflow: "hidden hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PageHeader>
        <Breadcrumb
          breadcrumbs={[
            {
              onClick: () => {
                navigate(`/lms/manage-courses`);
              },
              label: "Gerenciar treinamentos",
              active: true,
            },
          ]}
          separator={
            <StyledBreadcrumbSeparator size={24} name="IconChevronRight" />
          }
          style={{ marginBottom: "24px" }}
        />
      </PageHeader>
      <StyledPageContainer>
        <StyledGrid container>
          <StyledGrid item sm={12} md={5} lg={4}>
            <div style={{ marginRight: "24px" }}>
              <StyledTitle variant="headline7">
                {classId ? "Editar" : "Adicionar"} aulas ao módulo
              </StyledTitle>
              <div style={{ marginTop: "16px", marginBottom: "16px" }}>
                <StyledTypography variant="body3">
                  Adicione uma ou mais aulas ao módulo.
                </StyledTypography>
              </div>
            </div>
          </StyledGrid>

          <StyledGrid item sm={12} md={7} lg={8}>
            <AccordionAddingEdit
              courseHierachy={courseHierachy}
              chapterId={chapterId}
              courseId={courseId}
            />
          </StyledGrid>
        </StyledGrid>
      </StyledPageContainer>

      <FooterBar
        steps={steps}
        currentStep={parsedStep}
        cancelHandleClick={async () => {
          track({
            name: handleFooterBarTrack({
              page: "course",
              step: "information",
              action: "cancel",
            }),
          });
          navigate("/lms/manage-courses");
        }}
        previousHandleClick={() =>
          navigate(
            `/lms/manage-courses/createEditCourse/${type}/${1}/${courseId}`
          )
        }
        nextHandleClick={() => {
          track({
            name: handleFooterBarTrack({
              page: "course",
              step: "information",
              action: "cancel",
            }),
          });
          navigate(
            `/lms/manage-courses/createEditCourse/${type}/${2}/${courseId}`
          );
        }}
      />
    </div>
  );
};

export { AddingEditClassesModules };

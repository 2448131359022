import styled from "styled-components";
import { Grid } from "@mui/material";

import {
  Typography,
  TextField,
  PageContainer,
  DatePicker,
  Icons,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledTypography = styled(Typography)``;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

export const StyledFlex = styled.div`
  display: flex;
`;

export const StyledGrid = styled(Grid)`
  .isDragging {
    .DragArea {
      transition: 0.4s;
      background: ${({ theme }) => theme.colors.secondary[70]};
      svg {
        stroke: white;
      }
    }
  }
`;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledHeaderEditor = styled.div`
  padding: 8px 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: ${({ theme }) => theme.colors.neutral.light.light1};

  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px 8px 0px 0px;
`;

export const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

export const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddQuestionButton = styled(Button)`
  width: 100%;
`;

export const Container = styled.div`
  overflow: hidden hidden;
  display: flex;
  flex-direction: column;
`;

export const StepperArea = styled.div`
  margin-bottom: 40px;
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

import styled from "styled-components";

import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { Drawer } from "@mui/material";

export const ClassesButton = styled(Button)`
  width: 100%;
  border: none !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: ${({
    theme: {
      colors: { secondary },
    },
  }) => secondary.light.light2} !important;
  color: ${({ theme }) => theme.colors.secondary.dark.dark5} !important;
`;

export const MobileAccordion = styled(Drawer)`
  .MuiDrawer-paperAnchorRight {
    width: 352px;
    border-radius: 12px;
    top: 40px;
    right: 25px;
    height: 85%;
  }
  .MuiDrawer-paperAnchorBottom {
    @media screen and (max-width: ${({ theme }) =>
        `${theme.breakpoints.values.sm}px`}) {
      width: 100%;
      right: 0;
      top: 64px;
      height: 90%;
    }
  }
`;

import { StyledText } from "@utils";
import "./styles.css";

import { TimeSlider, Time } from "@vidstack/react";

export const SliderTimeWithTimeGroup = () => {
  return (
    <>
      <StyledText variant="body3" setColor="neutral30">
        <Time type="current" />
      </StyledText>

      <TimeSlider.Root
        className={`audioSlider`}
        style={{ height: "20px !important" }}
      >
        <TimeSlider.Track className={"audioTrack"} />
        <TimeSlider.TrackFill className={`audioTrackFill audioTrack`} />
        <TimeSlider.Progress className={`audioProgress audioTrack`} />

        <TimeSlider.Thumb className={"audioThumb"} />

        <TimeSlider.Preview className={"audioPreview"}>
          <TimeSlider.Value className={"audioTimeValue"} />
        </TimeSlider.Preview>
      </TimeSlider.Root>

      <StyledText variant="body3" setColor="neutral30">
        <Time type="duration" />
      </StyledText>
    </>
  );
};

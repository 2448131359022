import { useCallback, useMemo, useState } from "react";

import { Search } from "./Search";
import { Filters } from "./Filters";
import { CourseTable } from "./CourseTable";

import { trpc } from "@api/client";
import { getFromSS, setInSS } from "@flash-tecnologia/hros-web-utility";
import { CourseCard } from "./CourseCard";

import { NoCourseCreated } from "./NoCourseCreated";

export const CourseTab = () => {
  const courseTypeView = useMemo(
    () => getFromSS("lms_manage_course_type_view"),
    []
  );

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [typeView, setTypeView] = useState<any>(courseTypeView || "list");

  const [search, setSearch] = useState<string>("");
  const [filterStates, setFilterStates] = useState<{
    [key: string]: any[];
  }>({});

  const handleSetTypeView = useCallback(
    (type: string) => {
      setInSS({ key: "lms_manage_course_type_view", value: type });
      setTypeView(type);
    },
    [typeView]
  );

  const { data, isLoading: isLoadingGetCoursesForAdmin } =
    trpc.course.getManageCourses.useQuery(
      {
        limit: pageSize,
        page: page,
        order: "asc",
        sortBy: "_id",
        search: search,
        filters: filterStates,
      },
      {
        refetchOnWindowFocus: false,
        retry: 1,
      }
    );

  const filters = [
    {
      accessor: "status",
      label: "Status",
      options: [
        {
          label: "Disponível",
          value: "available",
        },
        {
          label: "Encerrado",
          value: "finished",
        },
        {
          label: "Rascunho",
          value: "draft",
        },
        {
          label: "Em breve",
          value: "beforeInit",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "isShared",
      label: "Compartilhamento",
      options: [
        {
          label: "Sim",
          value: "true",
        },
        {
          label: "Não",
          value: "false",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "hasCertificate",
      label: "Certificação",
      options: [
        {
          label: "Com certificação",
          value: "true",
        },
        {
          label: "Não certificação",
          value: "false",
        },
      ],
      type: "checkbox",
    },
    {
      accessor: "period",
      label: "Período",
      options: [],
      type: "date",
    },
  ] as any;

  if (
    !data?.courses?.length &&
    !isLoadingGetCoursesForAdmin &&
    !!!Object.keys(filterStates)?.length &&
    !!!search
  )
    return <NoCourseCreated />;

  return (
    <div>
      <Search onChange={setSearch} disabled={isLoadingGetCoursesForAdmin} />

      <Filters
        disabled={isLoadingGetCoursesForAdmin}
        typeView={typeView}
        handleSetTypeView={handleSetTypeView}
        filters={filters}
        onFilter={setFilterStates}
      />

      {typeView === "list" ? (
        <CourseTable
          data={data?.courses || []}
          rawData={data?.courses || []}
          total={data?.totalCount || 0}
          loading={isLoadingGetCoursesForAdmin}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      ) : (
        <CourseCard
          data={data?.courses || []}
          rawData={data?.courses || []}
          total={data?.totalCount || 0}
          loading={isLoadingGetCoursesForAdmin}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
    </div>
  );
};

import styled from "styled-components";

const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const FirstColumn = styled.div`
  width: 75%;
`;

const SecondColumn = styled.div`
  flex: 1;
`;

const NumberCircle = styled.div`
  display: flex;
  border-radius: 50px;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral[95]};
`;

export { Row, FirstColumn, SecondColumn, NumberCircle };

export const pageSizeOptions = [
  {
    label: "1 item",
    value: 1,
  },
  {
    label: "5 itens",
    value: 5,
  },
  {
    label: "10 itens",
    value: 10,
  },
  {
    label: "20 itens",
    value: 20,
  },
];

import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import EmptyState from "../../../../../../assets/emptyState.jpg";

import {
  Container,
  SubImageContainer,
  SubContainer,
  SubImageIcon,
  LabelTitle,
  LabelSubTitle,
  StyledIconButton,
  StyledFlex,
  StyledTag,
  TagArea,
  MenuButtonArea,
} from "./styled";

import {
  dayjs,
  Icons,
  Menu,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { GenerateReportModal } from "@components/GenerateReportModal";
import { ReplicateCourseModal } from "@components/Modals/ReplicateCourseModal";

import { ConfirmModal } from "../../../Modal/ConfirmModal";
import { WarningDeleteModal } from "../../../Modal/WarningDeleteModal";

import { track, dispatchToast } from "@utils";
import { trpc } from "@api/client";
import { OverflowCheck } from "@components/OverflowCheck";

export const Card = ({ classz }: { classz: any }) => {
  const navigate = useNavigate();

  const utils = trpc.useContext();

  const [reportModal, setReportModal] = useState<any>(false);
  const [confirmModal, setConfirmModal] = useState<any>(false);
  const [warningDeleteModal, setWarningDeleteModal] = useState<any>(false);

  const [replicateModal, setReplicateModal] = useState(false);
  const {
    _id,
    name = "-",
    start = "",
    end = "",
    banner = {},
    modulesTotal = "-",
    classesTotal = "-",
    peopleTotal = "-",
    certificateId = null,
    isShared = false,
    status,
  } = classz;

  const { mutate: duplicateCourseByIdMutate } =
    trpc.course.duplicateCourseById.useMutation({});

  const selectTag = useMemo(() => {
    let tag = <></>;

    switch (status) {
      case "beforeInit":
        tag = (
          <StyledTag
            variant="primary"
            style={{
              backgroundColor: "#D4E8FF",
              color: "#015FCB",
              margin: "16px 0px",
            }}
          >
            Em breve
          </StyledTag>
        );
        break;
      case "finished":
        tag = (
          <StyledTag
            variant="primary"
            style={{
              backgroundColor: "##EBE6E9",
              color: "#00244C",
              margin: "16px 0px",
            }}
          >
            Encerrado
          </StyledTag>
        );
        break;
      case "draft":
        tag = (
          <StyledTag
            variant="primary"
            style={{
              backgroundColor: "#FFECD6",
              color: "#4C2900",
              margin: "16px 0px",
            }}
          >
            Rascunho
          </StyledTag>
        );
        break;
      default:
        tag = (
          <StyledTag
            variant="primary"
            style={{
              backgroundColor: "#D7F9F3",
              color: "#094338",
              margin: "16px 0px",
            }}
          >
            Disponível
          </StyledTag>
        );
        break;
    }

    return tag;
  }, [status]);

  const menuOptions = () => {
    const editOption = {
      onClick: (e) => {
        if (isShared) {
          setReplicateModal(true);
          return;
        }
        navigate(`/lms/manage-courses/createEditCourse/edit/0/${_id}`);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconPencil"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">Editar treinamento</Typography>
        </StyledFlex>
      ),
    };

    const exportOption = {
      onClick: (e) => {
        track({
          name: "company_lms_home_exportreport_clicked",
        });

        setReportModal(true);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconDownload"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">
            Exportar relatório do treinamento
          </Typography>
        </StyledFlex>
      ),
    };

    const dupOption = {
      onClick: (e) => {
        duplicateCourseByIdMutate(
          {
            courseId: _id,
          },
          {
            onSuccess: () => {
              dispatchToast({
                content:
                  "O treinamento foi duplicado com sucesso e a cópia está disponível como rascunho.",
                type: "success",
              });
              utils.course.getManageCourses.refetch();
            },
          }
        );
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconCopy"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">Duplicar treinamento</Typography>
        </StyledFlex>
      ),
    };

    const deleteOption = {
      onClick: (e) => {
        setWarningDeleteModal(true);
        track({
          name: "company_lms_home_deletetraining_clicked",
        });
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconTrash"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">Excluir treinamento</Typography>
        </StyledFlex>
      ),
    };

    const previewOption = {
      onClick: (e) => {
        if (classesTotal === 0) {
          dispatchToast({
            content: "Esse treinamento não contem aulas",
            type: "error",
          });
          return;
        }
        window.open(`/lms/manage-courses/${_id}/0/class/preview`, "_blank");
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconFileSearch"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">
            Pré-visualização do treinamento
          </Typography>
        </StyledFlex>
      ),
    };

    const studentOption = {
      onClick: (e) => {
        track({
          name: "company_lms_home_addstudents_clicked",
        });
        navigate(`/lms/manage-courses/createEditCourse/edit/3/${_id}`);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconUsers"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">Adicionar alunos</Typography>
        </StyledFlex>
      ),
    };

    const detailsOption = {
      onClick: (e: any) => {
        navigate(`/lms/manage-courses/detail-course/${_id}`);
      },
      children: (
        <StyledFlex>
          <Icons
            name="IconFileDescription"
            fill="transparent"
            style={{ marginRight: "8px" }}
          />
          <Typography variant="body3">Ver detalhes do treinamento</Typography>
        </StyledFlex>
      ),
    };

    return status == "available"
      ? [
          detailsOption,
          previewOption,
          editOption,
          exportOption,
          studentOption,
          dupOption,
        ]
      : status == "finished"
      ? [
          detailsOption,
          previewOption,
          editOption,
          exportOption,
          dupOption,
          deleteOption,
        ]
      : [previewOption, editOption, studentOption, dupOption, deleteOption];
  };

  return (
    <Container>
      <SubImageContainer
        url={banner?.path ? `${banner?.path}${banner?.signature}` : EmptyState}
      >
        <Menu
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          options={menuOptions()}
          type="select"
        >
          <MenuButtonArea>
            <SubImageIcon
              hasNoImage={true}
              size="small"
              variant="filled"
              icon="IconDotsVertical"
            />
          </MenuButtonArea>
        </Menu>
      </SubImageContainer>

      <SubContainer>
        <TagArea>
          {selectTag}
          {isShared ? (
            <StyledTag
              variant="primary"
              style={{
                backgroundColor: "#EBE6E9",
                color: "#1D161B",
                margin: "16px 0px",
              }}
            >
              Compartilhado
            </StyledTag>
          ) : null}
        </TagArea>

        <LabelTitle variant="body2">{OverflowCheck(name || "")}</LabelTitle>

        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton variant="line" size="small" icon={"IconSchool"} />
          <LabelSubTitle variant="body4">
            {modulesTotal} módulos • {classesTotal} aulas
          </LabelSubTitle>
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton variant="line" size="small" icon={"IconUsers"} />
          <LabelSubTitle variant="body4">{peopleTotal} alunos</LabelSubTitle>
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton variant="line" size="small" icon={"IconCalendar"} />
          {start ? (
            <LabelSubTitle variant="body4">
              Início em {dayjs(start).format("DD/MM/YYYY")}
            </LabelSubTitle>
          ) : (
            <LabelSubTitle variant="body4">Não informado</LabelSubTitle>
          )}
        </StyledFlex>
        <StyledFlex style={{ marginBottom: "16px" }}>
          <StyledIconButton
            variant="line"
            size="small"
            icon={"IconCalendarTime"}
          />
          <LabelSubTitle variant="body4">
            {end ? (
              <LabelSubTitle variant="body4">
                Disponível até {dayjs(end).format("DD/MM/YYYY")}
              </LabelSubTitle>
            ) : (
              <LabelSubTitle variant="body4">Sempre disponível</LabelSubTitle>
            )}
          </LabelSubTitle>
        </StyledFlex>
        {certificateId ? (
          <StyledFlex>
            <StyledIconButton
              variant="line"
              size="small"
              icon={"IconCertificate"}
            />
            <LabelSubTitle variant="body4">Emite certificado</LabelSubTitle>
          </StyledFlex>
        ) : null}
      </SubContainer>

      <WarningDeleteModal
        type={"course"}
        _id={_id}
        open={warningDeleteModal}
        setOpen={setWarningDeleteModal}
        setConfirmModal={setConfirmModal}
      />
      <ConfirmModal
        type={"course"}
        open={confirmModal}
        setOpen={setConfirmModal}
      />

      <GenerateReportModal
        open={reportModal}
        courseId={_id}
        onClose={() => setReportModal(false)}
      />
      <ReplicateCourseModal
        open={replicateModal}
        onClose={() => setReplicateModal(false)}
        handleClick={() =>
          navigate(`/lms/manage-courses/createEditCourse/edit/0/${_id}`)
        }
      />
    </Container>
  );
};

import styled, { css } from "styled-components";
import { IconButton, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { MenuItem, Select } from "@mui/material";

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  text-align: center;
  margin-top: 8px;
`;

export const StyledFlexColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 311px;
  white-space: normal;
  margin-left: 16px;
`;

export const StyledTag = styled(Tag)``;

export const TitleCardContainer = styled.div`
  text-align: left;
  width: 100%;
`;

export const StyledDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 4px;
  margin-right: 4px;
  background-color: ${({ theme }) => theme.colors.neutral[40]};
`;

export const StyledIconButtonExtraSmall = styled(IconButton)`
  margin-right: 8px;
  && {
    &.MuiButtonBase-root {
      &.size-small {
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

export const StyledImageContainer = styled.div<{ url: string }>`
  width: 64px;
  height: 64px;
  background: ${({ url }) => `url(${url})`};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: 8px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

export const PaginationArea = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    flex-direction: column;
  }
  margin: 32px 0;
`;

export const ItensPerPageArea = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    justify-content: center;
  }
`;

export const StyledSelectField = styled(Select)`
  margin-right: 16px;

  border-radius: 200px !important;
  padding-right: 0px !important;

  .MuiFilledInput-root {
    padding: 9px 39px 9px 8px;
  }

  &:before,
  &:after {
    content: none !important;
  }

  &:focus {
    background-color: transparent !important;
  }

  .MuiSelect-select {
    padding: 9px 30px 9px 16px !important;
    border-radius: 200px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral[40]};
    &:focus {
      background-color: transparent !important;
    }
  }
`;

export const PaginationContainer = styled.div`
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
`;

export const StyledTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const SubImageIcon = styled(IconButton)<{ hasNoImage?: boolean }>`
  ${({ hasNoImage }) =>
    hasNoImage
      ? css`
          && {
            &.MuiButtonBase-root {
              background-color: ${({ theme }) =>
                theme.colors.neutral.light.light2};
            }
          }
        `
      : ""}
`;

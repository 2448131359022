import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import { DataCard } from "../DataCard";

interface BasicInfoTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export const BasicInfoTab = ({ data, onNavigate }: BasicInfoTabProps) => {
  const formatEnable = (enabled: boolean | undefined, gender: "f" | "m") => {
    const isBoolean = typeof enabled === "boolean";
    const end = gender === "f" ? "a" : "o";

    if (!isBoolean) return "-";
    if (enabled) return "Habilitad" + end;

    return "Não habilitad" + end;
  };

  const formatWorkload = (totalHours: number | undefined) => {
    const isNumber = typeof totalHours === "number";

    if (!isNumber) return "-";
    if (totalHours === 0) return "0 horas";
    if (totalHours < 2) return `${totalHours} hora`;

    return `${totalHours} horas`;
  };

  return (
    <DataCard
      title="Revisar informações básicas"
      subTitle="Confira as informações básicas do seu treinamento."
      editable
      onEditClick={onNavigate}
      sections={[
        {
          title: "Nome e descrição",
          items: [
            {
              title: "Nome do treinamento",
              description: data?.name || "-",
            },
            {
              title: "Descrição",
              description: data?.description || "-",
            },
          ],
        },
        {
          title: "Período de acesso",
          items: [
            {
              title: "Data de início",
              description:
                data?.start && dayjs(data?.start).isValid()
                  ? dayjs(data.start).format("DD/MM/YYYY")
                  : "-",
            },
            {
              title: "Data de encerramento",
              description: data?.end
                ? dayjs(data.end).format("DD/MM/YYYY")
                : "Sempre disponível",
            },
          ],
        },
        {
          title: "Personalização do treinamento",
          items: [
            {
              title: "Carga horária",
              description: formatWorkload(data?.totalHours),
            },
            {
              title: "Obrigatoriedade",
              description: formatEnable(data?.mandatory, "f"),
            },
            {
              title: "Destaque",
              description: formatEnable(data?.highlight, "m"),
            },
          ],
        },
      ]}
    />
  );
};

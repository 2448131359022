import styled from "styled-components";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";

import {
  Typography,
  PageContainer,
  DatePicker,
  Button,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledAccordionContainer = styled(Accordion)`
  padding: 16px 40px;
  margin-bottom: 24px;

  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;
  }
`;

export const StyledAddModuleContainer = styled.div`
  padding: 28px 40px;
  margin-bottom: 24px;
  border: 1px solid rgb(235, 230, 233);
  border-radius: 12px;
  box-shadow: none;

  &.MuiPaper-root {
    border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
    border-radius: 12px;
    box-shadow: none;
    background-color: white;
  }
`;

export const StyledTypography = styled(Typography)``;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

export const StyledFlex = styled.div`
  display: flex;
`;

export const StyledGrid = styled(Grid)``;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledFlexBetween = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  &:hover {
    opacity: 0.5;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    cursor: pointer;
    padding: 0;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled(Button)``;

export const StyledAddTextButton = styled(Typography)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledAddIconButton = styled(Icons)`
  fill: ${({ theme }) => theme.colors.primary};
`;

import {
  PageContainer,
  TextField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Grid, IconButton } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  padding-top: 53px;
  padding-bottom: 24px;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  margin-bottom: 40px;
  .field-margin {
    margin-top: 24px;
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weight: 600;
`;

export const DropzoneArea = styled.div`
  width: 100%;
  margin: 32px 0;
  text-align: center;
  .dropzone-text-container {
    align-items: center !important;
  }
`;

export const SelectArea = styled.div`
  width: 100%;
  margin-top: 33px;
`;

export const ToggleArea = styled.div`
  margin: 32px 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  p {
    margin-left: 20px;
  }
  p:first-child {
    font-weight: 700;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
  p:last-child {
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const PreviewArea = styled.div`
  scale: 0.28;
  width: max-content;
  height: max-content;
  background-color: white;
`;

export const FixedPreview = styled.div`
  position: fixed;
  width: 455px;
  display: flex;
  flex-direction: column;
`;

export const ExpandButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const CertificateArea = styled.div`
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`;

export const StyledBannerContainer = styled.div`
  position: relative;
  margin: 24px 0;
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

export const SubImageHoverContainer = styled.div`
  background: rgba(29, 22, 27, 0.4);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  opacity: 0;
  z-index: 10;

  &:hover {
    transition: 0.5s ease;
    opacity: 1;
  }
`;

export const IconButtonHover = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.dark.dark1};
        opacity: 0.6;
      }
      svg {
        stroke: ${({ theme }) => theme.colors.neutral.light.pure};
        width: 24px;
        height: 24px;
      }
    }
  }
`;

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledGrid = styled(Grid)`
  z-index: 1;
`;

export const StyledTypography = styled(Typography)``;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

import styled, { css } from "styled-components";
import { Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StatusTag = styled(Tag)<{ status: string }>`
  ${({ status, theme }) =>
    status === "done"
      ? css`
          background-color: ${theme.colors.feedback.success[90]} !important;
          color: ${theme.colors.feedback.success[10]} !important;
        `
      : status === "progress"
      ? css`
          background-color: ${theme.colors.feedback.info[90]} !important;
          color: ${theme.colors.feedback.info[10]} !important;
        `
      : css`
          background-color: ${theme.colors.feedback.error[90]} !important;
          color: ${theme.colors.feedback.error[10]} !important;
        `}
  white-space: nowrap;
`;

export const TotalAnswers = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
  font-weight: 600 !important;
`;

export const ProgressWrapper = styled.div`
  width: 75px;
  margin-right: 12px;
`;

export const ProgressBar = styled.div<{ totalDone: number }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 100px;
  span {
    display: block;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.secondary[50]};
    border-radius: 100px;
    width: ${({ totalDone }) => totalDone}%;
    transition: width 500ms ease-in-out;
  }
`;

export const ProgressArea = styled.div`
  display: flex;
  align-items: center;
`;

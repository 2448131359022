import { useNavigate } from "react-router-dom";

import {
  DataGrid,
  IconButton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";

import {
  ProgressArea,
  ProgressBar,
  ProgressWrapper,
  StatusTag,
  TotalAnswers,
} from "./styled";

import { pageSizeOptions } from "@utils";
import Avatar from "./Avatar";

interface TableGridProps {
  data: any[];
  rawData: any[];

  loading: boolean;
}

export const StudentPerformanceTable = ({
  data,
  rawData,
  loading,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: "Nome",
      accessor: "normalizedName",
      Cell: ({
        row: {
          original: { name = "" },
        },
      }) => <Avatar name={name} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        return (
          <StatusTag status={value} variant="primary">
            {value === "progress"
              ? "Em andamento"
              : value === "done"
              ? "Concluído"
              : "Não inciado"}
          </StatusTag>
        );
      },
    },
    {
      Header: "Quantidade de acessos",
      accessor: "access",
    },
    {
      Header: "Progresso",
      accessor: "progress",
      Cell: ({ value }) => {
        return (
          <ProgressArea>
            <ProgressWrapper>
              <ProgressBar totalDone={value}>
                <span />
              </ProgressBar>
            </ProgressWrapper>
            <TotalAnswers variant="body4">
              {value >= 100 ? 100 : value}%
            </TotalAnswers>
          </ProgressArea>
        );
      },
    },
    {
      Header: "",
      accessor: "actions",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { _id = null },
        },
      }) => (
        <Tooltip arrow title="Ver detalhes do aluno">
          <div>
            <IconButton
              onClick={() =>
                navigate(`/lms/manage-courses/detail-student/${_id}`)
              }
              size="small"
              variant="line"
              icon="IconUser"
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        pageSizeOptions={pageSizeOptions}
        emptyState={{
          isFiltered: true,
          message: "Nenhum aluno encontrado",
        }}
      />
    </>
  );
};

import { useContext } from "react";
import { Context } from "../../../../../context";

import { Button } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledSubTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexCenter,
} from "./styled";
import { useNavigate } from "react-router-dom";
import { clearClasseToCreate, updateModal } from "../../../../../api";

const ConfirmCreateContent = ({ open, onClose, customMessage = "" }) => {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();

  return (
    <StyledModal
      isLoading={false}
      onClose={onClose}
      allowBackdropClick={false}
      open={open}
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconMoodHappy" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <StyledContainer>
          <StyledFlexCenter>
            <StyledTitle variant="headline7">Pronto!</StyledTitle>
          </StyledFlexCenter>

          <StyledSubTitle
            variant="body3"
            style={{ marginBottom: "12px", textAlign: "center" }}
          >
            Seu conteúdo foi adicionado com sucesso! 😄
          </StyledSubTitle>
          <StyledSubTitle variant="body3" style={{ textAlign: "center" }}>
            {customMessage ??
              "Que ótima notícia! Já está disponível e você já pode incorporá-lo nas aulas dos seus treinamentos! Não se esqueça que você também pode acompanhar o nível de engajamento dos treinamentos e personalizar sempre que necessário conforme sua necessidade!"}
          </StyledSubTitle>
          <StyledButtonContainer>
            <Button
              size="large"
              variant="primary"
              onClick={async () => {
                await clearClasseToCreate({
                  dispatch,
                  callback: async () => {
                    await updateModal({
                      modal: {},
                      dispatch,
                      callback: () => navigate(`/lms/manage-courses`),
                    });
                  },
                });
              }}
            >
              Voltar para Gerenciar Treinamentos
            </Button>
          </StyledButtonContainer>
        </StyledContainer>
      </>
    </StyledModal>
  );
};

export { ConfirmCreateContent };

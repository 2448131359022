import { useMemo } from "react";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";
import { TabContainer, TabArea } from "./styled";

export const Tabs = ({ selected, defaultTab = 0 }) => {
  const tabItens = useMemo(
    () => [
      {
        label: "Sobre",
        component: <TabContainer>{selected?.description}</TabContainer>,
      },
    ],
    [selected]
  );

  return (
    <TabArea>
      <Tab tabItens={tabItens} defaultTab={defaultTab} />
    </TabArea>
  );
};

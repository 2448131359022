import styled from "styled-components";

import { Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NextPreviousContainer = styled.div`
  height: 122px;

  display: flex;
  align-items: center;

  background: ${({ theme }) => theme.colors.neutral[95]};
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.neutral[90]};

  padding: 0 24px;
  button {
    align-self: center !important;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    height: 88px;
  }
`;

export const NextPreviousTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
`;

export const NextPreviousSubTitle = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    max-width: 280px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.sm}px`}) {
    max-width: 90px;
  }
`;

import { Modal } from "@components/Modal";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  &.modal-custom-theme .modal-container {
    width: 100%;
    max-width: 938px;
    max-height: 97vh;
    border-radius: 10px 10px 0 0;
  }

  &.MuiModal-root {
    align-items: flex-end !important;
  }

  .modal-content-area {
    padding: 0 !important;
  }
`;

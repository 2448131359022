import styled from "styled-components";

import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

export const ModalTitle = styled(Typography)`
  font-weight: bold !important;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const WarnIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.secondary[50]};
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin: 4px 0 16px;
  text-align: center;
`;

export const SubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const ModalBody = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0 112px;
  margin-bottom: 44px;
`;

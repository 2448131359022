import styled from "styled-components";

export const Certificate = styled.div`
  width: 1200px;
  min-height: 700px;
  padding: 32px;
  background-color: white;
  display: none;
`;

export const IconButton = styled.div`
  width: 64px;
  height: 64px;
  background: ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    stroke: ${({ theme }) => theme.colors.neutral[40]};
  }
`;

export const InfoText = styled.p`
  font-family: "Cabinet Grotesk";
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 16px 0;
`;

export const Title = styled.p`
  font-family: "Cabinet Grotesk";
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-bottom: 8px;
`;

export const Duration = styled.p`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 40px;
`;

export const DurationIcon = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[60]};
  border-radius: 500px;
  align-items: center;
  display: flex;
  margin-right: 4px;
  position: relative;
  top: 8px !important;
  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 70%;
      position: relative;
      color: ${({ theme }) => theme.colors.neutral[40]};
    }
  }
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin-bottom: 40px;
`;
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 40px;
`;

export const Signature = styled.p`
  font-family: "Allura", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
`;

export const MiniDivider = styled.div`
  height: 1px;
  width: 30%;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  margin-bottom: 12px;
`;

export const SignerName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const SignerPosition = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 68px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ExpireZone = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: 12px;
  padding: 16px 16px 16px 16px !important;
  height: 52px;
`;

export const DurationFooter = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  &:last-child {
    margin-right: 0;
  }
`;

export const CompanyDataArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  img {
    max-width: 250px;
    max-height: 225px;
    width: 40%;
    margin: 0 auto;
  }
`;

export const CompanyInfo = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  color: #6b5b66;
`;

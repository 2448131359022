import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const TabDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[40]};
  font-weight: 400;
  margin-bottom: 32px;
`;

export const Container = styled.div`
  width: 758px;
`;

export const OptionContentArea = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  p {
    margin-left: 20px;
  }
`;

export const FooterBar = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.neutral.light.pure};

  padding: 16px 24px;
  z-index: 99;

  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.1);
  margin: 0px -37px;
  width: -webkit-fill-available;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

import EmptyCourseState from "../../../../../assets/emptyCourseState.svg";

import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  Container,
  TextContainer,
  LabelTitle,
  LabelSubTitle,
  StyledButton,
  StyledButtonLabel,
} from "./styles";
import { useNavigate } from "react-router-dom";

import { track } from "@utils";

export const NoCourseCreated = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <EmptyCourseState />

      <TextContainer>
        <LabelTitle variant="headline6">
          Você ainda não criou nenhum treinamento
        </LabelTitle>
        <LabelSubTitle variant="body2">
          Para publicar um treinamento para seus alunos e visualizar os dados,
          crie um novo treinamento.
        </LabelSubTitle>

        <StyledButton
          variant="primary"
          size="large"
          style={{ alignSelf: "center", marginTop: "40px" }}
          onClick={() => {
            track({ name: "company_lms_createcourse_clicked" });
            navigate(`/lms/manage-courses/createEditCourse/create/0/0`);
          }}
        >
          <StyledButtonLabel variant="body3">
            Criar treinamento
          </StyledButtonLabel>
          <Icons name="Add" color="#fff" />
        </StyledButton>
      </TextContainer>
    </Container>
  );
};

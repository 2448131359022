import { ExamQuestion } from "@components/ExamQuestion";
import { IntroductionExam } from "@components/IntroductionExam";
import { DoneExam } from "../../components/Common";

export const Content = ({
  isDone,
  aproved,
  examQuestions,
  handleSelectOption,
  selected: {
    name,
    description,
    questions,
    settings: { passingScore, showAnswerSheet, attempts },
  },
  attemptsLeft,
  examInitiate,
  totalCount: { correctCount = 0, questionCount = 0 },
}) => {
  const validation = (isDone && aproved && showAnswerSheet) || !isDone;

  if (!validation) return <></>;

  return (
    <div style={{ padding: "24px" }}>
      {isDone ? (
        <DoneExam
          title="Pronto!"
          type={aproved ? "success" : "error"}
          minimunAnswers={passingScore}
          showAnswerSheet={aproved && showAnswerSheet}
          rightAnswers={correctCount}
          totalAnswers={questionCount}
        />
      ) : !examInitiate ? (
        <IntroductionExam
          title={name}
          description={description}
          minimunGrade={Math.round(passingScore * (10 / questions?.length))}
          totalQuestions={questions?.length}
          attemptsLeft={attemptsLeft}
          totalAttempts={attempts}
        />
      ) : (
        <></>
      )}

      {examQuestions?.map(
        ({ question = null, mandatory = false, alternatives }, index) => {
          return (
            <ExamQuestion
              key={index}
              number={index + 1}
              title={question}
              handleSelectOption={handleSelectOption}
              alternatives={alternatives}
              isMandatory={mandatory}
              isDone={isDone}
              isMobile={false}
              totalQuestions={examQuestions?.length}
            />
          );
        }
      )}
    </div>
  );
};

import styled from "styled-components";

import {
  Typography,
  TextField,
  PageContainer,
  Button,
  LinkButton,
  DataGrid,
  SearchField,
  DatePicker,
  TagFilter,
  TagDateFilter,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const CharCounter = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  padding: 0 112px;
`;

export const StyledRadioContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 17px 16px;

  background: ${({ theme }) => theme.colors.neutral.light.light1};
  border-radius: 8px;
`;

export const ContentCardContainer = styled.div`
  margin-right: 24px;
  &:last-child {
    margin-right: 0px;
  }
`;

export const StyledClassFooter = styled.div`
  width: 100%;
  padding: 24px 112px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ebe6e9;
`;

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.neutral.light.pure} !important;
  padding: 19px 58px !important;
`;

export const StyledLinkButton = styled(LinkButton)`
  color: ${({ theme }) => theme.colors.primary} !important;
`;

export const StyledDataGrid = styled(DataGrid)``;

export const StyledSearchField = styled(SearchField)`
  margin-bottom: 24px;
`;

export const ContantContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin: 0 16px 0 8px;
  }
  p {
    font-weight: bold;
  }
  svg {
    fill: transparent;
  }
`;

export const ContentType = styled(Typography)`
  display: flex;
  text-transform: capitalize;
  align-content: center;
  svg {
    border: 1px solid ${({ theme }) => theme.colors.neutral.dark.dark4};
    padding: 5px;
    border-radius: 50px;
    fill: transparent;
    margin-right: 14px;
  }
`;

export const InfoTitle = styled(Typography)`
  font-weight: 700 !important;
  line-height: 116%;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin-bottom: 24px;
`;

export const SelectTitle = styled(Typography)`
  font-weight: 700 !important;
  line-height: 116%;
  color: ${({ theme }) => theme.colors.neutral[30]};
  margin: 24px 0;
`;

export const SearchArea = styled.div`
  width: 100%;
`;

export const FilterArea = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  .select-custom .select-field-custom-theme {
    border-radius: 0px;
  }
  .select-custom .select-field-custom-theme div.MuiFilledInput-root,
  .text-field-custom-theme div.MuiFilledInput-root {
    border-radius: 50px !important;
    border: 1px solid ${({ theme }) => theme.colors.neutral[80]} !important;
  }
`;

export const FilterLabel = styled(Typography)`
  width: fit-content;
  margin-right: 12px;
  color: ${({ theme }) => theme.colors.neutral[40]};
  white-space: nowrap;
`;

export const DateField = styled(DatePicker)`
  width: 40% !important;
  margin-right: 12px;
  border-radius: 0;
  div:first-child {
    border-radius: 0;
  }
`;

export const StatusFilter = styled(TagFilter)`
  align-self: center;
  margin-right: 12px;
  svg.icon-tabler-square-dot {
    display: none;
  }
`;

export const FiltersSection = styled.div`
  display: flex;
  align-items: center;
`;

export const ClearFilters = styled(LinkButton)`
  align-self: center !important;
  white-space: nowrap;
`;

export const DateRangePicker = styled(TagDateFilter)`
  .icon-tabler-square-dot {
    display: none;
  }
  margin-right: 12px;
`;

export const SharedTooltip = styled.div`
  display: flex;
  align-items: center;
  .sharedTooltip {
    margin-left: 4px;
    cursor: pointer;
    svg {
      stroke: ${({ theme }) => theme.colors.neutral[30]};
    }
  }
`;

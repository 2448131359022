import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { trpc } from "@api/client";

import { FirstStep } from "./Components/FirstStep";
import { SecondStep } from "./Components/SecondStep";
// import { ThirdStep } from "./ThirdStep";
import { FourthStep } from "./Components/FourthStep";
import { SixthStep } from "./Components/SixthStep";
import { CourseContent } from "./Components/CourseContent";

export const CreateEditCourse = () => {
  const { step = "", type = "", courseId = "" } = useParams();
  const navigate = useNavigate();

  const currentStep = step ? parseInt(step) : undefined;

  useEffect(() => {
    if (currentStep == undefined) navigate(`/lms/manage-courses`);
  }, [currentStep]);

  const { mutate: updateCourseMutate, isLoading: isUpdatingCourse } =
    trpc.course.updateCourse.useMutation({});

  const { mutate: createCourseMutate, isLoading: isCreatingCourse } =
    trpc.course.createCourse.useMutation({});

  const { data = {}, isFetching: isGettingCourseById } =
    trpc.course.getCourseById.useQuery(
      { courseId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!courseId && courseId != "0",
      }
    );

  const { data: learningObjectData, isLoading: isLoadingGetLearningObjects } =
    trpc.learningObject.getLearningObjects.useQuery(
      {
        page: 1,
        limit: 10,
        order: "asc",
        sortBy: "_id",
        search: "",
        filters: {},
      },
      {
        refetchOnWindowFocus: false,
        retry: 1,
      }
    );

  const steps = {
    [0]: (
      <FirstStep
        type={type}
        data={data}
        learningObjectData={learningObjectData?.learningObjects}
        createCourseMutate={createCourseMutate}
        updateCourseMutate={updateCourseMutate}
        courseId={courseId == "0" ? null : courseId}
        isFetching={
          isUpdatingCourse ||
          isCreatingCourse ||
          isGettingCourseById ||
          isLoadingGetLearningObjects
        }
        currentStep={currentStep}
      />
    ),
    [1]: (
      <SecondStep
        type={type}
        data={data}
        updateCourseMutate={updateCourseMutate}
        courseId={courseId}
        isFetching={isUpdatingCourse || isCreatingCourse || isGettingCourseById}
        currentStep={currentStep}
      />
    ),
    [2]: (
      <CourseContent
        type={type}
        data={data}
        learningObjectData={learningObjectData?.learningObjects}
        updateCourseMutate={updateCourseMutate}
        courseId={courseId}
        currentStep={currentStep}
        isFetching={isUpdatingCourse || isCreatingCourse || isGettingCourseById}
      />
    ),
    // [3]: (
    //   <ThirdStep
    //   type={type}
    //   data={data}
    //   updateCourseMutate={updateCourseMutate}
    //   courseId={courseId}
    //   isFetching={isUpdatingCourse || isCreatingCourse || isGettingCourseById}
    //   currentStep={currentStep}
    // />
    // )
    [3]: (
      <FourthStep
        type={type}
        data={data}
        updateCourseMutate={updateCourseMutate}
        courseId={courseId}
        isFetching={isUpdatingCourse || isCreatingCourse || isGettingCourseById}
        currentStep={currentStep}
      />
    ),
    [4]: (
      <SixthStep
        type={type}
        data={data}
        courseId={courseId}
        currentStep={currentStep}
        updateCourseMutate={updateCourseMutate}
        isFetching={isGettingCourseById || isLoadingGetLearningObjects}
        isMutating={isUpdatingCourse || isCreatingCourse}
      />
    ),
    ["default"]: <></>,
  };

  return steps[currentStep as any] || steps["default"];
};

import { useState } from "react";
import { dayjs, TagDateFilter } from "@flash-tecnologia/hros-web-ui-v2";
import {
  ClearFilters,
  DateField,
  DateRangePicker,
  FilterLabel,
  FiltersSection,
  StatusFilter,
  StatusSelect,
} from "./styles";

export const CourseFilter = ({ handleSetFilter, optionsFilters }) => {
  const initialValues = [
    {
      label: "Em andamento",
      value: "doing",
      checked: false,
    },
    {
      label: "Não iniciado",
      value: "notStarted",
      checked: false,
    },
    {
      label: "Concluído",
      value: "done",
      checked: false,
    },
  ];

  const [filters, setFilters] = useState(initialValues);
  const [mandatorySelect, setMandatorySelect] = useState<any>([]);
  const [certificateSelect, setCertificateSelect] = useState<any>([]);
  const [updateKey, setUpdateKey] = useState(0);

  const enableClearAll =
    optionsFilters?.status?.length ||
    mandatorySelect?.length ||
    certificateSelect?.length ||
    optionsFilters?.start ||
    optionsFilters?.end;

  return (
    <FiltersSection>
      <FilterLabel variant="body3">Filtrar por</FilterLabel>
      <StatusFilter
        variant="secondary"
        filterLabel="Status"
        optionIconType="checkbox"
        disableOptionsSearch
        selectedOptions={
          filters
            ?.filter(({ checked }) => checked)
            ?.map(({ value }) => value) || []
        }
        options={filters}
        onClick={(value) => {
          const filteredItens = filters?.map((item) => {
            if (item?.value === value) {
              return { ...item, checked: !item?.checked };
            }
            return item;
          });
          setFilters(filteredItens);
        }}
        onApply={(value) => handleSetFilter("status", value)}
        onClear={() => {
          handleSetFilter("status", []);
          setFilters(initialValues);
        }}
      />
      <StatusFilter
        variant="secondary"
        filterLabel="Obrigatoriedade"
        optionIconType="none"
        disableOptionsSearch
        selectedOptions={mandatorySelect}
        options={[
          {
            label: "Obrigatório",
            value: "true",
          },
          {
            label: "Não obrigatório",
            value: "false",
          },
        ]}
        onClick={(value) => {
          setMandatorySelect([value]);
        }}
        onApply={(value) => handleSetFilter("mandatory", value)}
        onClear={() => {
          setMandatorySelect([]);
          handleSetFilter("mandatory", null);
        }}
      />
      <StatusFilter
        variant="secondary"
        filterLabel="Certificação"
        optionIconType="none"
        disableOptionsSearch
        selectedOptions={certificateSelect}
        options={[
          {
            label: "Com certificação",
            value: "certificate",
          },
          {
            label: "Não certificação",
            value: "notCertificate",
          },
        ]}
        onClick={(value) => {
          setCertificateSelect([value]);
        }}
        onApply={(value) =>
          handleSetFilter(
            "certificate",
            value[0] === "certificate"
              ? true
              : value[0] === "notCertificate"
              ? false
              : null
          )
        }
        onClear={() => {
          setCertificateSelect([]);
          handleSetFilter("certificate", null);
        }}
      />
      <div key={updateKey + 10}>
        <DateRangePicker
          filterLabel="Início"
          onSubmit={(value) => {
            if (value?.from === undefined) {
              handleSetFilter("start", null);
              return;
            }
            handleSetFilter("start", value);
          }}
          variant="secondary"
        />
      </div>
      <div key={updateKey}>
        <DateRangePicker
          filterLabel="Encerramento"
          onSubmit={(value) => {
            if (value?.from === undefined) {
              handleSetFilter("end", null);
              return;
            }
            handleSetFilter("end", value);
          }}
          variant="secondary"
        />
      </div>

      {enableClearAll ? (
        <ClearFilters
          variant="secondary"
          onClick={() => {
            setFilters(initialValues);
            handleSetFilter("status", []);
            handleSetFilter("mandatory", null);
            handleSetFilter("certificate", null);
            handleSetFilter("start", null);
            handleSetFilter("end", null);
            setMandatorySelect([]);
            setCertificateSelect([]);
            setUpdateKey((prev) => prev + 1);
          }}
        >
          Limpar filtros
        </ClearFilters>
      ) : null}
    </FiltersSection>
  );
};

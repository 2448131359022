import styled from "styled-components";

import {
  IconButton,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const SuccessModal = styled(Modal)`
  && {
    .modal-icon-container {
      top: -40px;
    }
    .modal-content-area {
      padding: 0;
    }
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 12px;
  z-index: 1;
  display: block;
`;

export const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.light.light2};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledContainer = styled.div`
  width: 645px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 44px 44px 0px 44px;
`;

export const StyledFlexBetween = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 4px;
  padding: 0 66px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const SubTitle = styled(Typography)`
  font-weight: 600;
  margin-bottom: 16px;
  color: #83727d;
  text-align: center;
  padding: 0 66px;
`;

export const Description = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  padding: 0 66px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 44px;
`;

export const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  width: 100%;
  margin-bottom: 44px;
`;

export const Gap = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 44px;
`;

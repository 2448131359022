import EmptyContentState from "../../../../../assets/emptyContentState.svg";

import { Container, LabelTitle, TextContainer } from "./styles";

export const NoStudent = () => {
  return (
    <Container>
      <EmptyContentState />
      <TextContainer>
        <LabelTitle variant="headline6">Nenhum aluno encontrado</LabelTitle>
      </TextContainer>
    </Container>
  );
};

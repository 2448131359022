import "./styles.css";

import {
  useMediaState,
  PlayButton,
  MuteButton,
  VolumeSlider,
} from "@vidstack/react";

import PlayIcon from "../../../../../assets/audio-player-play.svg";
import PauseIcon from "../../../../../assets/audio-player-pause.svg";
import MuteSoundIcon from "../../../../../assets/audio-player-mute-sound.svg";
import LowSoundIcon from "../../../../../assets/audio-player-low-sound.svg";
import HighSoundIcon from "../../../../../assets/audio-player-high-sound.svg";
import { Tooltip } from "@flash-tecnologia/hros-web-ui-v2";

export interface MediaButtonProps {
  tooltipPlacement: any;
}

export function Play() {
  const isPaused = useMediaState("paused");

  return (
    <Tooltip title={isPaused ? "Play" : "Pause"} placement="top">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PlayButton className={`audioPlayButton`}>
          {isPaused ? <PlayIcon /> : <PauseIcon />}
        </PlayButton>
      </div>
    </Tooltip>
  );
}

export function Sound() {
  const volume = useMediaState("volume"),
    isMuted = useMediaState("muted");

  return (
    <Tooltip title={isMuted ? "Reativar o som" : "Sem áudio"} placement="top">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MuteButton className="audioMuteButtom">
          {isMuted || volume == 0 ? (
            <MuteSoundIcon />
          ) : volume < 0.5 ? (
            <LowSoundIcon />
          ) : (
            <HighSoundIcon />
          )}
        </MuteButton>
      </div>
    </Tooltip>
  );
}

export function SoundSlider() {
  return (
    <VolumeSlider.Root
      className={`audio-volume-slider audioSlider audioSliderSmall`}
    >
      <VolumeSlider.Track className={"audioTrack"} />
      <VolumeSlider.TrackFill className={`audioTrackFill audioTrack`} />
      <VolumeSlider.Preview className={"audioPreviewVolume"} noClamp>
        <VolumeSlider.Value className={"audioVolume"} />
      </VolumeSlider.Preview>
      <VolumeSlider.Thumb className={"audioThumb"} />
    </VolumeSlider.Root>
  );
}

import { useEffect, useState } from "react";

import { Button, TextField } from "@flash-tecnologia/hros-web-ui-v2";

import {
  StyledModal,
  StyledTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledFlexCenter,
  StyledIconButton,
  StyledLoader,
  StyledTextLoading,
} from "./styled";

import { trpc } from "@api/client";

const UpdateClassModulesModal = ({
  modal: { open, level, _id, courseId, title, setModal, variant },
}) => {
  const utils = trpc.useContext();

  const [name, setName] = useState<any>("");

  useEffect(() => {
    if (title) setName(title);
  }, [title]);

  const closeModal = () => {
    setModal({
      open: false,
      type: null,
      parentId: null,
      variant: null,
      level: null,
      _id: null,
      title: null,
    });
  };

  const { mutate: updateNodeCourseHierarchyMutate, isLoading } =
    trpc.course.updateNodeCourseHierarchy.useMutation({
      onSuccess: () => {
        utils.course.getCourseHierarchy.invalidate();
      },
    });

  return (
    <StyledModal isLoading={isLoading} onClose={closeModal} open={open}>
      {isLoading ? (
        <StyledFlexCenter style={{ flexDirection: "column" }}>
          <StyledLoader variant="primary" size="large" />
          <StyledTextLoading variant="body2">Atualizando...</StyledTextLoading>
        </StyledFlexCenter>
      ) : (
        <>
          <StyledFlexBetween>
            <StyledTitle variant="headline7">
              {variant === "editModule"
                ? "Editar módulo"
                : variant === "editClass"
                ? "Editar aula"
                : "Editar treinamento"}
            </StyledTitle>
            <StyledIconButton
              size="small"
              variant="line"
              icon="IconX"
              onClick={closeModal}
            />
          </StyledFlexBetween>
          <StyledContainer>
            <div style={{ marginBottom: "48px" }}>
              <TextField
                fullWidth
                label={`Nome ${
                  variant === "editModule" ? "do módulo" : "da aula"
                }`}
                value={name}
                onChange={(e) => setName(e?.target?.value)}
              />
            </div>
            <StyledButtonContainer>
              <Button size="large" variant="secondary" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                size="large"
                variant="primary"
                onClick={() => {
                  if (!name) return;

                  updateNodeCourseHierarchyMutate({
                    params: { name, id: _id, level },
                    courseId,
                  });
                }}
              >
                {variant === "editModule" ? (
                  <>Editar módulo</>
                ) : variant === "editClass" ? (
                  <>Editar aula</>
                ) : (
                  <>Editar Treinamento</>
                )}
              </Button>
            </StyledButtonContainer>
          </StyledContainer>
        </>
      )}
    </StyledModal>
  );
};

export { UpdateClassModulesModal };

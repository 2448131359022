import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

import {
  DataGrid,
  Skeleton,
  EmptyState,
  dayjs,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";

import EmptyImage from "../../../../../assets/emptyState.jpg";

import { StyledText, StyledTitle, track, pageSizeOptions } from "@utils";

import {
  StyledFlexRow,
  StyledImageContainer,
  StyledTag,
  ProgressArea,
  ProgressBar,
  ProgressWrapper,
} from "./styled";

import { NoMyCourse } from "../../NoMyCourse";

interface TableGridProps {
  data: any[];
  rawData: any[];
  pageSize: number;
  loading: boolean;
  refetch: () => void;
}

export const CourseTable = ({
  data,
  rawData,
  pageSize,
  loading,
  refetch,
}: TableGridProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        accessor: "name",
        Header: "Treinamento",
        Cell: ({
          row: {
            original: { banner = { path: null, signature: null }, name = null },
          },
        }) => (
          <StyledFlexRow>
            <div>
              <StyledImageContainer
                url={
                  banner?.path
                    ? `${banner?.path}${banner?.signature}`
                    : EmptyImage
                }
              />
            </div>
            <div style={{ marginLeft: "16px" }}>
              <StyledTitle variant="body3" tag="p">
                {name}
              </StyledTitle>
            </div>
          </StyledFlexRow>
        ),
      },
      {
        accessor: "status",
        Header: "Status",
        disableSortBy: true,
        Cell: ({ value }) => {
          let tag = <div></div>;

          switch (value) {
            case "beforeInit":
              tag = (
                <StyledTag
                  variant="primary"
                  style={{ backgroundColor: "#FFECD6", color: "#CB6D01" }}
                >
                  Não iniciado
                </StyledTag>
              );
              break;
            case "done":
              tag = (
                <StyledTag
                  variant="primary"
                  style={{ backgroundColor: "#D7F9F3", color: "#094338" }}
                >
                  Concluído
                </StyledTag>
              );
              break;
            default:
              tag = (
                <StyledTag
                  variant="primary"
                  style={{ backgroundColor: "#D1E7FF", color: "#015FCB" }}
                >
                  Em andamento
                </StyledTag>
              );
              break;
          }

          return <StyledFlexRow>{tag}</StyledFlexRow>;
        },
      },
      {
        accessor: "mandatory",
        Header: "Obrigatório",
        disableSortBy: true,
        Cell: ({ value }) => (
          <StyledFlexRow>
            <StyledTag variant={value ? "primary" : "secondary"}>
              {value == "mandatory" ? "Sim" : "Não"}
            </StyledTag>
          </StyledFlexRow>
        ),
      },
      {
        accessor: "certificate",
        Header: "Emissão de certificado",
        disableSortBy: true,
        Cell: ({ value }) => (
          <StyledFlexRow>
            <StyledTag
              variant={value === "certificate" ? "primary" : "secondary"}
            >
              {value === "certificate" ? "Sim" : "Não"}
            </StyledTag>
          </StyledFlexRow>
        ),
      },
      {
        Header: "Início em",
        accessor: "start",
        Cell: ({
          row: {
            original: { start = null },
          },
        }) => (
          <StyledText variant="body3">
            {start ? dayjs(start).format("DD/MM/YYYY") : "Não informado"}
          </StyledText>
        ),
      },
      {
        Header: "Disponível até",
        accessor: "end",
        Cell: ({
          row: {
            original: { end = null },
          },
        }) => (
          <StyledText variant="body3">
            {end ? dayjs(end).format("DD/MM/YYYY") : "Sempre disponível"}
          </StyledText>
        ),
      },
      {
        Header: "Progresso",
        accessor: "doneClassesTotal",
        Cell: ({
          row: {
            original: { doneClassesTotal = 0, classesTotal = 0 },
          },
        }) => {
          const percentDone =
            Math.round((doneClassesTotal / classesTotal) * 100) ?? 0;
          return (
            <ProgressArea>
              <ProgressWrapper>
                <ProgressBar totalDone={percentDone}>
                  <span />
                </ProgressBar>
              </ProgressWrapper>
              <StyledText variant="body4" setColor="primary">
                {percentDone >= 100 ? 100 : percentDone}%
              </StyledText>
            </ProgressArea>
          );
        },
      },
      {
        accessor: "_id",
        disableSortBy: true,
        sticky: "right",
        Cell: ({
          row: {
            original: {
              status,
              start = null,
              end = null,
              _id = null,
              classesTotal = 0,
            },
          },
        }) => {
          const startDate = dayjs(start);
          const endDate = dayjs(end);

          const disabled =
            status !== "done" &&
            (dayjs().isBefore(startDate, "day") ||
              (endDate && dayjs().isAfter(endDate, "day")) ||
              classesTotal === 0);

          return (
            <div style={{ display: "inline-flex" }}>
              <Button
                variant="primary"
                size="small"
                style={{ alignSelf: "center" }}
                disabled={disabled}
                onClick={() => {
                  track({
                    name: "employee_lms_mycourses_accesscourse_clicked",
                  });
                  navigate(`/lms/my-courses/${_id}/0/class/view`);
                }}
              >
                Acessar
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  if (loading) return <Skeleton variant="rounded" height={"400px"} />;

  if (!rawData.length)
    return (
      <EmptyState
        buttonText={""}
        description={<NoMyCourse />}
        buttonProps={{ size: "small", hidden: true }}
      />
    );

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum treinamento ativo encontrado.",
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </>
  );
};

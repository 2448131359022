import { segmentEventTracking } from "@flash-tecnologia/hros-web-utility";

type ObjectLiteral = {
  [key: string]: string;
};

export const track = ({
  name,
  params,
  identify,
}: {
  name: string;
  params?: ObjectLiteral;
  identify?: {
    id: string;
    params?: ObjectLiteral;
  };
}) => {
  segmentEventTracking({
    name,
    module: "LMS",
    businessUnit: "people",
    params,
  });
};

interface handleFooterBarTrack {
  page: "video" | "pdf" | "exam" | "podcast" | "article" | "course";
  step: "information" | "content" | "students" | "questions" | "configuration";
  action: "cancel" | "next" | "save";
}

export const handleFooterBarTrack = ({
  page,
  step,
  action,
}: handleFooterBarTrack): string => {
  const pageTranslator = (page: handleFooterBarTrack["page"]) => {
    if (page === "exam") return "test";
    return page;
  };

  const ac = action === "save" ? `save${pageTranslator(page)}` : action;

  const track = `company_lms_${pageTranslator(page)}${step}_${ac}_clicked`;

  return track;
};

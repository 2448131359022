import { useEffect, useState } from "react";
import OrgTree from "react-org-tree";

import { ChartArea } from "./styles";

import { Skeleton } from "@mui/material";

import { ContentChartCard } from "./contentChartCard";
import { WarningDeleteModal2 } from "@components/Modals/WarningDeleteModal2";
import { AddClassModulesModal } from "../AddClassModulesModal";
import { UpdateClassModulesModal } from "@components/Modals/UpdateClassModulesModal";
import { AddClassModal } from "../AddClassModal";

export const ContentChart = ({ courseId, courseHierachy }) => {
  const sectionLoading = {
    id: "0",
    expand: true,
    label: (
      <Skeleton
        width="311px"
        height="97px"
        animation="pulse"
        variant="rectangular"
      />
    ),
    children: [
      {
        id: 0,
        expand: false,
        label: (
          <Skeleton
            width="311px"
            height="97px"
            animation="pulse"
            variant="rectangular"
          />
        ),
        children: [
          {
            id: 1,
            expand: false,
            label: (
              <Skeleton
                width="311px"
                height="97px"
                animation="pulse"
                variant="rectangular"
              />
            ),
            children: [],
          },
          {
            id: 2,
            expand: false,
            label: (
              <Skeleton
                width="311px"
                height="97px"
                animation="pulse"
                variant="rectangular"
              />
            ),
            children: [],
          },
        ],
      },
    ],
  };

  const [modal, setModal] = useState<any>({
    open: false,
    type: null,
    variant: null,
    parentId: null,
    level: null,
    _id: null,
    title: null,
  });
  const [data, setData] = useState<any>(sectionLoading);
  const [classData, setClassData] = useState<any>({});
  const [addClass, setAddClass] = useState<any>(false);

  useEffect(() => {
    if (Object.keys(courseHierachy).length) {
      const parsedData = {
        ...mapDataToOrgchartNode([courseHierachy], 0, 0)[0],
      };
      setData(parsedData);
    }
  }, [courseHierachy]);

  const mapDataToOrgchartNode = (data, parentId, level) => {
    const mappedData = data?.map(
      ({
        _id,
        courseName,
        courseDescription,
        chapters,
        classes,
        name,
        description,
        learningObjectId,
      }) => {
        return {
          id: _id,
          expand: true,
          title: courseName ?? name,
          label: (
            <ContentChartCard
              params={{
                _id,
                title: courseName ?? name,
                subtitle: classes?.length
                  ? `${classes?.length} módulos`
                  : chapters?.length
                  ? `${chapters?.length} aulas`
                  : null,
                description:
                  courseDescription || description || "Sem descrição",
                canEdit: true,
                canDelete: true,
                canAdd: true,
                parentId: parentId,
                level: level,
                openModal: setModal,
                classes: classes,
                chapters: chapters,
                courseId: courseId,
                learningObjectId: learningObjectId,
                setClassData: setClassData,
                setAddClass: setAddClass,
              }}
            />
          ),
          children: classes?.length
            ? mapDataToOrgchartNode(classes, _id, 1)
            : chapters?.length
            ? mapDataToOrgchartNode(chapters, _id, 2)
            : null,
        };
      }
    );
    return mappedData;
  };

  return (
    <div
      style={{
        overflow: "hidden hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChartArea>
        <OrgTree
          data={data}
          expandAll={false}
          collapsable={true}
          horizontal={true}
        />
      </ChartArea>

      {modal?.type === "delete" ? (
        <WarningDeleteModal2 modal={{ ...modal, courseId, setModal }} />
      ) : modal?.type === "add" ? (
        <AddClassModulesModal modal={{ ...modal, courseId, setModal }} />
      ) : modal?.type === "edit" ? (
        <UpdateClassModulesModal modal={{ ...modal, courseId, setModal }} />
      ) : (
        <></>
      )}
      <AddClassModal
        open={addClass}
        closeModal={() => setAddClass(false)}
        classData={classData}
        courseHierachy={courseHierachy}
      />
    </div>
  );
};

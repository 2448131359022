import { EActions, IActions } from "./actions";
import { initialState } from "./store";
import { IContext } from "./types";

const reducer = (state: IContext, action: IActions) => {
  switch (action.type) {
    case EActions.SYSTEM_LOADING_START: {
      const array = state?.loading?.urls as any;
      const actionArray = [action.payload] as any;
      return {
        ...state,
        loading: {
          urls: [].concat(array, actionArray).filter((u) => u),
        },
      };
    }
    case EActions.SYSTEM_LOADING_END:
      return {
        ...state,
        loading: {
          urls: state.loading.urls.filter((u) => u !== action.payload),
        },
      };
    case EActions.UPDATE_MODAL:
      return {
        ...state,
        modalInfo: action.payload,
      };
    case EActions.GET_COURSES_FOR_ADMIN:
      return {
        ...state,
        classes: action.payload,
        filteredClasses: action.payload,
      };
    case EActions.GET_COURSES_FOR_ADMIN_FILTERED:
      return {
        ...state,
        adminCourses: {
          filteredCourses: action.payload,
        },
      };
    case EActions.GET_ALL_LEARNING_OBJECT:
      return {
        ...state,
        learningObject: action.payload,
      };
    case EActions.GET_ALL_LEARNING_OBJECT_FILTERED:
      return {
        ...state,
        filteredLearningObject: action.payload,
      };
    case EActions.FILTER_COURSES_FOR_ADMIN:
      const cleanValue = action.payload.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
      const regex = new RegExp(cleanValue, "gi");
      return {
        ...state,
        filteredClasses: state.classes.filter((u) => u.name.match(regex)),
      };
    case EActions.FILTER_CONTENT_FOR_ADMIN:
      const clearValue = action.payload.replace(/[-[/\]{}()*+?.,\\^$|#]/g, "");
      const regexContent = new RegExp(clearValue, "gi");
      return {
        ...state,
        filteredLearningObject: state.learningObject.filter((u) =>
          u.name.match(regexContent)
        ),
      };
    case EActions.GET_COURSES_MODULES:
      return {
        ...state,
        mainStudy: action.payload,
      };
    case EActions.GET_MY_COURSES:
      return {
        ...state,
        myCourses: action.payload,
      };
    case EActions.GET_MY_COURSES_FILTERED:
      return {
        ...state,
        myCoursesFiltered: action.payload,
      };
    case EActions.GET_COURSE_HIERARCHY:
      return {
        ...state,
        courseHierachy: action.payload,
      };

    case EActions.SAVE_STEP_INFO:
      return {
        ...state,
        createStepParam: { ...state.createStepParam, ...action.payload },
      };

    case EActions.GET_COURSE_BY_ID:
      return {
        ...state,
        classe: { ...state.createStepParam, ...action.payload },
      };

    case EActions.GET_GROUPS_COMPANY:
      return {
        ...state,
        companyGroups: action.payload,
      };

    case EActions.GET_EMPLOYEES_COMPANY:
      return {
        ...state,
        companyEmployees: action.payload,
      };

    case EActions.SAVE_CLASS_TO_CREATE:
      return {
        ...state,
        classToCreate: action.payload,
      };

    case EActions.SAVE_CLASS_URL:
      return {
        ...state,
        classUrl: action.payload,
      };

    case EActions.GET_EMPLOYEE_EXAM_MONITORING:
      return {
        ...state,
        examsMonitorings: action.payload,
      };

    case EActions.GET_CERTIFICATES:
      return {
        ...state,
        certificates: action.payload,
      };

    case EActions.GET_CERTIFICATES_FILTERED:
      return {
        ...state,
        certificatesFiltered: action.payload,
      };

    case EActions.GET_CERTIFICATE_BY_ID:
      return {
        ...state,
        certificate: action.payload,
      };

    case EActions.GET_MANAGE_COURSES_METRICS:
      return {
        ...state,
        manageCourses: {
          metrics: action.payload,
        },
      };

    case EActions.GET_COURSE_METRIC_BY_ID:
      return {
        ...state,
        manageCourses: {
          courseMetrics: action.payload,
        },
      };

    case EActions.GET_EMPLOYEES:
      return {
        ...state,
        employees: action.payload,
      };

    case EActions.GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        employee: action.payload,
      };

    case EActions.GET_EMPLOYEE_BY_ID_FILTERED:
      return {
        ...state,
        employee: action.payload,
      };

    case EActions.GET_DEFAULT_NOTIFICATIONS:
      return {
        ...state,
        defaultNotifications: action.payload,
      };

    case EActions.UPDATE_MANAGE_TAB:
      return {
        ...state,
        manageTab: action.payload,
      };

    default:
      throw new Error("Error in context reducer");
  }
};

export { reducer, initialState };

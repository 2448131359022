import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { AccordionDetails, Accordion } from "@mui/material";
import styled, { css } from "styled-components";

export const DescriptionArea = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 24px;

  color: ${({ theme }) => theme.colors.neutral[30]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  cursor: pointer;

  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.secondary.light.light2 : ""};

  p {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.secondary.dark.dark5 : ""} !important;
  }

  svg {
    stroke: ${({ theme, selected }) =>
      selected ? theme.colors.secondary.dark.dark5 : ""} !important;
  }
`;

export const SearchAccordionArea = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  input {
    padding: 0px;
    margin-left: 16px;
    color: ${({ theme }) => theme.colors.neutral[50]};
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 18px;
  }
  fieldset {
    border: none;
  }
`;

export const IconSearch = styled(Icons)`
  fill: transparent;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const IconInfoCircle = styled(Icons)`
  fill: transparent;
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const CheckIcon = styled(Icons)<{
  checked?: boolean;
}>`
  color: ${({ theme, checked }) =>
    checked
      ? theme.colors.feedback.success.dark1
      : theme.colors.neutral.light.light3};
`;

export const LockIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.neutral[60]};
`;

export const StyledAccordion = styled(Accordion)`
  z-index: 0;

  &.MuiPaper-root {
    margin: 0;
    border-radius: 0px;
    before: {
      display: none;
    }
  }
  .mobile-accordion .MuiAccordionSummary-content div {
    margin-left: 0px !important;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)<{
  selected: boolean;
  disabled?: boolean;
}>`
  &.MuiAccordionDetails-root {
    padding: 16px 24px 16px 40px;
    cursor: pointer;

    background-color: ${({ theme, selected }) =>
      selected ? theme.colors.secondary.light.light2 : ""};
    color: ${({ theme, selected, disabled }) =>
      selected
        ? theme.colors.secondary.dark.dark5
        : disabled
        ? theme.colors.neutral[60]
        : ""};
    p {
      color: ${({ theme, selected, disabled }) =>
        selected
          ? theme.colors.secondary.dark.dark5
          : disabled
          ? theme.colors.neutral[60]
          : ""};
    }
  }
`;

export const ModulesContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  overflow-x: hidden;
`;

export const StyledAccordionTitleNumber = styled(Typography)`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.feedback.success.dark1};
`;

export const StyledDescriptionSubContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const StyledFlexRow = styled.div`
  display: flex;
`;

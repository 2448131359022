import { Button, Icons, LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

import { StyledModal } from "../../../../../components/AddModuleModal/styled";
import {
  CloseButton,
  ModalFooterContainer,
  ModalHeader,
} from "@components/Modals/WarningDeleteModal2/styled";
import {
  IconContainer,
  ModalBody,
  ModalTitle,
  SubTitle,
  Title,
  WarnIcon,
} from "./styled";

export const NoContentModal = ({
  open = false,
  onClose,
  onConfirm,
}: {
  open?: boolean;
  onClose?: any;
  onConfirm?: () => any;
}) => (
  <StyledModal onClose={onClose} open={open}>
    <>
      <ModalHeader>
        <CloseButton
          size="small"
          variant="line"
          icon="IconX"
          onClick={onClose}
        />
        <IconContainer>
          <WarnIcon size={48} name="IconAlertCircle" fill="transparent" />
        </IconContainer>
        <ModalTitle variant="body3">Atenção!</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Title variant="headline6">
          Você ainda não adicionou seu conteúdo. <br /> Deseja sair mesmo assim?
        </Title>
        <SubTitle variant="body3">
          O conteúdo será salvo como rascunho e você poderá editar mais tarde.
        </SubTitle>
      </ModalBody>
      <ModalFooterContainer>
        <LinkButton variant="primary" onClick={onConfirm}>
          Sair e salvar rascunho
        </LinkButton>
        <Button size="large" variant="primary" onClick={onClose}>
          Continuar criação
          <Icons name="IconArrowRight" fill="transparent" />
        </Button>
      </ModalFooterContainer>
    </>
  </StyledModal>
);

import styled from "styled-components";

import {
  Icons,
  Typography,
  IconButton,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const StyledModal = styled(Modal)`
  && {
    .modal-icon-container {
      top: -40px;
    }
    .modal-content-area {
      padding: 40px 40px 40px 112px;
    }
  }
`;

export const StyledIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const StyledIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary.light.light2};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const StyledTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  width: 423px;
`;

export const SubTitle = styled(Typography)`
  line-height: 38px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin-bottom: 16px;
  font-weight: bold;
`;

export const Description = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[40]};
  margin-bottom: 16px;
`;

export const StyledContainer = styled.div`
  width: 423px;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledFlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 32px;
`;

export const WarnArea = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.feedback.info[70]};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
`;

export const WarnIcon = styled.div`
  background: ${({ theme }) => theme.colors.feedback.info[90]};
  padding: 8px;
  border-radius: 200px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${({ theme }) => theme.colors.feedback.info[50]};
  }
`;

export const WarnTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
  font-weight: bold !important;
  line-height: 22px;
`;

export const WarnDescription = styled(Typography)`
  color: ${({ theme }) => theme.colors.feedback.info[10]};
  line-height: 20px;
`;

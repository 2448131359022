import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";

import {
  Certificate,
  CompanyDataArea,
  CompanyInfo,
  Divider,
  Duration,
  DurationFooter,
  DurationIcon,
  ExpireZone,
  Footer,
  IconButton,
  InfoText,
  MiniDivider,
  Name,
  Signature,
  SignerName,
  SignerPosition,
  Title,
} from "./styled";

const CertificatePreview = ({
  data: {
    courseName = null as any,
    companyLogo = {} as any,
    companyName = null as any,
    signText = null as any,
    signerName = null as any,
    signerPostion = null as any,
    expiresDate = null as any,
    totalHours = null as any,
  },
}) => {
  return (
    <Certificate>
      <IconButton>
        <Icons name="IconCertificate" fill="transparent" size={20} />
      </IconButton>
      <InfoText>Certificado de conclusão</InfoText>
      <Title>
        {courseName && courseName != "" ? courseName : "Nome do treinamento"}
      </Title>
      {courseName && totalHours ? (
        <Duration>
          <DurationIcon>
            <Icons name="IconClock" fill="transparent" />
          </DurationIcon>
          {courseName ? (
            <>
              {totalHours > 0 ? (
                <>
                  Duração de {totalHours} hora{totalHours > 1 ? "s" : null}
                </>
              ) : null}
            </>
          ) : (
            <>Duração de 2 horas</>
          )}
        </Duration>
      ) : null}
      <Name>Nome do aluno</Name>
      {signText && (
        <>
          <Divider />
          <Signature>{signText}</Signature>
          <MiniDivider />
        </>
      )}
      {signerName && <SignerName>{signerName}</SignerName>}
      {signerPostion && <SignerPosition>{signerPostion}</SignerPosition>}
      <Footer>
        {expiresDate ? (
          <ExpireZone>
            <DurationFooter>
              <DurationIcon>
                <Icons name="IconCalendar" fill="transparent" />
              </DurationIcon>
              Emitido em DD/MM/AAAA
            </DurationFooter>
            <DurationFooter>
              <DurationIcon>
                <Icons name="IconCalendar" fill="transparent" />
              </DurationIcon>
              Válido até DD/MM/AAAA
            </DurationFooter>
          </ExpireZone>
        ) : (
          <div></div>
        )}

        <CompanyDataArea>
          {companyLogo?.data || companyLogo?.path ? (
            <img
              src={
                companyLogo?.data ??
                `${companyLogo?.path}${companyLogo?.signature}`
              }
              alt="company logo"
            />
          ) : null}
          {companyName && <CompanyInfo>{companyName}</CompanyInfo>}
        </CompanyDataArea>
      </Footer>
    </Certificate>
  );
};

export default CertificatePreview;

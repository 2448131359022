import { useState, useEffect } from "react";

import {
  Typography,
  TagCheckboxFilter,
  TagDateFilter,
  dayjs,
  ButtonGroup,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Container, ButtonArea, FilterContainer, StyledButton } from "./styled";

import { Filter, Option } from "../types";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
interface TableFiltersProps {
  data?: any[];
  disabled?: boolean;
  typeView?: string;
  handleSetTypeView?: any;
  filters?: Filter[];
  onFilter?: (el: { [key: string]: any[] }) => any;
}

export const Filters = ({
  data,
  filters,
  typeView = "",
  handleSetTypeView,
  onFilter,
  disabled,
}: TableFiltersProps) => {
  const [filterStates, setFilterStates] = useState<
    | {
        [key: string]: any[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (filterStates === undefined) return;

    const timer = setTimeout(() => {
      onFilter && onFilter(filterStates);
    }, 700);

    return () => timer && clearTimeout(timer);
  }, [filterStates]);

  const renderFilters = () => {
    return filters?.map((value) => {
      const renderOptions =
        value?.options?.map((e: Option) => {
          return { label: e.label || "", value: e.value || "" };
        }) || [];

      switch (value.type) {
        case "date":
          return (
            <TagDateFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onSubmit={(date) => {
                const filteredOptions = (data || []).filter((item) => {
                  const isEndAcessor = value.accessor === "end";
                  const endValidation =
                    isEndAcessor && item[value.accessor] == null;

                  if (date?.to) {
                    return (
                      (dayjs(item[value.accessor]).isSameOrAfter(
                        date.from,
                        "day"
                      ) &&
                        dayjs(item[value.accessor]).isSameOrBefore(
                          date.to,
                          "day"
                        )) ||
                      endValidation
                    );
                  }

                  if (date?.from && !date?.to)
                    return (
                      dayjs(item[value.accessor]).isSameOrAfter(
                        date.from,
                        "day"
                      ) || endValidation
                    );

                  return true;
                });

                const parsedValue = filteredOptions.map(
                  (data) => data[value.accessor]
                );

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: parsedValue,
                };

                if (!date.from) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
              key={(renderOptions || []).length + value.accessor}
            />
          );

        case "checkbox":
        default:
          return (
            <TagCheckboxFilter
              variant="secondary"
              badgeNumber={filterStates?.[value.accessor]?.length || 0}
              hasLeftIcon={false}
              filterLabel={value?.label || ""}
              options={renderOptions || []}
              onClick={(e) => {
                if (!e.length && !(filterStates?.[value.accessor]?.length || 0))
                  return;

                const stateUpdated = {
                  ...filterStates,
                  [value.accessor]: e,
                };

                if (!e.length) delete stateUpdated[value.accessor];

                setFilterStates(stateUpdated);
              }}
              showClearButton={true}
              key={(renderOptions || []).length + value.accessor}
            />
          );
      }
    });
  };

  return (
    <Container>
      <FilterContainer disabled={disabled}>
        <Typography variant="body3">Filtrar por</Typography>
        {renderFilters()}
      </FilterContainer>
      <ButtonArea>
        <ButtonGroup>
          <StyledButton
            selected={typeView === "list"}
            onClick={() => handleSetTypeView("list")}
          >
            <Icons name="IconList" />
          </StyledButton>
          <StyledButton
            selected={typeView === "grid"}
            onClick={() => handleSetTypeView("grid")}
          >
            <Icons name="IconLayoutGrid" fill="transparent" />
          </StyledButton>
        </ButtonGroup>
      </ButtonArea>
    </Container>
  );
};

import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";

import CertificatePreview from "./CertificatePreview";
import { dispatchToast } from "@utils";
import { trpc } from "@api/client";

import {
  MainContainer,
  Column,
  ContentCard,
  StyledTitle,
  StyledText,
  ContentContainer,
  StyledImg,
} from "./styled";

export const CourseDetails = ({ classe }) => {
  const { data: certificate, isFetching: isGettingCertificate } =
    trpc.certificate.getCertificateById.useQuery(
      { _id: classe?.certificateId },
      {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: !!classe?.certificateId,
        onError: () => {
          dispatchToast({
            content:
              "Erro ao carregar as informações do certificado. Tente novamente em breve.",
            type: "error",
          });
        },
      }
    );

  return (
    <MainContainer>
      <Column>
        <ContentCard>
          <ContentContainer>
            <StyledTitle variant="body2" setColor="title" children="Detalhes" />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Data da criação"
            />
            <StyledText
              variant="body3"
              children={
                dayjs(classe?.createdAt).isValid()
                  ? `${dayjs(classe?.createdAt).format("DD/MM/YY")} às ${dayjs(
                      classe?.createdAt
                    ).format("HH:mm")}`
                  : " - "
              }
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Título do treinamento"
            />
            <StyledText variant="body3" children={classe?.name || " - "} />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Descrição"
            />
            <StyledText
              variant="body3"
              children={classe?.description || " - "}
            />
          </ContentContainer>
        </ContentCard>
        <ContentCard>
          <ContentContainer>
            <StyledTitle
              variant="body2"
              setColor="title"
              children="Personalização"
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Obrigatoriedade"
            />
            <StyledText
              variant="body3"
              children={classe?.mandatory ? "Obrigatório" : "Opcional"}
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Destaque"
            />
            <StyledText
              variant="body3"
              children={classe?.highlight ? "Habilitado" : "Não habilitado "}
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Imagem do treinamento"
            />
            <StyledText
              variant="body3"
              children={
                classe?.banner?.path ? (
                  <StyledImg
                    src={classe?.banner?.path + classe?.banner?.signature}
                  />
                ) : (
                  "Sem imagem anexada"
                )
              }
            />
          </ContentContainer>
        </ContentCard>
      </Column>
      <Column>
        <ContentCard>
          <ContentContainer>
            <StyledTitle
              variant="body2"
              setColor="title"
              children="Informações de acesso"
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Início em"
            />
            <StyledText
              variant="body3"
              children={
                dayjs(classe?.start).isValid()
                  ? `${dayjs(classe?.start).format("DD/MM/YY")}`
                  : " - "
              }
            />
          </ContentContainer>
          <ContentContainer>
            <StyledTitle
              variant="caption"
              setColor="subtitle"
              children="Disponível até"
            />
            <StyledText
              variant="body3"
              children={
                dayjs(classe?.end).isValid()
                  ? `${dayjs(classe?.end).format("DD/MM/YY")}`
                  : "Sempre disponível"
              }
            />
          </ContentContainer>
        </ContentCard>

        <ContentCard>
          <ContentContainer>
            <StyledTitle
              variant="body2"
              setColor="title"
              children="Certificado"
            />
          </ContentContainer>
          {classe.certificateId ? (
            <>
              <ContentContainer>
                <StyledTitle
                  variant="caption"
                  setColor="subtitle"
                  children="Nome de identificação do certificado"
                />
                <StyledText
                  variant="body3"
                  children={certificate?.name || " - "}
                />
              </ContentContainer>
              <ContentContainer>
                <CertificatePreview
                  data={{
                    courseName: classe?.name,
                    companyLogo: certificate?.companyLogo,
                    companyName: certificate?.companyName,
                    signText: certificate?.signText,
                    signerName: certificate?.signerName,
                    signerPostion: certificate?.signerPostion,
                    expiresDate: certificate?.expiresDate,
                    totalHours: classe?.totalHours,
                  }}
                />
              </ContentContainer>
            </>
          ) : (
            <ContentContainer>
              <StyledText variant="body3" children={"Sem certificado"} />
            </ContentContainer>
          )}
        </ContentCard>
      </Column>
    </MainContainer>
  );
};

import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledModal,
  StyledIcon,
  StyledIconContainer,
  StyledTitle,
  StyledContainer,
  StyledButtonContainer,
  StyledFlexBetween,
  StyledIconButton,
  SubTitle,
} from "./styled";

export const DraftCourseModal = ({ open, onClose }) => {
  return (
    <StyledModal
      onClose={onClose}
      open={open}
      icon={
        <StyledIconContainer>
          <StyledIcon size={48} name="IconMoodHappy" fill="transparent" />
        </StyledIconContainer>
      }
    >
      <>
        <StyledFlexBetween>
          <StyledTitle variant="headline7">Pronto!</StyledTitle>
          <StyledIconButton
            size="small"
            variant="line"
            icon="IconX"
            onClick={onClose}
          />
        </StyledFlexBetween>
        <StyledContainer>
          <SubTitle variant="headline6">
            Rascunho do treinamento salvo com sucesso! 😄
          </SubTitle>
          <StyledButtonContainer>
            <Button size="large" variant="primary" onClick={onClose}>
              Voltar para Gerenciar Treinamentos
            </Button>
          </StyledButtonContainer>
        </StyledContainer>
      </>
    </StyledModal>
  );
};

import { useRef, useState, useEffect } from "react";
import { IconButton, Icons, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";

import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

import {
  Container,
  StyledPdfHeaderContainer,
  CanvasContainer,
  ExpandButton,
} from "./styles";

interface PDFVisualizerProps {
  path: string;
  style?: any;
  headerStyle?: any;
  isExpanded?: any;
  setIsExpanded?: any;
  onClose?: any;
}

const useFetchPDF = (url: string) => {
  const [state, setState] = useState({
    data: [],
    error: "",
    loading: url ? true : false,
  }) as any;

  useEffect(() => {
    if (!url) {
      setState({ error: "", data: [], loading: false });
      return;
    }

    const fetchPDF = async () => {
      try {
        pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

        const pdf = await pdfjsLib.getDocument(url).promise;

        const promises = Array(pdf.numPages)
          .fill(0)
          .map(async (_, index) => {
            const page = await pdf.getPage(index + 1);
            return page;
          });

        const pages = await Promise.all(promises);

        setState({ error: "", data: pages, loading: false });
      } catch (err: any) {
        setState({ error: err.message, data: [], loading: false });
      }
    };

    fetchPDF();
  }, [url]);

  return { data: state.data, loading: state.loading, error: state.error };
};

export const PDFVisualizer = ({
  path,
  isExpanded,
  setIsExpanded,
  onClose,
  headerStyle = {},
  style = {},
}: PDFVisualizerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<any>(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [pageIndex, setPageIndex] = useState(-1);
  const [pages, setPages] = useState<any>([]);

  const [scale, setScale] = useState({
    hasBeenModified: false,
    value: 1,
  });

  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    if (pageIndex + 1 == pages.length) {
      setCurrentPage(pages[pageIndex]);
      return;
    }

    if (pages.length && pageIndex >= 0) {
      setCurrentPage(pages[pageIndex]);
      return;
    }
  }, [pages, pageIndex]);

  useEffect(() => {
    const renderPage = (p: any) => {
      const _page = p;

      if (_page && containerRef?.current && canvasRef?.current) {
        const context = canvasRef.current?.getContext("2d");
        let viewport = _page.getViewport({ scale: 1 });
        const calculatedScale =
          containerRef?.current?.clientWidth / viewport?.width;

        if (calculatedScale > 0 && !scale?.hasBeenModified) {
          setScale({ hasBeenModified: true, value: calculatedScale });
          return;
        }

        viewport = _page.getViewport({
          scale: scale?.hasBeenModified ? scale?.value : calculatedScale || 1,
        });

        setWidth(viewport.width);
        setHeight(viewport.height);

        if (context) {
          _page.render({
            canvasContext: canvasRef.current?.getContext("2d"),
            viewport,
          });
        }
      }
    };

    if (currentPage !== null) {
      renderPage(currentPage);
    }
  }, [currentPage, scale]);

  const { data, loading } = useFetchPDF(path);

  useEffect(() => {
    if (!data.length && !pages.length) return;

    setPages(data);
    setPageIndex(data?.length ? 0 : -1);
  }, [data]);

  return (
    <Container style={style}>
      <StyledPdfHeaderContainer style={headerStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconZoomOut"
            onClick={() =>
              setScale({
                hasBeenModified: true,
                value: scale?.value / 2,
              })
            }
            style={{ marginRight: "16px" }}
            disabled={loading || scale?.value / 2 < 0.25}
          />

          <IconButton
            size="small"
            variant="line"
            icon="IconZoomIn"
            style={{ marginRight: "16px" }}
            onClick={() =>
              setScale({
                hasBeenModified: true,
                value: scale?.value * 2,
              })
            }
            disabled={loading}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronLeft"
            disabled={pageIndex - 1 < 0 || loading}
            onClick={() => {
              setCurrentPage(pages[pageIndex - 1]);
              setPageIndex(pageIndex - 1);
            }}
          />
          <span style={{ marginRight: "16px", marginLeft: "16px" }}>
            {pageIndex + 1}/{pages.length}
          </span>
          <IconButton
            size="small"
            variant="line"
            icon="IconChevronRight"
            disabled={pageIndex + 2 > pages.length || loading}
            onClick={() => {
              setCurrentPage(pages[pageIndex + 1]);
              setPageIndex(pageIndex + 1);
            }}
          />
        </div>

        {setIsExpanded ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {isExpanded ? (
              <IconButton
                size="small"
                variant="line"
                icon="IconX"
                onClick={onClose}
                style={{ marginRight: "16px" }}
              />
            ) : (
              <ExpandButton onClick={() => setIsExpanded(true)}>
                <Icons name="IconArrowsDiagonal" />
                <p>Expandir PDF</p>
              </ExpandButton>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              visibility: "hidden",
              pointerEvents: "none",
            }}
          >
            <IconButton
              size="small"
              variant="line"
              icon="IconZoomOut"
              style={{ marginRight: "16px" }}
            />

            <IconButton
              size="small"
              variant="line"
              icon="IconZoomIn"
              style={{ marginRight: "16px" }}
            />
          </div>
        )}
      </StyledPdfHeaderContainer>
      {loading ? (
        <Skeleton variant="rounded" height={"100%"} />
      ) : (
        <CanvasContainer>
          <div
            style={{ position: "relative", width: "100%", height: "100%" }}
            ref={containerRef}
          >
            <canvas ref={canvasRef} width={width} height={height} />
          </div>
        </CanvasContainer>
      )}
    </Container>
  );
};

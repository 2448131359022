import { useMemo } from "react";

import { CertificateTab } from "../CertificateTab";

import { Tab } from "@flash-tecnologia/hros-web-ui-v2";

export const Content = () => {
  const tabItems = useMemo(() => {
    const items = [
      {
        label: "Certificados",
        component: <CertificateTab />,
      },
    ];

    return items;
  }, []);

  return (
    <>
      <Tab tabItens={tabItems} />
    </>
  );
};

import styled from "styled-components";

import {
  Button,
  IconButton,
  Icons,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

import { Modal } from "@components/Modal";

export const StyledModal = styled(Modal)`
  .modal-container {
    min-width: 60% !important;
    max-width: 60% !important;
  }
  .modal-content-area {
    padding: 0 !important;
  }
`;

export const ModalTitle = styled(Typography)`
  font-weight: bold !important;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const IconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.feedback.error[90]};
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const WarnIcon = styled(Icons)`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const Title = styled(Typography)`
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: ${({ theme }) => theme.colors.neutral[10]};
  margin: 4px 0 16px;
  text-align: center;
`;

export const SubTitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const ModalBody = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 0 112px;
  margin-bottom: 44px;
`;

export const ConfirmButton = styled(Button)`
  background-color: ${({ theme }) =>
    theme.colors.feedback.error[90]} !important;
  color: ${({ theme }) => theme.colors.feedback.error[40]} !important;
  &:hover {
    box-shadow: 0 16px 40px ${({ theme }) => theme.colors.feedback.error[90]} !important;
  }
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 1;
`;

export const ModalFooterContainer = styled.div`
  padding: 0 112px 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    align-self: center !important;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 112px 0;
`;

import styled from "styled-components";
import { Grid } from "@mui/material";

import {
  Typography,
  PageContainer,
  DatePicker,
  Icons,
  SelectField,
  BoxAdornment,
} from "@flash-tecnologia/hros-web-ui-v2";

export const StyledPageContainer = styled(PageContainer)`
  flex: 1;
  height: 100%;
  overflow: auto;
`;

export const StyledContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const StyledTypography = styled(Typography)``;

export const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  font-weigth: 600;
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`;

export const StyledFlex = styled.div`
  display: flex;
`;

export const StyledGrid = styled(Grid)``;

export const StyledDatePicker = styled(DatePicker)``;

export const StyledHeaderEditor = styled.div`
  padding: 8px 10px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: ${({ theme }) => theme.colors.neutral.light.light1};

  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px 8px 0px 0px;
`;

export const StyledBreadcrumbSeparator = styled(Icons)`
  stroke: ${({ theme }) => theme.colors.neutral.dark.dark5};
  fill: transparent;
`;

export const StyledPageHeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionArea = styled.div`
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const OptionTitleArea = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  p {
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
    font-weight: 700;
    margin-right: 4px;
  }
  svg {
    stroke: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
`;

export const OptionContentArea = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
  p {
    margin-left: 20px;
  }
  p:first-child {
    font-weight: 700;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
  p:last-child {
    color: ${({ theme }) => theme.colors.neutral.dark.dark5};
  }
`;

export const RightQuestionsArea = styled.div`
  margin-top: 25px;
`;

export const Select = styled(SelectField)`
  margin-top: 10px;
`;

export const SelectArea = styled.div`
  width: 100%;
  p {
    margin-left: 0;
  }
  .select-field-popper-custom {
    z-index: 0 !important;
  }
`;

export const TextArea = styled.div`
  p:first-child {
    font-weight: 700;
    margin-bottom: 2px;
    color: ${({ theme }) => theme.colors.neutral.dark.dark3};
  }
  p:last-child {
    color: ${({ theme }) => theme.colors.neutral.dark.dark5} !important;
  }
`;

export const StyledSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.light.light2};
  border-radius: 8px;
`;

export const AccordionTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
`;

export const AccordionSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const StyledBoxAdornment = styled(BoxAdornment)`
  width: 100% !important;
  p.typography--variant-body3 {
    color: ${({ theme }) => theme.colors.neutral[30]};
  }
  p.typography--variant-body4 {
    color: ${({ theme }) => theme.colors.neutral[50]};
  }
`;

export const Container = styled.div`
  margin: 40px 0px;
`;

export const StepperArea = styled.div`
  margin-bottom: 40px;
`;

export const PageTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  font-weight: 700;
  margin-bottom: 56px;
`;

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { GenerateReportModal } from "@components/GenerateReportModal";
import { ReplicateCourseModal } from "@components/Modals/ReplicateCourseModal";
import { WarningDeleteModal } from "../../Modal/WarningDeleteModal";
import { ConfirmModal } from "../../Modal/ConfirmModal";

import {
  DataGrid,
  dayjs,
  Icons,
  Menu,
  Pagination,
} from "@flash-tecnologia/hros-web-ui-v2";
import {
  StyledText,
  StyledTitle,
  dispatchToast,
  pageSizeOptions,
  track,
} from "@utils";

import {
  StyledFlexRow,
  StyledFlexColumn,
  TitleCardContainer,
  StyledDot,
  StyledIconButtonExtraSmall,
  StyledImageContainer,
  StyledTag,
  PaginationArea,
  ItensPerPageArea,
  StyledSelectField,
  PaginationContainer,
  StyledMenuItem,
  StyledTypography,
  StyledFlex,
  SubImageIcon,
} from "./styled";

import { trpc } from "@api/client";

interface TableGridProps {
  data: any[];
  rawData: any[];
  page: number;
  setPage: any;
  pageSize: number;
  setPageSize: any;
  total: number;
  loading: boolean;
}

export const CourseTable = ({
  data,
  rawData,
  loading,
  total,
  page,
  setPage,
  pageSize,
  setPageSize,
}: TableGridProps) => {
  const utils = trpc.useContext();

  const [id, setId] = useState("");

  const [reportModal, setReportModal] = useState<any>(false);
  const [confirmModal, setConfirmModal] = useState<any>(false);
  const [warningDeleteModal, setWarningDeleteModal] = useState<any>(false);
  const [replicateModal, setReplicateModal] = useState(false);

  const navigate = useNavigate();

  const { mutate: duplicateCourseByIdMutate } =
    trpc.course.duplicateCourseById.useMutation({});

  const columns = [
    {
      Header: "Treinamento",
      accessor: "name",
      disableSortBy: true,
      Cell: ({
        row: {
          original: {
            name = null,
            banner = {} as any,
            modulesTotal = 0,
            classesTotal = null,
          },
        },
      }) => (
        <StyledFlexRow>
          <div>
            <StyledImageContainer
              url={banner?.path ? `${banner?.path}${banner?.signature}` : ""}
            />
          </div>
          <StyledFlexColumn>
            <TitleCardContainer>
              <StyledTitle variant="body3" tag="p">
                {name}
              </StyledTitle>
            </TitleCardContainer>

            <StyledFlexRow>
              <StyledIconButtonExtraSmall
                size="small"
                variant="line"
                icon="IconSchool"
                style={{ alignSelf: "center" }}
              />
              <StyledText variant="body3" tag="span">
                <StyledFlexRow>
                  {modulesTotal} módulo{modulesTotal !== 1 && "s"} <StyledDot />{" "}
                  {classesTotal} aula
                  {classesTotal !== 1 && "s"}
                </StyledFlexRow>
              </StyledText>
            </StyledFlexRow>
          </StyledFlexColumn>
        </StyledFlexRow>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ value }) => {
        let tag = <></>;

        switch (value) {
          case "beforeInit":
            tag = (
              <StyledTag
                variant="primary"
                style={{ backgroundColor: "#D4E8FF", color: "#00244C" }}
              >
                Em breve
              </StyledTag>
            );
            break;
          case "finished":
            tag = (
              <StyledTag
                variant="primary"
                style={{ backgroundColor: "#EBE6E9", color: "#83727D" }}
              >
                Encerrado
              </StyledTag>
            );
            break;
          case "draft":
            tag = (
              <StyledTag
                variant="primary"
                style={{ backgroundColor: "#FFECD6", color: "#4C2900" }}
              >
                Rascunho
              </StyledTag>
            );
            break;
          default:
            tag = (
              <StyledTag
                variant="primary"
                style={{ backgroundColor: "#D7F9F3", color: "#094338" }}
              >
                Disponível
              </StyledTag>
            );
            break;
        }

        return <StyledFlexRow>{tag}</StyledFlexRow>;
      },
    },
    {
      Header: "Compartilhamento",
      accessor: "isShared",
      disableSortBy: true,
      Cell: ({ value }) => (
        <StyledText variant="body3">{value ? "Sim" : "Não"}</StyledText>
      ),
    },
    {
      Header: "Alunos",
      accessor: "peopleTotal",
      disableSortBy: true,
      Cell: ({ value }) => <StyledText variant="body3">{value}</StyledText>,
    },
    {
      Header: "Emissão de certificado",
      accessor: "certificateId",
      disableSortBy: true,
      Cell: ({ value }) => (
        <StyledTag variant={value ? "primary" : "secondary"}>
          {value ? "Sim" : "Não"}
        </StyledTag>
      ),
    },
    {
      Header: "Início em",
      accessor: "start",
      disableSortBy: true,
      Cell: ({ value = null }) => (
        <StyledText variant="body3">
          {value ? dayjs(value).format("DD/MM/YYYY") : "Não informado"}
        </StyledText>
      ),
    },
    {
      Header: "Disponível até",
      accessor: "end",
      disableSortBy: true,
      Cell: ({ value = null }) => (
        <StyledText variant="body3">
          {value ? dayjs(value).format("DD/MM/YYYY") : "Sempre disponível"}
        </StyledText>
      ),
    },
    {
      Header: "",
      accessor: "actions",
      sticky: "right",
      disableSortBy: true,
      Cell: ({
        row: {
          original: { _id = "", classesTotal = 0, isShared = false, status },
        },
      }) => {
        const editOption = {
          onClick: (e) => {
            if (isShared) {
              setId(_id);
              setReplicateModal(true);
              return;
            }
            navigate(`/lms/manage-courses/createEditCourse/edit/0/${_id}`);
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconPencil"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">Editar treinamento</StyledText>
            </StyledFlex>
          ),
        };

        const exportOption = {
          onClick: (e) => {
            track({
              name: "company_lms_home_exportreport_clicked",
            });

            setId(_id);
            setReportModal(true);
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconDownload"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">
                Exportar relatório do treinamento
              </StyledText>
            </StyledFlex>
          ),
        };

        const dupOption = {
          onClick: (e) => {
            duplicateCourseByIdMutate(
              {
                courseId: _id,
              },
              {
                onSuccess: () => {
                  dispatchToast({
                    content:
                      "O treinamento foi duplicado com sucesso e a cópia está disponível como rascunho.",
                    type: "success",
                  });
                  utils.course.getManageCourses.refetch();
                },
              }
            );
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconCopy"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">Duplicar treinamento</StyledText>
            </StyledFlex>
          ),
        };

        const deleteOption = {
          onClick: (e) => {
            setWarningDeleteModal(true);
            setId(_id);
            track({
              name: "company_lms_home_deletetraining_clicked",
            });
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconTrash"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">Excluir treinamento</StyledText>
            </StyledFlex>
          ),
        };

        const previewOption = {
          onClick: (e) => {
            if (classesTotal === 0) {
              dispatchToast({
                content: "Esse treinamento não contem aulas",
                type: "error",
              });
              return;
            }
            window.open(`/lms/manage-courses/${_id}/0/class/preview`, "_blank");
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconFileSearch"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">
                Pré-visualização do treinamento
              </StyledText>
            </StyledFlex>
          ),
        };

        const studentOption = {
          onClick: (e) => {
            track({
              name: "company_lms_home_addstudents_clicked",
            });
            navigate(`/lms/manage-courses/createEditCourse/edit/3/${_id}`);
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconUsers"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">Adicionar alunos</StyledText>
            </StyledFlex>
          ),
        };

        const detailsOption = {
          onClick: (e: any) => {
            navigate(`/lms/manage-courses/detail-course/${_id}`);
          },
          children: (
            <StyledFlex>
              <Icons
                name="IconFileDescription"
                fill="transparent"
                style={{ marginRight: "8px" }}
              />
              <StyledText variant="body3">
                Ver detalhes do treinamento
              </StyledText>
            </StyledFlex>
          ),
        };

        const options =
          status == "available"
            ? [
                detailsOption,
                previewOption,
                editOption,
                exportOption,
                studentOption,
                dupOption,
              ]
            : status == "finished"
            ? [
                detailsOption,
                previewOption,
                editOption,
                exportOption,
                dupOption,
                deleteOption,
              ]
            : [
                previewOption,
                editOption,
                studentOption,
                dupOption,
                deleteOption,
              ];

        return (
          <>
            <Menu
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              type="select"
              options={options}
            >
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "24px",
                  paddingTop: "24px",
                }}
              >
                <SubImageIcon
                  hasNoImage={true}
                  size="small"
                  variant="filled"
                  icon="IconDotsVertical"
                />
              </div>
            </Menu>
          </>
        );
      },
    },
  ];

  const totalPages = Math.ceil(total / pageSize);

  return (
    <>
      <DataGrid
        hasPagination={true}
        columns={columns}
        data={data || []}
        loading={loading}
        initialState={{ pageSize: Number(pageSize) }}
        emptyState={{
          isFiltered: true,
          message: "Nenhum treinamento encontrado.",
        }}
        customPagination={({ gotoPage, setPageSize: setComponentPageSize }) => (
          <PaginationArea>
            <ItensPerPageArea>
              <StyledSelectField
                value={pageSize ?? 5}
                onChange={(e) => {
                  const newPageSize = Number(e?.target?.value);

                  setPageSize(newPageSize);
                }}
              >
                {pageSizeOptions?.map((opt) => (
                  <StyledMenuItem key={opt?.value} value={opt?.value}>
                    {opt?.label}
                  </StyledMenuItem>
                ))}
              </StyledSelectField>
              <StyledTypography variant="body3" style={{ fontWeight: 700 }}>
                Resultados:&nbsp;
                {page === 1 ? 1 : page * pageSize - pageSize + 1}-
                {page * pageSize > total ? total : page * pageSize}
                &nbsp;de&nbsp;{total || 0}&nbsp;
                {total === 1 ? "item" : "itens"}
              </StyledTypography>
            </ItensPerPageArea>
            <PaginationContainer>
              <Pagination
                defaultPage={1}
                page={page}
                count={totalPages}
                onChange={(_, value) => {
                  const currentPage = value;

                  setPage(currentPage);
                }}
              />
            </PaginationContainer>
          </PaginationArea>
        )}
      />
      <WarningDeleteModal
        type={"course"}
        _id={id}
        open={warningDeleteModal}
        setOpen={setWarningDeleteModal}
        setConfirmModal={setConfirmModal}
      />
      <ConfirmModal
        type={"course"}
        open={confirmModal}
        setOpen={setConfirmModal}
      />

      <GenerateReportModal
        open={reportModal}
        courseId={id}
        onClose={() => setReportModal(false)}
      />
      <ReplicateCourseModal
        open={replicateModal}
        onClose={() => setReplicateModal(false)}
        handleClick={() =>
          navigate(`/lms/manage-courses/createEditCourse/edit/0/${id}`)
        }
      />
    </>
  );
};

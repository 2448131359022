import "./styles.css";

import { Time } from "@vidstack/react";

export const TimeGroup = () => {
  return (
    <div className={"group"}>
      <Time className={"time"} type="current" />
      <div className={"divider"}>/</div>
      <Time className={"time"} type="duration" />
    </div>
  );
};

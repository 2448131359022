import {
  Typography,
  Divider,
  Icons,
  Button,
} from "@flash-tecnologia/hros-web-ui-v2";
import { useTheme } from "styled-components";

import { DataCard } from "../../DataCard";

import { Row, FirstColumn, SecondColumn, NumberCircle } from "./styled";

interface CourseContentTabProps {
  data: any;
  onNavigate: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ModulesData = ({
  modules,
  courseId,
}: {
  modules: {
    name: string;
    classes: {
      name: string;
      type: "video" | "podcast" | "pdf" | "article" | "exam" | "certificate";
    }[];
  }[];
  courseId: string | undefined;
}) => {
  const theme = useTheme();

  const iconByType = {
    ["video"]: "IconVideo",
    ["podcast"]: "IconMicrophone",
    ["pdf"]: "IconFile",
    ["exam"]: "IconListNumbers",
    ["certificate"]: "IconCertificate",
    ["article"]: "IconBlockquote",
  };

  const labelByType = {
    ["video"]: "Vídeo",
    ["podcast"]: "Podcast",
    ["pdf"]: "PDF",
    ["exam"]: "Avaliação",
    ["certificate"]: "Certificado",
    ["article"]: "Artigo",
  };

  const hasClasses = !!modules.some(
    (module) => (module?.classes || []).some((clasz) => clasz?.type) && courseId
  );

  return (
    <div>
      {modules.map((module, index) => {
        const { name, classes } = module;
        const isNotLastModule = index + 1 !== modules.length;

        return (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                marginBottom: "6px",
              }}
            >
              <Typography
                variant="headline8"
                variantColor={theme.colors.neutral[30]}
              >
                {name}
              </Typography>
              <Row>
                <FirstColumn>
                  <Typography
                    variant="headline9"
                    variantColor={theme.colors.neutral[30]}
                  >
                    Aulas
                  </Typography>
                </FirstColumn>
                <SecondColumn>
                  <Typography
                    variant="headline9"
                    variantColor={theme.colors.neutral[30]}
                  >
                    Conteúdo
                  </Typography>
                </SecondColumn>
              </Row>
            </div>

            {classes.map((moduleClass, index) => {
              const { name, type } = moduleClass;
              const isLastClass = index + 1 === classes.length;

              return (
                <Row
                  style={{
                    padding: "16px 0px",
                    borderBottom: !isLastClass
                      ? `1px solid ${theme.colors.neutral[90]}`
                      : "none",
                    gap: "4px",
                  }}
                >
                  <FirstColumn>
                    <Row style={{ gap: "6px" }}>
                      <NumberCircle>
                        <Typography
                          variant="caption"
                          variantColor={theme.colors.neutral[50]}
                          style={{ fontWeight: 600 }}
                        >
                          {index + 1}
                        </Typography>
                      </NumberCircle>
                      <Typography
                        variant="body3"
                        style={{ fontWeight: 600 }}
                        variantColor={theme.colors.neutral[40]}
                      >
                        {name}
                      </Typography>
                    </Row>
                  </FirstColumn>
                  <SecondColumn
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <Icons
                      size={24}
                      name={(iconByType[type] as any) || "IconCircle"}
                      color={theme.colors.neutral[30]}
                    />
                    <Typography
                      variant="body3"
                      style={{ fontWeight: 600 }}
                      variantColor={theme.colors.neutral[30]}
                    >
                      {labelByType[type] || type}
                    </Typography>
                  </SecondColumn>
                </Row>
              );
            })}

            {isNotLastModule && (
              <div style={{ margin: "32px 0px" }}>
                <Divider orientation="horizontal" />
              </div>
            )}
          </div>
        );
      })}

      {hasClasses && (
        <Button
          onClick={() =>
            window.open(
              `/lms/manage-courses/${courseId}/0/class/preview`,
              "_blank"
            )
          }
          size="medium"
          variant="secondary"
          style={{ width: "100%", marginTop: "40px" }}
          children={
            <Row style={{ justifyContent: "center", gap: "8px" }}>
              Pré-visualizar treinamento em nova janela
              <Icons name="IconExternalLink" />
            </Row>
          }
        />
      )}
    </div>
  );
};

export const CourseContentTab = ({
  data,
  onNavigate,
}: CourseContentTabProps) => {
  const modules = data?.modules || [];

  const modulesMapped = modules.map((module) => {
    return {
      _id: module._id,
      name: module?.name || "",
      classes: module?.classes?.map((clasz) => {
        const { learningObject, _id, name } = clasz;

        return {
          _id,
          name: name + " | " + learningObject?.name,
          type: learningObject?.type,
        };
      }),
    };
  });

  return (
    <DataCard
      title="Revisar conteúdo do treinamento"
      subTitle="Confira o conteúdo do seu treinamento."
      onEditClick={onNavigate}
      editable
    >
      <ModulesData modules={modulesMapped || []} courseId={data?._id} />
    </DataCard>
  );
};
